import React, { useState, useEffect, useRef } from "react";
import styles from './Participant.module.css';
import Video from 'twilio-video';
import { UserPublicData } from "../../models/UserModel";
import { ParticipantData } from "./VideoRoom";
import { AudioMutedOutlined, AudioOutlined, VideoCameraOutlined, MessageTwoTone } from '@ant-design/icons';
import { Avatar, notification } from "antd";


interface Props {
    participant?: ParticipantData,
    isSelf?: boolean;
    onToggleAudio?: (enabled: boolean) => void;
    onToggleVideo?: (enabled: boolean) => void;
}

function Participant({ participant, isSelf, onToggleAudio, onToggleVideo }: Props) {
    const [videoTracks, setVideoTracks] = useState(Array<Video.VideoTrack>());
    const [audioTracks, setAudioTracks] = useState(Array<Video.AudioTrack>());
    const [audioEnabled, setAudioEnabled] = useState(true);
    const [videoEnabled, setVideoEnabled] = useState(true);

    const videoRef = useRef<any>();
    const audioRef = useRef<any>();

    useEffect(() => {
        if (!participant) {
            return;
        }

        setVideoTracks(trackpubsToTracks(participant.current.videoTracks));
        setAudioTracks(trackpubsToTracks(participant.current.audioTracks));

        participant.current.on("trackSubscribed", trackSubscribed);
        participant.current.on("trackUnsubscribed", trackUnsubscribed);

        return () => {
            setVideoTracks([]);
            setAudioTracks([]);
            participant.current.removeAllListeners();
        };
    }, [participant]);

    useEffect(() => {
        const videoTrack = videoTracks[0];
        if (videoTrack) {
            videoTrack.attach(videoRef.current);
            return () => {
                videoTrack.detach();
            };
        }
    }, [videoTracks]);

    useEffect(() => {
        const audioTrack = audioTracks[0];
        if (audioTrack) {
            audioTrack.attach(audioRef.current);
            return () => {
                audioTrack.detach();
            };
        }
    }, [audioTracks]);


    const handleTrackDisabled = (track: Video.RemoteTrack) => {
        track.on('disabled', () => {
            if (track.kind === 'video') {
                setVideoEnabled(false)
            }
            if (track.kind === 'audio') {
                setAudioEnabled(false)
            }
        });
        track.on('enabled', () => {
            if (track.kind === 'video') {
                setVideoEnabled(true)
            }
            if (track.kind === 'audio') {
                setAudioEnabled(true)
            }
        });
    }

    const trackpubsToTracks = (trackMap: Map<string, any>) =>
        Array.from(trackMap.values())
            .map((publication) => publication.track)
            .filter((track) => track !== null);


    const trackSubscribed = (track: Video.LocalTrack) => {
        const remote = participant?.current as Video.RemoteParticipant;
        if (remote) {
            remote.tracks.forEach(publication => {
                if (publication.isSubscribed && publication.track) {
                    handleTrackDisabled(publication.track);
                }

                publication.on('subscribed', handleTrackDisabled);
            });
        }

        if (track.kind === "video") {
            setVideoTracks((videoTracks) => [...videoTracks, track]);
        } else if (track.kind === "audio") {
            setAudioTracks((audioTracks) => [...audioTracks, track]);
        } else if (track.kind === 'data') {
            track.on('message', data => {
                notification.info({
                    key: data,
                    message: <b>{participant?.info?.firstName}:</b>,
                    duration: 15,
                    placement: 'bottomLeft',
                    description: data,
                    icon: <MessageTwoTone twoToneColor={'#1CBEC0'} />,
                    onClick: () => {
                        notification.close(data);
                    },
                });
            });
        }
    };

    const trackUnsubscribed = (track: Video.LocalTrack) => {
        if (track.kind === "video") {
            setVideoTracks((videoTracks) => videoTracks.filter((v) => v !== track));
        } else if (track.kind === "audio") {
            setAudioTracks((audioTracks) => audioTracks.filter((a) => a !== track));
        }
    };


    const toggleAudio = () => {
        onToggleAudio && onToggleAudio(audioEnabled);
        setAudioEnabled(!audioEnabled);
    }

    const toggleVideo = () => {
        onToggleVideo && onToggleVideo(videoEnabled);
        setVideoEnabled(!videoEnabled);
    }

    return (
        <div className={styles.container}>
            <div className={`${styles.videoContainer} ${isSelf ? styles.local : styles.other}`}>
                <video className={styles.video} ref={videoRef} style={{ display: videoEnabled ? 'initial' : 'none' }} autoPlay={true} />
                <div className={styles.cameraOffContainer} style={{ display: !videoEnabled ? 'flex' : 'none' }}>
                    <Avatar src={participant?.info?.photoUrl} size={100} className={styles.avatar} />
                </div>

                <div className={styles.videoOverlay} />
                <div className={styles.userInfo}>
                    <Avatar src={participant?.info?.company?.logoUrl} size='large' />
                    <div className={styles.texts}>
                        <div className={styles.participantName}>{participant?.info?.firstName} {participant?.info?.lastName}</div>
                        <div className={styles.participantTitle}>{participant?.info?.role} at {participant?.info?.company?.name}</div>
                    </div>
                    <div className={styles.controlButtons}>
                        <div onClick={isSelf ? toggleAudio : () => { }} className={styles.controlButton}>
                            {audioEnabled ? <AudioOutlined style={{ color: '#06c2a6' }} /> : <AudioMutedOutlined style={{ color: '#ee1949' }} />}
                        </div>
                        <div onClick={isSelf ? toggleVideo : () => { }} className={styles.controlButton}>
                            {videoEnabled ? <VideoCameraOutlined style={{ color: '#06c2a6' }} /> : <VideoCameraOutlined style={{ color: '#ee1949' }} />}
                        </div>
                    </div>
                </div>
            </div>
            {!isSelf && <audio ref={audioRef} autoPlay={true} muted={isSelf || !audioEnabled} />}
        </div>
    );
};

export default Participant;