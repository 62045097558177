
export default class LocalStorage {
    static set(key: string, value: any) {
        const item: LocalStorageItem = {
            value: value,
        }

        window.localStorage.setItem(key, JSON.stringify(item))
    }

    static setWithExpiry(key: string, value: any, ttl: number) {
        const now = new Date()

        const item: LocalStorageItem = {
            value: value,
            expiry: now.getTime() + ttl,
        }

        window.localStorage.setItem(key, JSON.stringify(item))
    }

    static get(key: string) {
        const itemStr = window.localStorage.getItem(key)

        if (!itemStr) {
            return null
        }
        const item = JSON.parse(itemStr) as LocalStorageItem;

        if (!item.expiry) {
            return item.value;
        }

        const now = new Date()

        if (now.getTime() > item.expiry) {
            window.localStorage.removeItem(key)
            return null
        }
        
        return item.value
    }

    static remove(key: string) {
        window.localStorage.removeItem(key);
    }
}

type LocalStorageItem = {
    value: any,
    expiry?: number
}