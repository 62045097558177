import moment from "moment";
import React, { useEffect, useState } from "react";
import { EventTimerModel } from "../../../../../../../common/models/EventModel";
import styles from './EventTimer.module.css';

interface Props {
    timerModel?: EventTimerModel
}

function EventTimer ({ timerModel }: Props) {
    const [countdown, setCountdown] = useState({
        minutes: '',
        seconds: '',
    });

    const [finished, setFinished] = useState(false);
    const [countdownInterval, setCountdownInterval] = useState<NodeJS.Timeout>();

    useEffect(() => {
        console.log('timer changed')
        setFinished(false);
        clearTimer();
        updateCountdown();

        const interval = setInterval(() => {
            updateCountdown();
        }, 1000);

        setCountdownInterval(interval);

        return () => clearTimer();
    }, [timerModel]);

    useEffect(()=> {
        if(finished) {
            clearTimer();
        }
    },[finished])


    const updateCountdown = () => {
        const then = moment.unix(timerModel?.setTo || 0);
        const now = moment();

        if(now.unix() >= then.unix()) {
            console.log('old timer')
            setFinished(true);
            return;
        }

        const countdown = moment.unix(then.diff(now, 'seconds'));
        const minutes = countdown.format('mm');
        const seconds = countdown.format('ss');

        setCountdown({ minutes: minutes, seconds: seconds });
    }

    const clearTimer = () => {
        countdownInterval && clearInterval(countdownInterval)
        console.log('timer cleared')
    }

    if(!timerModel || finished) {
        return <></>
    }
    
    return <div className={styles.container}>
            <div className={styles.hideButton} onClick={() => setFinished(true)}>X</div>
            <div className={styles.label}>{timerModel?.label || 'Main session starts in...'}</div>
            <div className={styles.countdown}>{countdown.minutes}:{countdown.seconds}</div>
    </div>

}


export default EventTimer;