import React, { Component, useEffect, Fragment, Ref, RefObject, useState } from 'react';
import { useHistory, useLocation, withRouter } from 'react-router-dom';

interface Props {
    container: RefObject<HTMLDivElement>;
}

function ScrollToTop({ container }: Props) {
    const location = useLocation();
    const history = useHistory();
    const [previousLocation, setPreviousLocation] = useState(location.pathname.toString());
    const [currentLocation, setCurrentLocation] = useState(location.pathname.toString());
    const [prevScoll, setPrevScoll] = useState(0);

    useEffect(() => {
        console.log('scrollTop: ' + container.current?.scrollTop);

        if (history.action === 'PUSH' || history.action === 'REPLACE') {
            container?.current?.scrollIntoView();
            console.log('scrolled To top');
        }

        if(history.action === 'POP') {
            console.log('scroll To:' + prevScoll);
            container.current?.scrollIntoView();
            setPrevScoll(0);
        }

    }, [history.location])

    return <></>

}

export default ScrollToTop;