import { Button, Dropdown, Menu } from 'antd';
import { inject, observer } from 'mobx-react';
import { Typography } from 'antd';
import React, { useState } from 'react';
import UserAvatar from '../../../../../common/components/avatar/UserAvatar';
import UserModel, { CompanyInfoModel } from '../../../../../common/models/UserModel';
import styles from './CompanyInfo.module.css';
import { Link } from 'react-router-dom';

const { Paragraph } = Typography;

interface Props {
    companyModel?: CompanyInfoModel,
    onCompanyClicked?: (companyId?: string) => void,
    textColor?: string,
    hideLearnMore?: boolean,
}

function CompanyInfo({ companyModel, onCompanyClicked, textColor, hideLearnMore }: Props) {
    return (
        <div className={styles.container}>
            <div className={styles.company}>
                <img className={styles.image} src={companyModel?.logoUrl} />
                <div className={styles.description}>
                    <div className={styles.name} style={textColor ? { color: textColor } : {}}>
                        {companyModel?.name}
                    </div>
                    <Paragraph className={styles.description} style={textColor ? { color: textColor } : {}} ellipsis={{ rows: 2 }}>
                        {companyModel?.shortDescription}
                    </Paragraph>
                    {!hideLearnMore && <Button type="default"
                        size={'middle'}
                        onClick={() => onCompanyClicked && onCompanyClicked(companyModel?.slug)}
                    >
                        Learn More
                    </Button>
                    }
                </div>
            </div>
        </div>
    )
}

export default CompanyInfo;
