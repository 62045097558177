import { Upload, message, Avatar } from 'antd';
import { LoadingOutlined, PlusOutlined } from '@ant-design/icons';
import React, { useState } from 'react';
import { inject, observer } from 'mobx-react';
import FirebaseClient from '../../../../services/FirebaseClient';
import UserAvatar from '../../../../common/components/avatar/UserAvatar';
import styles from './ProfilePhoto.module.css'
import SessionStore from '../../../../store/SessionStore';
import { UserOutlined } from '@ant-design/icons';

function getBase64(img: any, callback: ((result: any) => void)) {
    const reader = new FileReader();
    reader.addEventListener('load', () => callback(reader.result));
    reader.readAsDataURL(img);
}

function beforeUpload(file: File) {
    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
    if (!isJpgOrPng) {
        message.error('You can only upload JPG/PNG file.');
    }
    const isLt2M = file.size / 1024 / 1024 < 3;
    if (!isLt2M) {
        message.error('Image must smaller than 3MB');
    }
    return isJpgOrPng && isLt2M;
}

interface Props {
    sessionStore?: SessionStore,
    initialImageUrl?: string,
}

function ProfilePhoto({ sessionStore, initialImageUrl }: Props) {
    const [loading, setLoading] = useState(false)
    const [imageUrl, setImageUrl] = useState(initialImageUrl)


    const handleChange = (info: any) => {
        if (info.file.status === 'uploading') {
            setLoading(true);
            return;
        }
        if (info.file.status === 'done') {
            // Get this url from response in real world.
            getBase64(info.file.originFileObj, imageUrl => {
                setLoading(false);
                setImageUrl(imageUrl);
            });
        }
    };

    const customUpload = async ({ onError, onSuccess, file }: any) => {
        try {
            const image = await sessionStore?.changeProfilePicture(file);
            onSuccess(null, image);
        } catch (e) {
            onError(e);
        }
    };


    return (
        <Upload
            showUploadList={false}
            customRequest={customUpload}
            beforeUpload={beforeUpload}
            onChange={handleChange}
        >

            <div className={styles.container}>
                { imageUrl ? 
                <div style={{ backgroundImage: `url("${imageUrl}")`}} className={styles.profileImage} />
                : <Avatar size={90} icon={<UserOutlined />} />
                }
                <div className={styles.changeButton}>
                   {imageUrl ? 'Change' : 'Upload' }
                </div>
            </div>


        </Upload>
    );

}

export default inject('sessionStore')(observer(ProfilePhoto));

