import React from "react";
import { isTablet, isWindows } from "react-device-detect";
// import { inject, observer } from "mobx-react";
import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import styles from './MeetingRoomSider.module.css';
import UserAvatar from "../../../../common/components/avatar/UserAvatar";
import { inject, observer } from "mobx-react";
import SessionStore from "../../../../store/SessionStore";
import MeetingStore from "../../../../store/MeetingStore";
import { Tooltip, Typography } from "antd";
import CompanyInfo from "../../../dashboard/componnents/userDrawer/companyInfo/CompanyInfo";
import { Link } from "react-router-dom";
import _ from "lodash";
import ViewStateStore from "../../../../store/ViewStateStore";
import Tag from "../../../../common/components/tag/Tag";
import externalLink from '../../../../common/images/external-link-white.svg'
import SmallLogoButton from "../../../../common/components/smallLogoButton/SmallLogoButton";
const flag = require('country-code-emoji');


interface Props {
    sessionStore?: SessionStore;
    meetingStore?: MeetingStore;
    viewStateStore?: ViewStateStore;
    siderCollapsed: boolean;
    setSiderCollapsed: (value: boolean) => void;
}

function MeetingRoomSider({ setSiderCollapsed, siderCollapsed, sessionStore, meetingStore, viewStateStore }: Props) {
    const { authUser } = sessionStore!;
    const { otherParticipants } = meetingStore!;
    const { primaryColor, primaryColorContrastLight } = viewStateStore!;

    const otherParticipant = otherParticipants && otherParticipants[0];

    const onCompanyClicked = (companySlug?: string) => {
        window.open(`/company/${companySlug}`, '_blank');
    }

    return (
        <div className={styles.container}>
            <div className={styles.topHeader}>
                {!siderCollapsed &&
                    <div className={styles.headerTitle}>Meet your partner 👋</div>
                }
                <SmallLogoButton className={styles.smallLogoButton} />
            </div>
            { !siderCollapsed &&
                <div className={styles.participantsInfo}>
                    <div className={styles.userBasicInfo}>
                        <UserAvatar
                            size={70}
                            className={styles.userAvatar}
                            userImage={otherParticipant?.user?.photoUrl}
                            companyImage={otherParticipant?.user?.company?.logoUrl} />
                        <div className={styles.userBasicInfoTexts}>
                            <div className={styles.userName}>{otherParticipant?.user?.firstName} {otherParticipant?.user?.lastName}</div>
                            <div className={styles.title}>
                                {
                                    otherParticipant?.user?.company && otherParticipant?.user?.company.id ?
                                        <div>
                                            <span>{otherParticipant?.user?.role} @ </span>
                                            <Tooltip title={<span> <img src={externalLink} style={{ width: 12, color: 'white', marginRight: 2 }} /> Learn more</span>}>
                                                <span onClick={() => onCompanyClicked(otherParticipant?.user?.company?.slug)} className={styles.linkedCompany}>{otherParticipant?.user?.company?.name}</span>
                                            </Tooltip>
                                        </div>
                                        :
                                        <div>
                                            <span>{otherParticipant?.user?.role}</span>
                                        </div>
                                }
                            </div>
                        </div>
                    </div>
                    <Typography.Paragraph ellipsis={{ rows: 7, expandable: true }}
                        className={styles.bio}>
                        {otherParticipant?.user?.bio}
                    </Typography.Paragraph>
                    {otherParticipant?.user?.sectors &&
                        <div className={styles.interests}>
                            <div className={styles.subHeader}>Interests</div>
                            <div className={styles.industryTags}>

                                {_.take(otherParticipant?.user?.sectors, 8).map(i => <Tag style={{ fontSize: 12 }} key={i}>{i}</Tag>)}
                            </div>
                        </div>
                    }
                    {otherParticipant?.user?.location &&
                        <div className={styles.location}>
                            <div className={styles.subHeader}>Location</div>
                            <div>
                                {!isWindows ?
                                    <span>
                                        {flag(otherParticipant.user.location.countryCode)}
                                    </span>
                                    :
                                    <span>
                                        {otherParticipant.user.location.flagIcon && <img src={otherParticipant.user.location.flagIcon} />}
                                    </span>
                                }
                                <span style={{ marginLeft: 4 }}>{otherParticipant.user.location.country}</span>
                            </div>
                        </div>
                    }
                    {otherParticipant?.user?.company && otherParticipant?.user?.company.id &&
                        <div className={styles.company}>
                            <div />
                            <Link to={`/company/${otherParticipant?.user?.company.slug}`} target='_blank'>
                                <CompanyInfo hideLearnMore companyModel={otherParticipant?.user?.company} />
                            </Link>
                        </div>
                    }
                </div>
            }
            <div className={isTablet ? styles.handlerTablet : styles.handler} onClick={() => setSiderCollapsed(!siderCollapsed)}>
                {siderCollapsed ? <LeftOutlined /> : <RightOutlined />}
            </div>
        </div >
    );
}


export default inject('meetingStore', 'sessionStore', 'viewStateStore')(observer(MeetingRoomSider));