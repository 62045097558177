import { Avatar, Typography } from 'antd'
import React from 'react';
import Card from '../../../../../common/components/card/Card';
import SectionHeader from '../../../../../common/components/sectionHeader/SectionHeader';
import { Question } from '../../../../../common/models/EventModel';
import styles from './EventQuestions.module.css';
const { Paragraph } = Typography;

interface Props {
    className?: string;
    style?: any;
    questions?: Question[],
}

function EventQuestions({ className, style, questions }: Props) {
    return (
        <div style={style} className={`${styles.container} ${className}`}>
            <SectionHeader size='medium' title={`Questions`}
                subTitle={`We'll do our best to address these questions during the session.`} />

            <div className={styles.questionsContainer}>
                {
                    questions?.map(question => {
                        const { askingUser, text } = question;
                        return <Card className={styles.question} key={question.id}>
                            <Paragraph ellipsis={{ rows: 2, expandable: true }}  className={styles.title} >{text}</Paragraph>
                            <div className={styles.askedByContainer}>
                                <Avatar size='small' src={question.askingUser?.photoUrl} style={{ flexShrink: 0}} />
                                <div>Asked by {askingUser?.firstName} from {askingUser?.company?.name}</div>
                            </div>
                        </Card>
                    })
                }
            </div>
        </div>)

}

export default EventQuestions;