import { message, Upload } from "antd";
import { inject, observer } from "mobx-react";
import React, { useState } from "react";
import { useEffect } from "react";
import FirebaseClient from "../../../services/FirebaseClient";
import { beforeUpload, getBase64 } from "../../utils/UploadUtils";
import styles from './ImageUpload.module.css';

interface Props {
    firebase?: FirebaseClient,
    onUploadFinish: (uploadUrl: string) => void,
    onRemove?: () => void; 
    prefix?: string,
    previousImage?: string,
    imageStyle?: any;
}

function ImageUpload({ firebase, onUploadFinish, prefix = "upload", previousImage, onRemove, imageStyle }: Props) {
    const [imageUrl, setImageUrl] = useState(previousImage || '');

    useEffect(() => {
        setImageUrl(previousImage || '');
    }, [previousImage])

    const handleChange = (info: any) => {
        if (info.file.status === 'uploading') {
            return;
        }
        if (info.file.status === 'done') {
            getBase64(info.file.originFileObj, imageUrl => {
                setImageUrl(imageUrl);
            });
        }
    };

    const customUpload = async ({ onError, onSuccess, file }: any) => {
        try {

            const uploadedImage: any = await firebase?.upload(file, prefix);
            onUploadFinish(uploadedImage);
            onSuccess(uploadedImage, file);
        } catch (e) {
            onError(e);
        }
    };

    const onRemoveClicked = () => {
        setImageUrl('');
        onRemove && onRemove();
    }

    return (
        <div className={styles.container}>
            <Upload
                listType='picture-card'
                accept="image/*"
                onChange={handleChange}
                customRequest={customUpload}
                showUploadList={false}
                multiple={false}
                beforeUpload={(file, list) => beforeUpload(file, (errorMessage) => message.error(errorMessage))}>
                <div>
                    {imageUrl ? <img src={imageUrl} className={styles.image} style={imageStyle} /> :
                        <div>
                            <div className={styles.uploadText}>Upload</div>
                        </div>}
                </div>
            </Upload>
            {onRemove && imageUrl && <div onClick={onRemoveClicked} className={styles.removeButton}>Remove</div> }
        </div>)
}


export default inject('firebase')(observer(ImageUpload));