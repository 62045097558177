import React from "react";
import styles from './AttendanceEncourager.module.css';
import fireEmoji from './fire-emoji.png';
import alertEmoji from './alert.png';
import EventModel from "../../../../../../../common/models/EventModel";
const THRESHOLD = 4;
const LAST_FEW_SEATS_THRESHOLD = 20;

interface Props {
    className?: string;
    event?: EventModel,
}


function AttendanceEncourager({ className, event }: Props) {
    const renderContent = () => {
        if (event?.attendeesCount && event?.attendeesCount >= LAST_FEW_SEATS_THRESHOLD) {
            return <><img className={styles.emoji} src={fireEmoji} /> Last seats - register now!</>
        }
        
        return <span>Limited seats - save your spot!</span>

    }

    if(!event?.hasSeatLimit) {
        return <></>;
    }

    return <div className={`${styles.container} ${className}`} >
        {renderContent()}
    </div>
}

export default AttendanceEncourager;
