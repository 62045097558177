import { Modal, Input, Button, notification, Select, Switch, message } from "antd";
import React, { useState } from "react";
import CopyToClipboard from "react-copy-to-clipboard";
import { inject, observer } from "mobx-react";
// import { } from '@ant-design/icons';
import styles from './GuestInviteModal.module.css';
import ControlPanelStore from "../../../../../store/ControlPanelStore";
import { useEffect } from "react";
import EventsStore from "../../../../../store/EventsStore";
import EventModel from "../../../../../common/models/EventModel";
import { AttendeeRole } from "../../../../../common/models/AttendeeModel";



interface Props {
    eventsStore?: EventsStore;
    visible: boolean;
    event?: EventModel;
    onDismiss: () => void;
}

function GuestInviteModal({ onDismiss, visible, eventsStore, event }: Props) {
    const [invitationId, setInvitationId] = useState('');
    const [guestLink, setGuestLink] = useState('');
    const [guestRole, setGuestRole] = useState<AttendeeRole>('attendee');
    const [skipApproval, setSkipApproval] = useState(false);
    const [busy, setBusy] = useState(true);


    useEffect(() => {
        if (visible) {
            eventsStore?.createGuestInvitation(event!, guestRole, skipApproval).then(result => {
                setInvitationId(result.id)
                setGuestLink(result.url);
                setBusy(false);
            })
        }
    }, [visible])

    const onGuestRoleChanged = async (value: string) => {
        await eventsStore?.updateGuestInvitation(invitationId, {
            guestRole: value
        });

        message.success('Link options has been updated!');
    }

    const onSkipApprovalChanged = async (value: boolean) => {
        await eventsStore?.updateGuestInvitation(invitationId, {
            skipApproval: value
        });

        message.success('Link options has been updated!');
    }

    const onCopyLinkClicked = () => {
        notification.success({
            message: 'Link Copied!',
            placement: 'bottomRight'
        })
    }

    return (<Modal width={800} destroyOnClose footer={null} visible={visible} onCancel={onDismiss}>
        <div className={styles.content}>
            <div className={styles.grid}>
                <div>
                    <div className={styles.subHeader}>share this link with potential guests.</div>
                    <div className={styles.inputContainer}>
                        <Input className={styles.input} size={'large'} value={guestLink} />
                        <CopyToClipboard text={guestLink}>
                            <Button loading={busy} className={styles.copyButton} onClick={onCopyLinkClicked}>COPY</Button>
                        </CopyToClipboard>
                    </div>
                </div>
                <div>
                    <div className={styles.label}>Options</div>
                    <div className={styles.form}>
                        <div>
                            <div className={styles.label}>Guest Role</div>
                            <Select loading={busy} onChange={onGuestRoleChanged} size='large' placeholder='Choose role' defaultValue='attendee'>
                                <Select.Option value={'attendee'}>Attendee</Select.Option>
                                <Select.Option value={'speaker'}>Speaker</Select.Option>
                            </Select>
                        </div>
                        <div>
                            <div className={styles.label}>Skip Approval</div>
                            <Switch loading={busy} defaultChecked={false} onChange={onSkipApprovalChanged} />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </Modal>);
}


export default inject('eventsStore')(observer(GuestInviteModal));