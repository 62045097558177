import { AttendeeRole } from "./AttendeeModel";
import { EventPublicData } from "./EventModel";

class InvitationModel {
    id?: string;
    inviterId?: string;
    inviterCompany?: InviterCompany;
    inviterFirstName?: string;
    inviterLastName?: string;
    inviterPhotoUrl?: string;
    inviterEmail?: string;
    isGuest?: boolean;
    eventInfo?: EventPublicData;
    guestRole?: AttendeeRole;
    membership?: InvitationMembership
    createdAt?: number;
    skipApproval?: boolean;


    constructor(id?: string,
        inviterId?: string,
        inviterCompany?: InviterCompany,
        inviterFirstName?: string,
        inviterLastName?: string,
        inviterPhotoUrl?: string,
        createdAt?: number,
        isGuest?: boolean,
        guestRole?: AttendeeRole,
        eventInfo?: EventPublicData,
        membership?: InvitationMembership,
        inviterEmail?: string,
        skipApproval?: boolean) {
        this.id = id;
        this.inviterId = inviterId
        this.inviterCompany = inviterCompany
        this.inviterFirstName = inviterFirstName
        this.inviterLastName = inviterLastName
        this.inviterPhotoUrl = inviterPhotoUrl
        this.inviterEmail = inviterEmail
        this.createdAt = createdAt
        this.isGuest = isGuest;
        this.eventInfo = eventInfo;
        this.guestRole = guestRole;
        this.membership = membership;
        this.skipApproval = skipApproval;
    }


    static mapFromServer(data: any) {
        return new InvitationModel(
            data.id,
            data.inviterId,
            data.inviterCompany,
            data.inviterFirstName,
            data.inviterLastName,
            data.inviterPhotoUrl,
            data.createdAt.seconds,
            data.isGuest,
            data.guestRole,
            data.eventInfo,
            data.membership,
            data.inviterEmail,
            data.skipApproval);
    }
}

export type InviterCompany = {
    name: string,
    photoUrl: string
};

export type InvitationMembership = {
    noTrial?: boolean,
    eventCredits?: number;
    introCredits?: number;
}

export default InvitationModel;