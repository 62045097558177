import React, { useEffect, useState } from "react";
import styles from './ConversationRequestModal.module.css';
import EventConversationModel from "../../../../../../../common/models/EventConversationModel";
import { Button, Modal } from "antd";
import UserAvatar from "../../../../../../../common/components/avatar/UserAvatar";
import { INCOMING_CONVERSATION_WAIT_PERIOD } from "../../LiveEventPage";
import { once } from "lodash";
const EXTRA_SECONDS = 3;

interface Props {
    conversation?: EventConversationModel;
    onCancel: (conversation?: EventConversationModel) => void;
}

function ConversationRequestModal({ conversation, onCancel }: Props) {
    const [counter, setCounter] = useState(INCOMING_CONVERSATION_WAIT_PERIOD + EXTRA_SECONDS)

    useEffect(() => {
        if(!conversation) {
            return;
        }

        setTimeout(() => {
            if (counter <= 0) {
                onCanceledClicked();
            }
            setCounter(value => value - 1);
        }, 1000);
    }, [counter, conversation])

    const onCanceledClicked = () => {
        onCancel(conversation);
        setCounter(INCOMING_CONVERSATION_WAIT_PERIOD + EXTRA_SECONDS);
    }

    return <Modal visible={conversation ? true : false} footer={null} onCancel={onCanceledClicked}>
        <div className={styles.container}>
            <div className={styles.title}>Asking {conversation?.toUser?.firstName} to have a private talk...  </div>
            <div className={styles.subTitle}>Let them a few seconds to accept your request.</div>
            <div className={styles.avatarsContainer}>
                <UserAvatar size='large' userImage={conversation?.byUser?.photoUrl} companyImage={conversation?.byUser?.company?.logoUrl} />
                <UserAvatar size='large' userImage={conversation?.toUser?.photoUrl} companyImage={conversation?.toUser?.company?.logoUrl} />
            </div>
            <div className={styles.cancelButtonContainer}>
                <Button onClick={() => onCanceledClicked()}>Cancel Request</Button>
                <div className={styles.countdownText}>Request will be canceled in {counter} seconds</div>
            </div>
        </div>
    </Modal>
}

export default ConversationRequestModal;