import firebase from "firebase";
import UserModel from "../../common/models/UserModel";

class AnalyticsReporter {
    trackPage = (screenName: string) => {
        firebase.analytics().setCurrentScreen(screenName);
        firebase.analytics().logEvent('page_view', {
            page_title: screenName,
            page_path: window.location.pathname
        })

        this.safeCall(() => {
            window.Tawk_API.addEvent('navigation', {
                'screenName': screenName,
                'path': window.location.pathname,
            }, (error: any) => { console.log(error) });
        })
    }

    setUserId = (userId: string, userModel?: UserModel) => {
        firebase.analytics().setUserId(userId);
        setTimeout(() => {
            this.safeCall(() => {
                this.setUserProperty(
                    {
                        name: userModel?.fullName || '',
                        email: userModel?.email || ''
                    });
            })
        }, 3000)
    }

    signup = (providerId: string) => {
        firebase.analytics().logEvent('sign_up', {
            method: providerId
        });

        firebase.analytics().logEvent('', {
            method: providerId
        });

        firebase.analytics().setUserProperties({
            sign_up_method: providerId
        });
    }

    login = (providerId: string) => {
        firebase.analytics().logEvent('login', {
            method: providerId
        });
    }

    logout = () => {
        firebase.analytics().logEvent('logout');
    }

    setOnboardingCompleted = () => {
        this.setUserProperty({ onboarding_completed: true });
    }


    setGuest = (isGuest: boolean) => {
        this.setUserProperty({ guest: isGuest });
    }


    setUserBasicDetails = (name: string, company: string, email: string) => {
        this.setUserProperty({
            name: name,
            company: company,
            email: email
        });
    }

    setUserType = (type: string) => {
        this.setUserProperty({ type: type });
    }

    setSignInWithEmail = () => {
        this.setUserProperty({ email_sign_in: true });
    }

    private setUserProperty = (prop: UserProp) => {
        firebase.analytics().setUserProperties(prop);
        setTimeout(() => {
            this.safeCall(() => window.Tawk_API.setAttributes(prop, (error: any) => { console.log(error) }));
        }, 5000); // script loading bug
    }

    private safeCall = (callback: () => void) => {
        try {
            callback();
        } catch (error) {
            console.log(error);
        }

    }

}

interface UserProp {
    [key: string]: number | string | boolean;
}

export default AnalyticsReporter;