import React from "react";
import EventModel from "../../../../../common/models/EventModel";
import styles from './EventCapabilities.module.css';
import loungeIcon from './the-toast.png'
import roundtablesIcon from './roundtabels.png'
import oneOnOneIcon from './handshake.png'
import questionsIcon from './qa.png'
import intimateIcon from './intimate.png'

interface Props {
    eventModel?: EventModel;
    className?: string;
}

function EventCapabilities({ eventModel, className }: Props) {
    return <div className={`${styles.container} ${className}`}>
        <div className={styles.header}>What's Included</div>
        <div className={styles.description}>This Leaders meeting features:</div>
        <div className={styles.capabilitiesGrid}>
            {eventModel?.hasSeatLimit &&
                <CapabilityItem
                    icon={intimateIcon}
                    header={'Intimate Discussion'}
                    description={`This meeting has limited seats for real intimate talk`} />
            }
            <CapabilityItem
                icon={loungeIcon}
                header={'Networking Lounge'}
                description={`You'll have time to network freely with other attendees`} />
            {eventModel?.isRoundTablesEnabled &&
                <CapabilityItem
                    icon={roundtablesIcon}
                    header={'Round Tables'}
                    description={`Have a seat in our interactive roundtables`} />
            }
            {eventModel?.isPrivateTalksEnabled &&
                <CapabilityItem
                    icon={oneOnOneIcon}
                    iconStyle={{ height: 18 }}
                    header={'1-1 Meetings'}
                    description={`You'll have a chance speak in private with other attendees`} />
            }
            {eventModel?.isQuestionsEnabled &&
                <CapabilityItem
                    icon={questionsIcon}
                    header={'Panelist/Q&A Session'}
                    description={`You'll have a chance to ask questions before and during the meeting`} />
            }
        </div>
    </div>
}

const CapabilityItem = ({ header, description, icon, iconStyle }: { header: string, description: string, icon: string, iconStyle?: any }) => {
    return <div className={styles.capability}>
        <img className={styles.capabilityIcon} src={icon} style={iconStyle} />
        <div className={styles.capabilityTexts}>
            <div className={styles.capabilityHeader}>{header}</div>
            <div className={styles.capabilityDescription}>{description}</div>
        </div>
    </div>
}

export default EventCapabilities;