import firebase from "firebase";
import { isRunningDebug } from "../../common/utils/Utils";
import FirebaseClient from "../FirebaseClient";


class RemoteSettingsProvider {
    remoteConfig: firebase.remoteConfig.RemoteConfig;

    constructor() {
        this.remoteConfig = firebase.remoteConfig();
        this.initRemoteConfig();
        this.remoteConfig.ensureInitialized().then(() => {
            console.log('Remote settings initialized');
        });
    }

    initRemoteConfig() {
        const settings: any = {}

        if (isRunningDebug()) {
            settings.minimumFetchIntervalMillis = 10000 // 10 mintus
        }

        this.remoteConfig.settings = { ...settings }
        this.remoteConfig.defaultConfig = ({
            'welcome_message': 'Our community is an invite-only network focused on breaking the barriers between decision makers in travel.',
            'founding_members': true,
        });

        this.remoteConfig.fetchAndActivate().catch(error => {
            console.error(`Failed to fetch remote config with error: ${error}`);
        });

    }

    welcomeMessage = async () => {
        await this.remoteConfig.ensureInitialized();
        return this.remoteConfig.getString('welcome_message');
    }

    foundingMembers = async () => {
        await this.remoteConfig.ensureInitialized();
        return this.remoteConfig.getBoolean('founding_members');
    }
}

export default RemoteSettingsProvider;