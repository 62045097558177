import Checkbox from 'antd/lib/checkbox/Checkbox';
import { AutoComplete, Avatar, Button, Card, Divider, Empty, Form, Input, message, notification, Radio, Select, Tag } from 'antd';
import React, { useEffect, useState } from 'react';
import styles from './ConnectionsPage.module.css'
import SessionStore from '../../../store/SessionStore';
import { inject, observer } from 'mobx-react';
import _ from 'lodash';
import { MessageTwoTone, FireTwoTone } from '@ant-design/icons'
import AnalyticsReporter from '../../../services/analytics/AnalyticsReporter';
import ConnectionsStore from '../../../store/ConnectionsStore';
import SectionHeader from '../../../common/components/sectionHeader/SectionHeader';
import ConversationItem from './components/conversationItem/ConversationItem';
import UserBox from '../members/components/userBox/UserBox';
import UserModel from '../../../common/models/UserModel';
import MembersStore from '../../../store/MembersStore';
import { useHistory } from 'react-router';
import MeetMemberModal from '../members/components/meetMemberModal/MeetMemberModal';
const { Option } = AutoComplete;

interface Props {
    sessionStore: SessionStore,
    analyticsReporter: AnalyticsReporter,
    connectionsStore: ConnectionsStore,
    membersStore: MembersStore
}

function ConnectionsPage({ sessionStore, connectionsStore, analyticsReporter, membersStore }: Props) {
    const { userConvesations, followedMembers } = connectionsStore;
    const { authUser, loggedIn } = sessionStore;
    const { viewMember } = membersStore;
    const [meetMemberModalVisible, setMeetMemberModalVisible] = useState(false);

    useEffect(() => {
        analyticsReporter.trackPage('Connections Page');
    }, [])

    useEffect(() => {
        if (loggedIn) {
            connectionsStore.subscribeToConversations();
        }
    }, [loggedIn]);

    useEffect(() => {
        connectionsStore.getFollowedMembers().catch(error => {
            console.error(`Error when fetching followers: ${error}`);
        })
    }, [authUser?.following])

    const onMemberClicked = (userModel: UserModel) => {
        membersStore.setCurrentViewedMember(userModel);
        setMeetMemberModalVisible(true);
    }

    const onMeetMemberModalDismissed = () => {
        membersStore.setCurrentViewedMember(undefined);
        setMeetMemberModalVisible(false);
    }

    return (
        <div className={styles.container}>
            <SectionHeader title={'My Connections'} subTitle={'Build and manage your connections with other members on the platform.'} />
            <div className={styles.content}>
                <div>
                    <SectionHeader size='medium' title={'Recent conversations'} />
                    {userConvesations.length > 0 ?
                        <div className={styles.conversationGrid}>
                            {userConvesations.map(conversation => {
                                return <ConversationItem key={conversation.id} conversationModel={conversation} />
                            })}
                        </div>
                        :
                        <NoConversationsEmptyState />
                    }
                </div>
                <div>
                    <SectionHeader size='medium' title={'Members you follow'} subTitle={`You'll be notified whenever one of the members attends a meeting`} />
                    {authUser?.followers && authUser?.followers.length > 0 &&
                        <div className={styles.followingYou}>
                            <FireTwoTone twoToneColor={'#f81f22'} style={{ marginRight: 4 }} />
                            <span style={{ fontWeight: 600}} >{authUser?.followers.length} {authUser?.followers.length === 1 ? 'member': 'members'} {authUser?.followers.length === 1 ? 'follows': 'follow'} you. </span> Make sure to attend our next meeting so they can meet you!
                        </div>
                    }
                    {followedMembers.length > 0 ?
                        <div className={styles.usersGrid}>
                            {followedMembers.map(user =>
                                <UserBox key={user.id} userModel={user} onClick={onMemberClicked} />
                            )}
                        </div>
                        :
                        <NoFollowingEmptyState membersStore={membersStore} />
                    }
                </div>

            </div>
            <MeetMemberModal visible={meetMemberModalVisible} onCancel={onMeetMemberModalDismissed} />
        </div >
    )
}

const NoConversationsEmptyState = () =>
(<Card className={styles.emptyCard}>
    <Empty
        description={
            <span>
                You haven't made any conversations with other members recently.
                </span>
        }
    >
    </Empty>
</Card>);


function NoFollowingEmptyState({ membersStore }: { membersStore: MembersStore }) {
    const history = useHistory();
    const [randomPeople, setRandomPeople] = useState(Array<UserModel>())

    useEffect(() => {
        membersStore.getRandomPeople().then(result => {
            setRandomPeople(result);
        })
    }, [])

    return (
        <Card bodyStyle={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column'
        }}
            className={styles.emptyCardFollowers}>
            <Avatar.Group className={styles.emotyStateRandomPeople}>
                {randomPeople.map((user, index) => {
                    return <Avatar size={40} key={index} className={styles.teamAvatar} src={user.photoUrl} />
                })}
            </Avatar.Group>
            <div>
            Get notified whenever a specific member attends one of our networking meetings.
              </div>
            <Button className={styles.browseMembersButton} onClick={() => history.push('/members')} type={'primary'}>Browse members to follow</Button>
        </Card>)
}


export default inject('analyticsReporter', 'sessionStore', 'connectionsStore', 'membersStore')(observer(ConnectionsPage));
