import React, { useEffect, useState } from "react";
import styles from './ConversationPrepareModal.module.css';
import EventConversationModel from "../../../../../../../common/models/EventConversationModel";
import { Button, Modal } from "antd";
import Lottie from "react-lottie";
import loadingAnimation from './roomLoading.json';

const lottieFile = {
    loop: true,
    autoplay: true,
    animationData: loadingAnimation,
    rendererSettings: {
        preserveAspectRatio: 'xMidYMid slice'
    }
};

const fadeInAnimation = 'animate__animated animate__fadeInUp';

interface Props {
    visible: boolean,
}

function ConversationPrepareModal({ visible }: Props) {
    const [title, setTitle] = useState('Conversation is on!')
    const [animate, setAnimate] = useState(false);

    useEffect(() => {
        if(!visible) { //reset
            setTitle('Conversation is on!');
            setAnimate(false);
            return;
        }

        // setTimeout(() => {
        //     setTitle('Getting your room ready...');
        //     setAnimate(true);
        // }, 2000)

        // setTimeout(() => {
        //     setAnimate(false);
        // }, 2000)

        setTimeout(() => {
            setTitle(`We're letting you in...`);
            setAnimate(true);
        }, 2000)
    },[visible])

    return <Modal visible={visible} closable={false} footer={null}>
        <div className={styles.container}>
            <div className={`${styles.title} ${animate ? fadeInAnimation : {}} `}>{title}</div>
            <div className={styles.subTitle}>We'll get you and your partner in very soon.</div>
            <div className={styles.loader}>
                <Lottie options={lottieFile} />
            </div>
        </div>
    </Modal>
}

export default ConversationPrepareModal;