import { inject, observer } from 'mobx-react';
import React, { useEffect, useState } from 'react';
import { Redirect, useHistory, useLocation, useRouteMatch } from 'react-router-dom';
import styles from './IntroSchedulePage.module.css'
import SessionStore from '../../store/SessionStore';
import AnalyticsReporter from '../../services/analytics/AnalyticsReporter';
import ConnectionsStore from '../../store/ConnectionsStore';
import FirebaseClient, { FieldValue } from '../../services/FirebaseClient';
import MeetingScheduler from './components/meetingScheduler/MeetingScheduler';
import IntroRequestModel from '../../common/models/IntroRequestModel';
import Loader from '../../common/components/loader/Loader';
import SmallLogoButton from '../../common/components/smallLogoButton/SmallLogoButton';
import Logo from '../../common/components/logo/Logo';
import { Button, Modal } from 'antd';
import MeetingChoices from './components/meetingChoices/MeetingChoices';
import doneAnimation from './../../common/images/doneAnimation.json';
import { CalendarOutlined, EnvironmentOutlined } from '@ant-design/icons';
import Lottie from 'react-lottie';
import moment from 'moment';
import UserModel from '../../common/models/UserModel';

const lottieFile = {
    loop: false,
    autoplay: true,
    animationData: doneAnimation,
    rendererSettings: {
        preserveAspectRatio: 'xMidYMid slice'
    }
};

interface Props {
    sessionStore: SessionStore;
    connectionsStore: ConnectionsStore;
    firebase: FirebaseClient;
}

function IntroSchedulePage({ sessionStore, connectionsStore, firebase }: Props) {
    const { authUser, loggedIn, sessionFetched } = sessionStore;
    const [introRequest, setIntroRequest] = useState<IntroRequestModel>();
    const [acceptedModalVisible, showAcceptedModal] = useState(false);
    const [expiredModalVisible, showExpiredModal] = useState(false);
    const [completionModalVisible, showCompletionModal] = useState(false);
    const location = useLocation();
    const match = useRouteMatch();
    const history = useHistory();

    useEffect(() => {
        const { introRequestId } = match.params as any;
        firebase.introRequest(introRequestId).onSnapshot((snap) => {
            const data = snap.data();
            const intro = IntroRequestModel.mapFromServer({ id: snap.id, ...data })
            setIntroRequest(intro);
        });
    }, []);

    useEffect(() => {
        if (introRequest?.status === 'completed') {
            showCompletionModal(true);
        }
        if (introRequest?.status === 'expired') {
            showExpiredModal(true);
        }
    }, [introRequest?.status])

    useEffect(() => {
        if (introRequest) {
            const mode = new URLSearchParams(location.search).get("mode") || '';
            connectionsStore?.checkIfRequestedUserOpenedIntro(introRequest, mode);
            connectionsStore?.checkIfRequesterUserOpenedIntro(introRequest, mode);
        }
    }, [introRequest]);

    const backToPlatformClicked = () => {
        history.push('/connections');
    }

    const renderContent = () => {
        const { authUser } = sessionStore;
        if (!introRequest) {
            return <></>;
        }

        const mode = new URLSearchParams(location.search).get("mode");

        if (introRequest.requested?.id === authUser?.id || mode === 'requested') {
            return <MeetingScheduler
                introRequest={introRequest}
                onFinished={() => showAcceptedModal(true)} />
        }

        if (introRequest.requester?.id === authUser?.id || mode === 'requester') {
            return <MeetingChoices
                introRequest={introRequest}
                onFinished={() => showCompletionModal(true)} />
        }
    }

    return (
        <div className={styles.container}>
            <div className={styles.header}>
                <SmallLogoButton className={styles.homeButton} />
                <Logo hideTagline className={styles.logo} />
            </div>

            {introRequest === undefined &&
                <div className={styles.loaderContainer}>
                    <Loader className={styles.loader} size='medium' visible={true} />
                </div>
            }

            { renderContent()}

            <Modal
                visible={completionModalVisible}
                closable={false}
                // onCancel={() => showCompletionModal(false)}
                footer={null}>
                <div className={styles.completionModalContainer}>
                    <div className={styles.doneAnimation}>
                        <Lottie options={lottieFile} />
                    </div>
                    <div className={styles.completionHeader}>
                        Meeting Scheduled
                    </div>

                    <div className={styles.dateAndTime}>
                        <div>
                            <CalendarOutlined className={styles.dateAndTimeIcon} />
                            <span>
                                {moment.unix(IntroRequestModel.scheduledTime(introRequest)!).format('HH:mm, dddd, MMMM, YYYY')}
                            </span>
                        </div>
                        <div>
                            <EnvironmentOutlined className={styles.dateAndTimeIcon} />
                            <span>
                                Video Call 
                            </span>
                        </div>
                    </div>
                    <div className={styles.completionDescription}>
                        A calendar invitation with all the details has been sent out to your email address.
                    </div>
                    <div className={styles.completionButtonsContainer}>
                        <Button size='large' onClick={backToPlatformClicked}
                            type={'primary'}>
                            Manage your connections
                        </Button>
                    </div>
                </div>
            </Modal>

            <Modal
                visible={acceptedModalVisible}
                closable={true}
                onCancel={() => showAcceptedModal(false)}
                footer={null}>
                <div className={styles.completionModalContainer}>
                    <div className={styles.doneAnimation}>
                        <Lottie options={lottieFile} />
                    </div>
                    <div className={styles.completionHeader}>
                        You're all set 💪
                    </div>
                    <div className={styles.completionDescription}>
                        We'll inform <b>{introRequest?.requester?.firstName}</b> about your availability and send a meeting invite with all the details once {UserModel.pronouneHe(introRequest?.requester?.gender)} approves.
                    </div>
                    <div className={styles.keepPosted}>
                        We'll keep you posted!
                    </div>

                    <div className={styles.completionButtonsContainer}>
                        <Button onClick={backToPlatformClicked}
                            type={'primary'}
                            size='large'>
                            Manage your connections
                    </Button>
                        <Button onClick={() => showAcceptedModal(false)}
                            type={'default'}
                            size='large'>
                            Change dates
                    </Button>
                    </div>
                </div>
            </Modal>

            <Modal
                visible={expiredModalVisible}
                closable={false}
                footer={null}>
                <div className={styles.completionModalContainer}>
                    <div className={styles.completionHeader}>
                        Introduction has been expired
                    </div>
                    <div className={styles.completionDescription}>
                        It seems like this introduction doesn't exist or expired.
                    </div>
                    <div className={styles.completionButtonsContainer}>
                        <Button onClick={backToPlatformClicked}
                            type={'primary'}
                            size='large'>
                            Manage your connections
                    </Button>
                    </div>
                </div>
            </Modal>
        </div>
    )
}

export default inject('sessionStore', 'connectionsStore', 'firebase')(observer(IntroSchedulePage));