import React, { ReactNode, useState } from 'react';
import { Link } from 'react-router-dom';
import styles from './SectionHeader.module.css';

interface Props {
    className?: string,
    style?: any,
    title: string | ReactNode
    subTitle?: string | ReactNode
    titleStyle?: any
    size?: 'large' | 'medium',
    linkTitle?: string,
    linkTo?: string,
    extra?: ReactNode,
    onLinkClicked?: () => void;
}

function SectionHeader({ className, style, title, subTitle, titleStyle, size = 'large', linkTitle, linkTo, onLinkClicked, extra }: Props) {
    return (
        <div className={`${styles.container} ${className}`} style={style}>
            <div className={styles.titleContainer}>
                <div className={`${styles.title} ${size === 'medium' && styles.mediumTitle}`}>{title}</div>
                {linkTitle && <div onClick={onLinkClicked} className={styles.linkContainer}>
                    {linkTo ?
                        <Link className={styles.link} to={linkTo || ''}>{linkTitle}</Link>
                        :
                        <span className={styles.link}>{linkTitle}</span>
                    }
                    { extra && <div className={styles.extraContainer}>{extra}</div> }
                </div>}
            </div>
            {subTitle && <div className={styles.subTitle} style={size === 'medium' ? { marginTop: 8 } : {}}>{subTitle}</div>}
        </div>
    )
}

export default SectionHeader;