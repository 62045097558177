import { Skeleton } from 'antd';
import { inject, observer } from 'mobx-react';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import SectionHeader from '../../../../../common/components/sectionHeader/SectionHeader';
import EventsStore, { MAX_EVENTS_TO_SHOW } from '../../../../../store/EventsStore';
import SettingsStore from '../../../../../store/SettingsStore';
import ViewStateStore from '../../../../../store/ViewStateStore';
import EventCard from '../../../events/components/eventCards/EventCard';
import styles from './EventsStrip.module.css';

interface Props {
    viewStateStore?: ViewStateStore,
    eventsStore?: EventsStore,
    settingsStore?: SettingsStore,
    className?: string,
    linkTitle?: string,
}

function EventsStrip({ viewStateStore, eventsStore, className, settingsStore, linkTitle = 'See All' }: Props) {
    const { upcomingEventsStrip, futureEvents, eventsFetched } = eventsStore!;
    const { isMobile, eventTerm } = viewStateStore!;
    const { terminology } = settingsStore!;

    const renderContent = () => {
        if(!eventsFetched) {
            return [1, 2, 3].map(i => {
                return <Skeleton.Avatar key={i} active={true} style={{ width: 400, height: 318 }} shape={'square'} />
            });
        }

        if(futureEvents.length === 0) {
            return <div className={styles.emptyEvents}>

                
                <div className={styles.emptyEventsHeader}>There are no upcoming {eventTerm}s soon</div>
                <div>We'll update you once a new {eventTerm} is scheduled. <br/> In the meanwhile, you can find all our previous {eventTerm}s <Link to='/calendar?filter=past'>here</Link>.</div>
                </div>
        }
    }

    return (
        <div className={`${styles.container} ${className}`}>
            <SectionHeader title={`Upcoming ${eventTerm}s`} size='medium'
                linkTo='/calendar'
                linkTitle={linkTitle}
                extra={futureEvents.length - MAX_EVENTS_TO_SHOW > 0 && <span>+{futureEvents.length - MAX_EVENTS_TO_SHOW} {terminology?.eventTerm}{futureEvents.length - MAX_EVENTS_TO_SHOW > 1 && 's' }</span>}
            />
            <div className={styles.stripWrapper}>
                <div className={styles.strip}>
                    {
                        upcomingEventsStrip.map((event, index) => {
                            return <div key={event.id} className={styles.stripItem}>
                                <EventCard eventModel={event} />
                            </div>
                        })
                    }

                    { renderContent() }
                </div>
            </div>
        </div>
    )
}

export default inject('viewStateStore', 'eventsStore', 'settingsStore')(observer(EventsStrip));