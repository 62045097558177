import AttendeeModel from "./AttendeeModel";
import { UserPublicData } from "./UserModel";

class RoundTableModel {
    id?: string;
    title?: string;
    participants?: UserPublicData[];
    preselectedAttendees?: string[];
    maxSeats?: number;

    constructor(id?: string,
        title?: string,
        participants?: UserPublicData[],
        maxSeats?: number,
        preselectedAttendees?: string[]) {
        this.id = id;
        this.title = title;
        this.participants = participants;
        this.maxSeats = maxSeats;
        this.preselectedAttendees = preselectedAttendees;
    }

    isPreselected(attendeeId: string) {
        return this.preselectedAttendees?.some(id => id === attendeeId);
    }

    get preselectedEnabled() {
        return this.preselectedAttendees?.length || 0 > 0 || false;
    }

    get isFull() {
        if(!this.participants || !this.maxSeats) {
            return false;
        }

        return this.participants?.length >= this.maxSeats;
    }

    static mapFromServer(data: any) {
        return new RoundTableModel(data.id,
            data.title,
            data.participants || [],
            data.maxSeats,
            data.preselectedAttendees || [])
    }
}


export default RoundTableModel;