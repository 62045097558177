import { message } from 'antd';
import { inject, observer } from 'mobx-react';
import React, { useEffect, useState } from 'react';
import { Link, Route, Switch } from 'react-router-dom';
import VideoRoom from '../../../../../../../common/components/videoRoom/VideoRoom';
import EventStore from '../../../../../../../store/EventStore';
import styles from './EventConversationRoom.module.css';

interface Props {
    eventStore?: EventStore;
}

function EventConversationRoom({ eventStore }: Props) {
    const { userActiveConversation, conversationVideoToken } = eventStore!;
    const { byUser, toUser } = userActiveConversation!;

    useEffect(() => {
        return () => {
            eventStore?.setAttendeeStatus('free');
        }
    }, []);

    const onLogout = () => {
        eventStore!.setActiveConversation(undefined);
        message.info('Conversation Over!');
    }

    return (
        <div className={styles.container}>
            <VideoRoom participantsInfo={[byUser!, toUser!]}
                onLogout={onLogout}
                videoToken={conversationVideoToken}
                roomName={userActiveConversation?.id || ''} />
        </div>
    )
}

export default inject('sessionStore', 'eventStore')(observer(EventConversationRoom));