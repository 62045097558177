import React, { useState } from "react";
import { SwapOutlined, CheckCircleOutlined, CloseCircleOutlined, ClockCircleOutlined } from '@ant-design/icons';
import styles from './IntroRequestInfoModal.module.css';
import { Modal, Timeline } from "antd";
import IntroRequestModel from "../../../../../common/models/IntroRequestModel";
import UserAvatar from "../../../../../common/components/avatar/UserAvatar";
import moment from "moment";
import _ from "lodash";


interface Props {
    visible: boolean;
    onCancel: () => void;
    introRequest?: IntroRequestModel
}

function IntroRequestInfoModal({ onCancel, visible, introRequest }: Props) {

    const onCancelClicked = () => {
        onCancel();
    }

    const dotAccordingToType = (type: string) => {
        switch (type) {
            case 'regular': return;
            case 'approved': return <CheckCircleOutlined className={styles.timelineIcon} />;
            case 'denied': return <CloseCircleOutlined className={styles.timelineIcon} />;
            case 'waiting': return <ClockCircleOutlined className={styles.timelineIcon} />;
            default: return '';
        }
    }

    return (<Modal
        centered
        visible={visible}
        footer={null}
        title={<div>
            {introRequest?.requested?.firstName} {introRequest?.requested?.lastName}
            <SwapOutlined style={{ margin: '0px 4px' }} />
            {introRequest?.requester?.firstName} {introRequest?.requester?.lastName}
        </div>}
        className={styles.container}
        onCancel={onCancelClicked}>
        <div className={styles.content}>
            <div className={styles.bubblesContainer}>
                <UserAvatar size={60} userImage={introRequest?.requested?.photoUrl} className={styles.userAvatar} />
                <UserAvatar size={60} userImage={introRequest?.requester?.photoUrl} />
            </div>
            <div>
                <div className={styles.header}>
                    Introduction Purpose
                </div>
            </div>
            <div className={styles.purpose}>
                {introRequest?.purpose ? `${introRequest?.purpose}` : 'No purpose added'}
            </div>
        </div>
        <Timeline className={styles.timeline}>
            {_.orderBy(introRequest?.timeline, i => i.createdAt, 'desc')?.map((item, index) =>
                <Timeline.Item dot={dotAccordingToType(item.type)} key={index}>
                    <span className={styles.timelineItemMessage}>
                        {item.message}
                    </span>
                    <span className={styles.timelineItemTime}>
                        {moment.unix(item.createdAt).format('HH:mm DD/MM/YY')}
                    </span>
                </Timeline.Item>)}
        </Timeline>

        <div className={styles.itemId}>
            Intro Request ID: {introRequest?.id}
        </div>
    </Modal>);
}


export default IntroRequestInfoModal;