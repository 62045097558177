
import React, { useState } from 'react';
import styles from './NavigationPanel.module.css';
import { useHistory } from 'react-router-dom';
import { Tooltip } from 'antd';
import EditProfileBubble from '../../../../../../componnents/editProfileBubble/EditProfileBubble';
import { inject, observer } from 'mobx-react';
import EventStore from '../../../../../../../../store/EventStore';
import { openTawkChatBubble } from '../../../../../../../../common/utils/Utils';
import EventSettingsModal from '../settingsModal/EventSettingsModal';
import SessionStore from '../../../../../../../../store/SessionStore';
import SmallLogoButton from '../../../../../../../../common/components/smallLogoButton/SmallLogoButton';

interface Props {
    eventStore?: EventStore;
    sessionStore?: SessionStore;
    className?: string,
    onEditEvent: () => void;
}

function NavigationPanel({ eventStore, sessionStore, onEditEvent, className }: Props) {
    const { event, currentUserLocation, canAccessBackstage, setOpenSider, openSider, onlineAttendees } = eventStore!;
    const { authUser } = sessionStore!;
    const [settingsModalVisible, setSettingsModalVisible] = useState(false);
    const [roundTablesClosedTooltipVisible, setRoundTablesClosedTooltipVisible] = useState(false);
    const [stageClosedTooltipVisible, setStageClosedTooltipVisible] = useState(false);

    const onHelpClicked = () => {
        openTawkChatBubble();
    }

    const onAdminClicked = () => {
        setSettingsModalVisible(true);
    }

    const onPeopleClicked = () => {
        if(openSider === 'people' && currentUserLocation === 'lounge') {
            setOpenSider('none');
        } else {
            setOpenSider('people');
        }
        
    }

    const onTablesClicked = () => {
        if (!authUser?.isStaff && !event?.isRoundTablesActive) {
            setRoundTablesClosedTooltipVisible(true);
            setTimeout(() => {
                setRoundTablesClosedTooltipVisible(false);
            }, 2000);
            return;
        }
        eventStore?.goToRoundTables();
    }

    const onLoungeClicked = () => {
        eventStore?.goToLounge();
    }

    const onStageClicked = () => {
        if (!canAccessBackstage() && !event?.isStageActive) {
            setStageClosedTooltipVisible(true);
            setTimeout(() => {
                setStageClosedTooltipVisible(false);
            }, 2000);
            return;
        }
        eventStore?.goToStage();
    }

    return <div className={`${styles.container} ${className}`}>
        <Tooltip title='Go back to Leaders platfrom' placement='left' mouseEnterDelay={0.8}>
            <SmallLogoButton />
        </Tooltip>
        <div className={styles.centerItems}>
            <NavigationButton onClick={onLoungeClicked}
                status={true}
                selected={currentUserLocation === 'lounge'}
                icon='icon-ic-lounge'
                label='Lounge' />
            {event?.isRoundTablesEnabled &&
                <Tooltip visible={roundTablesClosedTooltipVisible}
                    placement='left'
                    title='Roundtables arena is closed'>
                    <NavigationButton onClick={onTablesClicked}
                        status={event.isRoundTablesActive}
                        selected={currentUserLocation === 'tables'}
                        icon='icon-ic-tables'
                        disabeld={!event.isRoundTablesActive}
                        label='Tables' />
                </Tooltip>
            }
            {event?.isStageEnabled &&
                <Tooltip visible={stageClosedTooltipVisible}
                    placement='left'
                    title='Stage arena is closed'>
                    <NavigationButton onClick={onStageClicked}
                        status={event.isStageActive}
                        statusText='live'
                        statusColor='#eb4c4c'
                        selected={currentUserLocation === 'stage'}
                        icon='icon-ic-stage'
                        disabeld={!event.isStageActive}
                        label='Stage' />
                </Tooltip>
            }
            <NavigationButton onClick={onPeopleClicked}
                status={false}
                statusText={onlineAttendees ? onlineAttendees?.length + '' : ''}
                selected={openSider === 'people'}
                icon='icon-ic-people2'
                label='People' />
            {authUser?.isManager && <NavigationButton onClick={onAdminClicked}
                icon='icon-ic-admin' label='Settings' />}
        </div>
        <div className={styles.bottomItems}>
            <EditProfileBubble />
            <div className={styles.horizontalDivider} />
            <NavigationButton onClick={onHelpClicked}
                icon='icon-ic-help'
                label='Help' />
            {/* <div onClick={() => history.push(`/e/${event?.shortId}`)} className={styles.leaveEventButton}>
                <img src={leaveEventButton} />
                <div>Leave</div>
            </div> */}
        </div>


        <EventSettingsModal visible={settingsModalVisible}
            onCancel={() => setSettingsModalVisible(false)}
            onEditEvent={onEditEvent}
        />
    </div>
}


interface NavigationButtonProps {
    icon: string,
    label: string,
    selected?: boolean,
    disabeld?: boolean;
    status?: boolean;
    statusText?: string;
    statusColor?: string;
    onClick?: () => void;
}

const NavigationButton = ({ icon, label, selected, disabeld, onClick, status = false, statusText = 'open', statusColor }: NavigationButtonProps) => {
    return <div onClick={onClick} className={`${styles.navigationButton} ${selected ? styles.navigationButtonSelected : ''} ${disabeld ? styles.navigationButtonDisabled : ''}`}>
        {status && <div className={styles.statusBubble} style={statusColor ? { backgroundColor: statusColor } : {}}>
            {statusText}
        </div>
        }
        <div className={`${styles.navigationIconWrapper} ${selected ? styles.navigationIconWrapperSelected : ''}`}>
            <span className={`${icon} ${styles.navigationIcon}`}></span>
        </div>
        <div className={styles.navigationLabel}>{label}</div>
    </div>
}

export default inject('eventStore', 'sessionStore')(observer(NavigationPanel));