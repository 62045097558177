import { Skeleton } from 'antd';
import _ from 'lodash';
import { inject, observer } from 'mobx-react';
import React, { useState } from 'react';
import SectionHeader from '../../../../../common/components/sectionHeader/SectionHeader';
import UserModel from '../../../../../common/models/UserModel';
import MembersStore, { NEW_MEMBERS_STRIP_COUNT } from '../../../../../store/MembersStore';
import SessionStore from '../../../../../store/SessionStore';
import UserBox from '../../../members/components/userBox/UserBox';
import styles from './MembersStrip.module.css';

interface Props {
    sessionStore?: SessionStore,
    membersStore?: MembersStore,
    title?: string,
    subTitle?: string,
    members: UserModel[],
    className?: string;
}

function MembersStrip({ membersStore, members, title, subTitle, className }: Props) {
    const { viewMember } = membersStore!;

    const onMemberClicked = (userModel: UserModel) => {
        viewMember(userModel);
    }

    return (
        <div className={`${styles.container} ${className}`}>
            <SectionHeader title={title} size='medium'
                subTitle={subTitle}
                linkTitle={'See All'}
                linkTo='/members' />
            <div className={styles.stripWrapper}>
                <div className={styles.strip}>
                    {members.map((m: UserModel) => {
                        return <div className={styles.stripItem} key={m.id}>
                            <UserBox onClick={onMemberClicked} userModel={m} />
                        </div>

                    })
                    }

                    {!members || members.length === 0 &&
                        _.times(NEW_MEMBERS_STRIP_COUNT).map(i => {
                            return <Skeleton.Avatar key={i} active={true} style={{ width: 226, height: 226}} shape={'square'} />
                        })
                    }

                </div>
            </div>
        </div>
    )
}

export default inject('membersStore')(observer(MembersStrip));