import { inject, observer } from 'mobx-react';
import React, { useEffect, useState } from 'react';
import EventStore from '../../../../../../../store/EventStore';
import SessionStore from '../../../../../../../store/SessionStore';
import styles from './EventStage.module.css';
import eventStyles from '../../LiveEventPage.module.css'
import { Button, message, Modal, Popover, Tabs } from 'antd';
import { isRunningDebug, sleepAsync } from '../../../../../../../common/utils/Utils';
import { isMobile } from 'react-device-detect';

interface Props {
    sessionStore?: SessionStore;
    eventStore?: EventStore;
}

function EventStage({ eventStore, sessionStore }: Props) {
    const { event, eventFetched, changeEventState, isTransitioning } = eventStore!;
    const { authUser, loggedIn } = sessionStore!;
    const [iframeStyle, setiFrameStyle] = useState(styles.hideiFrame);
    const [iframeLoaded, setiFrameLoaded] = useState(false);
    const [explainerMessageVisible, setExplainerMessageVisible] = useState(false);

    const buildWebinarUrl = () => {
        const userEmail = authUser?.email;
        const userName = `${authUser?.fullName} - ${authUser?.company?.name}`;
        const role = authUser?.isManager ? '1' : '0';
        const webinarId = event?.webinarId;
        // const url = `http://localhost:3001/?userEmail=${userEmail}&userName=${userName}&role=${role}&webinarId=${webinarId}`
         const url = `https://ttnl-webinar.web.app/?userEmail=${userEmail}&userName=${userName}&role=${role}&webinarId=${webinarId}`;
        return url;
    }

    useEffect(() => {
        if (!event?.isStageActive) {
            sleepAsync(4000).then(() => {
                setExplainerMessageVisible(true);
                sleepAsync(8000).then(() => {
                    setExplainerMessageVisible(false);
                })
            })
        }
    }, [event?.isStageActive])

    const startSession = () => {
        Modal.confirm({
            content: <div>
                <div className={eventStyles.modalHeader}>Are you sure you want to start this session?</div>
                <div className={eventStyles.modalText}>All attendees will be transfered here in 10 seconds after you confirm.</div>
            </div>,
            okText: 'Start Session',
            async onOk() {
                if (event?.isRoundTablesActive) {
                    message.warn('Round Tables are still running. Please end it first.')
                    return;
                }
                await changeEventState('stage');
            },
            onCancel() {
                Modal.destroyAll();
            },
        });
    }

    const oniFrameLoad = () => {
        setiFrameStyle(styles.iframe);
        setiFrameLoaded(true);
    }

    const endSession = () => {
        Modal.confirm({
            content: <div>
                <div className={eventStyles.modalHeader}>Are you sure you want to end stage?</div>
                <div className={eventStyles.modalText}>All attendees will be transferred to the lounge in 10 seconds.</div>
            </div>,
            okText: 'Go to Lounge',
            async onOk() {
                await changeEventState('lounge');
            },
            onCancel() {
                Modal.destroyAll();
            },
        });
    }

    if (!loggedIn || !eventFetched) {
        return <div></div>
    }

    return (
        <div className={styles.container}>
            <div className={styles.topStrip}>
                <div>
                    <div className={styles.smallHeader}>
                        Main Stage
                        </div>
                    <div className={styles.header}>{event?.title}</div>
                </div>
                {authUser?.isManager &&
                    <div className={styles.buttons}>
                        {event?.isStageActive &&
                            <div className={styles.liveIndicator}>
                                <div className={styles.liveDot} />
                                <span>Live</span>
                            </div>
                        }
                        {event?.isStageActive ?
                            <Button size={'large'} type='primary' onClick={endSession} className={eventStyles.endSessionButton}>
                                End Session
                        </Button>
                            :
                            <Popover
                                trigger={'hover'}
                                placement={isMobile ? 'bottom' : 'left'}
                                visible={explainerMessageVisible}
                                content={<div className={styles.explainerMessage}>Conversations here are private untill you start</div>}>
                                <Button size={'large'} type='primary' onClick={startSession} className={eventStyles.startSessionButton}>
                                    Start Session
                    </Button>
                            </Popover>
                        }
                    </div>
                }
            </div>
            <div className={styles.videoContainer}>
                <div className={styles.frameContainer}>
                    <iframe allow="camera;microphone" onLoad={oniFrameLoad} className={iframeStyle} src={buildWebinarUrl()} />
                    <span className={`${styles.loading} animate__animated animate__fadeInUp`} style={iframeLoaded ? { display: 'none' } : {}}>We're getting you in...</span>

                </div>


            </div>
        </div >
    )
}

export default inject('sessionStore', 'eventStore')(observer(EventStage));