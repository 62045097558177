import { Card } from "antd";
import React from "react";
import { useHistory } from "react-router-dom";
import { ResourceModel } from "../../../../common/models/ResourceModel";
import SessionStore from "../../../../store/SessionStore";
import { inject, observer } from "mobx-react";
// import { } from '@ant-design/icons';
import styles from './ResourceCard.module.css';
import SettingsStore from "../../../../store/SettingsStore";
import ViewStateStore from "../../../../store/ViewStateStore";



interface Props {
    resource: ResourceModel;
    sessionStore?: SessionStore;
    settingsStore?: SettingsStore;
    viewStateStore?: ViewStateStore;
}

function ResourceCard({ resource, sessionStore, settingsStore, viewStateStore }: Props) {
    const history = useHistory();

    const onClick = () => {
        if (!sessionStore?.hasActiveMembership && resource.accessLevel === 'active-membership') {
            viewStateStore?.setPaymentWallVisible(true, `Resource: ${resource.title}`);
            return;
        }

        history.push(`/r/${resource.slug}`, JSON.stringify(resource));
    }

    return (<Card className={styles.itemContainer} onClick={onClick}>
        <div className={styles.itemHeader}>{resource.title}</div>
        <div className={styles.itemSubHeader}>{resource.description}</div>
        {resource.accessLevel === 'active-membership' &&
            <div className={styles.badge}>
                🔒 Member Perks
            </div>
        }
        <img className={styles.icon} src={resource.iconUrl} />
    </Card>);
}


export default inject('sessionStore', 'settingsStore', 'viewStateStore')(observer(ResourceCard));