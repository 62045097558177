class StringUtils {
    /**
     * Generating unique string id
     * @param {*} length - the length of id
     */
    static uniqueId(length: number = 6) {
        return (Math.random()*1e32).toString(36).substr(2, length);
    }


    static listToSentence(list: string[]) {
        if (list.length === 1) {
            return `${list[0]}`;
        }

        let senetence = '';
        list.forEach((item, index) => {
            if (index === list.length - 1) {
                senetence = senetence + `and ${item}`
            } else {
                senetence = senetence + `${item}${index === list.length - 2 ? '' : ','} `
            }
        })

        return senetence;
    }

    static percentage(num1: number = 0, num2: number = 0){
        if(num2 === 0) {
            return '0%';
        }

        return `${(num1 / num2 * 100).toFixed(0)}%`;
    }
}

export default StringUtils;