import Modal from "antd/lib/modal/Modal";
import React, { useEffect, useState } from "react";
import UserModel, { ActivityIcon, ActivityItem, ActivityType } from "../../../../../common/models/UserModel";
import { inject, observer } from "mobx-react";
import { InfoCircleOutlined, CheckCircleOutlined, ExclamationCircleOutlined, CalendarOutlined, DollarCircleOutlined, EyeOutlined, AuditOutlined, UserSwitchOutlined, UserDeleteOutlined, UserAddOutlined } from '@ant-design/icons';
import styles from './MemberMetricsModal.module.css';
import MembersStore from "../../../../../store/MembersStore";
import UserAvatar from "../../../../../common/components/avatar/UserAvatar";
import moment from "moment";
import { Avatar, List, Tabs, Tag, Timeline, Tooltip } from "antd";
import EventModel from "../../../../../common/models/EventModel";
import ControlPanelStore from "../../../../../store/ControlPanelStore";
import { useHistory } from "react-router-dom";
import _ from "lodash";
import { IntroRequestStatus } from "../../../introductions/IntroductionsManagementPage";
import IntroRequestModel from "../../../../../common/models/IntroRequestModel";
import FirebaseClient from "../../../../../services/FirebaseClient";
import SettingsStore from "../../../../../store/SettingsStore";



interface Props {
    visible: boolean,
    onDismiss: () => void;
    membersStore?: MembersStore;
    controlPanelStore?: ControlPanelStore,
    firebase?: FirebaseClient,
    settingsStore?: SettingsStore
}

function MemberMetricsModal({ visible, onDismiss, membersStore, controlPanelStore, firebase, settingsStore }: Props) {
    const { currentViewedMember } = membersStore!;
    const [user, setUser] = useState<UserModel>();
    const [events, setEvents] = useState<{ item: EventModel, attended: boolean }[]>()
    const [introRequests, setIntros] = useState<IntroRequestModel[]>()
    const [followers, setFollowers] = useState<UserModel[]>()
    const [following, setFollowing] = useState<UserModel[]>()
    const [referrerUser, setReferrerUser] = useState<UserModel>();
    const [activityItems, setActivityItems] = useState<ActivityItem[]>();

    useEffect(() => {
        if (currentViewedMember && visible) {
            membersStore?.fetchById(currentViewedMember?.id!).then(user => {
                setUser(user);
            })

            controlPanelStore?.fetchUserEvents(currentViewedMember?.id!).then(events => {
                setEvents(events);
            })

            controlPanelStore?.fetchUserIntros(currentViewedMember?.id!).then(intros => {
                setIntros(intros);
            })

            controlPanelStore?.fetchUserActivity(currentViewedMember?.id!).then(activity => {
                setActivityItems(activity);
            })

            if (currentViewedMember.followers && currentViewedMember.followers.length > 0) {
                firebase?.fetchUsers(currentViewedMember?.followers).then(followers => {
                    setFollowers(followers);
                });
            }

            if (currentViewedMember.following && currentViewedMember.following.length > 0) {
                firebase?.fetchUsers(currentViewedMember?.following).then(following => {
                    setFollowing(following);
                });
            }

        } else {
            setFollowers([]);
            setFollowing([]);
            setIntros([]);
            setEvents([]);
            setUser(undefined);
        }
    }, [currentViewedMember, visible]);

    useEffect(() => {
        if (user?.referrerUser) {
            membersStore?.fetchById(user.referrerUser).then(user => {
                setReferrerUser(user);
            });
        }
    }, [user])

    const getActivityIcon = (type: ActivityIcon) => {
        switch (type) {
            case 'regular': return;
            case 'success': return <CheckCircleOutlined className={styles.timelineIcon} />;
            case 'warning': return <ExclamationCircleOutlined className={styles.timelineIcon} />;
            case 'calendar': return <CalendarOutlined className={styles.timelineIcon} />;
            case 'info': return <InfoCircleOutlined className={styles.timelineIcon} />;
            case 'money': return <DollarCircleOutlined className={styles.timelineIcon} />;
            case 'view': return <EyeOutlined className={styles.timelineIcon} />;
            case 'follow': return <UserAddOutlined className={styles.timelineIcon} />;
            case 'unfollow': return <UserDeleteOutlined className={styles.timelineIcon} />;
            case 'intro': return <UserSwitchOutlined className={styles.timelineIcon} />;
            default: return '';
        }
    }


    return (<Modal
        destroyOnClose={true}
        bodyStyle={{ padding: 0 }}
        visible={visible}
        centered
        width={600}
        onCancel={onDismiss}
        footer={null}>
        <div className={styles.content}>
            <div className={styles.top}>
                <UserAvatar size={54} userImage={user?.photoUrl} companyImage={user?.company?.logoUrl} />
                <div className={styles.texts}>
                    <div className={styles.name}>{user?.fullName}</div>
                    <div className={styles.title}>{user?.title}</div>
                    {referrerUser &&
                        <div className={styles.referrerUserContainer}>
                            <div>Invited by {referrerUser.fullName}</div>
                        </div>
                    }
                </div>
            </div>
            <div className={styles.bottomPart}>
                <div className={styles.overview}>
                    <div className={styles.header}>Overview</div>
                    <div className={styles.overviewItems}>
                        <div className={styles.overviewItem}>
                            <div className={styles.overviewItemHeader}>Joined</div>
                            <div className={styles.overviewItemValue}>{moment.unix(user?.createdAt.seconds).format('MMM DD, YYYY')}</div>
                        </div>
                        <div className={styles.overviewItem}>
                            <div className={styles.overviewItemHeader}>Last Seen</div>
                            <div className={styles.overviewItemValue}>
                                {user?.presence ?
                                    moment.unix(user?.presence.lastChanged?.seconds).fromNow()
                                    :
                                    'Unknown'
                                }
                            </div>
                        </div>
                        <div className={styles.overviewItem}>
                            <div className={styles.overviewItemHeader}>Events Attended</div>
                            <div className={styles.overviewItemValue}>{user?.stats?.eventsRegistered ? `${events?.filter(e => e.attended).length} out of ${user?.stats?.eventsRegistered}` : 'None'}</div>
                        </div>
                        <div className={styles.overviewItem}>
                            <div className={styles.overviewItemHeader}>Intros</div>
                            <div className={styles.overviewItemValue}>{introRequests ? introRequests.length : 'None'}</div>
                        </div>
                    </div>
                </div>
                { settingsStore?.membership?.enabled && 
                <div className={styles.overview}>
                    <div className={styles.header}>Membership</div>
                    <div className={styles.membershipItems}>
                        <div className={styles.overviewItem}>
                            <div className={styles.overviewItemHeader}>Status</div>
                            <div className={styles.overviewItemValue}>{user?.membership?.status?.toUpperCase()}</div>
                        </div>
                        {user?.membership?.status === 'active' &&
                            <div className={styles.overviewItem}>
                                <div className={styles.overviewItemHeader}>Expired at</div>
                                <div className={styles.overviewItemValue}>{user?.membership?.expiredAt?.seconds ? moment.unix(user?.membership?.expiredAt?.seconds).format('MMM DD, YYYY') : 'N/A'}</div>
                            </div>
                        }
                        <div className={styles.overviewItem}>
                            <div className={styles.overviewItemHeader}>Revenue</div>
                            <div className={styles.overviewItemValue}>${user?.membership?.totalRevenue || 0}</div>
                        </div>
                        {user?.membership?.status === 'trial' &&
                            <div className={styles.overviewItem}>
                                <div className={styles.overviewItemHeader}>Credits</div>
                                <div className={styles.overviewItemValue}>
                                    Events: {user?.membership?.credits?.events || 0} Intros: {user?.membership?.credits?.intros}
                                </div>
                            </div>
                        }
                    </div>
                </div> }
                <div className={styles.activity}>
                    <div className={styles.header}>Engagement</div>
                    <Tabs className={styles.tabs} defaultActiveKey="activity">
                        <Tabs.TabPane tab="Activity" key="activity">
                            <Timeline className={styles.timeline}>
                                {_.orderBy(activityItems, i => i.createdAt, 'desc')?.map((item, index) =>
                                    <Timeline.Item dot={getActivityIcon(item.icon)} key={index + 1}>
                                        <span className={styles.timelineItemMessage}>
                                            {item.message}
                                        </span>
                                        <span className={styles.timelineItemTime}>
                                            {moment.unix(item.createdAt).fromNow()}
                                        </span>
                                    </Timeline.Item>)}
                                <Timeline.Item dot={<AuditOutlined />} key={0}>
                                    <span className={styles.timelineItemMessage}>
                                        Signed-up to the platform.
                                        </span>
                                    <span className={styles.timelineItemTime}>
                                        {moment.unix(user?.createdAt.seconds).fromNow()}
                                    </span>
                                </Timeline.Item>
                            </Timeline>
                        </Tabs.TabPane>
                        <Tabs.TabPane tab="Events" key="events">
                            <List
                                className={styles.events}
                                itemLayout="horizontal"
                                dataSource={_.orderBy(events, e => e.item.startDate, 'desc')}
                                renderItem={event => (
                                    <List.Item key={event.item.id}>
                                        <EventItem key={event.item.id} eventModel={event.item!} attended={event.attended} />
                                    </List.Item>
                                )}
                            />
                        </Tabs.TabPane>
                        <Tabs.TabPane tab="Intros" key="intros">
                            <List
                                className={styles.introList}
                                itemLayout="horizontal"
                                dataSource={_.orderBy(introRequests, i => i.createdAt, 'desc')}
                                renderItem={intro => (
                                    <List.Item key={intro.id}
                                    >
                                        <div className={styles.introItem}>
                                            <Avatar.Group>
                                                <Tooltip placement='top' title={`${intro.requester?.firstName} ${intro.requester?.lastName}, ${UserModel.title(intro.requester?.role, intro.requester?.company)}`}>
                                                    <Avatar className={styles.teamAvatar} src={intro.requester?.photoUrl} />
                                                </Tooltip>
                                                <Tooltip placement='top' title={`${intro.requested?.firstName} ${intro.requested?.lastName}, ${UserModel.title(intro.requested?.role, intro.requested?.company)}`}>
                                                    <Avatar className={styles.teamAvatar} src={intro.requested?.photoUrl} />
                                                </Tooltip>
                                            </Avatar.Group>
                                            <div className={styles.introTitle}>
                                                {/* <SwapOutlined style={{ margin: '0px 4px' }} /> */}
                                                <span className={styles.introName}>{UserModel.shortName(intro.requester?.firstName, intro.requester?.lastName)}</span>
                                                <span> requested intro to </span>
                                                <span className={styles.introName}>{UserModel.shortName(intro.requested?.firstName, intro.requested?.lastName)}</span>
                                            </div>
                                            <IntroRequestStatus status={intro.status!} />
                                        </div>
                                    </List.Item>
                                )}
                            />
                        </Tabs.TabPane>
                        <Tabs.TabPane tab="Following" key="following">
                            <List
                                className={styles.events}
                                itemLayout="horizontal"
                                dataSource={following}
                                renderItem={user => (
                                    <List.Item key={user.id}
                                    >
                                        <List.Item.Meta
                                            avatar={<Avatar src={user.photoUrl} />}
                                            title={user.fullName}
                                            description={user.title}
                                        />
                                    </List.Item>
                                )}
                            />
                        </Tabs.TabPane>
                        <Tabs.TabPane tab="Followers" key="Followers">
                            <List
                                className={styles.events}
                                itemLayout="horizontal"
                                dataSource={followers}
                                renderItem={user => (
                                    <List.Item key={user.id}
                                    >
                                        <List.Item.Meta
                                            avatar={<Avatar src={user.photoUrl} />}
                                            title={user.fullName}
                                            description={user.title}
                                        />
                                    </List.Item>
                                )}
                            />
                        </Tabs.TabPane>
                    </Tabs>
                </div>
            </div>
        </div>
    </Modal>);
}

const EventItem = ({ eventModel, attended }: { eventModel: EventModel, attended: boolean }) => {
    const onClick = () => {
        window.location.replace(`/e/${eventModel.shortId}`)
    }
    return <div className={styles.eventItem} onClick={onClick}>
        <div className={styles.eventTitle}>{eventModel.title}</div>
        <div className={styles.eventDate}>{eventModel.longDateString}</div>
        <div className={styles.tags}>
            <Tag color='orange'>Registered</Tag>
            {attended && <Tag color='success'>Attended</Tag>}
        </div>
    </div>

}

export default inject('membersStore', 'controlPanelStore', 'firebase', 'settingsStore')(observer(MemberMetricsModal));