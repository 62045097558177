import { Button, Modal, Tooltip } from "antd";
import moment from "moment-timezone";
import React, { useEffect, useState } from "react";
import { start } from "repl";
import UserAvatar from "../../../../common/components/avatar/UserAvatar";
import Card from "../../../../common/components/card/Card";
import IntroRequestModel from "../../../../common/models/IntroRequestModel";
import { inject, observer } from "mobx-react";
import { RightCircleOutlined, LeftCircleOutlined, ClockCircleOutlined, EnvironmentOutlined } from '@ant-design/icons';
import styles from './MeetingScheduler.module.css';
import ConnectionsStore from "../../../../store/ConnectionsStore";
import UserModel from "../../../../common/models/UserModel";
import { SuggestedHour, suggestHoursAccordingToTimezone } from "../../../../common/utils/TimeSuggest";
import ViewStateStore from "../../../../store/ViewStateStore";
const MIN_TIMESLOTS_TO_SELECT = 3;

export type SuggestedTime = {
    day: number,
    times: number[],
}

interface Props {
    introRequest: IntroRequestModel
    connectionsStore?: ConnectionsStore,
    viewStateStore?: ViewStateStore,
    onFinished?: () => void;
}

function MeetingScheduler({ introRequest, connectionsStore, viewStateStore, onFinished }: Props) {
    const { requested, requester } = introRequest;
    const { isMobile } = viewStateStore!;
    const [selectedTimes, setSelectedTimes] = useState(Array<number>());
    const [suggestedHours, setSuggestedHours] = useState(Array<SuggestedHour>());
    const [suggestedTimes, setSuggestedTimes] = useState(Array<SuggestedTime>());
    const [weekOffset, setWeekOffset] = useState(0);
    const [busy, setBusy] = useState(false);
    const timezone = moment.tz.guess();

    useEffect(() => {
        const hours = suggestHoursAccordingToTimezone(timezone, introRequest.requester?.timezone);
        setSuggestedHours(hours);
        if (introRequest.requestedTimeAvailability) {
            setSelectedTimes(introRequest.requestedTimeAvailability);
        }
    }, [introRequest])

    useEffect(() => {
        updateSuggestedTimes();
    }, [suggestedHours])

    useEffect(() => {
        updateSuggestedTimes();
    }, [weekOffset])

    const updateSuggestedTimes = () => {
        const today = moment();
        let startAt;

        // show 2 week ahead if this is the end of current week
        if (today.weekday() === 6 || today.weekday() === 7) {
            startAt = moment().add(weekOffset + 2, 'weeks').startOf('isoWeek');
        } else { // show dates dates starting next week
            startAt = moment().add(weekOffset + 1, 'weeks').startOf('isoWeek');
        }

        const suggestedTimes = Array<SuggestedTime>();
        for (let i = 0; i < 5; i++) {
            const dayOfWeek = moment(startAt).add(i, 'days');
            suggestedTimes.push({
                day: dayOfWeek.unix(),
                times: suggestedHours.map(suggestedHour => dayOfWeek.set('hour', suggestedHour.hour).unix())
            })
        }

        setSuggestedTimes(suggestedTimes);

    }

    const isTimeSelected = (time: number) => {
        return selectedTimes.indexOf(time) > -1;
    }


    const timeSelected = (time: number) => {
        if (isTimeSelected(time)) {
            setSelectedTimes(prev => prev.filter(t => t !== time));
        } else {
            setSelectedTimes(prev => [...prev, time]);
        }
    }

    const showNextWeek = () => {
        setWeekOffset(1);
    }

    const showPreviousWeek = () => {
        setWeekOffset(0);
    }

    const onFinish = () => {
        setBusy(true);
        connectionsStore?.introductionAccepted(introRequest, selectedTimes);
        setBusy(false);
        onFinished && onFinished();
    }


    return (<div className={styles.container}>
        <div className={styles.content}>
            <div className={styles.header}>
                <div className={styles.bubblesContainer}>
                    <UserAvatar size={80} userImage={requested?.photoUrl} companyImage={requested?.company?.logoUrl} className={styles.userAvatar} />
                    <UserAvatar size={80} userImage={requester?.photoUrl} companyImage={requester?.company?.logoUrl} />
                </div>
                <div className={styles.title}>
                    Meeting with {requester?.firstName} {requester?.lastName}
                </div>
                <div className={styles.icons}>
                    <span><ClockCircleOutlined className={styles.icon} /> 30 minutes</span>
                    <span style={{ marginLeft: 16 }}><EnvironmentOutlined className={styles.icon} /> Video Call</span>
                </div>
                <div className={styles.subTitle}>
                    When would be a good time to meet?
                </div>
            </div>
            <Card className={styles.calendar}>
                <div className={styles.calendarContent}>
                    {suggestedTimes.map(suggestedTime => {
                        return <div className={styles.dayColumn}>
                            <div className={styles.dayHeader}>
                                <div className={styles.dayName}>{moment.unix(suggestedTime.day).format('ddd')}</div>
                                <div className={styles.dayDate}>{moment.unix(suggestedTime.day).format('MMM D')}</div>
                            </div>
                            <div className={styles.timesColumn}>
                                {suggestedTime.times.map(time =>
                                    <Tooltip title={
                                        isMobile ? undefined :
                                            requester?.timezone ?
                                                `${moment.unix(time).tz(requester?.timezone).format('H:mm')} for ${requester?.firstName}`
                                                : undefined
                                    } key={time}>
                                        <div onClick={() => timeSelected(time)} className={`${styles.timeOption} ${isTimeSelected(time) && styles.timeOptionSelected}`}>
                                            {moment.unix(time).format('HH:mm')}
                                        </div>
                                    </Tooltip>
                                )}

                            </div>
                        </div>
                    })
                    }
                </div>
                {weekOffset === 0 &&
                    <div onClick={showNextWeek} className={styles.nextWeekButton}>
                        <Tooltip title={'Show next week'}>
                            <span className={styles.weekText}>Next Week </span> <RightCircleOutlined />
                        </Tooltip>
                    </div>
                }
                {weekOffset !== 0 &&
                    <div onClick={showPreviousWeek} className={styles.previousWeekButton}>
                        <Tooltip title={'Show previous week'}>
                            <LeftCircleOutlined /> <span className={styles.weekText}>Previous Week </span>
                        </Tooltip>
                    </div>
                }
                <div className={styles.calendarBottom}>
                    <span>Best times to meet according to your </span>
                    <Tooltip placement='bottom' title={<div className={styles.timezonesTooltip}>
                        <div>
                            <b>You:</b> {moment.tz(timezone).format('z')} ({timezone})
                        </div>
                        <div>
                            <b>{requester?.firstName}:</b> {requester?.timezone ? `${moment.tz(requester.timezone).format('z')} (${requester.timezone}` : 'Unknown'})
                        </div>
                    </div>}>
                        <span className={styles.undelineDercoration}>timezones</span>
                    </Tooltip>

                </div>
            </Card>

            <div className={styles.stickyBarOffset}>
            </div>
        </div>
        <div className={styles.stickyBar}>
            <div className={styles.stickyBarContent}>
                <div className={styles.guide}>
                    {selectedTimes.length === 0 &&
                        <span>Please select at least <span className={styles.selectedDatesNumber}>{MIN_TIMESLOTS_TO_SELECT}</span> dates you're available</span>
                    }
                    {selectedTimes.length > 0 && selectedTimes.length < MIN_TIMESLOTS_TO_SELECT &&
                        <span>Please select <span className={styles.selectedDatesNumber}>{MIN_TIMESLOTS_TO_SELECT - selectedTimes.length}</span> more date{selectedTimes.length === MIN_TIMESLOTS_TO_SELECT - 1 ? '' : 's'} </span>
                    }
                    {selectedTimes.length >= MIN_TIMESLOTS_TO_SELECT &&
                        <span style={{ fontWeight: 600 }}>You've selected <span className={styles.selectedDatesNumber}>{selectedTimes.length}</span> dates</span>
                    }
                </div>
                <Button size='large'
                    type={'primary'}
                    loading={busy}
                    disabled={selectedTimes.length < MIN_TIMESLOTS_TO_SELECT}
                    onClick={onFinish}
                    className={`${styles.doneButton} ${selectedTimes.length >= MIN_TIMESLOTS_TO_SELECT ? 'animate__animated animate__tada' : ''}`}>
                    I'm done
                    </Button>
            </div>
        </div>
    </div>);
}


export default inject('connectionsStore', 'viewStateStore')(observer(MeetingScheduler));



