import { inject, observer } from 'mobx-react';
import React, { useEffect, useState } from 'react';
import { Redirect } from 'react-router-dom';
import styles from './WaitingListPage.module.css'
import SessionStore from '../../store/SessionStore';
import { Button } from 'antd';
import AnalyticsReporter from '../../services/analytics/AnalyticsReporter';
import SettingsStore from '../../store/SettingsStore';
import Logo from '../../common/components/logo/Logo';

interface Props {
    sessionStore: SessionStore
    settingsStore: SettingsStore;
    analyticsReporter: AnalyticsReporter
}

function WaitingListPage({ sessionStore, analyticsReporter, settingsStore }: Props) {
    const { authUser, accessPermitted } = sessionStore;

    useEffect(() => {
        analyticsReporter.trackPage('Waiting List Page');
    }, [])

    if (!sessionStore.isAuthed) {
        return <Redirect to='/login' />
    }

    if (sessionStore.isAuthed && sessionStore.isLeadersMember) {
        return <Redirect to='/' />
    }

    if (accessPermitted) {
        return <Redirect to='/onboarding' />
    }

    return (
        <div className={styles.container}>
            <Logo />
            <div className={styles.header}>
                We're an invite only community<span style={{ color: settingsStore.space?.primaryColor }}>.</span>
            </div>
            <div>You must be invited in order to join.</div>

            <Button target='_blank' href={settingsStore.access?.requestAccessUrl} size='large' type='primary' className={styles.button}>
                Apply to join
            </Button>
            <div className={styles.logout}>
                Not {authUser?.firstName ? authUser?.firstName : authUser?.email}? <a onClick={sessionStore.logout}>Logout.</a>
            </div>
        </div>
    )
}

export default inject('sessionStore', 'analyticsReporter', 'settingsStore')(observer(WaitingListPage));