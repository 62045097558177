import { inject, observer } from 'mobx-react';
import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import SectionHeader from '../../../../../common/components/sectionHeader/SectionHeader';
import MembersStore from '../../../../../store/MembersStore';
import SessionStore from '../../../../../store/SessionStore';
import styles from './ResourcesStrip.module.css';
import phocuswrightLogo from '../../../../../common/components/logo/pw-logo.png'
import { isMobile } from 'react-device-detect';
import ViewStateStore from '../../../../../store/ViewStateStore';
import ResourcesStore from '../../../../../store/ResourcesStore';
import { useEffect } from 'react';
import { ResourceModel } from '../../../../../common/models/ResourceModel';
import ResourceCard from '../../../../resources/components/resourceCard/ResourceCard';

interface Props {
    sessionStore?: SessionStore;
    viewStateStore?: ViewStateStore;
    resourcesStore?: ResourcesStore;
}

function ResourcesStrip({ sessionStore, resourcesStore, viewStateStore }: Props) {
    const { loggedIn } = sessionStore!;
    const [resources, setResources] = useState<ResourceModel[]>();


    useEffect(() => {
        if (loggedIn) {
            resourcesStore?.fetchLatest().then(resources => {
                setResources(resources);
            });
        }
    }, [loggedIn])

    if (!resources || resources?.length === 0) {
        return <></>;
    }

    return (<div className={styles.container}>
        <SectionHeader title={'Recommended resources for you'} size='medium'
            subTitle={<div className={styles.subTitle}>Here are a few contents and resources you might find interesting.</div>}
            linkTitle={'See All'}
            linkTo='/resources' />
        <div className={styles.strip}>
            {resources?.map(resource => {
                return <ResourceCard key={resource.id} resource={resource} />
            })}
        </div>
    </div>
    )
}

export default inject('sessionStore', 'resourcesStore', 'viewStateStore')(observer(ResourcesStrip));