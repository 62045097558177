import { throws } from 'assert';
import { action, computed, makeObservable, observable, reaction, runInAction } from 'mobx';
import Video, { LocalVideoTrack } from 'twilio-video';
import RootStore from './RootStore';

class VideoRoomStore {
    currentRoom?: Video.Room;
    rootStore: RootStore;
    selectedVideoInput?: string;
    selectedAudioInput?: string; 
    screenShareTrack?: LocalVideoTrack;
    videoToken?: string; 
    isLocalScreenShare: boolean = false;

    constructor(rootStore: RootStore) {
        makeObservable(this, {
            currentRoom: observable,
            screenShareTrack: observable,
            selectedVideoInput: observable,
            selectedAudioInput: observable,
            isLocalScreenShare: observable,
            videoToken: observable,
            screenShareMode: computed,
            setCurrentRoom: action,
            clearCurrentRoom: action,
            setSelectedInputs: action,
            setScreenShareTrack: action,
        })

        this.rootStore = rootStore;
    }

    get screenShareMode() {
        return this.screenShareTrack !== undefined;
    }

    setCurrentRoom = (room: Video.Room) => {
        this.currentRoom = room;
    }

    setScreenShareTrack(track?: LocalVideoTrack, isLocal: boolean = false) {
        this.screenShareTrack = track;
        this.isLocalScreenShare = isLocal;
    }

    clearCurrentRoom = () => {
        this.currentRoom?.disconnect();
        this.currentRoom = undefined;
    }

    setSelectedInputs(videoInput: string, audioInput: string) {
        this.selectedVideoInput = videoInput;
        this.selectedAudioInput = audioInput;
    }

    generateConversationToken = async () => {
        if (this.videoToken) {
            return this.videoToken;
        }

        const token = await this.rootStore.firebase.generateVideoToken();
        runInAction(() => {
            this.videoToken = token;
        })
        
        return token;
    }

}

export default VideoRoomStore;