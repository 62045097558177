import { AutoComplete, Button, Form, Input, message, Upload } from 'antd';
import { inject, observer } from 'mobx-react';
import React, { useState } from 'react';
import OnboardingStore, { Objective } from '../../../../store/OnboardingStore';
import NavigationButtons from '../navigationButtons/NavigationButtons';
import StepWrapper from '../stepWrapper/StepWrapper';
import styles from './ObjectivesStep.module.css';
import brain from './images/brain.png';
import business from './images/business.png';
import explore from './images/explore.png';
import grow from './images/grow.png';
import invest from './images/invest.png';
import learn from './images/learn.png';
import meet from './images/meet.png';
import raise from './images/raise.png';
import team from './images/team.png';
import _ from 'lodash';


export const objectives: Objective[] = [
    { id: 'raise-funding', title: `Raise funding`, icon: raise },
    { id: 'network', title: `Meet interesting people`, icon: meet },
    { id: 'knowledge', title: `Gain professional knowledge`, icon: learn },
    { id: 'business-development', title: `Business development`, icon: business },
    { id: 'brainstorm', title: `Brainstorm with peers`, icon: brain },
    { id: 'invest', title: `Invest`, icon: invest },
    { id: 'team-growth', title: `Grow my team`, icon: team },
    { id: 'mentor', title: `Mentor others`, icon: grow },
    { id: 'explore-companies', title: `Explore interesting companies`, icon: explore },
    //{ name: 'start-company', title: `Start a new company`, icon: '' },
]

interface Props {
    onNext: () => void,
    onboardingStore?: OnboardingStore,
}

function ObjectivesStep({ onboardingStore, onNext }: Props) {
    const [selectedObjectives, setSelectedObjectives] = useState<Objective[]>([]);
    const [isBusy, setBusy] = useState(false);

    const onSelected = (objective: Objective) => {
        if (!objective.checked && selectedObjectives.length >= 3) {
            message.info('Please your top most 3 objectives.');
            return;
        }

        objective.checked = !objective.checked;

        if (objective.checked) {
            selectedObjectives.push(objective);
            setSelectedObjectives(_.clone(selectedObjectives));
        } else {
            setSelectedObjectives(selectedObjectives.filter(o => o.id !== objective.id));
        }

    }

    const onSubmit = async () => {
        setBusy(true);
        setTimeout(async () => {
            await onboardingStore?.onObjectivesSubmit(selectedObjectives);
            setBusy(false);
            onNext();
        }, 1000);
    }

    return <StepWrapper title={`What're your main objectives?`}
        subTitle={`This will help us to connect you with right people & content.`}>
        <div className={styles.content}>
            <div className={styles.objectivesContainer}>
                {
                    objectives.map(objective => {
                        return <div key={objective.id}
                            className={`${styles.objectiveButton} ${objective.checked ? styles.objectiveSelected : ''}`}
                            onClick={() => onSelected(objective)}>
                            <img src={objective.icon} className={styles.icon} />
                            <div>{objective.title}</div>
                        </div>
                    })
                }
            </div>

            <NavigationButtons buttonTitle={`I'm done!`} onNext={onSubmit} isBusy={isBusy} />
        </div>

    </StepWrapper>
}


export default inject('onboardingStore')(observer(ObjectivesStep));