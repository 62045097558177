import { Typography } from "antd";
import _ from "lodash";
import React from "react";
import UserAvatar from "../../../../../../../common/components/avatar/UserAvatar";
import Tag from "../../../../../../../common/components/tag/Tag";
import AttendeeModel from "../../../../../../../common/models/AttendeeModel";
import styles from './AttendeeInfo.module.css'
import { isWindows } from 'react-device-detect';
import { UserPublicData } from "../../../../../../../common/models/UserModel";
const flag = require('country-code-emoji');
const { Paragraph } = Typography;

interface Props {
    userInfo?: UserPublicData;
}

function AttendeeInfo({ userInfo }: Props) {

    return (
        <div className={styles.attendeePopover}>
            <div className={styles.basicInfo}>
                <UserAvatar size='small' userImage={userInfo?.photoUrl} companyImage={userInfo?.company?.logoUrl} />
                <div className={styles.basicInfoTexts}>
                    <div className={styles.fullname}>{userInfo?.firstName} {userInfo?.lastName}</div>
                    <div className={styles.title}>{userInfo?.role} { userInfo?.company?.name && `at ${userInfo?.company?.name}`}</div>
                </div>
            </div>
            { userInfo?.bio &&
                <div className={styles.bio}>
                    <Paragraph style={{ color: '#79818f' }} ellipsis={{ // handle expande bug
                        rows: 2, expandable: true, onExpand: (e) => {
                            try {
                                e.currentTarget.parentElement!.parentElement!.className! = styles.bioAuto
                            } catch (error) {
                                console.log(error);
                            }
                        }
                    }} >
                        {userInfo?.bio}
                    </Paragraph>
                </div>
            }
            <div className={styles.sectors}>
                <div className={styles.industryTags}>
                    {_.take(userInfo?.sectors, 1).map(i => <Tag key={i}>{i}</Tag>)}
                </div>
            </div>
            { userInfo?.location && userInfo?.location.countryCode &&
                <div>
                    <div className={styles.header}>Location</div>
                    <div>
                        {!isWindows ?
                            <span>
                                {flag(userInfo?.location?.countryCode)}
                            </span>
                            :
                            <span>
                                {userInfo?.location?.flagIcon && <img src={userInfo?.location?.flagIcon} />}
                            </span>
                        }
                        <span style={{ marginLeft: 4 }}> {userInfo?.location?.country}</span>
                    </div>
                </div>
            }


        </div>
    )
}

export default AttendeeInfo;