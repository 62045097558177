import React from 'react';
import ReactDOM from 'react-dom';
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import App from './App';
import { unregister } from './serviceWorker';
import RootStore from './store/RootStore';

Sentry.init({
  dsn: "https://338cb8d01f694bd284e8a7bffa6ef731@o488085.ingest.sentry.io/5547797",
  integrations: [
    new Integrations.BrowserTracing(),
  ],

  // We recommend adjusting this value in production, or using tracesSampler
  // for finer control
  tracesSampleRate: 1.0,
});


const rootStore = new RootStore();
(window as any).rootStore = rootStore;

ReactDOM.render(
  <React.StrictMode>
    <App rootStore={rootStore} />
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
unregister();
