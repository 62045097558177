import React, { useEffect, useRef, useState } from "react";
import styles from './AboutPage.module.css';
import SectionHeader from "../../../common/components/sectionHeader/SectionHeader";
import { inject, observer } from "mobx-react";
import SessionStore from "../../../store/SessionStore";
import doneAnimation from '../../../common/images/doneAnimation.json';
import AnalyticsReporter from "../../../services/analytics/AnalyticsReporter";
import MembersStore from "../../../store/MembersStore";
import UserModel from "../../../common/models/UserModel";
import FirebaseClient from "../../../services/FirebaseClient";
import { Card, Skeleton } from "antd";
import UserBox from "../members/components/userBox/UserBox";
import _ from "lodash";
import { Link } from "react-router-dom";
import logoImage from '../../../common/components/logo/logo-svg.svg'

interface Props {
    sessionStore: SessionStore;
    analyticsReporter: AnalyticsReporter;
    membersStore: MembersStore;
    firebase: FirebaseClient;
}

function AboutPage({ sessionStore, analyticsReporter, membersStore, firebase }: Props) {
    const { authUser } = sessionStore;
    const [busy, setBusy] = useState(false);
    const [teamMembers, setTeamMembers] = useState(new Array<UserModel>())

    useEffect(() => {
        analyticsReporter.trackPage('About Page');
    }, [])

    useEffect(() => {
        firebase.users()
            .limit(10)
            .where('manager', '==', true)
            .get()
            .then(result => {
                const members = result.docs.map(d => UserModel.mapFromServer({ id: d.id, ...d.data() }))
                setTeamMembers(_.orderBy(members, u => u.company?.name, 'desc'));
            })
    }, [])

    function onMemberClicked(userModel: UserModel) {
        membersStore.viewMember(userModel);
    }

    return <div className={styles.container}>
        <SectionHeader size='large' title={<div className={styles.title}>Our Core Values</div>}
            subTitle={`We're building a platform for creating real, authentic professional connections with the right people for you.`} />
        <Card className={styles.content}>
        What is Lorem Ipsum Lorem Ipsum is simply dummy text of the printing and typesetting industry Lorem Ipsum has been the industry's standard dummy text ever since the 1500s when an unknown printer took a galley of type and scrambled it to make a type specimen book it has?
        What is Lorem Ipsum Lorem Ipsum is simply dummy text of the printing and typesetting industry Lorem Ipsum has been the industry's standard dummy text ever since the 1500s when an unknown printer took a galley of type and scrambled it to make a type specimen book it has?
        </Card>

        <div className={styles.team}>
            <SectionHeader size='medium' title={`The Team`}
                subTitle={<span>Feel free to <Link className={styles.contactLink} to='/contact'>drop us a message</Link> or approach us directly in one of our events.</span>} />

            <div className={styles.strip}>
                {teamMembers && teamMembers.map((m: UserModel) => {
                    return <UserBox onClick={onMemberClicked} key={m.id} userModel={m} />
                })
                }

                {(!teamMembers || teamMembers.length === 0) &&
                    [1, 2, 3].map(i => {
                        return <Skeleton.Avatar key={i} active={true} style={{ width: 200, height: 200 }} shape={'square'} />
                    })
                }

            </div>

        </div>
    </div>
}


export default inject('sessionStore', 'analyticsReporter', 'membersStore', 'firebase')(observer(AboutPage));
