
import { Avatar, Form, Input, message, Modal, Radio, Select, Spin } from 'antd';
import { inject, observer } from 'mobx-react';
import React, { useEffect, useState } from 'react';
import ImageUpload from '../../../../../common/components/imageUpload/ImageUpload';
import { Speaker, TargetAudience } from '../../../../../common/models/EventModel';
import UserModel, { UserPublicData } from '../../../../../common/models/UserModel';
import MembersStore from '../../../../../store/MembersStore';
import styles from './TargetingModal.module.css';
import debounce from 'lodash/debounce';

interface Props {
    visible: boolean;
    onCreateOrUpdate: (target: TargetAudience, edit: boolean) => void;
    target?: TargetAudience
    onCancel: () => void;
    membersStore?: MembersStore;
}

function TargetingModal({ membersStore, visible, onCancel, onCreateOrUpdate, target }: Props) {
    const [form] = Form.useForm();
    const [editMode, setEditMode] = useState(false);
    const [fetching, setFetching] = useState(false);
    const [fetchedUsers, setFetchedUsers] = useState(Array<UserPublicData>());
    const [selectedMembers, setSelectedMembers] = useState(Array<UserPublicData>());

    useEffect(() => {
        if(target) {
            setEditMode(true);
            setSelectedMembers(target?.members || []);
    
            form.setFieldsValue({
                members: target?.members.map(m => m?.id)
            })
        }
    }, [target])

    const fetchUser = async (value: string) => {
        setFetching(true);
        const result = await membersStore!.fetchByName(value)
        setFetchedUsers(result.map(u => u.toPublicData()));
        setFetching(false);
    }

    const onSelect = (value: string, option: any) => {
        const user = option.extra;
        setSelectedMembers([...selectedMembers, user]);
    }

    const onDeselect = (value: string, option: any) => {
        const user = option.extra;
        setSelectedMembers([...selectedMembers.filter(m => m.id !== user.id)]);
    }

    const getOptions = () => {
        if(fetchedUsers.length > 0) {
            return fetchedUsers;
        }

        return selectedMembers;
    }

    const onConfirm = () => {
        form.validateFields()
            .then((values: any) => {
                if (!selectedMembers) {
                    message.error('Please select members to target')
                    return;
                }

                onCreateOrUpdate({ type: values.type, members: selectedMembers }, editMode)
                form.resetFields();
            })
            .catch(info => {
                console.log('Validate Failed:', info);
            });
    }

    return (
        <Modal
            visible={visible}
            title={editMode ? 'Edit Target Audience' : 'Add Target Audience'}
            okText={editMode ? 'Update' : 'Add'}
            cancelText="Cancel"
            centered
            className={'event-targeting-modal'}
            onCancel={onCancel}
            onOk={onConfirm}
        >
            <Form
                form={form}
                initialValues={{
                    type: 'members',
                }}
                layout="vertical"
                name="addTargetForm"
            >
                <Form.Item label="Select targeting type"
                    rules={[{ required: true, message: `Please select an option` }]}
                    name="type">
                    <Radio.Group size='large'>
                        <Radio.Button value={'members'}>Specfic Members</Radio.Button>
                    </Radio.Group>
                </Form.Item>
                <Form.Item
                    name="members"
                    style={{ marginTop: 54 }}
                    label='Target Members'
                    tooltip={'Select specific members who can see this event.'}>
                    <Select
                        mode="multiple"
                        allowClear
                        notFoundContent={fetching ? <Spin size="small" /> : null}
                        filterOption={false}
                        onSelect={onSelect}
                        onDeselect={onDeselect}
                        onSearch={debounce(fetchUser, 600)}
                        size={'large'}
                        className={styles.userSelect}>
                        {
                            getOptions().map(user => {
                                return <Select.Option extra={user} value={user.id!} key={user.id}>
                                    <div className={styles.attendeeOption}>
                                        <Avatar className={styles.attendeeAvatar} src={user.photoUrl} />
                                        <div className={styles.attendeeName}>{user.firstName} {user.lastName}</div>
                                    </div>
                                </Select.Option>

                            })
                        }
                    </Select>
                </Form.Item>
            </Form>
        </Modal>)
}

export default inject('membersStore')(observer(TargetingModal));