

const oneSecondInMilliseconds = 1000;

export default class TimeUtils {
    static getSeconds(seconds: number) {
        return oneSecondInMilliseconds * seconds; 
    }

    static getMintues(mintues: number) {
        return this.getSeconds(60) * mintues;
    }

    static getHours(hours: number) {
        return this.getMintues(60) * hours;
    }
}
