import _ from "lodash";
import { computed, makeObservable, observable, runInAction } from "mobx";
import moment from "moment";
import AttendeeModel, { AttendeeRole } from "../common/models/AttendeeModel";
import EventModel, { EventPublicData } from "../common/models/EventModel";
import IntroRequestModel from "../common/models/IntroRequestModel";
import StatModel from "../common/models/StatModel";
import UserModel, { ActivityItem } from "../common/models/UserModel";
import FirebaseClient from "../services/FirebaseClient";
import RootStore from "./RootStore";
import SessionStore from "./SessionStore";


class ControlPanelStore {
    rootStore: RootStore;
    firebase: FirebaseClient;
    sessionStore: SessionStore;
    allUsers: UserModel[] = [];
    recentStats: StatModel[] = [];

    constructor(rootStore: RootStore, firebase: FirebaseClient) {
        makeObservable(this, {
            allUsers: observable,
            recentStats: observable,
            members: computed,
            payingMembers: computed,
            membersChange: computed,
            payingMembersChange: computed,
        })

        this.rootStore = rootStore;
        this.firebase = firebase;
        this.sessionStore = rootStore.sessionStore;
    }

    get members() {
        return this.allUsers.filter(u =>u.leadersMember && u.onboardingCompleted)
    }

    get payingMembers() {
        return this.members.filter(m => m.membership?.status === 'active');
    }

    get membersChange() {
        if (this.members.length === 0) {
            return 0;
        }

        const latestStat = _.first(this.recentStats);
        return this.members.length - (latestStat?.totalMembers || 0);
    }

    get payingMembersChange() {
        if (this.members.length === 0) {
            return 0;
        }

        const latestStat = _.first(this.recentStats);
        return this.payingMembers.length - (latestStat?.totalPaidMembers || 0);
    }


    fetchStats = async (timeframe: number) => {
        const start = moment().subtract(timeframe, 'days').toDate();
        const end = moment().toDate();
        const result = await this.firebase.stats()
            .where('date', '>=', start)
            .where('date', '<=', end)
            .orderBy('date', 'asc')
            .get();


        runInAction(() => {
            this.recentStats = result.docs.map(s => StatModel.mapFromServer({ id: s.id, ...s.data() }));
        })

    }

    generateInvitationLink = async (options: any) => {
        await this.firebase.generateInvitationLinksForUser(this.sessionStore.authUser!, options);
    }

    deleteInvitation = async (id: string) => {
        await this.firebase.invitation(id).delete();
    }

    generateGuestInvitationLink = async (eventInfo: EventPublicData, guestRole: AttendeeRole, skipApproval: boolean) => {
        const invitationId = await this.firebase.generateGuestInvitation(this.sessionStore.authUser!, eventInfo, guestRole, skipApproval);
        return `${window.location.protocol}//${window.location.host}/guest-invitation/${invitationId}`;
    }

    fetchInvitationLinks = async () => {
        const result = await this.firebase.fetchInvitationLinksForUser(this.sessionStore.authUser!.id!);
        return result.filter(i => !i.isGuest);
    }

    fetchUserReferrals = async () => {
        return await this.firebase.fetchUserReferrals(this.sessionStore.authUser!.id!);
    }

    fetchAllMembers = async (force = false) => {
        if (this.allUsers.length !== 0 && !force) {
            return this.allUsers;
        }

        const result = await this.firebase.fetchAllMembers();
        const unique =  _.uniqBy(result, u => u.id);
        runInAction(() => {
            this.allUsers = unique;
        })

        return unique;
    }

    updateUser = async (id: string, change: any) => {
        this.firebase.updateUser(id, change);
    }

    deleteMember = async (userId: string) => {
        await this.firebase.deleteUser(userId);
    }

    fetchUserEvents = async (userId: string) => {
        const registeredEventsResult = await this.firebase.events()
            .where('attendeesIds', 'array-contains', userId)
            .get();


        const attendedEventsResult = await this.firebase.db.collectionGroup('attendees')
            .where('id', '==', userId)
            .get();


        const attended = attendedEventsResult.docs.map(a => AttendeeModel.mapFromServer({ id: a.id, ...a.data() }));
        const eventsAttended = attended.filter(a => a.attended).map(a => a.eventId);
        const registeredEvents = registeredEventsResult.docs.map(d => EventModel.mapFromServer({ id: d.id, ...d.data() }));

        return registeredEvents.map(e => {
            return {
                item: e,
                attended: eventsAttended.indexOf(e.id) > -1
            }
        });
    }

    fetchUserIntros = async (userId: string) => {
        const asRequester = this.firebase.introRequests()
            .where('requester.id', '==', userId)

        const asRequested = this.firebase.introRequests()
            .where('requested.id', '==', userId)

        const result = await Promise.all([asRequested.get(), asRequester.get()]);
        return _.merge(result[0].docs, result[1].docs).map(d => IntroRequestModel.mapFromServer({ id: d.id, ...d.data() }));
    }

    fetchUserActivity = async (userId: string) => {
        const result = await this.firebase.userActivity(userId).orderBy('createdAt', 'desc').get();
        return result.docs.map(d => d.data() as ActivityItem);

    }
}

export default ControlPanelStore;