
import _ from 'lodash';
import { observable, makeObservable, runInAction } from 'mobx';
import moment from 'moment';
import { ResourceModel } from '../common/models/ResourceModel';
import FirebaseClient from '../services/FirebaseClient';
import RootStore from './RootStore';
import { paramCase } from "param-case";

class ResourcesStore {
    rootStore: RootStore;
    firebase: FirebaseClient;
    resources: ResourceModel[] = []

    constructor(rootStore: RootStore, firebase: FirebaseClient) {
        makeObservable(this, {
            resources: observable,
        })

        this.rootStore = rootStore;
        this.firebase = firebase;
    }

    fetchBySlug = (slug: string) => {
        return this.firebase.resources().where('slug', '==', slug);
    }

    fetchLatest = async (limit = 4) => {
        const result = await this.firebase.resources()
            .limit(limit)
            .orderBy('createdAt', 'desc')
            .get();

        return result.docs.map(d => {
            return { id: d.id, ...d.data() as ResourceModel }
        });
    }

    subscribeAll = async () => {
        this.firebase.resources()
            .orderBy('createdAt', 'desc')
            .onSnapshot((snap) => {
                const result = snap.docs.map(d => {
                    return { id: d.id, ...d.data() } as ResourceModel
                })

                runInAction(() => {
                    this.resources = result;
                })
            });
    }

    addNew = async (data: any) => {
        await this.firebase.resources().add({
            ...data,
            createdAt: moment().unix(),
        });
    }

    editExisting = async (resourceId: string, change: any) => {
        await this.firebase.resource(resourceId).set({
            ...change,
            lastUpdate: moment().unix(),
        }, { merge: true });
    }

    delete = async (resourceId: string) => {
        await this.firebase.resource(resourceId).delete();
    }

}


export default ResourcesStore;


