import React, { useEffect, useState } from 'react';
import EventStore from '../../../../../../../store/EventStore';
import SessionStore from '../../../../../../../store/SessionStore';
import styles from './EventRoundTables.module.css';
import eventStyles from '../../LiveEventPage.module.css';
import { Button, message, Modal } from 'antd';
import { inject, observer } from 'mobx-react';
import RoundTable from './components/RoundTable';
import AddOrEditRoundTableModal from './components/addTableModal/AddOrEditRoundTableModal';
import RoundTableModel from '../../../../../../../common/models/RoundTableModel';
import RoundTableVideoRoom from './components/videoGroup/RoundTableVideoRoom';
import { SettingOutlined } from '@ant-design/icons';
import SettingsModal from '../settingsModal/SettingsModal';
import VideoRoomStore from '../../../../../../../store/VideoRoomStore';
import EventTimer from '../eventTimer/EventTimer';


interface Props {
    sessionStore?: SessionStore;
    eventStore?: EventStore;
    videoRoomStore?: VideoRoomStore;
}

function EventRoundTables({ sessionStore, eventStore, videoRoomStore }: Props) {
    const { authUser } = sessionStore!;
    const { event,
        isTransitioning,
        roundTables,
        currentTable } = eventStore!;
    const [showTableAddOrEditModal, setShowTableAddOrEditModal] = useState<{ show: boolean, roundTable?: RoundTableModel }>({ show: false })
    const [deviceSettingsVisible, setDeviceSettingsVisible] = useState(false);

    useEffect(() => {
        window.addEventListener("beforeunload", onUnload);

        return () => {
            onUnload();
            window.removeEventListener("beforeunload", onUnload);
        }
    }, [])

    const onUnload = () => {
        eventStore?.leaveRoundTable();
    }

    const startSession = () => {
        Modal.confirm({
            content: <div>
                <div className={eventStyles.modalHeader}>Are you sure?</div>
                <div className={eventStyles.modalText}>All attendees will be transfered here in 10 seconds after you confirm.</div>
            </div>,
            okText: 'Start Round Tables',
            async onOk() {
                if (event?.isStageActive) {
                    message.warn('Main stage is still running. Please end it first.')
                    return;
                }

                await eventStore?.changeEventState('tables');
            },
            onCancel() {
                Modal.destroyAll();
            },
        });
    }

    const endSession = () => {
        Modal.confirm({
            content: <div>
                <div className={eventStyles.modalHeader}>Are you sure you want to end stage?</div>
                <div className={eventStyles.modalText}>All attendees will be transferred to the lounge in 10 seconds.</div>
            </div>,
            okText: 'Go to Lounge',
            async onOk() {
                await eventStore?.changeEventState('lounge', async () => {
                    await eventStore?.cleanRoundTables();
                });
            },
            onCancel() {
                Modal.destroyAll();
            },
        });
    }

    const onRoundTableModalConfirm = async (title: string, maxSeats: number, preselectedAttendees: string[], roundTableId?: string) => {
        if (roundTableId) {
            await eventStore?.editRoundTable(roundTableId, title, maxSeats, preselectedAttendees);
        } else {
            await eventStore?.addRoundTable(title, maxSeats, preselectedAttendees);
        }

        setShowTableAddOrEditModal({ show: false, roundTable: undefined });
    }

    const onDelete = async (roundTable?: RoundTableModel) => {
        await eventStore?.deleteRoundTable(roundTable?.id!);
        setShowTableAddOrEditModal({ show: false, roundTable: undefined });
    }

    return (
        <div className={`round-tables ${styles.container}`}>
            <div className={styles.topStrip}>
                <div>
                    <div className={styles.header}>Round Tables</div>
                    <div className={styles.subHeader}>Take a seat to join the conversation.</div>
                </div>

                <div className={styles.buttons}>
                    {authUser?.isStaff &&
                        <div className={styles.adminButtons}>
                            <Button size={'large'} onClick={() => setShowTableAddOrEditModal({ show: true })} type='default' className={'white-button'}>
                                Add Table
                        </Button>
                            <div className={eventStyles.buttonsDivider} />
                            {event?.isRoundTablesActive ?
                                <Button size={'large'} type='primary' onClick={endSession} className={eventStyles.endSessionButton}>
                                    End Round Tables
                        </Button>
                                :
                                <Button size={'large'} type='primary' onClick={startSession} className={eventStyles.startSessionButton}>
                                    Start Round Tables
                             </Button>
                            }
                        </div>
                    }
                    {/* <div className={styles.settingsButton} onClick={() => setDeviceSettingsVisible(true)}>
                        <SettingOutlined />
                    </div> */}
                </div>


            </div>
            <div className={styles.content}>
                <div className={styles.roundTables}>
                    {roundTables.map((table, index) => <RoundTable key={index}
                        roundTable={table}
                        tableNumber={index + 1}
                        onEdit={roundTable => setShowTableAddOrEditModal({ show: true, roundTable: roundTable })} />)}
                </div>
            </div>

            {showTableAddOrEditModal.show && <AddOrEditRoundTableModal
                visible={showTableAddOrEditModal.show}
                roundTable={showTableAddOrEditModal.roundTable}
                onCancel={() => setShowTableAddOrEditModal({ show: false })}
                onConfirm={onRoundTableModalConfirm}
                onDelete={onDelete} />
            }

            { currentTable && <RoundTableVideoRoom />}

            <EventTimer timerModel={event?.timer} />
        </div>
    )
}

export default inject('sessionStore', 'eventStore', 'videoRoomStore')(observer(EventRoundTables));