export function beforeUpload(file: File, onError: (message: string) => void) {
    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png' || file.type === 'image/svg+xml';
    if (!isJpgOrPng) {
        onError('You can only upload JPG/PNG/SVG file.');
    }
    const isLt2M = file.size / 1024 / 1024 < 1;
    if (!isLt2M) {
        onError('Image must smaller than 1MB');
    }
    return isJpgOrPng && isLt2M;
}

export function getBase64(img: any, callback: ((result: any) => void)) {
    const reader = new FileReader();
    reader.addEventListener('load', () => callback(reader.result));
    reader.readAsDataURL(img);
}
