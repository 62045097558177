import React, { useState } from "react";
import styles from './IncomingConversationNotification.module.css';
import EventConversationModel from "../../../../../../../common/models/EventConversationModel";
import UserAvatar from "../../../../../../../common/components/avatar/UserAvatar";
import { Button } from "antd";

interface Props {
    onAccept: (conversation: EventConversationModel) => void;
    onReject: (conversation: EventConversationModel) => void;
    conversation: EventConversationModel;
}

function IncomingConversationNotification({ conversation, onAccept, onReject }: Props) {
    const [isBusy, setBusy] = useState(false);
    const { byUser } = conversation;

    const onAcceptClicked = () => {
        setBusy(true);
        onAccept(conversation);
    }

    const onRejectClicked = () => {
        onReject(conversation);
    }

    return <div className={styles.container}>
        <UserAvatar userImage={byUser?.photoUrl} 
        companyImage={byUser?.company?.logoUrl} />
        <div className={styles.messageContainer}>
            <div className={styles.title}>{byUser?.firstName} wants to talk with you</div>
            <div className={styles.subTitle}>If you accept, you'll be directed to a private video room.</div>
        </div>
        <div className={styles.buttonContainer}>
            <Button loading={isBusy} onClick={onAcceptClicked} type={'primary'} className={styles.acceptButton} size='large'>Accept</Button>
            <Button onClick={onRejectClicked} className={styles.rejectButton} size='large'>Reject</Button>
        </div>
    </div>
}

export default IncomingConversationNotification;