import { inject, observer } from 'mobx-react';
import React, { useEffect, useState } from 'react';
import Lottie from 'react-lottie';
import { EventState, EventStateTransition } from '../../../../../../../common/models/EventModel';
import UserModel from '../../../../../../../common/models/UserModel';
import EventStore from '../../../../../../../store/EventStore';
import MembersStore from '../../../../../../../store/MembersStore';
import SessionStore from '../../../../../../../store/SessionStore';
import ViewStateStore from '../../../../../../../store/ViewStateStore';
import styles from './EventTransitionNotification.module.css'
import doneAnimation from './loading.json';

const lottieFile = {
    loop: false,
    autoplay: true,
    animationData: doneAnimation,
    rendererSettings: {
        preserveAspectRatio: 'xMidYMid slice'
    }
};

interface Props {
    loaderMode?: boolean;
    eventTransition?: EventStateTransition
}

function EventTransitionNotification({ eventTransition, loaderMode }: Props) {
    const [counter, setCounter] = useState(10);

    useEffect(() => {
        if(loaderMode) {
            return;
        }
        
        setTimeout(() => {
            if (counter <= 0) {
                return;
            }
            setCounter(value => value - 1);
        }, 1000);
    }, [counter])

    return (
        <div className={styles.container}>
            <div className={styles.counterBox}>
                {loaderMode ?
                    <Lottie style={{padding: 12}} options={lottieFile} />
                    :
                    <div>{counter}</div>
                }
            </div>
            <div>
                <div className={styles.header}>
                    { loaderMode ? <span>Waiting for people to get in...</span> : <span>Wrap up!</span> }
                 </div>
                <div className={styles.subHeader}>
                    We're moving to the {eventTransition?.to} in a few seconds.
                </div>
            </div>
        </div>
    )
}

export default inject('sessionStore', 'eventStore', 'membersStore', 'viewStateStore')(observer(EventTransitionNotification));