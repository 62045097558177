import React, { useState } from 'react';
import Avatar from '../../../../common/components/avatar/UserAvatar';
import styles from './InvitationBubble.module.css';
import Lottie from 'react-lottie';
import boardingPassIcon from './boarding-pass.png';
import boardingPassAnimaton from './boardingpass.json'

const lottieFile = {
    loop: true,
    autoplay: true,
    animationData: boardingPassAnimaton,
    rendererSettings: {
        preserveAspectRatio: 'xMidYMid slice'
    }
};

interface Props {
    className?: string,
    user?: { firstName?: string, userImage?: string, companyName?: string, companyImage?: string }
}

function InvitationBubble({ user, className }: Props) {
    if (!user) {
        return <div></div>;
    }

    return (
        <div className={`${styles.container} ${className}`}>
            <div className={`${styles.boardingPassIcon} animate__animated animate__fadeIn`}>
                <Lottie options={lottieFile} />
            </div>
            <div className={`${styles.content} animate__animated animate__fadeIn`}>
                <Avatar userImage={user.userImage} companyImage={user.companyImage} />
                <div className={styles.textContainer}>
                    <div className={styles.header}>You received a boarding pass<span style={{ color: '#1cbec0' }}>.</span></div>
                    <div className={styles.secondaryText}><span>By </span><span style={{ fontWeight: 700 }}>{user.firstName} from {user.companyName}</span></div>
                </div>
            </div>

        </div>
    )
}

export default InvitationBubble;