import React, { useEffect, useRef, useState } from "react";
import Card from "../../../../../common/components/card/Card";
import { ConversationModel } from "../../../../../common/models/UserModel";
import { inject, observer } from "mobx-react";
import { MailFilled, EditFilled, StarFilled, LockOutlined, InfoCircleOutlined } from '@ant-design/icons';
import styles from './ConversationItem.module.css';
import SessionStore from "../../../../../store/SessionStore";
import _ from "lodash";
import UserAvatar from "../../../../../common/components/avatar/UserAvatar";
import { Button, Divider, Input, message, Modal, Tag, Tooltip } from "antd";
import MembersStore from "../../../../../store/MembersStore";
import ConnectionsStore from "../../../../../store/ConnectionsStore";
import moment from "moment";
import numeral from 'numeral';
import MeetingFeedback from "../../../../meetingRoom/components/feedback/MeetingFeedback";
import { useLocation } from "react-router-dom";



interface Props {
    conversationModel: ConversationModel,
    sessionStore?: SessionStore;
    membersStore?: MembersStore;
    connectionsStore?: ConnectionsStore;
}
 
function ConversationItem({ conversationModel, sessionStore, membersStore, connectionsStore }: Props) {
    const { authUser } = sessionStore!;
    const { participants } = conversationModel;
    const [followUpBusy, setFollowUpBusy] = useState(false);
    const [showFeedbackModal, setShowFeedbackModal] = useState(false);
    const notesInputRef = useRef<any>(null);
    const location = useLocation();

    useEffect(() => {
        const feedbackTo = new URLSearchParams(location.search).get("feedbackTo");
        if (feedbackTo && feedbackTo === conversationModel.id) {
            setShowFeedbackModal(true);
        }
    }, [])

    const otherParticipant = () => {
        const otherParticipants = participants?.filter(p => p.id !== authUser?.id);
        return _.first(otherParticipants);
    }

    const followUp = async () => {
        if (conversationModel.isFuture) {
            message.info(`You'll be able to follow up after the meeting`);
            return;
        }

        if (followUpBusy) {
            return;
        }

        setFollowUpBusy(true);
        const user = await membersStore?.fetchById(participant?.id!)
        window.location.href = `mailto:${user?.email}`;
        setFollowUpBusy(false);
        sessionStore?.addActivityItem(`Clicked Follow-up with ${user?.fullName}`, 'engagement');
    }

    const onNotesSaved = async () => {
        const text = notesInputRef.current?.state.value;
        await connectionsStore?.saveNotes(conversationModel.id!, text);
    }

    const addFeedback = () => {
        setShowFeedbackModal(true);
    }

    const dateTitle = () => {
        return moment.unix(conversationModel.date!).fromNow();
    }

    const onFeedbackCompleted = () => {
        setShowFeedbackModal(false);
    }

    const openNotes = () => {
        Modal.confirm({
            width: 600,
            content: (<div className={styles.notesContainer}>
                <div className={styles.notesDescription}><LockOutlined style={{ marginRight: 4 }} />Your notes are private and won't be shared with others.</div>
                <Divider />
                <Input.TextArea
                    ref={notesInputRef}
                    defaultValue={conversationModel?.notes}
                    bordered={false}
                    placeholder='Add your notes here...'
                    rows={8}
                    className={styles.notesInput} />
            </div>),
            icon: '',
            okText: 'Save Notes',
            async onOk() {
                await onNotesSaved();
            },
            onCancel() {
                Modal.destroyAll();
            },
        })
    }

    const onMemberInfoClicked = async () => {
        const user = await membersStore?.fetchById(participant?.id!)
        membersStore?.viewMember(user);
    }

    const renderFeedback = () => {
        if (!conversationModel.happend && conversationModel.happend !== undefined) {
            return <div className={styles.didntHappen}>
                MEETING DIDN'T HAPPEN
            </div>
        }

        if (conversationModel.feedback) {
            return <div className={styles.feedbackScore}>
                <span style={{ marginRight: 4 }} className={styles.scoreText}>{numeral(conversationModel.feedback?.score).format('0.0')}</span>
                <StarFilled className={styles.starIcon} />
            </div>
        }

        if (conversationModel.isFuture) {
            return <div className={styles.upcomingMeeting}>
                Upcoming Meeting
            </div>
        }

        return <div onClick={addFeedback} style={{ cursor: 'pointer' }} className={styles.scoreText}>
            + Add Feedback
        </div>

    }

    const participant = otherParticipant();

    return (<Card className={styles.container}>
        <div className={styles.topBar}>
            <Tooltip placement={'top'} title={moment.unix(conversationModel.date!).format('dddd, MMMM D, h:mmA')}>
                <div className={styles.date}>
                    {dateTitle()} {conversationModel.isFuture && <InfoCircleOutlined className={styles.infoIcon} />}
                </div>
            </Tooltip>
            {renderFeedback()}
        </div>
        <div className={styles.participantInfo} onClick={onMemberInfoClicked}>
            <div className={styles.texts}>
                <div className={styles.name}>{participant?.user?.firstName} {participant?.user?.lastName}</div>
                <div className={styles.title}>{participant?.user?.company?.name}</div>
            </div>
            <UserAvatar className={styles.avatar} size={60} userImage={participant?.user?.photoUrl} companyImage={participant?.user?.company?.logoUrl} />
        </div>

        <div className={styles.actions}>
            <div className={styles.horizontalDivider} />
            <div className={styles.buttons}>
                <div onClick={followUp} className={`${styles.button} ${conversationModel.isFuture && styles.buttonDisabled}`}>
                    <MailFilled className={`${styles.icon} ${conversationModel.isFuture && styles.buttonDisabled}`} />
                    Follow-up
                    </div>
                <div className={styles.verticalDivider} />
                <div onClick={openNotes} className={styles.button}>
                    <EditFilled className={styles.icon} />
                    My notes
                    </div>
            </div>
        </div>

        <Modal bodyStyle={{ padding: 0 }} footer={null} visible={showFeedbackModal} onCancel={() => setShowFeedbackModal(false)}>
            <MeetingFeedback onComplete={onFeedbackCompleted} conversation={conversationModel} />
        </Modal>
    </Card >);
}


export default inject('sessionStore', 'membersStore', 'connectionsStore')(observer(ConversationItem));