import { Avatar, Dropdown, Menu } from 'antd';
import { inject, observer } from 'mobx-react';
import React from 'react';
import UserAvatar from '../../../../common/components/avatar/UserAvatar';
import SessionStore from '../../../../store/SessionStore';
import { LogoutOutlined, HomeOutlined, UserOutlined, SettingOutlined, StarOutlined } from '@ant-design/icons';

import styles from './EditProfileBubble.module.css'
import { useHistory } from 'react-router-dom';
import SettingsStore from '../../../../store/SettingsStore';
import ViewStateStore from '../../../../store/ViewStateStore';

interface Props {
    sessionStore?: SessionStore;
    settingsStore?: SettingsStore;
    viewStateStore?: ViewStateStore;
}

function EditProfileBubble({ sessionStore, settingsStore, viewStateStore }: Props) {
    const history = useHistory();
    const { authUser } = sessionStore!;

    return <Dropdown overlay={menu(sessionStore!, settingsStore!, viewStateStore!, history)} placement="bottomRight" trigger={['click']}>
        <div className={styles.avatarContainer}>
        <Avatar icon={<UserOutlined />} className={styles.avatar} style={settingsStore?.membership?.enabled && sessionStore?.loggedIn && sessionStore?.hasActiveMembership ? { borderColor: '#caa83c' } :{ }} src={authUser?.photoUrl} />
        {settingsStore?.membership?.enabled  &&  <div className={styles.badge} style={sessionStore?.hasActiveMembership ? { backgroundColor: '#caa83c' } : {}}>{sessionStore?.hasActiveMembership ? 'Premium' : 'Trial' }</div> }
        </div>
        
    </Dropdown>
}

function menu(sessionStore: SessionStore, settingsStore: SettingsStore, viewStateStore: ViewStateStore, history: any) {
    const { authUser } = sessionStore;
    const { membership } = settingsStore;

    const isLiveEvent = () => {
        return window.location.pathname.indexOf('/live') > -1;
    }

    const goHome = () => {
        history.push('/');
    }

    const goToAccountSettings = () => {
        history.push('/account');
    }

    const proMemberClicked = () => {
        if(authUser?.hasActiveMembership) {
            goToAccountSettings();
        } else {
            viewStateStore?.setPaymentWallVisible(true, 'User Profile Bubble');
        }
        
    }

    return (
        <div className={styles.menu}>
            <div className={styles.profileDetails} onClick={() => history.push('/profile')}>
                <UserAvatar userImage={sessionStore.profilePhoto} />
                <div className={styles.profileText}>
                    <div className={styles.userName}>
                        {sessionStore.fullName}
                    </div>
                    <div className={styles.editProfileText}>
                        EDIT PROFILE
                </div>
                </div>
            </div>

            <Menu style={{ marginTop: 24, fontWeight: 600 }}>
                {authUser?.leadersMember && isLiveEvent() &&
                    <Menu.Item icon={<HomeOutlined />} onClick={goHome}>
                        Leaders Homepage
             </Menu.Item>
                }
                {membership?.enabled &&
                    < Menu.Item className={styles.proItem} style={authUser?.hasActiveMembership ? { backgroundColor: viewStateStore.premiumColor, color: 'white' } : {}} icon={<StarOutlined />} onClick={proMemberClicked}>
                        {authUser?.hasActiveMembership ? <span className={styles.proMember}>You're a Premium Member</span> : <span className={styles.trialMember}>Become a Premium Member</span>}
                    </Menu.Item>
                }
                <Menu.Item icon={<SettingOutlined />} onClick={goToAccountSettings}>
                    Account Settings
             </Menu.Item>
                <Menu.Item icon={<LogoutOutlined />} onClick={sessionStore.logout}>
                    Logout
             </Menu.Item>
            </Menu>
        </div >
    )
}



export default inject('sessionStore', 'settingsStore', 'viewStateStore')(observer(EditProfileBubble));