

import { observable, makeObservable, computed, runInAction, action, makeAutoObservable } from 'mobx';
import { LocalVideoTrack } from 'twilio-video';
import MeetingModel from '../common/models/MeetingModel';
import FirebaseClient from '../services/FirebaseClient';
import RootStore from './RootStore';
import SessionStore from './SessionStore';

class MeetingStore {
    rootStore: RootStore;
    sessionStore: SessionStore;
    firebase: FirebaseClient;
    state?: 'preview' | 'room' | 'feedback' = 'preview';
    currentMeetingModel?: MeetingModel;

    constructor(rootStore: RootStore, firebase: FirebaseClient, sessionStore: SessionStore) {
        makeObservable(this, {
            currentMeetingModel: observable,
            state: observable,
            otherParticipants: computed,
            joinMeeting: action,
        });


        this.rootStore = rootStore;
        this.sessionStore = sessionStore;
        this.firebase = firebase;
    }

    get otherParticipants() {
        const { authUser } = this.sessionStore;
        return this.currentMeetingModel?.participants?.filter(p => p.id !== authUser?.id);
    }

    joinMeeting = async () => {
        const { authUser } = this.sessionStore;

        const joined = await this.firebase.joinMeeting(this.currentMeetingModel?.id!, {
                id: authUser?.id,
                user: authUser?.toPublicData(),
                attended: true,
        });
        

        if(joined) {
            await this.rootStore.connectionsStore.createOrUpdateConversation(this.currentMeetingModel?.id!, {
                participants: this.currentMeetingModel?.participants
            });

            runInAction(() => {
                this.state = 'room';
            })
        }

        return joined;
        
    }

    endMeeting = () => {
        runInAction(() => {
            this.state = 'feedback';
        })
    }

    goToPreview = () => {
        runInAction(() => {
            this.state = 'preview';
        })
    }

    subscribeForMeeting = (meetingId: string) => {
        this.firebase.meeting(meetingId).onSnapshot((snap) => {
            const data = snap.data();

            runInAction(() => {
                this.currentMeetingModel = MeetingModel.mapFromServer({ id: snap.id, ...data });
            })
        })
    }

    
}


export default MeetingStore;


