
import { Avatar, Button, Dropdown, Form, Input, Menu, notification, Radio, Select, Typography, Modal, Switch, message } from 'antd';
import { inject, observer } from 'mobx-react';
import React, { useEffect, useRef, useState } from 'react';
import Lottie from 'react-lottie';
import MembersStore from '../../../../../store/MembersStore';
import SessionStore from '../../../../../store/SessionStore';
import styles from './RequestIntroModal.module.css';
import UserModel, { UserPublicData } from '../../../../../common/models/UserModel';
import ViewStateStore from '../../../../../store/ViewStateStore';
import _ from 'lodash';
import UserAvatar from '../../../../../common/components/avatar/UserAvatar';
import ConnectionsStore from '../../../../../store/ConnectionsStore';
import doneAnimation from './../../../../../common/images/doneAnimation.json';

const lottieFile = {
    loop: false,
    autoplay: true,
    animationData: doneAnimation,
    rendererSettings: {
        preserveAspectRatio: 'xMidYMid slice'
    }
};

interface Props {
    visible: boolean,
    onCancel: () => void,
    sessionStore?: SessionStore,
    membersStore?: MembersStore,
    connectionsStore?: ConnectionsStore,
    viewStateStore?: ViewStateStore,
}

function RequestIntroModal({ visible, onCancel, sessionStore, membersStore, connectionsStore }: Props) {
    const [isBusy, setBusy] = useState(false);
    const { currentViewedMember } = membersStore!;
    const { authUser } = sessionStore!;
    const [showSuccess, setShowSuccess] = useState(false);

    const onFinish = async (values: any) => {
        setBusy(true);
        const { message } = values;

        const requestUser = await membersStore?.fetchById(currentViewedMember!.id!);
        await connectionsStore?.createIntroduction(requestUser!, authUser!, 'member', undefined, message);
        setBusy(false);
        setShowSuccess(true);
    }

    const onDismiss = () => {
        onCancel();
        setTimeout(() => {
            setShowSuccess(false);
        }, 1000);
    }

    return (
        <Modal visible={visible}
            width={600}
            footer={null}
            bodyStyle={{ padding: 0 }}
            onCancel={onDismiss}>
            {!showSuccess ?
                <div className={styles.container}>
                    <div className={styles.bubblesContainer}>
                        <UserAvatar userImageClass={`${styles.avatar} ${styles.leftAvatar}`} size={60} userImage={authUser?.photoUrl} />
                        <UserAvatar userImageClass={styles.avatar} size={60} userImage={currentViewedMember?.photoUrl} />
                    </div>

                    <div className={styles.subHeader}>
                        Get a warm intro to <b>{currentViewedMember?.firstName}</b> as fellow club members.
                    <br />
                    </div>

                    <div className={styles.reasonHeader}>
                        What do you want to talk about?
                </div>
                    <div className={styles.reasonSubHeader}>
                    It will help set the meeting for you.
                </div>

                    <div>
                    </div>

                    <Form name='member-intro-form'
                        className={`member-intro-form ${styles.form}`}
                        layout='vertical'
                        onFinish={onFinish}>
                        <Form.Item
                            name='message'
                            rules={[{ required: true, message: 'Please add the reason for introdution.' }]}>
                            <Input.TextArea placeholder={`Add introduction purpose here...`} className={styles.textarea} minLength={20} />
                        </Form.Item>
                        <Form.Item>
                            <Button loading={isBusy} htmlType='submit' size='large' type='primary' className={styles.button}>
                                Request Introduction
                        </Button>
                        </Form.Item>
                    </Form>
                </div>
                :
                <div className={styles.container}>
                    <div className={styles.doneAnimation}>
                        <Lottie options={lottieFile} />
                    </div>
                    <div className={styles.header}>
                        Intro request sent!
                    </div>
                    <div className={styles.subHeader}>
                        We'll be checking with {currentViewedMember?.firstName} and if it works for {UserModel.pronouneHim(currentViewedMember?.gender)}, we'll set up a meeting for you.
                        We'll keep you posted!
                    </div>
                </div>
            }
        </Modal>)
}


export default inject('sessionStore', 'membersStore', 'viewStateStore', 'connectionsStore')(observer(RequestIntroModal));