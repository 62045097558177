import { Button, Modal, Tooltip } from "antd";
import moment from "moment-timezone";
import React, { useEffect, useState } from "react";
import UserAvatar from "../../../../common/components/avatar/UserAvatar";
import Card from "../../../../common/components/card/Card";
import IntroRequestModel, { TimeAvailability } from "../../../../common/models/IntroRequestModel";
import { inject, observer } from "mobx-react";
import styles from './MeetingChoices.module.css';
import { ClockCircleOutlined, EnvironmentOutlined } from '@ant-design/icons';
import ConnectionsStore from "../../../../store/ConnectionsStore";
import { SuggestedTime } from "../meetingScheduler/MeetingScheduler";
import _ from "lodash";

interface Props {
    introRequest: IntroRequestModel
    connectionsStore?: ConnectionsStore,
    onFinished?: () => void;
}

function MeetingChoices({ introRequest, connectionsStore, onFinished }: Props) {
    const { requested, requester } = introRequest;
    const [suggestedTimes, setSuggestedTimes] = useState(Array<SuggestedTime>());
    const [timezone, setTimezone] = useState('');
    const [selectedTime, setSelectedTime] = useState<TimeAvailability>();
    const [busy, setBusy] = useState(false);

    useEffect(() => {
        setTimezone(moment.tz.guess());
        if (introRequest) {
            showSuggestedTimes();
        }
    }, [introRequest])


    const showSuggestedTimes = () => {
        const requestedSuggstedTimes = introRequest.requestedTimeAvailability;
        const groupByDay = _.groupBy(requestedSuggstedTimes, time => moment.unix(time).startOf('day').format());
        const timesSorted = _.map(groupByDay, (group, day) => {
            return {
                day: _.first(group) || 0,
                times: group
            }
        });

        setSuggestedTimes(timesSorted);
    }

    const timeSelected = (time: number) => {
        if(selectedTime === time) {
            setSelectedTime(undefined);    
        } else {
            setSelectedTime(time);
        }
    }

    const onConfirm = () => {
        connectionsStore?.introductionCompleted(introRequest, selectedTime!);
        onFinished && onFinished();
    }

    return (<div className={styles.container}>
        <div className={styles.content}>
            <div className={styles.header}>
                <div className={styles.bubblesContainer}>
                    <UserAvatar size={80} userImage={requested?.photoUrl} companyImage={requested?.company?.logoUrl} className={styles.userAvatar} />
                    <UserAvatar size={80} userImage={requester?.photoUrl} companyImage={requester?.company?.logoUrl} />
                </div>
                <div className={styles.title}>
                    Meeting with {requested?.firstName}
                </div>
                <div className={styles.icons}>
                    <span><ClockCircleOutlined className={styles.icon} /> 30 minutes</span>
                    <span style={{ marginLeft: 16 }}><EnvironmentOutlined className={styles.icon} /> Video Call</span>
                </div>
                <div className={styles.subTitle}>
                    Click or tap a time to schedule a meeting instantly
                </div>
            </div>
            <Card className={styles.calendar}>
                {suggestedTimes.map(suggestedTime => {
                    return <div className={styles.dayColumn}>
                        <div className={styles.dayHeader}>
                            <div className={styles.dayName}>{moment.unix(suggestedTime.day).format('MMM D')}</div>
                            <div className={styles.dayDate}>{moment.unix(suggestedTime.day).format('dddd')}</div>
                        </div>
                        <div className={styles.timesColumn}>
                            {suggestedTime.times.map(time =>
                                <div onClick={() => timeSelected(time)} className={`${styles.timeOption} ${time === selectedTime && styles.timeOptionSelected}`}>
                                    {moment.unix(time).format('HH:mm')}
                                </div>
                            )}

                        </div>
                    </div>
                })
                }
            </Card>
            <div className={styles.timezone}>Times are in {moment.tz(timezone).format('z')} ({timezone})</div>
            <div className={styles.stickyBarOffset}>

            </div>
        </div>

        <div className={styles.stickyBar}>
            <div className={styles.stickyBarContent}>
                <div className={styles.guide}>
                    {selectedTime ?
                        <div>
                            <div>You chose to meet {requested?.firstName} on:</div>
                            <div className={styles.selectedDate}>{moment.unix(selectedTime).format('dddd, MMMM D, HH:mm')}</div>
                        </div>
                        :
                        <div>
                            Pick a time for this meeting
                        </div>
                    }

                </div>
                <Button size='large'
                    type={'primary'}
                    loading={busy}
                    disabled={!selectedTime}
                    onClick={onConfirm}
                    className={`${styles.doneButton} ${selectedTime ? 'animate__animated animate__tada' : ''}`}>
                    Confirm Meeting
               </Button>
            </div>
        </div>

    </div >);
}


export default inject('connectionsStore')(observer(MeetingChoices));



