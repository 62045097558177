import _, { intersectionBy } from "lodash";
import moment from "moment-timezone";
const MAX_OPTIONS_TO_SUGGEST = 4;

export type ConveniencyLevel = 'bad' | 'ok' | 'good';
export type SuggestedHour = { hour: number, conveniency: ConveniencyLevel, default?: boolean, time?: number }

export const suggestHoursAccordingToTimezone = (timezoneA: string, timezoneB?: string) => {
    if (!timezoneB) {
        return hours.filter(h => h.default);
    }
    
    const timezoneTimesA: SuggestedHour[] = [];
    const timezoneTimesB: SuggestedHour[] = [];

    const A1 = moment().tz(timezoneA).utcOffset()
    const B1 = moment().tz(timezoneB).utcOffset()
    const hoursDifference = (A1 - B1) / 60;

    hours.forEach(suggestedHour => {
        timezoneTimesA.push({
            hour: suggestedHour.hour,
            conveniency: suggestedHour.conveniency,
            default: suggestedHour.default,
        }); timezoneTimesB.push({
            hour: suggestedHour.hour + hoursDifference,
            conveniency: suggestedHour.conveniency,
            default: suggestedHour.default,
        });
    });

    const idealIntersection = _.intersectionWith(timezoneTimesA, timezoneTimesB, (a, b) => a.hour === b.hour && a.conveniency === 'good' && b.conveniency === 'good');
    const goodIntersection = _.intersectionWith(timezoneTimesA, timezoneTimesB, (a, b) => a.hour === b.hour && a.conveniency === 'good' && b.conveniency === 'ok');
    const goodMinusIntersection = _.intersectionWith(timezoneTimesA, timezoneTimesB, (a, b) => a.hour === b.hour && a.conveniency === 'ok' && b.conveniency === 'good');
    const okIntersection = _.intersectionWith(timezoneTimesA, timezoneTimesB, (a, b) => a.hour === b.hour && a.conveniency === 'ok' && b.conveniency === 'ok');
    const okMinusIntersection = _.intersectionWith(timezoneTimesA, timezoneTimesB, (a, b) => a.hour === b.hour && a.conveniency === 'good' && b.conveniency === 'bad');
    const badIntersection = _.intersectionWith(timezoneTimesA, timezoneTimesB, (a, b) => a.hour === b.hour && a.conveniency === 'ok' && b.conveniency === 'bad');
    const badMinusIntersection = _.intersectionWith(timezoneTimesA, timezoneTimesB, (a, b) => a.hour === b.hour && a.conveniency === 'bad' && b.conveniency === 'good');

    if(idealIntersection.length >= MAX_OPTIONS_TO_SUGGEST) {
        const defaults = idealIntersection.filter(h => h.default);

        if(defaults.length === MAX_OPTIONS_TO_SUGGEST) {
            return defaults;
        }

        const fillers = _.shuffle(idealIntersection.filter(h => !h.default));
        const defaultsWithFillers = _.concat(defaults, _.take(fillers, MAX_OPTIONS_TO_SUGGEST - defaults.length));
        const ordered = _.orderBy(defaultsWithFillers, h => h.hour)
        return ordered;
    }

    const merged = [...idealIntersection, ...goodIntersection, ...goodMinusIntersection, ...okIntersection, ...okMinusIntersection, ...badIntersection, ...badMinusIntersection];
    const suggestedTimes = _.take(merged, MAX_OPTIONS_TO_SUGGEST);
    const ordered =  _.orderBy(suggestedTimes, h => h.hour);
    return ordered;
}


const hours: SuggestedHour[] = [{
    hour: 7,
    conveniency: 'bad',
},
{
    hour: 8,
    conveniency: 'ok',
},
{
    hour: 9,
    conveniency: 'ok',
},
{
    hour: 10,
    conveniency: 'good',
},
{
    hour: 11,
    conveniency: 'good',
    default: true,
}, {
    hour: 12,
    conveniency: 'good',
},
{
    hour: 13,
    conveniency: 'good',
},
{
    hour: 14,
    conveniency: 'good',
    default: true,
},
{
    hour: 15,
    conveniency: 'good',
},
{
    hour: 16,
    conveniency: 'good',
    default: true,
},
{
    hour: 17,
    conveniency: 'good',
},
{
    hour: 18,
    conveniency: 'good',
    default: true,
},
{
    hour: 19,
    conveniency: 'ok',
},
{
    hour: 20,
    conveniency: 'ok',
},
{
    hour: 21,
    conveniency: 'bad',
},
{
    hour: 22,
    conveniency: 'bad',
},
{
    hour: 23,
    conveniency: 'bad',
}]
