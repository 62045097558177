import moment from "moment";
import { UserPublicData } from "./UserModel";

class MeetingModel {
    id?: string;
    shortId?: string;
    participants?: MeetingParticipant[];
    scheduledOn?: number;
    createdAt?: number;

    constructor(id?: string,
        shortId?: string,
        participants?: MeetingParticipant[],
        scheduledOn?: number,
        createdAt?: number) {
        this.id = id;
        this.shortId = shortId;
        this.participants = participants;
        this.scheduledOn = scheduledOn;
        this.createdAt = createdAt;
    }

    get isFuture() {
        return moment().unix() < (this.scheduledOn || 0);
    }

    static mapFromServer(data: any) {
        return new MeetingModel(data.id,
            data.shortId,
            data.participants,
            data.scheduledOn,
            data.createdAt);
    }
}


export type MeetingFeedback = {
    score: number;
}

export type MeetingParticipant = {
    id?: string,
    user?: UserPublicData,
    attended?: boolean
}

export default MeetingModel;