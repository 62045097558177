import { inject, observer } from "mobx-react";
import React, { ChangeEventHandler, useEffect, useRef, useState } from "react";
import Video, { createLocalTracks, LocalDataTrack, LocalParticipant, LocalVideoTrack } from "twilio-video";
import { ParticipantData, } from "../../../../common/components/videoRoom/VideoRoom";
import MeetingStore from "../../../../store/MeetingStore";
import MembersStore from "../../../../store/MembersStore";
import VideoRoomStore from "../../../../store/VideoRoomStore";
import VideoParticipant from "../videoParticipant/VideoParticipant";
import noVideoIcon from '../../../../common/images/ic-no-video.png';
import { MessageFilled, DesktopOutlined, CopyFilled, SnippetsFilled, EditFilled, MessageTwoTone, SettingFilled, AudioFilled, VideoCameraFilled, AudioMutedOutlined, SendOutlined } from '@ant-design/icons';
import styles from './MeetingVideo.module.css';
import { Popover, Tooltip, Form, Input, Button, Typography, notification, Divider, Tag, message, Popconfirm } from "antd";
import { useForm } from "antd/lib/form/Form";
import ViewStateStore from "../../../../store/ViewStateStore";
import ConnectionsStore from "../../../../store/ConnectionsStore";
import { ConversationModel } from "../../../../common/models/UserModel";
import { hideTawkChatBubble } from "../../../../common/utils/Utils";
const fadeInAnimation = 'animate__animated animate__fadeIn animate__fast';
const fadeOutAnimation = 'animate__animated animate__fadeOut animate__fast';


interface Props {
    videoRoomStore?: VideoRoomStore;
    meetingStore?: MeetingStore;
    membersStore?: MembersStore;
    viewStateStore?: ViewStateStore;
    connectionsStore?: ConnectionsStore;
}

function MeetingVideo({ videoRoomStore, meetingStore, membersStore, viewStateStore, connectionsStore }: Props) {
    const { currentMeetingModel } = meetingStore!;
    const { videoToken, currentRoom, screenShareTrack } = videoRoomStore!;
    const { primaryColor, isMobile } = viewStateStore!
    const [participants, setParticipants] = useState(Array<ParticipantData>());
    const [currentParticipant, setCurrentParticipant] = useState<ParticipantData>();
    const [localDataTrack, setLocalDataTrack] = useState(new LocalDataTrack());
    const [audioEnabled, setAudioEnabled] = useState(true);
    const [videoEnabled, setVideoEnabled] = useState(true);
    const [sendMessageForm] = Form.useForm();
    const messageInputRef = useRef<Input>(null);
    const notesInputRef = useRef<any>(null);
    const [showNotesSavedBadge, setShowNotesSavedBadge] = useState(false);
    const [inputBounceTimer, setInputBounceTimer] = useState<NodeJS.Timeout>();
    const shareScreenVideoRef = useRef<any>();
    const [conversationModel, setConversationModel] = useState<ConversationModel>();

    useEffect(() => {
        window.addEventListener('beforeunload', () => leave);
        connectionsStore?.getConversation(currentMeetingModel?.id!).then(model => {
            setConversationModel(model);
        });

        if (isMobile) {
            hideTawkChatBubble();
        }

        return () => {
            leave();
            window.removeEventListener('beforeunload', () => leave);
        }
    }, [])

    useEffect(() => {
        console.log('=== Initiating Room ===');
        createLocalTracks({
            video: videoRoomStore?.selectedVideoInput ? { deviceId: videoRoomStore.selectedVideoInput } : true,
            audio: videoRoomStore?.selectedVideoInput ? { deviceId: videoRoomStore.selectedAudioInput } : true,
        }).then(localTracks => {
            Video.connect(videoToken!, {
                name: currentMeetingModel?.id,
                tracks: [...localTracks, localDataTrack],
            }).then(room => {
                console.log(`Room connected (ID: ${room.sid})`)
                videoRoomStore?.setCurrentRoom(room);
                setCurrentParticipant({ current: room.localParticipant, info: mapToParticipantInfo(room.localParticipant) });
                setCurrentParticipants(room.participants);
                room.on('participantConnected', participantConnected);
                room.on('participantDisconnected', participantDisconnected);
                room.on('disconnected', (room: Video.Room) => {
                    console.log('Disconnected from room.')
                    stopVideoTracks(room.localParticipant.videoTracks);
                    stopAudioTracks(room.localParticipant.audioTracks);
                });

            }).catch(error => {
                console.log('meeting video:')
                console.error(error);
            });
        })

    }, []);

    const setCurrentParticipants = async (participants: Map<string, Video.RemoteParticipant>) => {
        const currentParticipantsRaw = Array.from(participants, ([name, value]) => ({ name, value }));;
        const currentParticipants = currentParticipantsRaw.map(p => {
            return { current: p.value, info: mapToParticipantInfo(p.value) }
        })

        setParticipants(prev => [...prev, ...currentParticipants]);
    }

    const participantConnected = (participant: Video.Participant) => {
        const participantInfo = mapToParticipantInfo(participant)

        if (!participantInfo) {
            console.warn(`Connected: Couldn't find participant info for ${participant.identity}`);
            return;
        }

        console.log(`Partcipant connected: ${participantInfo?.firstName}`);
        const newParticipant: ParticipantData = { current: participant, info: participantInfo };
        setParticipants(prevParticipants => [...prevParticipants, newParticipant]);
    };

    const participantDisconnected = (participant: Video.Participant) => {
        const participantInfo = mapToParticipantInfo(participant)

        if (!participantInfo) {
            console.warn(`Disconnected: Couldn't find participant info for ${participant.identity}`);
            return;
        }

        console.log(`Partcipant diconnected: ${participantInfo?.firstName}`);
        setParticipants(prevParticipants => [...prevParticipants.filter(p => p.current.identity !== participant.identity)]);
    };


    const stopVideoTracks = (tracks: Map<String, Video.LocalVideoTrackPublication>) => {
        tracks.forEach(trackPublication => {
            trackPublication.track.stop();
            trackPublication.unpublish();
        });
    }

    const stopAudioTracks = (tracks: Map<string, Video.LocalAudioTrackPublication>) => {
        tracks.forEach(trackPublication => {
            trackPublication.track.stop();
            trackPublication.unpublish();
        });
    }

    const toggleAudio = () => {
        videoRoomStore!.currentRoom?.localParticipant.audioTracks.forEach(publication => {
            audioEnabled ? publication.track.disable() : publication.track.enable();
        });

        setAudioEnabled(!audioEnabled);
    }

    const toggleVideo = () => {
        videoRoomStore!.currentRoom?.localParticipant.videoTracks.forEach(publication => {
            videoEnabled ? publication.track.disable() : publication.track.enable();
        });

        setVideoEnabled(!videoEnabled);
    }

    const mapToParticipantInfo = (participant: Video.Participant | LocalParticipant) => {
        const participantInfo = currentMeetingModel?.participants?.find(p => p.id === participant.identity)?.user;
        return participantInfo;
    }

    const goToPreview = () => {
        meetingStore?.goToPreview();
    }

    const leave = async () => {
        const { currentRoom } = videoRoomStore!;
        videoRoomStore?.clearCurrentRoom();
        console.log('Left room.');
        if (currentRoom) {
            console.log('Stopped media');
            stopVideoTracks(currentRoom.localParticipant.videoTracks);
            stopAudioTracks(currentRoom.localParticipant.audioTracks);
        }
    }

    const endMeeting = async () => {
        await connectionsStore?.createOrUpdateConversation(currentMeetingModel?.id!, {
            participants: currentMeetingModel?.participants,
        });

        meetingStore?.endMeeting();
    }

    const showWaitingForOthers = () => {
        const remoteParticipants = currentRoom?.participants.size || 0;
        return remoteParticipants === 0;
    }

    const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (e.key === 'Enter') {
            sendMessageForm.submit();
        }
    }

    const onMessagePopconfirmVisibleChanged = (visible: boolean) => {
        if (visible) {
            setTimeout(() => {
                messageInputRef.current!.focus();
            }, 300);
        }
    }

    const onSendMessage = (values: any) => {
        const message = values.message;
        if (!message) {
            return;
        }

        console.log('Message sent:' + message);
        localDataTrack.send(message);
        sendMessageForm.resetFields();

        notification.info({
            key: message.toString(),
            message: <b>You:</b>,
            duration: 15,
            placement: 'bottomLeft',
            description: message,
            icon: <MessageTwoTone twoToneColor={primaryColor} />,
            onClick: () => {
                notification.close(message);
            },
        });
    }

    const onNotesOpened = (visible: boolean) => {
        if (visible) {
            setTimeout(() => {
                notesInputRef.current?.focus();
            }, 300)
        }
    }

    const onNotesChanged = () => {
        if (inputBounceTimer) {
            clearTimeout(inputBounceTimer);
        }

        const timeout = setTimeout(() => {
            const input = notesInputRef.current;
            const text = input.state.value;
            try {
                saveNotes(text);
            } catch (error) {
                console.error(`Failed to save notes: ${error}`)
            }

        }, 1000);

        setInputBounceTimer(timeout);
    }

    const saveNotes = async (text: string) => {
        await connectionsStore?.saveNotes(currentMeetingModel?.id!, text);
        console.log(`Notes saved! ${text}`);
        setShowNotesSavedBadge(true);
        setTimeout(() => {
            setShowNotesSavedBadge(false);
        }, 3000);
    }

    const shareScreen = () => {
        if (videoRoomStore?.screenShareMode && !videoRoomStore?.isLocalScreenShare) {
            message.info(`You can't share your screen while others sharing their screen.`)
            return;
        }

        if (!videoRoomStore?.screenShareTrack) {
            // @ts-ignore 
            navigator.mediaDevices.getDisplayMedia().then(stream => {
                console.log('Screen sharing started')
                const track = new LocalVideoTrack(stream.getTracks()[0], { name: 'screen-share', logLevel: 'info' });
                videoRoomStore?.setScreenShareTrack(track, true);
                currentRoom?.localParticipant.publishTrack(track);
                track.attach(shareScreenVideoRef.current);
                track.mediaStreamTrack.onended = () => { shareScreen() };
            }).catch(() => {
                console.log('Screen share failed');
            });
        }
        else {
            currentRoom?.localParticipant.unpublishTrack(videoRoomStore?.screenShareTrack);
            screenShareTrack?.stop();
            screenShareTrack?.detach();
            videoRoomStore?.setScreenShareTrack(undefined);
        }
    };

    const onRemoteScreenShared = (track: LocalVideoTrack, enabled: boolean) => {
        if (enabled) {
            videoRoomStore?.setScreenShareTrack(track);
            track.attach(shareScreenVideoRef.current);
        } else {
            track.detach();
            videoRoomStore?.setScreenShareTrack(undefined)

        }
    }

    return (<div className={styles.container}>
        <div className={`${styles.participantsGrid} ${screenShareTrack && styles.screenShareMode}`}>
            <VideoParticipant
                selfAudioEnabled={audioEnabled}
                selfVideoEnabled={videoEnabled}
                isSelf={true}
                participant={currentParticipant} />
            {showWaitingForOthers() &&
                <WaitingForOthers />
            }
            {participants.map(participant => {
                return <VideoParticipant key={participant.current.identity}
                    isSelf={false}
                    onRemoteScreenShared={onRemoteScreenShared}
                    participant={participant} />
            })}
        </div>
        {
            videoRoomStore?.screenShareMode &&
            <div className={styles.screenShareContainer}>
                <video className={styles.shareScreenVideo} ref={shareScreenVideoRef} autoPlay={true} />
            </div>
        }
        <div>

        </div>
        <div className={styles.buttonStripContainer}>
            <div className={styles.buttonStrip}>
                <div className={`${styles.stripButton} ${!audioEnabled && styles.stripButtonDisabled}`} onClick={() => toggleAudio()}>
                    {audioEnabled ?
                        <AudioFilled className={styles.stripButtonIcon} /> :
                        <AudioMutedOutlined className={styles.stripButtonIcon} />
                    }
                </div>

                <div className={`${styles.stripButton} ${!videoEnabled && styles.stripButtonDisabled}`} onClick={() => toggleVideo()}>
                    {videoEnabled ?
                        <VideoCameraFilled className={styles.stripButtonIcon} /> :
                        <img src={noVideoIcon} className={styles.noVideoIcon} />
                    }
                </div>
                <Popconfirm title={'Are you sure?'}
                    okType='danger'
                    onConfirm={endMeeting}>
                    <div className={`${styles.stripButton} ${styles.endMeetingButton}`}>
                        End Meeting
                    </div>
                </Popconfirm>
                <div className={styles.divider} />
                <Popover
                    onVisibleChange={onMessagePopconfirmVisibleChanged}
                    content={
                        <div className={styles.sendMessageContainer}>
                            <Form
                                form={sendMessageForm}
                                name='send-message-form'
                                onFinish={onSendMessage}>
                                <Form.Item name='message'>
                                    <Input
                                        ref={messageInputRef}
                                        onKeyPress={handleKeyDown}
                                        placeholder='Type your message here...' />
                                </Form.Item>
                                <div className={styles.sendMessageButtonContainer}>
                                    <Button htmlType="submit" type='primary' icon={<SendOutlined />}>
                                        Send Message
                                </Button>
                                    <div className={styles.sendMessageText}>
                                        Or press <Typography.Text keyboard>Enter</Typography.Text>
                                    </div>
                                </div>
                            </Form>
                        </div>}
                    title="Send message"
                    trigger={['click']}>
                    <div className={`${styles.stripButton}`}>
                        <MessageFilled className={styles.stripButtonIcon} />
                    </div>
                </Popover>

                <Tooltip placement='top' title={'Share screen'}>
                    <div className={`${styles.stripButton}`} onClick={shareScreen} style={videoRoomStore?.screenShareMode && videoRoomStore.isLocalScreenShare ? { background: '#52c41a' } : {}}>
                        <DesktopOutlined className={styles.stripButtonIcon} />
                    </div>
                </Tooltip>

                <div className={`${styles.stripButton} ${styles.settingsButton}`} onClick={goToPreview}>
                    <SettingFilled className={styles.stripButtonIcon} />
                </div>

                <Popover
                    placement={'bottomLeft'}
                    trigger={['click']}
                    onVisibleChange={onNotesOpened}
                    content={
                        <div className={styles.notesContainer}>
                            <Tag color={'#87d068'} className={`${styles.notesSavedBadge} ${showNotesSavedBadge ? fadeInAnimation : ''}`}>
                                Saved!
                            </Tag>
                            <div className={styles.notesHeader}>My meeting notes</div>
                            <div className={styles.notesDescription}>Your notes are private and won't be shared with others.</div>
                            <Divider />
                            <Input.TextArea
                                defaultValue={conversationModel?.notes}
                                onChange={onNotesChanged}
                                ref={notesInputRef}
                                bordered={false}
                                placeholder='Add your notes here...'
                                rows={8}
                                className={styles.notesInput} />
                        </div>
                    }>
                    <div className={`${styles.stripButton} ${styles.myNotesButton}`}>
                        <EditFilled className={styles.stripButtonIcon} />
                        <div style={{ marginLeft: 8, fontWeight: 600 }}>My Notes</div>
                    </div>
                </Popover>
            </div>
        </div>

    </div>);
}


const WaitingForOthers = () => {
    return <div className={styles.waitingForOthersContainer}>
        <div className={styles.waitingForOthersHeader}>Hurray, you’re here 👋 </div>
        <div>Please allow a few minutes for others to join...</div>
    </div>
}

export default inject('videoRoomStore', 'meetingStore', 'membersStore', 'viewStateStore', 'connectionsStore')(observer(MeetingVideo));