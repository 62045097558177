import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/functions';
import 'firebase/storage';
import 'firebase/analytics';
import 'firebase/remote-config';
import _ from 'lodash';
import EventModel, { EventPublicData, Question, Speaker } from '../common/models/EventModel';
import InvitationModel from '../common/models/InvitationModel';
import UserModel, { UserPublicData } from '../common/models/UserModel';
import StringUtils from '../common/utils/StringUtils';
import { isMobile, isRunningDebug } from '../common/utils/Utils';
import { EventData } from '../store/EventsStore';
import RootStore from '../store/RootStore';
import AnalyticsReporter from './analytics/AnalyticsReporter';
import CompanyModel from '../common/models/CompanyModel';
import EventConversationModel from '../common/models/EventConversationModel';
import moment from 'moment';
import { INVIATION_CAHCE_KEY as INVIATION_CACHE_KEY } from '../store/SessionStore';
import RoundTableModel from '../common/models/RoundTableModel';
import AttendeeModel from '../common/models/AttendeeModel';
import MeetingModel, { MeetingParticipant } from '../common/models/MeetingModel';
import firebaseConfig from '../firebase-config.json';
const EMULATOR_URL = 'http://localhost:5001';

export const FieldValue = firebase.firestore.FieldValue;
export const FirestoreQuery = firebase.firestore.Query;

export enum LoginProvider {
    Google,
    LinkedIn,
}

class FirebaseClient {
    app: firebase.app.App;
    db: firebase.firestore.Firestore;
    realtimeDb: firebase.database.Database;
    functions: firebase.functions.Functions;
    rootStore: RootStore;
    analyticsReporter: AnalyticsReporter;

    constructor(rootStore: RootStore, analyticsReporter: AnalyticsReporter) {
        this.app = firebase.initializeApp(firebaseConfig);
        firebase.analytics();
        this.db = firebase.firestore();
        this.realtimeDb = firebase.database();
        this.functions = firebase.functions();
        this.db.settings({ ignoreUndefinedProperties: true })
        this.rootStore = rootStore;
        this.analyticsReporter = analyticsReporter;

        if (isRunningDebug()) {
            this.functions.useFunctionsEmulator(EMULATOR_URL);
        }
    }

    user = (id: string) => this.db.collection('users').doc(id);
    users = () => this.db.collection('users').where('leadersMember', '==', true).where('onboardingCompleted', '==', true);
    userAccount = (id: string) => this.user(id).collection('account');
    userConversations = (id: string) => this.user(id).collection('conversations');
    userActivity = (id: string) => this.user(id).collection('activity');
    event = (id: string) => this.db.collection('events').doc(id);
    events = () => this.db.collection('events');
    eventMessages = (eventId: string) => this.event(eventId).collection('messages');
    eventConversations = (eventId: string) => this.event(eventId).collection('conversations');
    eventConversation = (eventId: string, conversationId: string) => this.event(eventId).collection('conversations').doc(conversationId);
    eventFeedbacks = (eventId: string) => this.event(eventId).collection('feedbacks');
    eventFeedback = (eventId: string, feedbackId: string) => this.event(eventId).collection('feedbacks').doc(feedbackId);
    eventRoundTables = (eventId: string) => this.event(eventId).collection('roundTables');
    eventRoundTable = (eventId: string, roundTableId: string) => this.event(eventId).collection('roundTables').doc(roundTableId);
    eventAttendees = (eventId: string) => this.event(eventId).collection('attendees');
    eventAttendee = (eventId: string, attendeeId: string) => this.event(eventId).collection('attendees').doc(attendeeId);
    invitation = (id: string) => this.db.collection('invitations').doc(id);
    invitations = () => this.db.collection('invitations');
    meeting = (id: string) => this.db.collection('meetings').doc(id);
    meetings = () => this.db.collection('meetings');
    introRequest = (id: string) => this.db.collection('introRequests').doc(id);
    introRequests = () => this.db.collection('introRequests');
    stat = (statId: string) => this.db.collection('stats').doc(statId);
    stats = () => this.db.collection('stats');
    resource = (id: string) => this.db.collection('resources').doc(id);
    resources = () => this.db.collection('resources');
    settings = (settingsId: string) => this.db.collection('settings').doc(settingsId);
    

    fetchUsers = async (ids: string[]) => {
        const result = await this.users()
            .where(firebase.firestore.FieldPath.documentId(), 'in', ids)
            .limit(10)
            .get();
        return result.docs.map(d => UserModel.mapFromServer({ id: d.id, ...d.data() }));
    }

    async createOrUpdateUser(userData: any) {
        await this.user(userData.id).set(userData, { merge: true });
    }

    getEventByShortId = async (shortId: string) => {
        let result = await this.events().where('shortId', '==', shortId).get();
        return EventModel.mapFromServer({ id: result.docs[0].id, ...result.docs[0].data() });
    }

    deleteEvent = async (id: string) => {
        await this.event(id).delete();
    }

    startEvent = async (eventId: string) => {
        await this.functions.httpsCallable('startEvent')({ eventId });
    }

    endEvent = async (eventId: string) => {
        await this.event(eventId).update({
            isActive: false,
            isEnded: true,
        });
    }

    addCompany = async (companyDetails: any) => {
        const result = await this.functions.httpsCallable('addNewCompany')(companyDetails);
        return result.data;
    }

    addCompanyIntro = async (message: string, fromUser: UserModel, company: CompanyModel) => {
        await this.db.collection('introductions').add({
            reason: 'cooperation',
            message: message,
            fromName: fromUser.fullName,
            fromId: fromUser.id,
            fromPhotoUrl: fromUser.photoUrl,
            fromHeadline: fromUser.title,
            fromEmail: fromUser.email,
            companyId: company.id,
            companyName: company.name,
            companySlug: company.slug,
            companyLogo: company.logo,
        });
    }

    onboardingCompleted = async (user: UserModel) => {
        const result = await this.functions.httpsCallable('onboardingCompleted')(user);
        return result.data;
    }

    followUser = async (followeeId: string, followerId: string, follow = true) => {
        await this.functions.httpsCallable('followUser')({ followeeId, followerId, follow });
    }

    getCompanyById = async (id: string) => {
        const result = await this.functions.httpsCallable('getCompanyById')(id);
        return CompanyModel.mapFromServer(result.data);
    }

    addEvent = async (data: EventData) => {
        await this.events().add({
            title: data.title,
            description: data.description,
            startDate: data.startDate,
            endDate: data.endDate,
            timezone: data.timezone,
            speakers: data.speakers,
            externalLink: data.externalLink,
            isHidden: data.isHidden,
            isActive: false,
            isEnded: false,
            isQuestionsEnabled: data.isQuestionsEnabled,
            eventPlatform: data.eventPlatform,
            type: data.type,
            location: data.location,
            theme: data.theme,
            themeColor: data.themeColor,
            themeTitle: data.themeTitle,
            webinarId: data.webinarId,
            spatialLink: data.spatialLink,
            themeBackground: data.themeBackground,
            targets: data.targets,
            seatLimit: data.seatLimit,
            autoStart: data.autoStart,
            recordUrl: data.recordUrl,
            approvalNeeded: data.approvalNeeded,
            reminder: data.reminder,
            shortId: StringUtils.uniqueId()
        })
    }

    updateEvent = async (eventId: string,
        change: EventData) => {
        await this.event(eventId).set({
            title: change.title,
            description: change.description,
            startDate: change.startDate,
            endDate: change.endDate,
            timezone: change.timezone,
            speakers: change.speakers,
            externalLink: change.externalLink,
            type: change.type,
            eventPlatform: change.eventPlatform,
            location: change.location,
            theme: change.theme,
            themeColor: change.themeColor,
            themeTitle: change.themeTitle,
            isActive: change.isActive,
            isHidden: change.isHidden,
            isQuestionsEnabled: change.isQuestionsEnabled,
            isEnded: change.isEnded,
            webinarId: change.webinarId,
            spatialLink: change.spatialLink,
            targets: change.targets,
            seatLimit: change.seatLimit,
            themeBackground: change.themeBackground,
            autoStart: change.autoStart,
            recordUrl: change.recordUrl,
            approvalNeeded: change.approvalNeeded,
            reminder: change.reminder
        }, { merge: true })
    }

    attendToEvent = async (eventId: string, userData: any, notify = true, ignoreMembership = false) => {
        const result = await this.functions.httpsCallable('attendToEvent')({ eventId, userData, notify, ignoreMembership });
        return result.data;
    }

    unattendToEvent = async (eventId: string, userData: UserModel, ignoreMembership = false, applicationDenied = false) => {
        return this.functions.httpsCallable('unattendToEvent')({ eventId, userData, ignoreMembership, applicationDenied });
    }

    setEventTimer = async (eventId: string, label: string, minutesFromNow: number) => {
        await this.event(eventId).update({
            timer: {
                label: label,
                setTo: moment().unix() + minutesFromNow * 60
            }
        }).catch(error => {
            console.error('failed to set a timer: ' + error);
        });
    }

    clearEventTimer = async (eventId: string) => {
        return this.event(eventId).update({
            timer: null
        })
    }

    fetchAttendee = async (eventId: string, attendeeId: string) => {
        const result = await this.eventAttendee(eventId, attendeeId).get();
        return AttendeeModel.mapFromServer({ id: result.id, ...result.data() })
    }

    addQuestionToEvent = async (eventId: string, question: Question) => {
        this.event(eventId).update({
            questions: firebase.firestore.FieldValue.arrayUnion({ ...question })
        });
    }

    generateVideoToken = async () => {
        const result = await this.functions.httpsCallable('generateVideoToken')();
        return result.data;
    }

    fetchInvitationInfo = async (id: string) => {
        const result = await this.invitation(id).get();
        return result.exists ? InvitationModel.mapFromServer({ id: result.id, ...result.data() }) : {};
    }

    fetchUserReferrals = async (userId: string) => {
        const result = await this.users().where('referrerUser', '==', userId).limit(20).get();
        return result.docs.map(u => UserModel.mapFromServer({ id: u.id, ...u.data() }));
    }

    fetchAllMembers = async (): Promise<UserModel[]> => {
        const result = await this.functions.httpsCallable('fetchAllMembers')();
        const members = result.data.map((u: any) => UserModel.mapFromServer(u));
        return members;
    }

    notify = async (message: string) => {
        await this.functions.httpsCallable('notify')(message);
    }

    deleteUser = async (id: string) => {
        await this.user(id).delete();
    }

    generateInvitationLinksForUser = async (user: UserModel, options: any) => {
        await this.invitations().add({
            inviterId: user.id,
            inviterCompany: {
                name: user.company?.name,
                photoUrl: user.company?.logoUrl,
            },
            inviterFirstName: user.firstName,
            inviterLastName: user.lastName,
            inviterPhotoUrl: user.photoUrl,
            membership: {
                noTrial: options.noTrial,
            },
            createdAt: firebase.firestore.FieldValue.serverTimestamp(),
        })
    }

    generateGuestInvitation = async (user: UserModel, eventInfo: EventPublicData, guestRole: string, skipApproval: boolean) => {
        const docRef = await this.invitations().add({
            inviterId: user.id,
            inviterCompany: {
                name: user.company?.name,
                photoUrl: user.company?.logoUrl,
            },
            inviterFirstName: user.firstName,
            inviterLastName: user.lastName,
            inviterPhotoUrl: user.photoUrl,
            inviterEmail: user.email,
            isGuest: true,
            guestRole: guestRole,
            skipApproval: skipApproval,
            eventInfo: eventInfo,
            createdAt: firebase.firestore.FieldValue.serverTimestamp(),
        });

        return docRef.id;
    }

    fetchInvitationLinksForUser = async (userId: string) => {
        const result = await this.invitations()
            .where('inviterId', '==', userId)
            .orderBy('createdAt', 'desc')
            .limit(100).get();
        return result.docs.map(i => InvitationModel.mapFromServer({ id: i.id, ...i.data() }));
    }

    updateUser = async (id: string, change = {}) => {
        await this.user(id).set(change, { merge: true })
    }

    getUser = async (id: string) => {
        const doc = await this.user(id).get();
        return UserModel.mapFromServer({ id: doc.id, ...doc.data() });
    }

    getMembersForCompany = async (companyId: string) => {
        const result = await this.users().limit(10).where('company.id', '==', companyId).get();
        return result.docs.map(doc => UserModel.mapFromServer({ id: doc.id, ...doc.data() }))
    }

    getEventConversation = async (eventId: string, conversationId: string) => {
        const result = await this.eventConversation(eventId, conversationId).get();
        return EventConversationModel.mapFromServer({ id: result.id, ...result.data() });
    }

    addMessageToEvent = async (eventId: string, data: { message: string; by?: UserPublicData, mentions: string[], role: string }) => {
        const { firstName, lastName, company, photoUrl } = data.by!;
        await this.eventMessages(eventId).add({
            message: data.message,
            by: { firstName, lastName, company, photoUrl },
            role: data.role,
            createdAt: moment().unix(),
        })
    }

    addRoundTable = async (eventId: string, title: string, maxSeats: number, preselectedAttendees: string[]) => {
        await this.eventRoundTables(eventId).add({
            title: title,
            maxSeats: maxSeats,
            preselectedAttendees: preselectedAttendees,
            createdAt: firebase.firestore.FieldValue.serverTimestamp(),
        });
    }

    addInitialRoundTables = async (eventId: string) => {
        const batch = this.db.batch();
        
        _.times(5, (index) => {
            batch.set(this.event(eventId).collection('roundTables').doc(), {
                maxSeats: 8, 
                createdAt: firebase.firestore.FieldValue.serverTimestamp(),
            });
        })

        await batch.commit();
    }

    updateRoundTable = async (id: string, eventId: string, title: string, maxSeats: number, preselectedAttendees: string[]) => {
        await this.eventRoundTable(eventId, id).update({
            title: title,
            maxSeats: maxSeats,
            preselectedAttendees: preselectedAttendees,
        });
    }

    deleteRoundTable = async (id: string, eventId: string) => {
        await this.eventRoundTable(eventId, id).delete();
    }

    joinRoundTable = async (id: string, eventId: string, participant?: UserPublicData) => {
        return this.db.runTransaction(async (transaction) => {
            const roundTableDoc = await transaction.get(this.eventRoundTable(eventId, id));

            if (!roundTableDoc.exists) {
                return false;
            }

            const roundTable = RoundTableModel.mapFromServer({ id: roundTableDoc.id, ...roundTableDoc.data() });
            if (!roundTable.participants || roundTable.participants.length < roundTable.maxSeats! || participant?.isManager) {
                transaction.update(roundTableDoc.ref, {
                    participants: firebase.firestore.FieldValue.arrayUnion(participant)
                });

                return true;
            }

            return false;
        }).then(joined => {
            return joined;
        })
    }

    leaveRoundTable = async (id: string, eventId: string, participant?: UserPublicData) => {
        await this.eventRoundTable(eventId, id).update({
            participants: firebase.firestore.FieldValue.arrayRemove(participant)
        });
    }

    cleanRoundTables = async (roundTables: RoundTableModel[], eventId: string) => {
        const batch = this.db.batch();

        roundTables.forEach(table => {
            batch.update(this.eventRoundTable(eventId, table.id!), {
                participants: []
            });
        });

        await batch.commit();
    }

    getMeeting = async (id: string) => {
        const result = await this.meeting(id).get();
        return MeetingModel.mapFromServer({ id: result.id, ...result.data() });
    }

    joinMeeting = async (meetingId: string, participant: MeetingParticipant) => {
        return this.db.runTransaction(async (transaction) => {
            const meeting = await transaction.get(this.meeting(meetingId));

            if (!meeting.exists) {
                return false;
            }

            const prevParticipants = meeting.data()?.participants || [];
            const participants = [...prevParticipants.filter((p: any) => p.id !== participant.id), participant];

            transaction.update(meeting.ref, {
                participants: participants
            });

            return true;
        });
    }

    updateIntroRequest = async (introRequestId: string, change: any) => {
        await this.introRequest(introRequestId).update({
            ...change,
            lastUpdate: moment().unix(),
        })
    }

    makeIntro = async (introRequestId?: string) => {
        try {
            await this.functions.httpsCallable('makeIntro')({ id: introRequestId });
            return true;
        } catch(error) {
            console.error(error);
            return false;
        }
    }

    introAccepted = async (introRequestId?: string) => {
        try {
            await this.functions.httpsCallable('introAccepted')({ id: introRequestId });
        } catch(error) {
            console.error(error);
        }
    }

    introCompleted = async (introRequestId?: string) => {
        try {
            await this.functions.httpsCallable('introCompleted')({ id: introRequestId });
        } catch(error) {
            console.error(error);
        }
    }

    introPreview = async (requestedUser: UserPublicData, requesterUser: UserPublicData, introducer: UserModel, purpose: string) => {
        try {
            const result = await this.functions.httpsCallable('introPreview')({ requesterUser, requestedUser, introducer, purpose });
            return { subject: result.data.subject as string, html: result.data.html as string };
        } catch(error) {
            console.error(error);
        }
    }


    userSubmitFeedback = async (name?: string, company?: string, freeText?: string, reasons?: string[], email?: string) => {
        await this.functions.httpsCallable('userSubmittedFeedback')({ name, company, freeText, reasons, email });
    }

    collectEventFeedback = async (eventId: string) => {
        await this.functions.httpsCallable('collectEventFeedback')({ eventId: eventId });
    }

    async loginSSO(provider: LoginProvider) {
        let authProvider;
        if (provider === LoginProvider.Google) {
            authProvider = new firebase.auth.GoogleAuthProvider();
            if (isMobile()) {
                firebase.auth().signInWithRedirect(authProvider);
            } else {
                const result = await firebase.auth().signInWithPopup(authProvider);
                const userAuthData = this.extractAuthData(result);
                await this.onLoginSuccess(userAuthData);
            }
        }
    }

    setRedirectLoginListener() {
        firebase.auth().getRedirectResult().then(async (result) => {
            if (result?.credential) {
                const userAuthData = this.extractAuthData(result);
                await this.onLoginSuccess(userAuthData);
            }
        }).catch(function (error) {
            var errorCode = error.code;
            if (errorCode === 'auth/account-exists-with-different-credential') {
                alert('You have already signed up with a different auth provider for that email.');
            } else {
                console.error(error);
            }
        });
    }


    handleEmailLogin = async () => {
        if (firebase.auth().isSignInWithEmailLink(window.location.href)) {
            let email = window.localStorage.getItem('emailForSignIn');
            if (!email) {
                email = window.prompt('Please provide your email for confirmation');
            }

            const result = await firebase.auth().signInWithEmailLink(email || '', window.location.href);
            const authData = this.extractAuthData(result);
            this.onLoginSuccess(authData)
        }
    }

    sendEmailLoginLink = async (email: string, actionCodeSettings: any) => {
        await firebase.auth().sendSignInLinkToEmail(email, actionCodeSettings)
    }

    changeEmail = async (newEmail: string) => {
        const actionCodeSettings = {
            url: `${window.location.origin}/login`,
            handleCodeInApp: true,
        };

        await firebase.auth().currentUser?.verifyBeforeUpdateEmail(newEmail, actionCodeSettings);
    }

    private onLoginSuccess = async (userAuthData: UserAuthData) => {
        if (userAuthData.isNewUser) {
            await this.signUpUser(userAuthData);
        } else {
            await this.signInUser(userAuthData);
        }

        if (userAuthData.token) {
            this.userAccount(userAuthData.id).doc('auth').set({
                accessToken: userAuthData.token,
            }, { merge: true });
        }

        if (userAuthData.email) {
            this.user(userAuthData.id).set({
                email: userAuthData.email,
            }, { merge: true });
        }

        window.localStorage.removeItem(INVIATION_CACHE_KEY);
    }

    private signInUser = async (userAuthData: UserAuthData) => {
        this.analyticsReporter.login(userAuthData.providerId);

        if (this.rootStore.sessionStore.hasInvitation) {
            const user = await this.getUser(userAuthData.id);
            if (user.leadersMember || user.staff) {
                return;
            }

            const invitation = this.rootStore.sessionStore.invitation!;
            if (invitation.isGuest) {
                await this.updateUser(userAuthData.id, {
                    leadersMember: false,
                    guest: true,
                    guestInvitationId: invitation.id,
                });
                this.analyticsReporter.setGuest(true);
            } else { // regular invitation
                await this.updateUser(userAuthData.id, {
                    leadersMember: true,
                    guest: false,
                    referrerUser: invitation.inviterId,
                });
                this.analyticsReporter.setGuest(false);
            }
        }
    }

    private signUpUser = async (userAuthData: UserAuthData) => {
        this.analyticsReporter.signup(userAuthData.providerId);
        let userProps: any = {
            id: userAuthData.id,
            firstName: userAuthData.firstName,
            lastName: userAuthData.lastName,
            photoUrl: userAuthData.photoUrl,
            email: userAuthData.email,
            createdAt: firebase.firestore.FieldValue.serverTimestamp(),
            leadersMember: this.rootStore.settingsStore.access === 'open',
            membership: {
                status: 'trial',
                credits: {
                    events: this.rootStore.settingsStore.membership?.defaultEventCredits || 0,
                    intros: this.rootStore.settingsStore.membership?.defaultIntroCredits || 0,
                }
            }
        };

        const invitation = this.rootStore.sessionStore.invitation;
        if (invitation) {
            if (invitation.isGuest) {
                userProps = {
                    ...userProps,
                    leadersMember: false,
                    guest: true,
                    guestInvitationId: invitation.id,
                };
                this.analyticsReporter.setGuest(true);
            } else { // regular member invitation
                userProps = {
                    ...userProps,
                    leadersMember: true,
                    referrerUser: invitation.inviterId,
                    guestInvitationId: invitation.id,
                };
                this.analyticsReporter.setGuest(false);
            }
        }

        await this.createOrUpdateUser({
            ...userProps,
        });

        this.userAccount(userAuthData.id).doc('auth').set({
            providerId: userAuthData.providerId,
        });

        this.userAccount(userAuthData.id).doc('consent').set({
            date: firebase.firestore.FieldValue.serverTimestamp(),
            text: `By continuing, you are indicating that you're agree with our Terms of Use and Privacy Policy`,
        });
    }

    async logout() {
        const currentUserId = this.app.auth().currentUser?.uid;
        const userStatusDatabaseRef = this.realtimeDb.ref(`/status/${currentUserId}`);
        await userStatusDatabaseRef.set(this.isOfflineForDatabase);
        await this.updateUser(currentUserId!, { presence: this.isOfflineForFirestore })
        await this.app.auth().signOut();
    }

    onAuthStatusChange = (callback: (user: any) => void) => {
        this.app.auth().onAuthStateChanged(callback);
    }

    handleUserPresence = (user: any) => {
        const uid = user.uid;
        const userStatusDatabaseRef = this.realtimeDb.ref('/status/' + uid);

        this.realtimeDb.ref('.info/connected').on('value', (snapshot) => {
            if (snapshot.val() == false) {
                console.log('User offline');
                this.updateUser(uid, { presence: this.isOfflineForFirestore })
                return;
            };

            userStatusDatabaseRef.onDisconnect().set(this.isOfflineForDatabase).then(() => {
                console.log('User online');
                userStatusDatabaseRef.set(this.isOnlineForDatabase);
                this.updateUser(uid, { presence: this.isOnlineForFirestore })
            });
        });
    }

    upload = async (file: File, namePreffix = '', onProgress?: any) => {
        return new Promise((resolve, reject) => {
            const fileName = namePreffix ? `${namePreffix}_${StringUtils.uniqueId()}_${file.name}` : `${StringUtils.uniqueId()}_${file.name}`;
            const ref = firebase.storage().ref('general/').child(fileName);

            const uploadTask = ref.put(file);
            uploadTask.on('state_changed', snapshot => {
                const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                onProgress && onProgress(progress);
            }, (error) => {
                reject(error);
            }, async () => {
                let url = await uploadTask.snapshot.ref.getDownloadURL();
                resolve(url);
            });
        });

    }

    getFileType = (contentType: string) => {
        return contentType.split('/')[0];
    }

    private extractAuthData(result: firebase.auth.UserCredential): UserAuthData {
        const user = result.user;
        if (!user) throw Error('User is null for some reason.');

        const token = ((result.credential) as firebase.auth.OAuthCredential)?.accessToken;
        const isNewUser = result.additionalUserInfo?.isNewUser;
        const photoUrl = result.user?.photoURL;
        const providerId = result?.credential?.providerId || result?.additionalUserInfo?.providerId || '';
        let firstName = '';
        let lastName = '';

        if (result?.additionalUserInfo?.profile) {
            const { given_name, family_name } = result?.additionalUserInfo?.profile as any;
            firstName = given_name;
            lastName = family_name;
        } else if (result.user?.displayName) {
            const splitted = result.user.displayName.split(' ');
            firstName = _.first(splitted) || '';
            lastName = _.last(splitted) || '';
        }

        return {
            firstName: firstName,
            lastName: lastName,
            photoUrl: photoUrl || '',
            email: user.email || '',
            id: user.uid,
            token: token || '',
            providerId: providerId,
            isNewUser: isNewUser
        };
    }

    isOfflineForDatabase = {
        state: 'offline',
        lastChanged: firebase.database.ServerValue.TIMESTAMP,
    };

    isOnlineForDatabase = {
        state: 'online',
        lastChanged: firebase.database.ServerValue.TIMESTAMP,
    };

    isOfflineForFirestore = {
        state: 'offline',
        lastChanged: firebase.firestore.FieldValue.serverTimestamp(),
    };

    isOnlineForFirestore = {
        state: 'online',
        lastChanged: firebase.firestore.FieldValue.serverTimestamp(),
    };
}




type UserAuthData = {
    id: string,
    token: string,
    providerId: string,
    firstName: string,
    lastName: string,
    photoUrl?: string,
    email?: string,
    isNewUser?: boolean
}

export default FirebaseClient;

