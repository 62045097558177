import moment from "moment";

class CompanyModel {
    id?: string;
    slug?: string;
    name?: string;
    shortDescription?: string;
    longDescription?: string;
    industries?: string[];
    location?: string;
    lastFundingType?: string;
    fundingStatus?: string;
    investors?: string[];
    numberOfFundingRounds?: number;
    numberOfInvestors?: number;
    totalFunding?: number;
    numberOfEmployees?: string;
    founders?: string[];
    country?: string;
    logo?: string;
    founded?: string;
    website?: string;
    twitter?: string;
    facebook?: string;
    linkedin?: string;
    ownerId?: string;
    fundraising?: boolean;
    acquiredBy?: string;

    constructor(id?: string,
        slug?: string,
        name?: string,
        shortDescription?: string,
        longDescription?: string,
        industries?: string[],
        location?: string,
        lastFundingType?: string,
        fundingStatus?: string,
        investors?: string[],
        numberOfFundingRounds?: number,
        numberOfInvestors?: number,
        totalFunding?: number,
        numberOfEmployees?: string,
        founders?: string[],
        country?: string,
        logo?: string,
        founded?: string,
        website?: string,
        twitter?: string,
        facebook?: string,
        linkedin?: string,
        ownerId?: string,
        fundraising?: boolean,
        acquiredBy?: string) {
        this.id = id;
        this.name = name;
        this.shortDescription = shortDescription;
        this.slug = slug;
        this.longDescription = longDescription;
        this.industries = industries;
        this.location = location;
        this.lastFundingType = lastFundingType;
        this.fundingStatus = fundingStatus;
        this.investors = investors;
        this.numberOfFundingRounds = numberOfFundingRounds;
        this.numberOfInvestors = numberOfInvestors;
        this.totalFunding = totalFunding;
        this.numberOfEmployees = numberOfEmployees;
        this.founders = founders;
        this.country = country;
        this.logo = logo;
        this.founded = founded;
        this.website = website;
        this.twitter = twitter;
        this.facebook = facebook;
        this.linkedin = linkedin;
        this.ownerId = ownerId;
        this.fundraising = fundraising;
        this.acquiredBy = acquiredBy;
    }

    get hasSocial() {
        return this.twitter || this.facebook || this.linkedin;
    }

    get isClaimed() {
        return this.ownerId ? true : false;
    }

    static mapFromServer(data: any) {
        return new CompanyModel(
            data.id,
            data.slug,
            data.name,
            data.shortDescription,
            data.longDescription,
            data.industries,
            data.location,
            data.lastFundingType,
            data.fundingStatus,
            data.investors,
            data.numberOfFundingRounds,
            data.numberOfInvestors,
            data.totalFunding,
            data.numberOfEmployees,
            data.founders,
            data.country,
            data.logo,
            data.founded,
            data.website,
            data.twitter,
            data.facebook,
            data.linkedin,
            data.ownerId,
            data.fundraising,
            data.acquiredBy,
        )
    }
}

export default CompanyModel;