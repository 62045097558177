
import { Avatar, Button, Dropdown, Form, Input, Menu, Select, Typography } from 'antd';
import Modal from 'antd/lib/modal/Modal';
import { inject, observer } from 'mobx-react';
import React, { useEffect, useRef, useState } from 'react';
import CompanyModel from '../../../../common/models/CompanyModel';
import SessionStore from '../../../../store/SessionStore';
import styles from './CompanyIntroModal.module.css';

interface Props {
    visible: boolean,
    onCancel: () => void,
    onSubmit: (questionText: string) => void,
    company: CompanyModel,
    sessionStore?: SessionStore,
}

function CompanyIntroModal({ visible, onCancel, onSubmit, sessionStore, company }: Props) {
    const [isBusy, setBusy] = useState(false);
    const { authUser } = sessionStore!;

    const onFinish = async (values: any) => {
        setBusy(true);
        setTimeout(() => {
            onSubmit(values.message);
            setBusy(false);
        }, 1500);
    }

    return (
        <Modal visible={visible} footer={null} onCancel={onCancel}>
            <div className={styles.container}>
                <div className={styles.header}>Intro Requst</div>
                <div className={styles.subHeader}>
                    We'll connect you with the right people on <b>{company.name}</b>. Some members in our club are working for this company.
                </div>
                <Form name='company-intro-form'
                    style={{ width: '100%' }}
                    className='company-intro-form'
                    layout='vertical'
                    onFinish={onFinish}>
                    <Form.Item
                        name='message'
                        rules={[{ required: true, message: 'Please add a longer message' }]}>
                        <Input.TextArea placeholder={`Add a message to the team.`} maxLength={500} minLength={20} />
                    </Form.Item>
                    <Form.Item>
                        <Button loading={isBusy} htmlType='submit' size='large' type='primary' className={styles.loginButton}>
                            Request Introduction
                        </Button>
                    </Form.Item>
                    <div className={styles.askingAsContainer}>
                        <Avatar src={authUser?.photoUrl} size='small' />
                        <div>Requesting intro as <span>{authUser?.fullName}</span></div>
                    </div>
                </Form>
            </div>
        </Modal>)

}

export default inject('sessionStore')(observer(CompanyIntroModal));