import React, { useEffect } from "react";
import { inject, observer } from "mobx-react";
import { EditOutlined } from '@ant-design/icons';
import styles from './ResourcePage.module.css';
import AnalyticsReporter from "../../services/analytics/AnalyticsReporter";
import SessionStore from "../../store/SessionStore";
import { Button, Card, message } from "antd";
import SectionHeader from "../../common/components/sectionHeader/SectionHeader";
import AddResourceDrawer from "./components/addResourceDrawer/AddResourceDrawer";
import { useState } from "react";
import ResourcesStore from "../../store/ResourcesStore";
import ResourceCard from "./components/resourceCard/ResourceCard";
import { ResourceModel } from "../../common/models/ResourceModel";
import { useHistory, useLocation, useRouteMatch } from "react-router-dom";
import Loader from "../../common/components/loader/Loader";
import externalLink from '../../common/images/external-link-white.svg'
import ReactMarkdown from "react-markdown";

interface Props {
    analyticsReporter: AnalyticsReporter;
    resourcesStore: ResourcesStore;
    sessionStore: SessionStore;
}

function ResourcePage({ sessionStore, resourcesStore, analyticsReporter }: Props) {
    const { authUser, loggedIn } = sessionStore;
    const { resources } = resourcesStore;
    const [resource, setResource] = useState<ResourceModel>();
    const [resourceDrawerVisible, setResourceDrawerVisible] = useState(false);
    const match = useRouteMatch();
    const history = useHistory();
    const location = useLocation();
    const [loader, showLoader] = useState(true);
    const [userActivityAdded, setUserActivityAdded] = useState(false);

    useEffect(() => {
        analyticsReporter.trackPage('Resources Page');
    }, []);

    useEffect(() => {
        if (loggedIn) {
            if (!authUser?.hasActiveMembership && resource?.accessLevel === 'active-membership') {
                history.replace('/');
            }

            if (location.state) {
                const resourceState = JSON.parse(location.state + "") as ResourceModel;
                setResource(resourceState);
            }

            const { slug } = match.params as any;
            resourcesStore.fetchBySlug(slug).onSnapshot(snap => {
                const result = snap.docs[0];
                
                if(!result) {
                    return;
                }

                setResource({ id: result.id, ...result.data() } as ResourceModel);
            });
        }
    }, [loggedIn])

    useEffect(() => {
        if (resource && !userActivityAdded) {
            setUserActivityAdded(true);
            sessionStore.addActivityItem(`Content viewed: ${resource.title}`, 'content', 'view');
        }

    }, [resource, userActivityAdded])

    const openNewResourceDrawer = () => {
        setResourceDrawerVisible(true);
    }

    const onExternalClicked = () => {
        window.open(resource?.link, '_blank');
    }

    return (<div className={styles.container}>
        <div className={styles.sectionContainer}>
            <SectionHeader title={resource?.title}
                subTitle={resource?.description} />
            <div className={styles.buttons}>
                {authUser?.isManager &&
                    <Button className={styles.addButton}
                        type='primary'
                        size={"large"}
                        icon={<EditOutlined />}
                        onClick={(openNewResourceDrawer)}>
                        Edit
                    </Button>
                }
            </div>
        </div>


        <div className={styles.contentContainer}>
            {resource?.type === 'content' &&
                <Card className={styles.card}>
                    <ReactMarkdown className={styles.resourceContent} source={resource.content || ''} />
                </Card>
            }
            {resource?.type === 'link' && resource?.mode === 'external' &&
                <Card className={styles.card}>
                    <div style={{ marginBottom: 12 }}>Click the button below to view this content:</div>
                    <Button onClick={onExternalClicked} type='primary'><img className={styles.externalIcon} src={externalLink} />Open Link</Button>
                </Card>
            }
            {resource?.type === 'link' && resource?.mode === 'embed' &&
                <div className={styles.embedContainer}>
                    <embed className={styles.embed} onLoad={() => showLoader(false)} src={resource.link} />
                    <Loader visible={loader} size={'large'} className={styles.loader} />
                </div>
            }

        </div>
        <AddResourceDrawer resourceModel={resource} visible={resourceDrawerVisible} onClose={() => setResourceDrawerVisible(false)} />

    </div>);
}


export default inject('sessionStore', 'resourcesStore', 'analyticsReporter')(observer(ResourcePage));