import React, { useEffect, useState } from 'react';
import { observer, inject } from 'mobx-react'
import Logo from '../../common/components/logo/Logo';
import ttnLogo from '../../common/components/logo/ttn-logo.svg';
import phocuswrightLogo from '../../common/components/logo/pw-logo.png';
import LoginWith from './components/buttons/LoginWith';
import InvitationBubble from './components/invitation/InvitationBubble';
import styles from './LoginPage.module.css';
import SessionStore from '../../store/SessionStore';
import FirebaseClient, { LoginProvider } from '../../services/FirebaseClient';
import _ from 'lodash';
import { Button, Divider, Form, Input, message, Modal } from 'antd';
import Lottie from 'react-lottie';
import emailSentAnimation from './emailsent.json';
import AnalyticsReporter from '../../services/analytics/AnalyticsReporter';
import RemoteSettingsProvider from '../../services/settings/RemoteSettingsProvider';
import { PrivacyPolicy, TermsOfUse } from '../onboarding/components/LegalLinks';
import GuestInvitationBubble from './components/invitation/GuestInvitationBubble';
import { hideTawkChatBubble } from '../../common/utils/Utils';
import SettingsStore from '../../store/SettingsStore';
import Loader from '../../common/components/loader/Loader';


interface Props {
    sessionStore: SessionStore,
    firebase: FirebaseClient,
    analyticsReporter: AnalyticsReporter,
    remoteSettingsProvider: RemoteSettingsProvider,
    settingsStore: SettingsStore,
    location: any,
    history: any,
    match: any,
}


function LoginPage({ sessionStore, firebase, location, history, match, analyticsReporter, remoteSettingsProvider, settingsStore }: Props) {
    const { invitationId } = match.params;
    const { invitation, loggedIn, isAuthed, authUser } = sessionStore;
    const [isBusy, setIsBusy] = useState(false);
    const [loginLinkSentModalVisible, setLoginLinkSentModalVisible] = useState(false);
    const [credentialsSignup, setCredentialsSignup] = useState(true);

    useEffect(() => {
        analyticsReporter.trackPage('Login Screen');
    }, []);

    useEffect(() => {
        if (location.state) {
            const { from } = location.state as any
            let encodedUrl = encodeURIComponent(from);

            history.push(`${location.pathname}?from=${encodedUrl}`);
        }

        sessionStore.firebase.setRedirectLoginListener();
    }, []);

    useEffect(() => {
        sessionStore.handleEmailLoginIfNeeded().then(() => {
            window.localStorage.removeItem('emailForSignIn');
        }).catch(error => {
            message.error(error.message);
        });
    }, []);

    useEffect(() => {
        if (invitationId) {
            sessionStore.getInvitation(invitationId).catch(() => {
                message.error('Invitation is expired or invalid. Please contact your referrer.')
                history.replace('/');
            })
        }
    }, [invitationId]);


    const handleNavigation = () => {
        if (loggedIn) {
            if (!sessionStore.accessPermitted) {
                if (sessionStore.isInvitationLink(location.pathname)) {
                    return;
                }

                history.push('/waiting-list');
                return;
            }

            if (!sessionStore.onboardingCompleted) {
                history.push('/onboarding');
                return;
            }

            if (sessionStore.isInvitationLink(location.pathname)) {
                const invitation = sessionStore.invitation;
                if (authUser?.isGuest) {
                    if (invitation?.isGuest) {
                        history.push('/guest-confirmation');
                        return;
                    } else {
                        return;
                    }
                } else { // for cases of a member with a guest link
                    if (invitation?.isGuest) {
                        history.push(`/e/${invitation?.eventInfo?.shortId}`);
                        return;
                    }

                }
            }

            if (sessionStore.isGuest) {
                history.push('/guest-confirmation');
                return;
            }

            const fromEncoded = new URLSearchParams(location.search).get("from")
            const from = decodeURIComponent(fromEncoded || '');
            if (from) {
                history.push(from);
            } else {
                history.push('/');
            }
        }
    }

    const onSignInWithEmail = () => {
        setCredentialsSignup(true);
    }

    const onLogin = async (loginProvider: LoginProvider) => {
        try {
            await sessionStore.loginSSO(loginProvider);
        } catch (error) {
            console.log(error);
        }
    }

    const sendLoginLink = async (values: any) => {
        analyticsReporter.setSignInWithEmail();

        const { email } = values;
        const valid = validateCredentials(email);

        if (!valid) {
            return;
        }

        try {
            setIsBusy(true);
            await sessionStore.sendEmailLoginLink(email);
            setLoginLinkSentModalVisible(true);
        } catch (error) {
            message.error(error.message);
        } finally {
            setIsBusy(false)
        }
    }


    const validateCredentials = (email: string) => {
        const emailRegx = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/g

        if (!emailRegx.test(email)) {
            message.error('Please enter a valid email address.')
            return false;
        }

        return true;
    }

    const onDismissMagicLinkModal = () => {
        setLoginLinkSentModalVisible(false);
        sessionStore.listenForUser();
    }

    const renderInvitation = () => {
        if (!invitation) {
            return <></>;
        }

        if (invitation.isGuest) {
            return <div className={styles.invitation}>
                <GuestInvitationBubble className={styles.invitationBubble}
                    invitation={invitation} />
            </div>
        } else {
            return <div className={styles.invitation}>
                <InvitationBubble className={styles.invitationBubble}
                    user={{ firstName: invitation?.inviterFirstName, userImage: invitation?.inviterPhotoUrl, companyName: invitation.inviterCompany?.name, companyImage: invitation?.inviterCompany?.photoUrl }} />
            </div>
        }
    }


    handleNavigation();


    return (
        <div className={`${styles.container} ${credentialsSignup ? styles.containerLong : ''}`}>
            <div className={styles.content}>
                <Logo style={{ marginBottom: 20 }} />
                <div className={styles.textContainer}>
                    <div className={styles.subHeader}>{settingsStore?.space?.description}</div>
                </div>
                {renderInvitation()}

                <div className={styles.loginButtons}>
                    <div className={styles.signupContainer}>
                        <LoginWith preffix='Continue with' type='google' onClick={() => onLogin(LoginProvider.Google)} />
                        {!credentialsSignup && <div className={styles.alreadyMemberContainer}>

                            <div onClick={() => onSignInWithEmail()}>or use your email</div>
                        </div>
                        }
                    </div>
                    {credentialsSignup &&

                        <div className={styles.credentialContainer}>
                            <Divider className={styles.divider}>Or</Divider>
                            <Form name={'signupForm'}
                                onFinish={sendLoginLink}
                                layout="vertical">
                                <Form.Item name='email'
                                    style={{ marginBottom: 10 }}
                                >
                                    <Input placeholder='Enter your email' type='email' />
                                </Form.Item>
                                <Form.Item>
                                    <Button loading={isBusy} htmlType='submit' size='large' type='primary' className={styles.loginButton}>
                                        Send Login Link
                                    </Button>
                                </Form.Item>
                            </Form>

                        </div>
                    }
                    <div>
                        <div className={styles.tosContainer}>
                            <span>
                                By continuing, you are indicating that agree with our <TermsOfUse /> &amp; <PrivacyPolicy />
                            </span>
                        </div>
                    </div>

                </div>
            </div>

            <Modal footer={null}
                onCancel={onDismissMagicLinkModal}
                visible={loginLinkSentModalVisible}>
                <div className={styles.modalContainer}>
                    <div className={styles.emailAnimation}>
                        <Lottie options={lottieFile} />
                    </div>
                    <div className={styles.modalHeader}>Check your inbox</div>
                    <div className={styles.modalSubHeader}>
                        We've sent a special link to your inbox that will get you in automatically. Yes, it's magic.
                        <br />
                        <br />
                        <b>P.S - </b> Check the spam folder in case it gets there for some reason (email from:
                        <b>noreply@{window.location.host}</b>).
                    </div>

                </div>

            </Modal>
        </div>
    )
}

const lottieFile = {
    loop: true,
    autoplay: true,
    animationData: emailSentAnimation,
    rendererSettings: {
        preserveAspectRatio: 'xMidYMid slice'
    }
};

export default inject('sessionStore', 'firebase', 'analyticsReporter', 'remoteSettingsProvider', 'settingsStore')(observer(LoginPage));