import { AutoComplete, Button, Form, Input, Tag, Upload, message } from 'antd';
import _ from 'lodash';
import { inject, observer } from 'mobx-react';
import React, { useState } from 'react';
import OnboardingStore, { Sector } from '../../../../store/OnboardingStore';
import NavigationButtons from '../navigationButtons/NavigationButtons';
import StepWrapper from '../stepWrapper/StepWrapper';
import styles from './SectorsStep.module.css';


const { Option } = AutoComplete;
const MAX_SELECTED = 3;

interface Props {
    onNext: () => void,
    onBack: () => void,
    onboardingStore?: OnboardingStore,
}


function SectorsStep({ onboardingStore, onNext, onBack }: Props) {
    const { sectorsTags, selectedSectors, onSectorsPrimarySubmit } = onboardingStore!;
    const [selectedTags, setSelctedTags] = useState<Sector[]>(selectedSectors);
    const [isBusy, setIsBusy] = useState(false);

    const handleChange = (tag: Sector, checked: boolean) => {
        if (checked) {
            if (selectedTags.length === MAX_SELECTED) {
                message.info(`Please choose up to ${MAX_SELECTED} sectors you're most active in.`)
                return;
            }

            setSelctedTags([...selectedTags, tag]);
        } else {
            setSelctedTags(selectedTags.filter(t => t.name !== tag.name));
        }

    }

    const submitSectors = async () => {
        if(selectedTags.length === 0) {
            message.info('Please selected at least one.')
            return;
        }

        setIsBusy(true);
        
        setTimeout(async () => {
            await onSectorsPrimarySubmit(selectedTags);
            setIsBusy(false);
            onNext();
        }, 1000);
        
        
    }

    return <StepWrapper title={`What's your sector focus?`}
        subTitle={`We know you're into travel, but tell us which sectors you're active in.`}>
        <div className={`sectors ${styles.content}`}>
            <div className={styles.sectorsContainer}>
                {sectorsTags.map(tag => <Tag.CheckableTag
                    key={tag.name}
                    onChange={checked => handleChange(tag, checked)}
                    checked={_.some(selectedTags, tag)}>
                    {tag.name}
                </Tag.CheckableTag>
                )}
            </div>

        </div>

        <NavigationButtons buttonTitle={'Next'}
            isBusy={isBusy}
            onNext={submitSectors}
            showBack={false}
            onBack={onBack} />

    </StepWrapper>
}


export default inject('onboardingStore')(observer(SectorsStep));