import moment from "moment";
import { AttendeeRole } from "./AttendeeModel";
import { UserPublicData } from "./UserModel";


class MessageModel {
    id?: string;
    by?: UserPublicData;
    createdAt?: number;
    message?: string;
    mentions?: string[];
    role?: AttendeeRole;

    constructor(id?: string, by?: UserPublicData, message?: string, mentions?: string[], role?: AttendeeRole, createdAt?: number) {
        this.id = id;
        this.by = by;
        this.createdAt = createdAt;
        this.message = message;
        this.mentions = mentions;
        this.role = role;
    }

    get isRecent() {
        if (!this.createdAt) {
            return false;
        }

        const now = moment().unix();
        return this.createdAt >= now - 5; // last 5 seconds
    }

    get isForEveryone() {
        if (!this.mentions || !this.message) {
            return false;
        }

        return this.mentions?.indexOf('everyone') > -1 || this.message?.indexOf('@everyone') > -1;
    }

    static mapFromServer(data: any) {
        return new MessageModel(data.id,
            data.by,
            data.message,
            data.mentions || [],
            data.role || 'attendee',
            data.createdAt);
    }

}

export default MessageModel;