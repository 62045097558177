import React, { useState } from 'react';
import Avatar from '../../../../common/components/avatar/UserAvatar';
import styles from './LoginWith.module.css';
import googleIcon from './google.svg';
import googleIconWhite from './google-white.svg';
import linkedinIcon from './linkedin.svg';

interface LoginWithProps {
    type: 'google' | 'linkedIn',
    onClick?: () => void,
    preffix: string,
}

const loginProviders = {
    google: {
        text: 'Google',
        icon: googleIconWhite,
        color: '#4284f4'
    },
    linkedIn: {
        text: 'LinkedIn',
        icon: linkedinIcon,
        color: '#4284f4'

    }
}

function LoginWith({ type, onClick, preffix }: LoginWithProps) {
    return (
        <div className={styles.container} style={{ backgroundColor: loginProviders[type].color}} onClick={onClick}>
            <img src={loginProviders[type].icon} className={styles.icon} />
            <div className={styles.text}>
                {preffix} {loginProviders[type].text}
            </div>
        </div>
    )
}

LoginWith.defaultProps = {
    type: 'google'
}

export default LoginWith;