import { Button, Form, Input, InputNumber, message, Modal, Popover, Switch } from "antd";
import { inject, observer } from "mobx-react";
import React, { useState } from "react";
import EventStore from "../../../../../../../../store/EventStore";
import SessionStore from "../../../../../../../../store/SessionStore";
import {SelectOutlined } from '@ant-design/icons';
import styles from './EventSettingsModal.module.css';
import { cleanUrl } from "../../../../../../../../common/utils/Utils";

interface Props {
    visible: boolean;
    eventStore?: EventStore;
    sessionStore?: SessionStore;
    onEditEvent: () => void;
    onCancel: () => void;
}

function EventSettingsModal({ visible, onCancel, eventStore, onEditEvent }: Props) {
    const { event } = eventStore!;
    const [isBusy, setBusy] = useState(false);
    const [timerPopoverVisible, setTimerPopoverVisible] = useState(false);


    const onPrivateTalksEnbaledChange = async (enabled: boolean) => {
        setBusy(true);
        await eventStore?.enablePrivateTalks(enabled);
        setBusy(false);

        if (enabled) {
            message.success('Members can now speak with each other direcly')
        }
    }

    const onRoundTablesEnbaledChange = async (enabled: boolean) => {
        setBusy(true);
        await eventStore?.enableRoundTables(enabled);
        setBusy(false);

        if (enabled) {
            message.success('Round tables arena is now enabled')
        }
    }

    const onStageEnbaledChange = async (enabled: boolean) => {
        setBusy(true);
        await eventStore?.enableStage(enabled);
        setBusy(false);

        if (enabled) {
            message.success('Stage is now enabled')
        }
    }

    const onSetTimer = async (values: any) => {
        const { label, minutesFromNow } = values;
        setBusy(true);
        await eventStore?.setEventTimer(label, minutesFromNow);
        setBusy(false);
        setTimerPopoverVisible(false);
    }

    const clearEventTimer = async () => {
        setBusy(true);
        await eventStore?.clearEventTimer();
        setBusy(false);
    }

    const buildZoomLink = (webinarId: string) => {
        const splitted =  webinarId.split(':');
        const id = splitted[0];
        const pwd = splitted[1];

        return `https://zoom.us/j/${id}?pwd=${pwd}`
    }

    return <Modal
        visible={visible}
        footer={null}
        onCancel={onCancel}>
        <div className={styles.settingsTab}>
            <div className={styles.modalHeader}>Event Settings</div>
            <div className={styles.enblePrivateTalksContainer}>
                <div className={styles.header}>On-Screen Timer</div>
                <div>Set a virtual timer for everyone to see during the event.</div>
                <Popover visible={timerPopoverVisible}
                    placement="top"
                    content={
                        <Form layout={'vertical'}
                            initialValues={{
                                minutesFromNow: 30,
                                label: `We'll start in...`,
                            }}
                            onFinish={onSetTimer}>
                            <Form.Item
                                tooltip={'This will show up above the timer'}
                                name="label"
                                rules={[{ required: true, message: `Please set label` }]}
                                label="Label">
                                <Input />
                            </Form.Item>
                            <Form.Item
                                name="minutesFromNow"
                                tooltip={'In minutes'}
                                label="For how long?">
                                < InputNumber max={60} min={0} />
                            </Form.Item>
                            <div style={{ display: 'flex' }}>
                                <Button loading={isBusy} type={'primary'} htmlType='submit'>Confirm</Button>
                                <Button loading={isBusy} onClick={() => setTimerPopoverVisible(false)} className={styles.cancelTimerButton} >Cancel</Button>
                            </div>
                        </Form>
                    }
                    trigger="click">
                    {event?.timer ?
                        <Button loading={isBusy} onClick={clearEventTimer} type='default' danger className={styles.clearTimerButton}>Clear Current Timer</Button>
                        :
                        <Button loading={isBusy} onClick={() => setTimerPopoverVisible(true)} type='default' className={styles.setTimerButton}>Set Timer</Button>
                    }
                </Popover>
            </div>
            <div className={styles.enblePrivateTalksContainer}>
                <div className={styles.header}>Private Talks</div>
                <div>Allow members to have private talks with each other during lounge.</div>
                <Switch style={{ marginTop: 8 }}
                    defaultChecked={event?.isPrivateTalksEnabled}
                    loading={isBusy}
                    onChange={onPrivateTalksEnbaledChange}
                    checkedChildren="Yes"
                    unCheckedChildren="No" />
            </div>
            <div className={styles.enblePrivateTalksContainer}>
                <div className={styles.header}>Round Tables</div>
                <div>Enable round tables arena for this event.</div>
                <Switch disabled={event?.isRoundTablesActive}
                    style={{ marginTop: 8 }}
                    defaultChecked={event?.isRoundTablesEnabled}
                    loading={isBusy} onChange={onRoundTablesEnbaledChange}
                    checkedChildren="Yes"
                    unCheckedChildren="No" />
            </div>
            <div className={styles.enblePrivateTalksContainer}>
                <div className={styles.header}>Stage</div>
                <div>Enable Zoom-like stage for this event</div>
                <Switch disabled={event?.isStageActive}
                    style={{ marginTop: 8 }}
                    defaultChecked={event?.isStageEnabled}
                    loading={isBusy} onChange={onStageEnbaledChange}
                    checkedChildren="Yes"
                    unCheckedChildren="No" />
            </div>
            <div className={styles.externalLinksContainer}>
                <div className={styles.header}>External Links</div>
                <div className={styles.smallInfoText}>Share these links to external guests.</div>
                <div className={styles.links}>
                    <div><SelectOutlined style={{ marginRight: 8 }} /><a href={cleanUrl(event?.spatialLink)} target='_blank'>Lounge</a></div>
                    <div><SelectOutlined style={{ marginRight: 8 }} /><a href={`${buildZoomLink(event?.webinarId || '')}`} target='_blank'>Back Stage (Zoom)</a></div>
                </div>
            </div>
        </div>

    </Modal>
}


export default inject('eventStore')(observer(EventSettingsModal));