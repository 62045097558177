import { Modal } from 'antd';
import React, { useEffect, useState } from 'react';
import { isChrome, isMobile } from 'react-device-detect';
import LocalStorage from '../../utils/LocalStorage';
import TimeUtils from '../../utils/TimeUtils';
import styles from './BrowserSupport.module.css';
import laptop from './laptop.png';

const IGNORE_WARNING_KEY = 'ignoreBrowserSupportWarning';

interface Props {
    className?: string,
    style?: any,
}


function BrowserSupport({ className, style }: Props) {
    const [shouldShow, setShouldShow] = useState(false);

    useEffect(() => {
        const ignoreWarning = LocalStorage.get(IGNORE_WARNING_KEY);
        if(ignoreWarning) {
            setShouldShow(false)
            return;
        }

        setShouldShow(isMobile);
    }, []);
    
    const proceed = () => {
        LocalStorage.setWithExpiry(IGNORE_WARNING_KEY, true, TimeUtils.getHours(24));
        setShouldShow(false);
    }

    return (
        <Modal visible={shouldShow}
            closeIcon={<div></div>}
            footer={null}
            className={`${styles.container} ${className}`}
            style={style}>
            <div className={styles.content}>
                <div className={styles.header}> We recommend using a Chrome/Safari/Firefox browser on a desktop or laptop for the best experience</div>
                <div className={styles.description}>Having said that, you should still be able to use your current device. However, some features might not work as expected.</div>
                <div className={styles.illustration}>
                    <img src={laptop}  />
                </div>
                <div onClick={proceed} className={styles.proceedButton}>Proceed anyway →</div>
            </div>
        </Modal>
    )
}

export default BrowserSupport;