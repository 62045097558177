import React, { useState } from 'react';
import styles from './CompanyBox.module.css'

interface Props {
    name: string,
    logoUrl: string,
    style?: any,
    onUnlink: () => void;
}

function CompanyBox({ name, logoUrl, onUnlink, style }: Props) {
    return <div className={styles.container} style={style}>
        <img src={logoUrl} className={styles.logo} />
        <div className={styles.content}>
            <div className={styles.name}>
                {name}
            </div>

            <a onClick={onUnlink} className={styles.unlinkButton}>
                Unlink
        </a>
        </div>
    </div>

}

export default CompanyBox;