import { Tooltip } from "antd";
import React from "react";
import styles from './EventCardSpeaker.module.css'
import UserAvatar from "../../../../../../../common/components/avatar/UserAvatar";
import { Speaker } from "../../../../../../../common/models/EventModel";

interface Props {
    speaker: Speaker;
    theme?: 'light' | 'dark',
}


function EventCardSpeaker({ speaker, theme = 'dark' }: Props) {
    return <Tooltip placement={'bottom'} title={`${speaker.name} - ${speaker.title} at ${speaker.companyName}`}>
        <div className={styles.speaker}>
            <UserAvatar size={44}
                userImageClass={theme === 'light' ? '' : styles.speakerAvatar} 
                userImage={speaker.photoUrl}
                companyImage={speaker.companyLogoUrl} />
            <div className={styles.speakerRole} style={theme === 'light' ? { color: 'white' } : {}}>
                {speaker.role}
            </div>
        </div>
    </Tooltip>
}

export default EventCardSpeaker;