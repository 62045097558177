import React, { useState } from 'react';
import OnboardingHeader from '../header/OnboardingHeader';
import styles from './StepWrapper.module.css'

interface Props {
    title?: string,
    subTitle?: string,
}

function StepWrapper({ title, subTitle, children }: React.PropsWithChildren<Props>) {
    return <div className={styles.container}>
        <OnboardingHeader title={title} subTitle={subTitle} />
        <div className={styles.content}>
            {children}
        </div>
    </div>
}

export default StepWrapper;