import { create } from "lodash";
import moment from "moment";
import UserModel, { Presence, UserPublicData } from "./UserModel";
const OLD_CONVERSATION_THRESHOLD_IN_SECONDS = 30;

class EventConversationModel {
    id?: string;
    byUser?: UserPublicData;
    toUser?: UserPublicData;
    status?: ConversationStatus;
    canceled?: boolean;
    createdAt?: number

    constructor(id?: string,
        byUser?: UserPublicData,
        toUser?: UserPublicData,
        status?: ConversationStatus,
        createdAt?: number,
        canceled?: boolean) {
        this.id = id;
        this.byUser = byUser;
        this.toUser = toUser;
        this.status = status;
        this.createdAt = createdAt;
        this.canceled = canceled;
    }

    get isOldRequest() {
        const now = moment().unix();
        return now - (this.createdAt || 0) >  OLD_CONVERSATION_THRESHOLD_IN_SECONDS;
    }

    static mapFromServer(data: any) {
        return new EventConversationModel(
            data.id,
            data.byUser,
            data.toUser,
            data.status,
            data.createdAt,
            data.canceled,
        );
    }
}

type ConversationStatus = 'requested' | 'accepted' | 'rejected';


export default EventConversationModel;