import React, { useState } from 'react';
import styles from './Tag.module.css';

interface Props {
    className?: string,
    style?: any,
}

function Tag({ className, style, children }: React.PropsWithChildren<Props>) {
    return (
        <div className={`${styles.container} ${className}`} style={style}>
            {children}
        </div>
    )
}

export default Tag;