import React, { useEffect, useState } from "react";
import styles from './RoundTable.module.css';
import RoundTableModel from "../../../../../../../../common/models/RoundTableModel";
import { Button, message, Popover, Tooltip } from "antd";
import Avatar from "antd/lib/avatar/avatar";
import { inject, observer } from "mobx-react";
import SessionStore from "../../../../../../../../store/SessionStore";
import EventStore from "../../../../../../../../store/EventStore";
import { EditOutlined } from '@ant-design/icons';
import { UserPublicData } from "../../../../../../../../common/models/UserModel";
import AttendeeInfo from "../../attendeeInfo/AttendeeInfo";
const fadeInAnimationClass = 'animate__animated animate__fadeIn';

interface Props {
    tableNumber: number;
    roundTable: RoundTableModel;
    sessionStore?: SessionStore;
    eventStore?: EventStore;
    onEdit: (roundTable: RoundTableModel) => void;
}

function RoundTable({ roundTable, tableNumber, sessionStore, eventStore, onEdit }: Props) {
    const { authUser } = sessionStore!;
    const [animationClass, setAnimationClass] = useState('');
    const [isBusy, setBusy] = useState(false);
    const [showPreselectedMessage, setShowPreselectedMessage] = useState(false);

    useEffect(() => {
        setTimeout(() => {
            setAnimationClass(fadeInAnimationClass);
        }, 100 * tableNumber)
    }, [tableNumber])

    const onEditClicked = () => {
        onEdit(roundTable);
    }

    const onJoin = async () => {
        if(!authUser) {
            message.info('You must be signed-in to join a table');
            return;
        }

        if (roundTable.preselectedEnabled &&
            !roundTable.isPreselected(authUser?.id!)
            && !authUser?.isManager) {
            setShowPreselectedMessage(true);
            setTimeout(() => {
                setShowPreselectedMessage(false);
            }, 2500);
            return;
        }

        setBusy(true);
        const joined = await eventStore?.joinRoundTable(roundTable);
        setBusy(false);

        if (joined) {
        } else {
            message.info('Sorry, table is full!');
        }
    }

    const onLeave = async () => {
        setBusy(true);
        await eventStore?.leaveRoundTable(roundTable);
        setBusy(false);
    }

    const getParticipantForChair = (index: number) => {
        if (!roundTable.participants) {
            return;
        }

        if (roundTable.participants.length < index) {
            return;
        }

        return roundTable.participants[index]
    }

    if (!animationClass) {
        return <></>
    }

    const isHere = roundTable.participants?.find(p => p.id === authUser?.id);;
    const preselectedForTable = roundTable?.isPreselected(authUser?.id || '');

    return <div className={`${styles.container} ${preselectedForTable && styles.preselectedTable} ${authUser?.isStaff ? styles.adminContainer : ''} ${animationClass}`}
    // onClick={() => isHere ? onLeave() : !roundTable.isFull && onJoin()}
    >
        <div className={styles.top}>
            <div className={styles.tableNumber}>{tableNumber}</div>
            <div className={styles.editButton} onClick={onEditClicked}>
                <EditOutlined color={'white'} />
            </div>
            {(isHere || preselectedForTable) &&
                <div className={styles.userTableIndicator}>
                    {isHere ?
                        <span>{roundTable.participants && roundTable.participants?.length  > 1 ? `You're here` : `Waiting for others`}</span>
                        :
                        <span>{preselectedForTable ? `Your table is here` : `You're here`}</span>
                    }
                    <Avatar className={styles.userAvatar} size={24} src={authUser?.photoUrl} />
                </div>
            }
        </div>
        <div className={styles.tableContainer}>
            <div className={`${styles.chairCoupleTopBottom} ${styles.chairCoupleTop}`}>
                {roundTable.maxSeats! >= 3 && <Chair participant={getParticipantForChair(2)} />}
                {roundTable.maxSeats! >= 7 && <Chair participant={getParticipantForChair(6)} />}
            </div>
            <div className={`${styles.chairCoupleTopBottom} ${styles.chairCoupleBottom}`}>
                {roundTable.maxSeats! >= 4 && <Chair participant={getParticipantForChair(3)} />}
                {roundTable.maxSeats! >= 8 && <Chair participant={getParticipantForChair(7)} />}
            </div>
            <div className={`${styles.chairCoupleRightLeft} ${styles.chairCoupleLeft}`}>
                {roundTable.maxSeats! >= 2 && <Chair participant={getParticipantForChair(0)} />}
                {roundTable.maxSeats! >= 5 && <Chair participant={getParticipantForChair(4)} />}
            </div>
            <div className={`${styles.chairCoupleRightLeft} ${styles.chairCoupleRight}`}>
                {roundTable.maxSeats! >= 2 && <Chair participant={getParticipantForChair(1)} />}
                {roundTable.maxSeats! >= 6 && <Chair participant={getParticipantForChair(5)} />}
            </div>
            <div className={styles.table}>
                <div>{roundTable?.title}</div>
            </div>
        </div>

        <div className={styles.buttonContainer}>
            {roundTable.isFull && !isHere ?
                <Tooltip title={'Sorry, no seats left on this table.'}>
                    <Button loading={isBusy}
                        onClick={() => authUser?.isManager && onJoin()}
                        className={`${styles.joinButton} 
                        ${styles.full}`}
                        size='large'>
                        FULL
                </Button>
                </Tooltip>
                :
                <Tooltip style={{ textAlign: 'center' }} title={`Sorry, that's not your table.`} visible={showPreselectedMessage}>
                    <Button loading={isBusy} onClick={isHere ? onLeave : onJoin} className={styles.joinButton} size='large'>{isHere ? 'Leave' : 'Join'}</Button>
                </Tooltip>
            }
        </div>
    </div>
}

interface ChairProps {
    className?: string;
    participant?: UserPublicData;
    onClick?: () => void;
}

const Chair = ({ className, participant, onClick }: ChairProps) => {
    return (
        <div className={`${styles.chairContainer} ${className}`} style={participant ? { zIndex: 8 } : {}}>
            <div className={`${styles.chair}`} />
            {participant &&
                <Popover mouseEnterDelay={0.3} destroyTooltipOnHide trigger={['hover']}
                    content={<AttendeeInfo userInfo={participant} />}
                    placement={'top'}>
                    <Avatar shape='square'
                        src={participant?.photoUrl}
                        className={styles.chairAvatar} />
                </Popover>
            }
        </div>);
}


export default inject('sessionStore', 'eventStore')(observer(RoundTable));