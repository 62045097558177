import { inject, observer } from 'mobx-react';
import React, { useEffect, useState } from 'react';
import styles from './GuestConfirmationPage.module.css'
import SessionStore from '../../store/SessionStore';
import { Avatar, Button, Card, Divider, message, notification, Popover, Tooltip, Typography } from 'antd';
import EventsStore from '../../store/EventsStore';
import EventModel from '../../common/models/EventModel';
import loadingAnimation from '../../common/images/loading.json';
import Lottie from 'react-lottie';
import { EnvironmentOutlined, ClockCircleOutlined, InfoCircleOutlined, UserOutlined } from '@ant-design/icons';
import EventPromo from '../dashboard/events/components/eventPromo/EventPromo';
import SpeakerItem from '../dashboard/events/components/speakerItem/SpeakerItem';
import _ from 'lodash';
import FirebaseClient from '../../services/FirebaseClient';
import AnalyticsReporter from '../../services/analytics/AnalyticsReporter';
import Logo from '../../common/components/logo/Logo';
import SettingsStore from '../../store/SettingsStore';
import EventTheme from '../dashboard/events/components/eventTheme/EventTheme';
import ReactMarkdown from 'react-markdown';
import moment from 'moment';
import EventCapabilities from '../dashboard/events/components/eventCapabilities/EventCapabilities';
import AttendeeModel from '../../common/models/AttendeeModel';
import UserBox from '../dashboard/members/components/userBox/UserBox';
import SectionHeader from '../../common/components/sectionHeader/SectionHeader';
import { MIN_ATTENDEES_TO_SHOW_EXACT_NUMBER } from '../dashboard/events/components/eventCards/components/peopleGoingSnippet/PeopleGoingSnippet';
import UserModel from '../../common/models/UserModel';
import { isMobile } from 'react-device-detect';
import AttentedModal from '../dashboard/events/components/eventAttentedModal/AttentedModal';
import { useLocation } from 'react-router';
const { Paragraph } = Typography;

export type RegistrationStatus = 'confirmed' | 'pending' | 'none';

interface Props {
    eventsStore: EventsStore;
    sessionStore: SessionStore;
    analyticsReporter: AnalyticsReporter;
    settingsStore: SettingsStore;
    firebase: FirebaseClient,
    history: any;
    match: any;
}

function GuestConfirmationPage({ eventsStore, sessionStore, history, analyticsReporter, firebase, settingsStore }: Props) {
    const { loggedIn, authUser, invitation } = sessionStore;
    const { space, terminology, access } = settingsStore
    const [event, setEvent] = useState<EventModel>();
    const [eventFetched, setEventFetched] = useState(false);
    const [isBusy, setBusy] = useState(false);
    const [unsubscribeFromEvent, setUnsubscribeFromEvent] = useState<() => void>(() => { });
    const [attendees, setAttendees] = useState(Array<AttendeeModel>());
    const [explainerMessageVisible, setExplainerMessageVisible] = useState(false);
    const [attendedModalVisible, showAttendedModal] = useState(false);
    const [registrationStatus, setRegistrationStatus] = useState<RegistrationStatus>('none')
    const approvalNeeded = eventsStore?.checkIfApprovalNeeded(event);
    const location = useLocation();

    useEffect(() => {
        analyticsReporter.trackPage('Guest Confirmation Page');
        return () => unsubscribeFromEvent && unsubscribeFromEvent();
    }, [])


    useEffect(() => {
        if (loggedIn) {
            if (sessionStore.hasInvitation && sessionStore.invitation?.isGuest) {
                return;
            }

            const invtiationId = authUser?.guestInvitationId;
            if (!invtiationId) {
                message.error('Seems like your invitation is invalid...');
                sessionStore.logout();
                return;
            }

            sessionStore.getInvitation(invtiationId).catch(error => {
                message.error('Invitation expired');
                sessionStore.logout();
            });

        } else {
            history.push('/login')
        }
    }, [loggedIn]);

    useEffect(() => {
        if (invitation?.isGuest) {
            firebase.events().where('shortId', '==', invitation.eventInfo?.shortId!).onSnapshot(snap => {
                const result = EventModel.mapFromServer({ id: snap.docs[0].id, ...snap.docs[0].data() });
                setEvent(result);
                setEventFetched(true);
            });
        }
    }, [invitation]);

    useEffect(() => {
        if (!event || !event.id) {
            return;
        }
        const unsubscribe = firebase.event(event.id).collection('attendees').onSnapshot(snap => {
            const attendees: AttendeeModel[] = [];
            snap.docs.map(doc => {
                attendees.push(AttendeeModel.mapFromServer({ id: doc.id, ...doc.data() }));
            })

            const attendee = attendees.find(a => a.id === authUser?.id);
            if (attendee) {
                setRegistrationStatus(attendee?.waitingApproval ? 'pending' : 'confirmed');
            } else {
                setRegistrationStatus('none');
                setTimeout(() => {
                    setExplainerMessageVisible(true)
                }, 1000);
            }

            setAttendees(attendees);
            setBusy(false);
        });

        return () => unsubscribe();

    }, [event])

    useEffect(() => {
        if (eventFetched) {
            const auto = new URLSearchParams(location.search).get("auto");
            
            if(!auto) {
                return;
            }

            const isCurrentlyAttended = _.some(event?.attendeesIds, a => a === authUser?.id);

            if(isCurrentlyAttended) {
                return;
            }

            attend();
        }

    }, [eventFetched])

    const attend = async () => {
        if (!event?.id || isBusy) {
            return;
        }

        setBusy(true);
        const result = await eventsStore.attendEvent(event, 'attendee', true);
        setBusy(false);

        if (!result?.registered) {
            message.error('Something went wrong...');
        } else {
            showAttendedModal(true);
        }
    }

    const unattend = async () => {
        setBusy(true);
        await eventsStore.unattendEvent(event, true);
        setTimeout(() => {
            setBusy(false);
        }, 1000);
    };

    const joinEvent = () => {
        if (registrationStatus === 'none') {
            notification.open({
                message: 'Please register first',
                description:
                    'You must register to the event before joining.',
                icon: <InfoCircleOutlined className={styles.notificationIcon} />,
            });
            return;
        }

        if (registrationStatus === 'pending') {
            notification.open({
                message: 'Your registration is still pending',
                description:
                    'Our team needs to approve your registration first',
                icon: <InfoCircleOutlined className={styles.notificationIcon} />,
            });
            return;
        }


        if (event?.isInHouseEvent) {
            history.push(`/live/${event.shortId}`, {});
        } else {
            window.open(event?.externalLink, '_blank');
        }
    }

    const canEnterBackstage = () => {
        const attendeeModel = attendees.find(a => a.id === authUser?.id);
        if (attendeeModel) {
            return attendeeModel.isSpeaker || attendeeModel.isHost;
        } else {
            return false;
        }
    }

    const logout = () => {
        sessionStore.logout();
    }

    const registrationStatusText = () => {
        if (registrationStatus === 'confirmed') {
            return `✅ You're going!`
        }
        if (registrationStatus === 'pending') {
            return `⏳ Waiting Approval`
        }

        return '⚡️ Save your spot!'
    }


    return (
        <div className={styles.container}>
            <div className={styles.content}>
                <div className={styles.header}>
                    <div className={styles.headerContent}>
                        <div className={styles.logoContainer}>
                            <img onClick={() => access?.accessLevel === 'invite-only' && window.open(`${access?.requestAccessUrl}/?utm_source=platform&utm_medium=guest-page`, '_blank')} className={styles.logo} src={space?.smallLogo} />
                            <div className={styles.guestAccess}>Guest Access</div>
                        </div>
                        <div className={styles.rightSide}>
                            <Avatar src={authUser?.photoUrl} />
                            <div className={styles.logoutButton} onClick={logout}>Logout</div>
                        </div>
                    </div>
                </div>
                <div className={styles.eventDetails}>
                    <Card className={styles.leftSide}>
                        <div className={styles.eventTitle}>{event?.title}</div>
                        {event?.themeTitle && <EventTheme theme={event?.themeTitle} themeColor={event?.themeColor} className={styles.themesContainer} />}
                        <Divider />
                        <div className={styles.date}>
                            <ClockCircleOutlined className={styles.icon} />
                            <div className={styles.dateContainer}>
                                <span>{event?.fullDateString} ({moment.tz.guess()})</span>
                            </div>
                        </div>
                        <div className={styles.location}>
                            <EnvironmentOutlined className={styles.icon} />
                            <div>{event?.locationString}</div>
                        </div>
                        <ReactMarkdown className={styles.eventDescription} source={event?.description || ''} />
                        {event?.speakers && event?.speakers.length > 0 &&
                            <div className={styles.miniHeader}>
                                {`Featuring`}
                            </div>
                        }
                        <div className={styles.speakersGrid}>
                            {_.sortBy(event?.speakers, s => s.role).map((speaker, index) => {
                                return <SpeakerItem colored={true} mode='large' key={index} speaker={speaker} />
                            })}
                        </div>
                        <EventCapabilities className={styles.eventCapabilities} eventModel={event} />
                    </Card>
                    <div className={styles.rightSide}>

                        {event && <EventPromo
                            event={event}
                            joinEvent={joinEvent}
                            isEnded={event.isEnded || false}
                            isActive={event.isActive || false}
                            startDate={event.startDate}
                            backstageAccess={canEnterBackstage()}
                            endDate={event.endDate}
                            hideAddToCalendar={registrationStatus !== 'confirmed'} />
                        }
                        {access?.accessLevel === 'invite-only' &&
                            <div className={styles.guestDisclaimer}>
                                <div><b>💡 Want to join more {terminology?.eventTerm}s like this?</b> <br />Apply for an official membership on {space?.name}.</div>
                                <Button className={styles.applyMembershipButton} onClick={() => window.open(`${access?.requestAccessUrl}/?utm_source=platform&utm_medium=guest-page`, '_blank')}>Learn more</Button>
                            </div>
                        }
                    </div>
                </div>
                {attendees.length >= 0 && attendees.length > MIN_ATTENDEES_TO_SHOW_EXACT_NUMBER &&
                    <div className={styles.attendeesContainer}>
                        <SectionHeader size='medium' title={`Attendees`} />
                        <div className={styles.membersContainer}>
                            {attendees.filter(a => !a.waitingApproval).map(attendee => <UserBox
                                key={attendee.id}
                                userModel={attendee.user} />)}
                        </div>
                    </div>
                }
            </div>
            <div className={styles.stickyBottom}>
                <div className={styles.stickyContent}>
                    <div className={styles.leftSide}>
                        <div>
                            <div className={styles.dateBottom}>{event?.longDateString} ({moment.tz.guess()})</div>

                        </div>
                        <Paragraph ellipsis={{ rows: 1 }} className={styles.titleBottom}>{event?.title}</Paragraph>
                    </div>
                    <div className={styles.rightSide}>
                        <div className={styles.registrationStatus}>{registrationStatusText()}</div>
                        {registrationStatus === 'none' &&
                            <div className={`${styles.registrationButtonContainer}`}>
                                {explainerMessageVisible && <div className={`${styles.tooltip} animate__animated animate__fadeIn animate__fast`}>
                                    Complete your {approvalNeeded ? 'application' : 'registration'} 👇
                                </div>}
                                <Button
                                    onClick={attend}
                                    loading={isBusy}
                                    size='large'
                                    type='primary'
                                    className={styles.registerButton}>
                                    {approvalNeeded ? 'Apply to join' : 'Register'}
                                </Button>
                            </div>
                        }
                        {registrationStatus === 'confirmed' &&
                            <Button
                                onClick={unattend}
                                loading={isBusy}
                                size='large'
                            >
                                Cancel registration
                            </Button>
                        }
                        {registrationStatus === 'pending' &&
                            <Button
                                onClick={unattend}
                                loading={isBusy}
                                size='large'>
                                Cancel Registration
                            </Button>
                        }
                    </div>
                </div>

            </div>

            <AttentedModal registrationStatus={registrationStatus} event={event} visible={attendedModalVisible} onCancel={() => showAttendedModal(false)} />
        </div>
    )
}

export default inject('eventsStore', 'sessionStore', 'analyticsReporter', 'firebase', 'settingsStore', 'membersStore')(observer(GuestConfirmationPage));