import { Upload, message, Button } from 'antd';
import { LoadingOutlined, PlusOutlined } from '@ant-design/icons';
import React, { useState } from 'react';
import styles from './NavigationButtons.module.css';

type Props  = {
    onNext?: () => void;
    onBack?: () => void;
    showBack?: boolean;
    buttonTitle: string;
    isBusy?: boolean;
}

const buttonStyle: any = {
    padding: '12px 80px',
    height: 'auto',
    fontWeight: 'bold'
};


function NavigationButtons({ onNext, onBack, buttonTitle, showBack, isBusy = false }: Props) {
    return (
        <div className={styles.buttonsContainer}>
            {showBack &&
                <a style={{ marginRight: 50 }} onClick={onBack} type={'default'}>
                    Back
                </a>
            }
            <Button style={buttonStyle}
                loading={isBusy}
                size={"large"}
                autoFocus={false}
                onClick={onNext}
                htmlType="submit"
                type={'primary'}>
                {buttonTitle}
            </Button>
        </div>
    );

}

export default NavigationButtons;