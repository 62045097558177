import React from 'react';
import styles from './Loader.module.css';
import loadingAnimation from '../../images/loading.json'
import Lottie from 'react-lottie';

interface Props {
    className?: string,
    style?: any,
    visible?: boolean,
    size?: 'small' | 'medium' | 'large';
}


const lottieFile = {
    loop: true,
    autoplay: true,
    animationData: loadingAnimation,
    rendererSettings: {
        preserveAspectRatio: 'xMidYMid slice'
    }
};


function Loader({ className, style, size = 'small', visible = true }: Props) {
    const getSize = () => {
        switch (size) {
            case 'small':
                return styles.small;
            case 'medium':
                return styles.medium;
            case 'large':
                return styles.large;
        }
    }
    return (
        <div style={style} className={`${getSize()} ${className} ${!visible && styles.hide}`} >
            <Lottie options={lottieFile} />
        </div>
    )
}

export default Loader;