import React, { useState } from 'react';
import styles from './OnboardingHeader.module.css'

interface Props {
    title?: string,
    subTitle?: string,
}

function OnboardingHeader({ title, subTitle}: Props) {
    return <div className={styles.headerContainer}>
        {title && <div className={styles.header}>{title}</div> }
        {subTitle && <div className={styles.subTitle}>{subTitle}</div>}
    </div>
}

export default OnboardingHeader;