import React, { useEffect, useRef, useState } from "react";
import styles from './IntroductionsManagementPage.module.css';
import { SwapOutlined, MoreOutlined } from '@ant-design/icons';
import SectionHeader from "../../../common/components/sectionHeader/SectionHeader";
import { inject, observer } from "mobx-react";
import SessionStore from "../../../store/SessionStore";
import { Avatar, Button, Card, Dropdown, Form, Input, List, Menu, message, Modal, Tag, Tooltip } from "antd";
import CheckableTag from "antd/lib/tag/CheckableTag";
import doneAnimation from '../../../common/images/doneAnimation.json';
import Lottie from "react-lottie";
import { Link, useHistory } from "react-router-dom";
import AnalyticsReporter from "../../../services/analytics/AnalyticsReporter";
import ConnectionsStore from "../../../store/ConnectionsStore";
import IntroRequestModel, { IntroStatus } from "../../../common/models/IntroRequestModel";
import MembersStore from "../../../store/MembersStore";
import UserModel, { UserPublicData } from "../../../common/models/UserModel";
import NewIntroModal from "./components/newIntroModal/NewIntroModal";
import modal from "antd/lib/modal";
import IntroRequestInfoModal from "./components/introRequestInfoModal/IntroRequestInfoModal";
import MeetingModel from "../../../common/models/MeetingModel";
import _ from "lodash";
import MeetingInfoModal from "./components/meetingInfoModal/MeetingInfoModal";


interface Props {
    sessionStore: SessionStore;
    connectionsStore: ConnectionsStore;
    membersStore: MembersStore;
}

function IntroductionsManagementPage({ sessionStore, connectionsStore, membersStore }: Props) {
    const [busy, setBusy] = useState(false);
    const [introsLoading, setIntrosLoading] = useState(false);
    const [newIntroModalVisibile, setNewIntroModalVisibile] = useState(false);
    const [introInfoModalVisible, setIntroInfoModalVisible] = useState(false);
    const [viewdIntro, setViewdIntro] = useState<IntroRequestModel>();
    const [viewdMeeting, setViewdMeeting] = useState<MeetingModel>();
    const { authUser, loggedIn } = sessionStore;
    const { introRequests, meetings } = connectionsStore;
    const [unsubscribeIntroslistener, setUnsubscribeIntroslistener] = useState<() => void>();
    const history = useHistory();

    useEffect(() => {
        if (loggedIn) {
            if (!authUser?.isStaff) {
                history.replace('/home');
            }

            connectionsStore.subscribeToIntroductions();
            connectionsStore.subscribeToMeetings();
        }
    }, [loggedIn])

    useEffect(() => {
        return () => {
            connectionsStore.unsubscribe && connectionsStore.unsubscribe();
            connectionsStore.unsubscribeMeetings && connectionsStore.unsubscribeMeetings();
        };
    }, [])

    const onNewIntroClicked = () => {
        setNewIntroModalVisibile(true);
    }

    const viewMember = (user: UserPublicData) => {
        membersStore.viewMember(user)
    }


    const approveIntroRequest = async (intro: IntroRequestModel) => {
        setViewdIntro(intro);
        setNewIntroModalVisibile(true);
        // const hide = message.loading('Approving introduction request...', 0);
        // const approved = await connectionsStore.approveIntroRequest(introRequestId);
        // hide();
        // if (approved) {
        //     message.success('Introdution has been approved!');
        // } else {
        //     message.error('Something went wrong...');
        // }
    }

    const rejectIntroRequest = async (introRequestId: string) => {
        await connectionsStore.rejectIntroRequest(introRequestId);
        message.info('Introduction request was declined.')
    }

    const viewIntroInfo = (introRequest: IntroRequestModel) => {
        setViewdIntro(introRequest);
    }

    const onIntroDelete = (introRequestId: string) => {
        modal.warning({
            title: 'Are you sure?',
            content: 'This action can not be undo.',
            okText: 'Delete',
            onOk: async () => {
                await connectionsStore.deleteIntroduction(introRequestId);
            },
            okCancel: true
        });
    }

    const onIntroductionCreated = async (requestedUser: UserModel, requesterUser: UserModel, selectedIntroducer: UserModel, purpose: string, isNew: boolean) => {
        try {
            if(isNew) {
                await connectionsStore.createIntroduction(requestedUser, requesterUser, 'manager', selectedIntroducer, purpose);
            } else {
                await connectionsStore.approveIntroRequest(viewdIntro?.id!, selectedIntroducer);
            }
            
            setNewIntroModalVisibile(false);
            setViewdIntro(undefined);
            message.success('Introduction has been created!');
        } catch (error) {
            console.error(error);
            message.error('Something went wrong while creating this introduction...')
        }
    }

    const renderMeetingStatus = (meeting: MeetingModel) => {
        if (meeting.isFuture) {
            return <Tag color={'warning'} className={styles.meetingStatus}>Upcoming</Tag>;
        }

        let happend = true;

        for (const participant of meeting.participants!) {
            if (!participant.attended) {
                happend = false;
                break;
            }
        }

        if (happend) {
            return <Tag color={'success'} className={styles.meetingStatus}>Completed</Tag>
        } else {
            return <Tag color={'warning'} className={styles.meetingStatus}>Didn't happen</Tag>
        }
    }

    return <div className={`${styles.container} intro-list`}>
        <SectionHeader title={'Introduction Manager'} subTitle={'Manage and create new introductions among your community members.'} />
        <div className={styles.content}>
            <div className={styles.sideBySide}>
                <Card className={styles.card}>
                    <div className={styles.cardHeader}>
                        Recent Intro Requests
                    </div>
                    <Button onClick={onNewIntroClicked} type={'primary'} size='middle' className={styles.createIntroButton}>
                        New Intro
                    </Button>
                    <List
                        className={styles.introList}
                        itemLayout="horizontal"
                        // loadMore={loadMore}
                        dataSource={introRequests}
                        renderItem={intro => (
                            <List.Item key={intro.id}
                                actions={[
                                    <Dropdown
                                        overlay={
                                            <Menu>
                                                <Menu.Item onClick={() => {
                                                    viewIntroInfo(intro)
                                                    setIntroInfoModalVisible(true);
                                                }}>
                                                    Info
                                                </Menu.Item>
                                                {(intro.status === 'pending-admin-approval' || intro.status === 'admin-rejected') &&
                                                    <Menu.Item onClick={() => approveIntroRequest(intro)}>
                                                        Approve
                                                </Menu.Item>
                                                }
                                                {intro.status === 'pending-admin-approval' &&
                                                    <Menu.Item onClick={() => rejectIntroRequest(intro.id!)}>
                                                        Decline
                                                </Menu.Item>
                                                }
                                                <Menu.Divider />
                                                <Menu.Item onClick={() => { onIntroDelete(intro.id!) }}>
                                                    <span className={styles.deleteIntroButton}>Delete</span>
                                                </Menu.Item>
                                            </Menu>
                                        } placement='bottomCenter'
                                        trigger={['click']}>
                                        <div className={styles.moreItem}>
                                            <MoreOutlined />
                                        </div>
                                    </Dropdown>
                                ]}
                            >
                                <div className={styles.introItem}>
                                    <Avatar.Group>
                                        <Tooltip placement='top' title={`${intro.requester?.firstName} ${intro.requester?.lastName}, ${UserModel.title(intro.requester?.role, intro.requester?.company)}`}>
                                            <Avatar className={styles.teamAvatar} src={intro.requester?.photoUrl} />
                                        </Tooltip>
                                        <Tooltip placement='top' title={`${intro.requested?.firstName} ${intro.requested?.lastName}, ${UserModel.title(intro.requested?.role, intro.requested?.company)}`}>
                                            <Avatar className={styles.teamAvatar} src={intro.requested?.photoUrl} />
                                        </Tooltip>
                                    </Avatar.Group>
                                    <div className={styles.introTitle}>
                                        {/* <SwapOutlined style={{ margin: '0px 4px' }} /> */}
                                        <span className={styles.introName} onClick={() => viewMember(intro.requester!)}>{UserModel.shortName(intro.requester?.firstName, intro.requester?.lastName)}</span>
                                        <span> requested intro to </span>
                                        <span className={styles.introName} onClick={() => viewMember(intro.requested!)}>{UserModel.shortName(intro.requested?.firstName, intro.requested?.lastName)}</span>
                                    </div>
                                    <IntroRequestStatus status={intro.status!} />
                                </div>
                            </List.Item>
                        )}
                    />
                </Card>
                <Card className={styles.card}>
                    <div className={styles.cardHeader}>
                        Recent Meetings
                    </div>
                    <List
                        className={styles.introList}
                        itemLayout="horizontal"
                        // loadMore={loadMore}
                        dataSource={meetings}
                        renderItem={meeting => (
                            <List.Item key={meeting.id}
                                actions={[
                                    <Dropdown
                                        overlay={
                                            <Menu>
                                                <Menu.Item onClick={() => setViewdMeeting(meeting)}>
                                                    Info
                                                </Menu.Item>
                                            </Menu>
                                        } placement='bottomCenter'
                                        trigger={['click']}>
                                        <div className={styles.moreItem}>
                                            <MoreOutlined />
                                        </div>
                                    </Dropdown>
                                ]}>
                                <div className={styles.meetingItem} onClick={() => setViewdMeeting(meeting)}>
                                    <Avatar.Group>
                                        {meeting.participants?.map(p => <Tooltip key={p.id} placement='top' title={`${p.user?.firstName} ${p.user?.lastName}, ${UserModel.title(p.user?.role, p.user?.company)}`}>
                                            <Avatar className={styles.teamAvatar} src={p.user?.photoUrl} />
                                        </Tooltip>)}
                                    </Avatar.Group>
                                    <div className={styles.meetingHeader}>
                                        {meeting.participants?.map((p, index) => <span key={index}>
                                            <span className={styles.introName}>{UserModel.shortName(p.user?.firstName, p.user?.lastName)}</span>
                                            {index === 0 &&
                                                <SwapOutlined style={{ margin: '0px 4px' }} />
                                            }
                                        </span>
                                        )}
                                    </div>
                                    {renderMeetingStatus(meeting)}
                                </div>
                            </List.Item>
                        )}
                    />
                </Card>
            </div>
        </div>

        <NewIntroModal
            visible={newIntroModalVisibile}
            onConfirm={onIntroductionCreated}
            viewedIntro={viewdIntro}
            onCancel={() => {
                setNewIntroModalVisibile(false)
                setViewdIntro(undefined);
            }} />
        <IntroRequestInfoModal
            onCancel={() => {
                setIntroInfoModalVisible(false);
                setViewdIntro(undefined)
            }}
            visible={introInfoModalVisible}
            introRequest={viewdIntro} />

        <MeetingInfoModal
            onCancel={() => setViewdMeeting(undefined)}
            visible={viewdMeeting !== undefined}
            meeting={viewdMeeting} />
    </div>
}

export function IntroRequestStatus({ status }: { status: IntroStatus }) {

    const themeAccordingToStatus = () => {
        switch (status) {
            case 'pending-admin-approval': return { color: 'magenta', text: 'Approval Needed' }
            case 'admin-rejected': return { color: 'volcano', text: 'Admin Rejected' }
            case 'pending-member-approval': return { color: 'purple', text: 'Pending' }
            case 'member-accepted': return { color: 'cyan', text: 'Member Accepted' }
            case 'member-rejected': return { color: 'gold', text: 'Member Rejected' }
            case 'expired': return { color: 'red', text: 'Expired' }
            case 'rescheduled': return { color: 'blue', text: 'Rescheduled' }
            case 'completed': return { color: 'green', text: 'Completed' }
            default: return { color: 'red', text: 'Unknown' };
        }
    }

    const theme = themeAccordingToStatus();
    return <Tag color={theme?.color} className={styles.introStatusTag}>
        {theme?.text}
    </Tag>
}

export default inject('sessionStore', 'connectionsStore', 'membersStore')(observer(IntroductionsManagementPage));


