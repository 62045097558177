import React, { useEffect } from "react";
import { inject, observer } from "mobx-react";
import { PlusOutlined } from '@ant-design/icons';
import styles from './ResourcesPage.module.css';
import AnalyticsReporter from "../../services/analytics/AnalyticsReporter";
import SessionStore from "../../store/SessionStore";
import { Button } from "antd";
import SectionHeader from "../../common/components/sectionHeader/SectionHeader";
import AddResourceDrawer from "./components/addResourceDrawer/AddResourceDrawer";
import { useState } from "react";
import ResourcesStore from "../../store/ResourcesStore";
import ResourceCard from "./components/resourceCard/ResourceCard";

interface Props {
    analyticsReporter: AnalyticsReporter;
    resourcesStore: ResourcesStore;
    sessionStore: SessionStore;
}

function ResourcesPage({ sessionStore, resourcesStore, analyticsReporter }: Props) {
    const { authUser, loggedIn } = sessionStore;
    const { resources } = resourcesStore;
    const [resourceDrawerVisible, setResourceDrawerVisible] = useState(false);

    useEffect(() => {
        analyticsReporter.trackPage('Resources Page');
    }, []);

    useEffect(() => {
        if (loggedIn) {
            resourcesStore.subscribeAll();
        }
    }, [loggedIn])

    const openNewResourceDrawer = () => {
        setResourceDrawerVisible(true);
    }

    return (<div className={styles.container}>
        <div className={styles.sectionContainer}>
            <SectionHeader title='Resources'
                subTitle={`Browse through our exclusive community resources and guides.`} />
            <div className={styles.buttons}>
                {authUser?.isManager &&
                    <Button className={styles.addButton}
                        type='primary'
                        size={"large"}
                        icon={<PlusOutlined />}
                        onClick={(openNewResourceDrawer)}>
                        Add
                    </Button>
                }
            </div>
            <div className={styles.resourcesGrid}>
                {resources.map(resource => <ResourceCard key={resource.id} resource={resource} />)}
            </div>
        </div>

        <AddResourceDrawer visible={resourceDrawerVisible} onClose={() => setResourceDrawerVisible(false)} />

    </div>);
}


export default inject('sessionStore', 'resourcesStore', 'analyticsReporter')(observer(ResourcesPage));