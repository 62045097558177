import { AutoComplete, Button, Form, Input, Upload } from 'antd';
import { inject, observer } from 'mobx-react';
import React, { useEffect, useState } from 'react';
import OnboardingStore from '../../../../store/OnboardingStore';
import StepWrapper from '../stepWrapper/StepWrapper';
import styles from './UserTypeStep.module.css';

import expertIcon from './expert3.png';
import corporateIcon from './corporate.png';
import startupIcon from './startup.png';
import investorIcon from './investor.png';
import AnalyticsReporter from '../../../../services/analytics/AnalyticsReporter';

const { Option } = AutoComplete;

interface Props {
    onNext: () => void,
    onboardingStore?: OnboardingStore,
    analyticsReporter?: AnalyticsReporter,
}

const types = [
    { name: 'startup-founder', title: `I'm a startup founder`, icon: startupIcon, width: 36, bottom: -8 },
    { name: 'corporate', title: `I'm a corporate executive`, icon: corporateIcon, width: 38, bottom: -7 },
    { name: 'investor', title: `I'm an investor`, icon: investorIcon, width: 42, bottom: -2 },
    { name: 'expert', title: `I'm an expert/advisor`, icon: expertIcon, width: 36, bottom: -5 },
    { name: 'other', title: `Other`, icon: '', width: 36, bottom: -5 },
]


function UserTypeStep({ onboardingStore, analyticsReporter, onNext }: Props) {

    useEffect(() => {
        analyticsReporter?.trackPage('Onboarding User Type');
    }, [])

    const onSubmit = (type: string) => {
        onboardingStore?.onUseTypeSubmit(type);
        onNext();
    }

    return <StepWrapper title={'What describes you the best?'}>
        <div className={styles.content}>
            {
                types.map(type => {
                    return <div key={type.name}
                        onClick={() => onSubmit(type.name)}
                        className={styles.typeButton}>
                        {type.title}
                        <img src={type.icon} className={styles.icon} style={{ width: type.width, bottom: type.bottom }} />
                    </div>
                })
            }



        </div>

    </StepWrapper>
}


export default inject('onboardingStore', 'analyticsReporter')(observer(UserTypeStep));