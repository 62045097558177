import React, { useEffect, useRef, useState } from "react";
import { Form, message, Modal, Select } from "antd";
import styles from './SettingsModal.module.css';
import { SoundFilled, VideoCameraFilled } from '@ant-design/icons';
import _ from "lodash";
import { inject, observer } from "mobx-react";
import { createLocalVideoTrack, LocalVideoTrack } from 'twilio-video';
import VideoRoomStore from "../../../../../../../store/VideoRoomStore";


interface Props {
    visible?: boolean,
    onCancel: () => void;
    onInputsChanged: (videoInput: string, audioInput: string) => void;
    videoRoomStore?: VideoRoomStore;
}

function SettingsModal({ visible, videoRoomStore, onCancel, onInputsChanged }: Props) {
    const [form] = Form.useForm();
    const [audioInputs, setAudioInputs] = useState(Array<MediaDeviceInfo>());
    const [videoInputs, setVideoInputs] = useState(Array<MediaDeviceInfo>());
    const [videoTrack, setVideoTrack] = useState<LocalVideoTrack>()
    const [stream, setStream] = useState<MediaStream>();
    const [dirty, setDirty] = useState(false);
    const videoRef = useRef<any>();


    useEffect(() => {
        console.log('starting device settings...')
        navigator.mediaDevices.enumerateDevices().then(devices => {
            setAudioInputs(devices.filter(device => device.kind === 'audioinput'));
            setVideoInputs(devices.filter(device => device.kind === 'videoinput'));
        }).catch(error => {
            console.log('Error trying to get user device settings:' + error);
        });
    }, [])

    useEffect(() => {
        if (videoInputs.length > 0) {
            const selectedVideoInput = videoRoomStore?.selectedVideoInput || _.first(videoInputs)?.deviceId;
            form.setFieldsValue({
                videoInput: selectedVideoInput,
            });

            createLocalVideoTrack({ deviceId: selectedVideoInput })
                .then(track => {
                    setVideoTrack(track);
                    track.attach(videoRef.current);
                }).catch(error => {
                    message.error('Please enable camera and audio permission on your browser.')
                });
        }
    }, [videoInputs])

    useEffect(() => {
        const selectedAudioInput = videoRoomStore?.selectedAudioInput || _.first(audioInputs)?.deviceId;
        form.setFieldsValue({
            audioInput: selectedAudioInput,
        });
    }, [audioInputs])

    const onConfirm = () => {
        form.validateFields().then(values => {
            const { videoInput, audioInput } = values;
            videoRoomStore?.setSelectedInputs(videoInput, audioInput);
            if(dirty) {
                onInputsChanged(videoInput, audioInput);
            }
            onDismiss();
        })
    }

    const stopMediaTracks = () => {
        videoTrack?.stop();
        videoTrack?.detach();
    }

    const onAudioInputChanged = (value: string, option: any) => {
        setDirty(true);
    };

    const onVideoInputChanged = (value: string, option: any) => {
        setDirty(true);
        stopMediaTracks();

        createLocalVideoTrack({
            deviceId: value
        }).then(track => {
            setVideoTrack(track);
            track.attach(videoRef.current);
        });
    };

    const onDismiss = () => {
        stopMediaTracks();
        onCancel();
    }

    return <Modal
        visible={visible}
        title="Device Settings"
        className='device-settings'
        okText="Save"
        cancelText="Cancel"
        onCancel={onDismiss}
        onOk={onConfirm}>
        <div className={styles.content}>
            <div className={styles.settingsContainer}>
                <div>
                    <video ref={videoRef} className={styles.video} autoPlay={true} />
                </div>
                <div className={styles.devices}>
                    <Form form={form}
                        style={{ width: '100%' }}
                        className='device-settings'
                        name="deviceSettings"
                        layout="vertical">

                        <Form.Item
                            name="audioInput"
                            label="Audio">
                            <Select
                                className={styles.select}
                                size='large'
                                suffixIcon={<SoundFilled />}
                                placeholder="Please select"
                                onChange={onAudioInputChanged}
                            >
                                {audioInputs.map(input =>
                                    <Select.Option
                                        key={input.deviceId}
                                        value={input.deviceId}
                                        extra={input}>
                                        {input.label}
                                    </Select.Option>
                                )}
                            </Select>
                        </Form.Item>

                        <Form.Item
                            name="videoInput"
                            label="Camera">
                            <Select
                                className={styles.select}
                                size='large'
                                suffixIcon={<VideoCameraFilled />}
                                placeholder="Please select"
                                onChange={onVideoInputChanged}
                            >
                                {videoInputs.map(input =>
                                    <Select.Option key={input.deviceId}
                                        value={input.deviceId}
                                        extra={input}>
                                        {input.label}
                                    </Select.Option>
                                )}
                            </Select>
                        </Form.Item>
                    </Form>
                </div>

            </div>
        </div>

    </Modal>
}

export default inject('videoRoomStore')(observer(SettingsModal));