import React from "react";
// import { inject, observer } from "mobx-react";
// import { } from '@ant-design/icons';
import styles from './DiscountBadge.module.css';



interface Props {
    className?: string;
    style?: any; 
}

function DiscountBadge({ className, style } : Props) {

    return (<div style={style} className={`${styles.container} ${className}`}>
            50% off for limited time        
    </div>);
}


export default DiscountBadge;