import { inject, observer } from 'mobx-react';
import React, { useEffect, useState } from 'react';
import Lottie from 'react-lottie';
import OnboardingStore from '../../../../store/OnboardingStore';
import StepWrapper from '../stepWrapper/StepWrapper';
import styles from './OnboardingCompleted.module.css';
import doneAnimation from './../../../../common/images/doneAnimation.json';

const lottieFile = {
    loop: false,
    autoplay: true,
    animationData: doneAnimation,
    rendererSettings: {
        preserveAspectRatio: 'xMidYMid slice'
    }
};

interface Props {
    onboardingStore?: OnboardingStore,
    history: any;
}

function OnboardingCompleted({ onboardingStore, history }: Props) {

    useEffect(() => {
        setTimeout( async () => {
            await onboardingStore?.markAsCompleted();
        }, 3500)
    }, [])

    return <StepWrapper title={`We're all set! 🎉`}
        subTitle={`You'll be transfered in a second...`}>
        <div className={styles.content}>
            <div className={styles.doneAnimation}>
                <Lottie options={lottieFile} />
            </div>
        </div>

    </StepWrapper>
}


export default inject('onboardingStore')(observer(OnboardingCompleted));