import { Button, Drawer, Form, Input, Radio, message, Switch, Divider, Popconfirm } from 'antd';
import { inject, observer } from 'mobx-react';
import React, { useEffect, useState } from 'react';
import styles from './AddResourceDrawer.module.css';
import { RadioChangeEvent } from 'antd/lib/radio';
import ReactMde from "react-mde";
import Showdown from "showdown";
import "react-mde/lib/styles/css/react-mde-all.css";
import _ from 'lodash';
import ImageUpload from '../../../../common/components/imageUpload/ImageUpload';
import EventsStore from '../../../../store/EventsStore';
import SessionStore from '../../../../store/SessionStore';
import ViewStateStore from '../../../../store/ViewStateStore';
import { ResourceModel } from '../../../../common/models/ResourceModel';
import { isMobile } from 'react-device-detect';
import ResourcesStore from '../../../../store/ResourcesStore';
import { paramCase } from "param-case";
import { useHistory } from 'react-router-dom';
import SettingsStore from '../../../../store/SettingsStore';

const converter = new Showdown.Converter({
    tables: true,
    simplifiedAutoLink: true,
    strikethrough: true,
    tasklists: true
});

interface Props {
    resourceModel?: ResourceModel;
    resourcesStore?: ResourcesStore,
    sessionStore?: SessionStore
    settingsStore?: SettingsStore
    visible: boolean,
    onClose: () => void,
}

function AddResourceDrawer({ resourcesStore, sessionStore, visible, onClose, resourceModel, settingsStore }: Props) {
    const editMode = resourceModel ? true : false;
    const { authUser } = sessionStore!;
    const [form] = Form.useForm();
    const [busy, setBusy] = useState(false);
    const [type, setType] = useState<'content' | 'link'>('link');
    const [content, setContent] = useState('');
    const [selectedTab, setSelectedTab] = useState<"write" | "preview">("write");
    const [icon, setIcon] = useState('');
    const history = useHistory();

    useEffect(() => {
        if (resourceModel) {
            form.setFieldsValue({
                title: resourceModel.title,
                description: resourceModel.description,
                type: resourceModel.type,
                link: resourceModel.link,
                content: resourceModel.content,
                mode: resourceModel.mode,
                accessLevel: resourceModel.accessLevel,
            });

            setType(resourceModel.type || 'link');
            setIcon(resourceModel.iconUrl || '');
        }
    }, [resourceModel]);

    const onSubmit = () => {
        form.submit();
    }

    const onTypeChanged = (e: RadioChangeEvent) => {
        const type = e.target.value;
        setType(type);
    }

    const onIconUploaded = (imageUrl: any) => {
        setIcon(imageUrl);
    }

    const onDeleteClicked = async () => {
        await resourcesStore?.delete(resourceModel?.id!);
        message.info('Resource has been deleted.')
        history.push('/resources');

    }

    const onFinish = async (values: any) => {
        const { title, description, content, type, link, accessLevel, mode } = values;

        try {
            setBusy(true);
            if (editMode) {
                await resourcesStore?.editExisting(resourceModel!.id!, {
                    title,
                    description,
                    content,
                    type,
                    link,
                    accessLevel,
                    mode,
                    iconUrl: icon,
                });
            } else {
                await resourcesStore?.addNew({
                    title,
                    slug: paramCase(title),
                    description,
                    content,
                    type,
                    link,
                    accessLevel,
                    mode,
                    iconUrl: icon,
                    author: authUser?.id,
                });
            }

            onClose();
            message.success(editMode ? 'Resource has been updated!' : 'Resource has been created successfully!');
        } catch (error) {
            console.error(error);
            message.error('Something went wrong while adding this resource...')
        } finally {
            setBusy(false);
        }
    }

    return <Drawer
        className={styles.container}
        bodyStyle={{ padding: 0, lineHeight: 1.2 }}
        placement='right'
        width={isMobile ? '100%' : 660}
        onClose={onClose}
        closable={true}
        title={editMode ? 'Edit Resource' : 'Add New Resource'}
        visible={visible}
        footer={
            <div
                style={{
                    textAlign: 'right',
                }}
            >
                <Button size={'large'} onClick={onClose} style={{ marginRight: 8, fontWeight: 600 }}>
                    Cancel
                </Button>
                <Button loading={busy} size={'large'} onClick={onSubmit} type="primary" style={{ fontWeight: 600 }}>
                    {editMode ? 'Update Resource' : 'Add Resource'}
                </Button>
            </div>
        }
    >
        <div className={styles.content}>
            <Form
                className='resource-form'
                form={form}
                name="resource-form"
                initialValues={{
                    type: 'link',
                    mode: 'embed',
                    accessLevel: 'everyone',
                }}
                onFinish={onFinish}
                layout="vertical"
            >
                <Form.Item label='Title'
                    name='title'
                    rules={[{ required: true, message: 'Please enter a title for this resource.' }]}>
                    <Input maxLength={240} />
                </Form.Item>
                <Form.Item label='Short Description'
                    name='description'
                    tooltip='Add a short description for this resource - what is it about etc.'
                    rules={[{ required: true, message: 'Please enter a short description for this resource.' }]}>
                    <Input />
                </Form.Item>

                <Form.Item label="Resource Type"
                    rules={[{ required: true, message: `Please select an option` }]}
                    name="type">
                    <Radio.Group size='large' onChange={onTypeChanged}>
                        <Radio.Button value={'link'}>Link</Radio.Button>
                        <Radio.Button value={'content'}>Content</Radio.Button>
                    </Radio.Group>
                </Form.Item>

                {type === 'content' &&
                    <Form.Item label='Content'
                        name='content'
                        rules={[{ required: true, message: 'Please add something here.' }]}>
                        <ReactMde
                            value={content}
                            onChange={setContent}
                            selectedTab={selectedTab}
                            onTabChange={setSelectedTab}
                            generateMarkdownPreview={markdown =>
                                Promise.resolve(converter.makeHtml(markdown))
                            }
                        />
                    </Form.Item>
                }

                {type === 'link' &&
                    <Form.Item label='Link'
                        name='link'
                        rules={[{ required: true, type: 'url', message: `Please add a valid URL (forgot to add "https://"?)` }]}>
                        <Input placeholder='https://' />
                    </Form.Item>
                }

                {type === 'link' &&
                    <Form.Item label="Representation Mode"
                        rules={[{ required: true, message: `Please select an option` }]}
                        tooltip={`Whether this link's content should be embeded or opened in a new tab`}
                        name="mode">
                        <Radio.Group size='large'>
                            <Radio.Button value={'embed'}>Embed</Radio.Button>
                            <Radio.Button value={'external'}>External</Radio.Button>
                        </Radio.Group>
                    </Form.Item>
                }

`                <Form.Item label="Access Level"
                    rules={[{ required: true, message: `Please select an option` }]}
                    name="accessLevel"
                    tooltip='Control who can see and interact with this resource.'>
                    <Radio.Group size='large'>
                        <Radio.Button value={'everyone'}>Everyone</Radio.Button>
                        { settingsStore?.membership?.enabled &&<Radio.Button value={'active-membership'}>Premium Memberships</Radio.Button>}
                    </Radio.Group>
                </Form.Item>`

                <Form.Item
                    name="iconUrl"
                    label="Icon">
                    <ImageUpload previousImage={resourceModel?.iconUrl} prefix={`resouce_${resourceModel?.id}_bg`} onUploadFinish={onIconUploaded} />
                </Form.Item>

                {/* <Divider />
                <Form.Item label="Is hidden?"
                    tooltip={'Set whether this resource is hidden for non-admin members.'}
                    name='isHidden'
                    valuePropName='checked'>
                    <Switch disabled={!authUser?.staff} checkedChildren="Yes" unCheckedChildren="No" />
                </Form.Item> */}

            </Form>
            {editMode &&
                <Popconfirm title='Are you sure?' onConfirm={onDeleteClicked}>
                    <Button style={{ width: 'fit-content' }} type='primary' danger>Delete</Button>
                </Popconfirm>
            }
        </div>
    </Drawer>
}

export default inject('resourcesStore', 'sessionStore')(observer(AddResourceDrawer));