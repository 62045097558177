import { Dropdown, Menu } from 'antd';
import { inject, observer } from 'mobx-react';
import React, { useState } from 'react';
import UserAvatar from '../../../../../common/components/avatar/UserAvatar';
import UserModel, { UserPublicData } from '../../../../../common/models/UserModel';
import styles from './UserDrawerHeader.module.css';

interface Props {
    userModel?: UserPublicData,
}

function UserDrawerHeader({ userModel }: Props) {
    return (
        <div className={styles.container}>
            <div className={styles.top}>
                <div className={styles.avatarContainer}>
                    <UserAvatar userImageClass={styles.avatar} size='medium' userImage={userModel?.photoUrl} companyImage={userModel?.company?.logoUrl} />
                </div>
                <div className={styles.userDetailsContainer}>
                    <div className={styles.memberName}>
                        {userModel?.firstName} {userModel?.lastName}
                    </div>
                    <div className={styles.memberTitle}>
                        { UserModel.title(userModel?.role, userModel?.company)}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default UserDrawerHeader;
