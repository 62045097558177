import { Popover, Select, Spin, Avatar, Button } from "antd";
import { debounce } from "lodash";
import { inject, observer } from "mobx-react";
import React, { useState } from "react";
import MembersStore from "../../../store/MembersStore";
import UserModel from "../../models/UserModel";
import styles from './MemberSearchPopover.module.css';


interface Props {
    multiple?: boolean;
    membersStore?: MembersStore;
    visible: boolean;
    onSelected: (users: UserModel[]) => void;
    onVisibiltyChanged: (visible: boolean) => void;
}

function MemberSearchPopover({ children, visible, membersStore, onSelected, onVisibiltyChanged, multiple = false }: React.PropsWithChildren<Props>) {
    const [fetching, setFetching] = useState(false);
    const [selectedMembers, setSelectedMembers] = useState(Array<UserModel>());
    const [fetchedUsers, setFetchedUsers] = useState(Array<UserModel>());
    const [isBusy, setBusy] = useState(false);

    const onSelect = (value: string, option: any) => {
        const user = option.extra;
        setSelectedMembers([...selectedMembers, user]);
    }

    const onDeselect = (value: string, option: any) => {
        const user = option.extra;
        setSelectedMembers([...selectedMembers.filter(m => m.id !== user.id)]);
    }

    const fetchUser = async (value: string) => {
        setFetching(true);
        const result = await membersStore!.fetchByName(value)
        setFetchedUsers(result);
        setFetching(false);
    }

    return (<Popover
        placement={'bottom'}
        content={
            <div className={`${styles.addMemberContainer} member-search-popover`} >
                <Button className={styles.addMemberButton} loading={isBusy} onClick={() => onSelected(selectedMembers)}>
                    Select
                </Button>
                <Select
                    mode={'multiple'}
                    allowClear
                    notFoundContent={fetching ? <Spin size="small" /> : null}
                    filterOption={false}
                    onSelect={onSelect}
                    onDeselect={onDeselect}
                    onSearch={debounce(fetchUser, 600)}
                    size={'large'}
                    className={styles.userSelect}>
                    {
                        fetchedUsers.map(user => {
                            return <Select.Option extra={user} value={user.id!} key={user.id}>
                                <div className={styles.attendeeOption}>
                                    <Avatar className={styles.attendeeAvatar} src={user.photoUrl} />
                                    <div className={styles.attendeeName}>{user.firstName} {user.lastName}</div>
                                </div>
                            </Select.Option>

                        })
                    }
                </Select>
            </div>
        }
        trigger="click"
        visible={visible}
        onVisibleChange={onVisibiltyChanged}
    >
        { children}
    </Popover>)
}


export default inject('membersStore')(observer(MemberSearchPopover));