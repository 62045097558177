import { Alert, Avatar, Button, Form, Input, List, Modal, notification, Select, Skeleton, Tabs, Tooltip } from 'antd';
import { inject, observer } from 'mobx-react';
import React, { useEffect, useState } from 'react';
import Card from '../../../../common/components/card/Card';
import ControlPanelStore from '../../../../store/ControlPanelStore';
import SessionStore from '../../../../store/SessionStore';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import styles from './MembershipTab.module.css';
import moment from 'moment';
import UserModel from '../../../../common/models/UserModel';
import EventsStore from '../../../../store/EventsStore';
import { AttendeeRole } from '../../../../common/models/AttendeeModel';
import { ControlPanelHeader } from '../../admin/ControlPanel';
import { StarOutlined } from '@ant-design/icons';
import SettingsStore from '../../../../store/SettingsStore';
import ViewStateStore from '../../../../store/ViewStateStore';
const { TabPane } = Tabs;

interface Props {
    settingsStore?: SettingsStore;
    sessionStore?: SessionStore;
    eventsStore?: EventsStore;
    viewStateStore?: ViewStateStore;
}

function MembershipTab({ settingsStore, sessionStore, eventsStore, viewStateStore }: Props) {
    const { loggedIn, authUser } = sessionStore!;
    const [busy, setBusy] = useState(false);
    const { membership } = settingsStore!;

    const cancelMembershipClicked = () => {
        window.open(`mailto:hello@traveltechnation.com?subject=Cancel Membership&body=User ID: ${authUser?.id}`, '_self');
    }

    return (
        <Card className={styles.container}>
            <ControlPanelHeader title={'Membership'} />
            <div>Control and manage your membership on our platform.</div>
            { membership?.enabled ?
                <div className={styles.content}>
                    <div>
                        <div className={styles.smallHeader}>Status</div>
                        <div className={styles.text}>{authUser?.membership?.status?.toUpperCase()}</div>
                    </div>
                    {!authUser?.hasActiveMembership &&
                        <Button onClick={() => viewStateStore?.setPaymentWallVisible(true, 'Account Page')} icon={<StarOutlined />} className={styles.button} type='default'>
                            Become a Premium Member
                     </Button>
                    }
                    {authUser?.hasActiveMembership &&
                        <div>
                            <div className={styles.smallHeader}>Expiry</div>
                            <div className={styles.text}>{authUser?.membership?.expiredAt && moment.unix(authUser?.membership?.expiredAt?.seconds).format('MMMM DD, YYYY')}</div>
                        </div>
                    }
                    {authUser?.hasActiveMembership &&
                        <Button onClick={cancelMembershipClicked} className={styles.button} type='default' danger>
                            Cancel Membership
                </Button>
                    }

                </div>
                :
                <div className={styles.content}>
                    <div>
                        <div className={styles.smallHeader}>Status</div>
                        <div className={styles.text}>Active</div>
                    </div>
                </div>
            }
        </Card>
    )
}

export default inject('sessionStore', 'settingsStore', 'viewStateStore')(observer(MembershipTab));