import React, { useState } from 'react';
import styles from './GuestInvitationBubble.module.css';
import Lottie from 'react-lottie';
import { StarFilled } from '@ant-design/icons';
import boardingPassAnimaton from './boardingpass.json'
import { EventPublicData } from '../../../../common/models/EventModel';
import { Avatar, Popconfirm } from 'antd';
import moment from 'moment';
import { AttendeeRole } from '../../../../common/models/AttendeeModel';
import InvitationModel from '../../../../common/models/InvitationModel';
import { inject, observer } from 'mobx-react';
import SessionStore from '../../../../store/SessionStore';

const lottieFile = {
    loop: true,
    autoplay: true,
    animationData: boardingPassAnimaton,
    rendererSettings: {
        preserveAspectRatio: 'xMidYMid slice'
    }
};

interface Props {
    className?: string;
    invitation?: InvitationModel;
    sessionStore?: SessionStore;
}

function GuestInvitationBubble({ invitation, className, sessionStore }: Props) {
    const { inviterFirstName, inviterPhotoUrl, eventInfo, guestRole } = invitation!;

    const roleText = (role: AttendeeRole) => {
        switch (role) {
            case 'speaker':
                return 'Special Guest';
            default:
                return 'Special Guest';
        }
    }

    const localizedDate = (startDate: number) => {
        const localizedDate = moment.unix(startDate).tz(moment.tz.guess())
        return localizedDate?.format('dddd, MMM D, h:mm A');
    }

    const deleteInvitation =() => {
        sessionStore?.removeInvitation();
    }

    if (!inviterFirstName) {
        return <div></div>;
    }

    return (
        <div className={`${styles.container} ${className}`}>
            <Popconfirm title={'Are you sure you want to delete this invitation?'}
                onConfirm={deleteInvitation}>
                <div className={styles.closeIcon}>X</div>
            </Popconfirm>
            <div className={`${styles.content} animate__animated animate__fadeIn`}>
                <div className={styles.inviterContainer}>
                    <Avatar className={styles.avatar} src={inviterPhotoUrl} size={30} />
                    <div className={styles.subHeader}>{inviterFirstName} invites you to:</div>
                </div>

                <div className={styles.header}>{eventInfo?.title}</div>
                <div className={styles.date}>{localizedDate(eventInfo?.startDate!)} ({moment.tz.guess()})</div>
                <div className={styles.role}><StarFilled className={styles.starIcon} />{roleText(guestRole!)}</div>
            </div>

        </div>
    )
}

export default inject('sessionStore')(observer(GuestInvitationBubble));