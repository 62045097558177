import { Collapse, Form, message, Select } from "antd";
import _ from "lodash";
import { inject, observer } from "mobx-react";
import React, { useEffect, useRef, useState } from "react";
import Video, { createLocalVideoTrack, LocalTrackPublication, LocalVideoTrack } from "twilio-video";
import { SoundFilled, VideoCameraFilled } from '@ant-design/icons';
import styles from './DeviceSettings.module.css';
import VideoRoomStore from "../../../../store/VideoRoomStore";



interface Props {
    videoRoomStore?: VideoRoomStore
    onInputsChanged?: (videoInput: string, audioInput: string) => void;
}

function DeviceSettings({ videoRoomStore, onInputsChanged }: Props) {
    const [form] = Form.useForm();
    const [audioInputs, setAudioInputs] = useState(Array<MediaDeviceInfo>());
    const [videoInputs, setVideoInputs] = useState(Array<MediaDeviceInfo>());
    const [videoTrack, setVideoTrack] = useState<LocalVideoTrack>()
    const [dirty, setDirty] = useState(false);
    const videoRef = useRef<any>();

    useEffect(() => {
        console.log('starting device settings...')
        
        navigator.mediaDevices.getUserMedia({audio: true, video: true}).then(() => {
            navigator.mediaDevices.enumerateDevices().then(devices => {
                console.log('media devies fethced');
                console.log('devices: ', devices)
                setAudioInputs(devices.filter(device => device.kind === 'audioinput'));
                setVideoInputs(devices.filter(device => device.kind === 'videoinput'));
            }).catch(error => {
                console.log('Error trying to get user device settings:' + error);
            });
        }).catch(error => {
            message.error('Please allow media permissions.')
            console.log('Error trying to get user device settings:' + error);
        });
        

        return () => {
            console.log('Deattach preview media.')
            stopMediaTracks();
        }
    }, [])

    useEffect(() => {
        if (videoInputs.length > 0) {
            const selectedVideoInput = videoRoomStore?.selectedVideoInput || _.first(videoInputs)?.deviceId;
            form.setFieldsValue({
                videoInput: selectedVideoInput,
            });

            console.log('Creating video preview track...')
            createLocalVideoTrack({ deviceId: selectedVideoInput })
                .then(track => {
                    setVideoTrack(track);
                    track.attach(videoRef.current);
                }).catch(error => {
                    message.error('Please enable camera and audio permission on your browser.')
                });
        }
    }, [videoInputs])

    useEffect(() => {
        const selectedAudioInput = videoRoomStore?.selectedAudioInput || _.first(audioInputs)?.deviceId;
        form.setFieldsValue({
            audioInput: selectedAudioInput,
        });
    }, [audioInputs])

    const onConfirm = () => {
        form.validateFields().then(values => {
            const { videoInput, audioInput } = values;
            videoRoomStore?.setSelectedInputs(videoInput, audioInput);
            if (dirty) {
                onInputsChanged && onInputsChanged(videoInput, audioInput);
            }
        })
    }

    const stopMediaTracks = () => {
        videoTrack?.stop();
        videoTrack?.detach();
        videoTrack?.disable();
        setVideoTrack(undefined);
        
    }

    const onAudioInputChanged = (value: string, option: any) => {
        setDirty(true);
    };

    const onVideoInputChanged = (value: string, option: any) => {
        setDirty(true);
        stopMediaTracks();

        createLocalVideoTrack({
            deviceId: value
        }).then(track => {
            setVideoTrack(track);
            track.attach(videoRef.current);
        });
    };

    return <div className={styles.settingsContainer}>
        <div>
            <video ref={videoRef} className={styles.video} autoPlay={true} />
        </div>
        {/* <Collapse ghost expandIconPosition={'right'}>
            <Collapse.Panel header={`Camera & audio settings`} key="1"> */}
                <div className={styles.devices}>

                    <Form form={form}
                        style={{ width: '100%' }}
                        className='device-settings'
                        name="deviceSettings"
                        layout="vertical">

                        <Form.Item
                            name="audioInput">
                            <Select
                                className={styles.select}
                                size='large'
                                suffixIcon={<SoundFilled />}
                                placeholder="Please select"
                                onChange={onAudioInputChanged}
                            >
                                {audioInputs.map(input =>
                                    <Select.Option
                                        key={input.deviceId}
                                        value={input.deviceId}
                                        extra={input}>
                                        {input.label}
                                    </Select.Option>
                                )}
                            </Select>
                        </Form.Item>

                        <Form.Item
                            name="videoInput">
                            <Select
                                className={styles.select}
                                size='large'
                                suffixIcon={<VideoCameraFilled />}
                                placeholder="Please select"
                                onChange={onVideoInputChanged}
                            >
                                {videoInputs.map(input =>
                                    <Select.Option key={input.deviceId}
                                        value={input.deviceId}
                                        extra={input}>
                                        {input.label}
                                    </Select.Option>
                                )}
                            </Select>
                        </Form.Item>
                    </Form>
                </div>
            {/* </Collapse.Panel>
        </Collapse> */}
    </div>

}


export default inject('videoRoomStore')(observer(DeviceSettings));