import { Alert, Button, message, notification, Skeleton, Typography } from 'antd';
import { FireOutlined } from '@ant-design/icons';
import { inject, observer } from 'mobx-react';
import React, { useEffect, useState } from 'react';
import Card from '../../../common/components/card/Card';
import Tag from '../../../common/components/tag/Tag';
import CompanyModel from '../../../common/models/CompanyModel';
import FirebaseClient from '../../../services/FirebaseClient';
import ViewStateStore from '../../../store/ViewStateStore';
import styles from './CompanyPage.module.css';
import investorIcon from './investor.png';
import pigIcon from './pig.png';
import linkedinIcon from './linkedin.png';
import facebookIcon from './facebook.png';
import twitterIcon from './twitter.png';
import roundsIcon from './rounds.png';
import fireIcon from './fireIcon.png';
import numeral from 'numeral';
import moment from 'moment';
import _ from 'lodash';
import SectionHeader from '../../../common/components/sectionHeader/SectionHeader';
import UserBox from '../members/components/userBox/UserBox';
import UserModel from '../../../common/models/UserModel';
import MembersStore from '../../../store/MembersStore';
import { reaction } from 'mobx';
import CompanyIntroModal from './companyIntroModal/CompanyIntroModal';
import SessionStore from '../../../store/SessionStore';
import { Link } from 'react-router-dom';
import AnalyticsReporter from '../../../services/analytics/AnalyticsReporter';
const { Paragraph } = Typography;

interface Props {
    membersStore: MembersStore;
    sessionStore: SessionStore;
    firebase: FirebaseClient,
    analyticsReporter: AnalyticsReporter,
    match: any;
}

function CompanyPage({ match, firebase, membersStore, sessionStore, analyticsReporter }: Props) {
    const [company, setCompany] = useState<CompanyModel>()
    const [members, setMembers] = useState<UserModel[]>()
    const [requestIntroModalVisible, setRequestIntroModalVisible] = useState(false);

    const { companyId } = match.params;
    const { viewMember } = membersStore;

    useEffect(() => {
        analyticsReporter.trackPage('Company Page');
    },[])

    useEffect(() => {
        if (companyId) {
            firebase.getCompanyById(companyId).then(company => {
                setCompany(company);
            }).catch(error => {
                message.error('We had a problem getting info about this company.')
                console.error(error);
            });
        }

    }, [companyId]);

    useEffect(() => {
        if (company) {
            firebase.getMembersForCompany(company.id!).then(users => {
                setMembers(users);
            }).catch(error => {
                console.error(`Error getting members for company ${company.id} - error: ${error}`)
            });
        }
    }, [company]);

    const showRequestIntroButton = () => {
        if (company?.ownerId === sessionStore.authUser?.id) {
            return false;
        }

        if (company?.id === sessionStore.authUser?.company?.id) {
            return false;
        }

        if (company?.isClaimed) {
            return true;
        }

        if (members && members.length > 0) {
            return true;
        }

        return false;
    }

    const onMemberClicked = (userModel: UserModel) => {
        viewMember(userModel);
    }

    const onRequestIntroModalOpen = () => {
        setRequestIntroModalVisible(true);
    }

    const onRequestIntroModalCanceled = () => {
        setRequestIntroModalVisible(false);
    }

    const onRequestIntroModalSubmited = (message: string) => {
        firebase.addCompanyIntro(message, sessionStore.authUser!, company!);
        setRequestIntroModalVisible(false);
        notification.success({ message: `We're on it.`, description: `We'll do our best to connect you with right people in ${company?.name}`, placement: 'bottomRight' })
    }
    
    const hasFunding = () => {
        if(!company?.totalFunding) {
            return false;
        }

        if(company.totalFunding === 0) {
            return false;
        }

        return true;
    }

    if (!company) {
        return <div className={styles.container} >
            <div className={styles.companyHeaderContainer}>
                <Skeleton.Avatar active={true} style={{ width: 80, height: 80 }} shape={'square'} />
            </div>
            <Card style={{ height: 200 }}>
                <Skeleton />
            </Card>
        </div>
    }

    return (
        <div className={styles.container}>
            <div className={styles.companyHeaderContainer}>
                <img className={styles.companyLogo} src={company?.logo} />
                <div className={styles.companyTitlesContainer}>
                    <div className={styles.companyName}>{company?.name}</div>
                    <Paragraph ellipsis={{ rows: 2 }} className={styles.companyShortDescription}>{company?.shortDescription}</Paragraph>
                    <div className={styles.statusContainer}>
                        {company?.fundraising && <div className={styles.statusTag}>
                            <img src={fireIcon} className={styles.statusIcon} /> <span> Fundraising</span>
                        </div>}
                    </div>
                </div>
                {showRequestIntroButton() &&
                    <Button className={styles.requestIntroButton}
                        type='primary'
                        size={"large"}
                        onClick={onRequestIntroModalOpen}>
                        Request Introduction
                </Button>
                }
            </div>
            <div className={styles.sideBySide}>
                <div style={{ width: '100%'}}>
                    <Card className={styles.info}>
                        <div className={styles.description}>
                            {company?.longDescription}
                        </div>
                        {company?.founders && <div className={styles.team}>
                            <div className={styles.subHeader}>Founders</div>
                            <div>{company?.founders?.join(', ')}</div>
                        </div>
                        }
                        {company?.industries &&
                            <div className={styles.industries}>
                                <div className={styles.subHeader}>Industries</div>
                                <div className={styles.industryTags}>
                                    {company?.industries?.map(i => <Tag key={i}>{i}</Tag>)}
                                </div>
                            </div>
                        }
                    </Card>
                    {hasFunding() &&
                        <Card className={styles.funding}>
                            <div className={styles.bigHeader} style={{ marginBottom: 30 }}>Funding</div>
                            <div className={styles.cardGrid}>
                                {company.fundraising && < Alert message={`${company.name} looking for invesments. Request Introduction if interested.`} type='error' showIcon icon={<FireOutlined />} />}
                                <div className={styles.fundingQubes}>
                                    {company?.totalFunding && <Qube icon={pigIcon} title={numeral(company.totalFunding).format('($0.[0]a)').toUpperCase()} subTitle={'Total funding'} />}
                                    {company?.numberOfInvestors && <Qube icon={investorIcon} title={`${company.numberOfInvestors}`} subTitle={'Investors'} />}
                                    {company?.numberOfFundingRounds && <Qube icon={roundsIcon} title={`${company.numberOfFundingRounds}`} subTitle={'Funding Rounds'} />}
                                </div>
                                {company?.investors && <div>
                                    <div className={styles.subHeader}>Top Investors</div>
                                    <div className={styles.text}>
                                        {company?.investors.join(', ')}
                                    </div>
                                </div>
                                }
                                <div>
                                    <div className={styles.subHeader}>Last Funding Type</div>
                                    <div>
                                        {company?.lastFundingType}
                                    </div>
                                </div>
                            </div>
                        </Card>
                    }
                </div>
                <Card className={styles.generalInfoBox}>
                    {company?.location && <InfoItem title='Location'><Paragraph style={{ marginLeft: 10, textAlign: 'right' }} className={styles.paragraph} ellipsis={{ rows: 2 }}>{company?.location}</Paragraph></InfoItem>}
                    {company?.founded && <InfoItem title='Founded'>{moment(company?.founded).year()}</InfoItem>}
                    {company?.numberOfEmployees && <InfoItem title='Employees'>{company?.numberOfEmployees}</InfoItem>}
                    {company?.fundingStatus && <InfoItem title='Stage'>{company?.fundingStatus}</InfoItem>}
                    {company?.website && <InfoItem title='Website'><Paragraph className={styles.paragraph} ellipsis={{ rows: 1 }}><a target={'_blank'} href={company?.website}>{_.replace(company?.website, /^https?:\/\//, '')}</a></Paragraph></InfoItem>}
                    {company?.acquiredBy && <InfoItem title='Acquired By'>{company?.acquiredBy}</InfoItem>}
                    {company?.hasSocial && <InfoItem title='Social'>
                        <div className={styles.socialContainer}>
                            {company?.facebook && <a href={company?.facebook} target='_blank'><img src={facebookIcon} className={styles.socialIcon} /></a>}
                            {company?.twitter && <a href={company?.twitter} target='_blank'><img src={twitterIcon} className={styles.socialIcon} /></a>}
                            {company?.linkedin && <a href={company?.linkedin} target='_blank'><img src={linkedinIcon} className={styles.socialIcon} /></a>}
                        </div>
                    </InfoItem>}
                    {sessionStore.authUser?.kapara &&
                        <a target='_blank' href={`https://www.traveltechnation.com/user/company?ref=${company.id}`} className={styles.editCompany}>Edit this company</a>
                    }
                </Card>

            </div>
            {members && members?.length > 0 &&
                <div className={styles.members}>
                    <SectionHeader size='medium' title={`Members`}
                        subTitle={`See which members are working for this company.`} />
                    <div className={styles.membersContainer}>
                        {members.map(user => <UserBox onClick={onMemberClicked} key={user.id} userModel={user} />)}
                    </div>
                </div>
            }
            <CompanyIntroModal visible={requestIntroModalVisible} onCancel={onRequestIntroModalCanceled} company={company} onSubmit={onRequestIntroModalSubmited} />
        </div>
    )
}

const InfoItem = ({ title, children }: React.PropsWithChildren<{ title: string }>) => (
    <div className={styles.infoItem}>
        <div className={styles.infoItemTitle}>{title}</div>
        <div className={styles.infoItemChildren}>{children}</div>
    </div>
);

const Qube = ({ title, subTitle, icon }: { title: string, subTitle: string, icon: string }) => (
    <div className={styles.qube}>
        <img className={styles.qubeImg} src={icon} />
        <div className={styles.qubeTitle}>{title}</div>
        <div className={styles.qubeSubTitle}>{subTitle}</div>
    </div>);

export default inject('membersStore', 'firebase', 'sessionStore', 'analyticsReporter')(observer(CompanyPage));