import { Layout, message } from "antd";
import { inject, observer } from "mobx-react";
import React, { useEffect, useState } from "react";
import { isMobile } from "react-device-detect";
import { Prompt, useLocation, useRouteMatch } from "react-router-dom";
import Loader from "../../common/components/loader/Loader";
import { hideTawkChatBubble } from "../../common/utils/Utils";
import MeetingStore from "../../store/MeetingStore";
import SessionStore from "../../store/SessionStore";
import VideoRoomStore from "../../store/VideoRoomStore";
import ViewStateStore from "../../store/ViewStateStore";
import MeetingFeedback from "./components/feedback/MeetingFeedback";
import MeetingRoomSider from "./components/navigation/MeetingRoomSider";
import MeetingVideo from "./components/video/MeetingVideo";
import VideoPreview from "./components/videoPreview/VideoPreview";
import styles from './MeetingRoomPage.module.css'
const { Header, Footer, Sider, Content } = Layout;

const siderWidth = 450;
const siderWidthCollapsed = 70;

interface Props {
    sessionStore: SessionStore;
    viewStateStore: ViewStateStore;
    meetingStore: MeetingStore;
    videoRoomStore: VideoRoomStore;
}

function MeetingRoomPage({ sessionStore, viewStateStore, meetingStore, videoRoomStore }: Props) {
    const [siderCollapsed, setSiderCollapsed] = useState(true);
    const { currentMeetingModel, state } = meetingStore;
    const { authUser, loggedIn } = sessionStore;
    const match = useRouteMatch();

    useEffect(() => {
        viewStateStore.setFullPageMode(true);
        setSiderCollapsed(true);

        return () => {
            viewStateStore.setFullPageMode(false);
        };

    }, [])

    useEffect(() => {
        if (!loggedIn) {
            return;
        }

        const { meetingId } = match.params as any;
        videoRoomStore.generateConversationToken().then(() => {
            console.log('Video token generated.')
        })

        if (!meetingId) {
            message.error('Meeting not found.', 8);
        } else {
            meetingStore.subscribeForMeeting(meetingId);
        }
    }, [loggedIn])

    useEffect(() => {
        if (currentMeetingModel) {
            !isMobile && setSiderCollapsed(false)
        }

    }, [currentMeetingModel])

    const renderCurrentState = () => {
        if (!currentMeetingModel || !videoRoomStore.videoToken) {
            return <LoadingState />
        }

        if(state === 'preview') {
            return <VideoPreview />
        }

        if (state === 'room') {
            return <MeetingVideo />
        }

        if (state === 'feedback') {
            return <MeetingFeedback />
        }
        
    }


    return (<Layout className={styles.container}>
        <Prompt
            message={() => `Are you sure you want to leave the event? Don't worry though, you can re-join later.`}
        />
        <Layout>
            <Content className={styles.content} >
                {renderCurrentState()}
            </Content>
        </Layout>

        <Sider className={styles.sider}
            width={isMobile ? '92vw' : siderWidth}
            collapsible={true}
            style={{ padding: 0 }}
            breakpoint='xl'
            onBreakpoint={(wasBreak) => {
                !isMobile && setSiderCollapsed(wasBreak);
            }}
            collapsedWidth={isMobile ? 0 : siderWidthCollapsed}
            trigger={null}
            collapsed={siderCollapsed}>
            <MeetingRoomSider
                setSiderCollapsed={setSiderCollapsed}
                siderCollapsed={siderCollapsed} />
        </Sider>
    </Layout>)
}

const LoadingState = () => {
    return <div className={styles.loadingContainer}>
        <Loader size='medium' />
        <div className={styles.loadingHeader}>Getting you in...</div>
    </div>
}

export default inject('sessionStore', 'viewStateStore', 'meetingStore', 'videoRoomStore')(observer(MeetingRoomPage));