
import { Avatar, Tooltip, Typography } from 'antd';
import React from 'react';
import { Speaker } from '../../../../../common/models/EventModel';
import { getEventThemes } from '../../../../../common/utils/constants';
import styles from './EventTheme.module.css';
const { Paragraph } = Typography;

interface Props {
    theme?: string;
    themeColor?: string;
    className?: string,
}

function EventTheme({ theme, themeColor, className }: Props) {
    return (
        <div className={`${styles.container} ${className}`}>
            <div className={styles.theme} style={{ backgroundColor: themeColor }}>
                {theme}
            </div>
        </div>)

}

export default EventTheme;