class StatModel {
    id?: string;
    totalMembers?: number;
    totalPaidMembers?: number;
    date: any;

    constructor(id?: string,
        date?: any,
        totalMembers?: number,
        totalPaidMembers?: number,
        ) {
        this.id = id;
        this.date = date;
        this.totalMembers = totalMembers;
        this.totalPaidMembers = totalPaidMembers;
    }

    static mapFromServer(data: any) {
        return new StatModel(data.id,
            data.date,
            data.totalMembers,
            data.totalPaidMembers || 0,
            );
    }
}

export default StatModel;