import React, { useState, useEffect, useRef } from "react";
import styles from './VideoParticipant.module.css';
import Video, { LocalVideoTrack } from 'twilio-video';
import { AudioMutedOutlined, AudioOutlined, VideoCameraOutlined, MessageTwoTone, InfoCircleOutlined } from '@ant-design/icons';
import { Avatar, notification, Popover, Tooltip, Typography } from "antd";
import { deviceDetect, isSafari } from "react-device-detect";
import { ParticipantData } from "../../../../common/components/videoRoom/VideoRoom";
import VideoRoomStore from "../../../../store/VideoRoomStore";
import { inject, observer } from "mobx-react";
const { Paragraph } = Typography;

interface Props {
    participant?: ParticipantData,
    isSelf?: boolean;
    selfAudioEnabled?: boolean,
    selfVideoEnabled?: boolean,
    videoRoomStore?: VideoRoomStore;
    onRemoteScreenShared?: (track: LocalVideoTrack, enabled: boolean) => void;
}

function VideoParticipant({ participant, isSelf, selfAudioEnabled, selfVideoEnabled, videoRoomStore, onRemoteScreenShared }: Props) {
    const [videoTracks, setVideoTracks] = useState(Array<Video.VideoTrack>());
    const [audioTracks, setAudioTracks] = useState(Array<Video.AudioTrack>());
    const [audioEnabled, setAudioEnabled] = useState(true);
    const [videoEnabled, setVideoEnabled] = useState(true);
    const [tooltipVisible, setTooltipVisible] = useState(false);

    const videoRef = useRef<any>();
    const audioRef = useRef<any>();

    useEffect(() => {
        if (!participant) {
            return;
        }

        console.log(`Initiating participant: ${participant.info?.firstName}`)
        setVideoTracks(trackpubsToTracks(participant.current.videoTracks));
        setAudioTracks(trackpubsToTracks(participant.current.audioTracks));

        participant.current.on("trackSubscribed", trackSubscribed);
        participant.current.on("trackUnsubscribed", trackUnsubscribed);

        return () => {
            setVideoTracks([]);
            setAudioTracks([]);
            participant.current.removeAllListeners();
        };
    }, [participant]);

    useEffect(() => {
        const videoTrack = videoTracks[0];
        if (videoTrack) {
            setVideoEnabled(videoTrack.isEnabled);
            videoTrack.attach(videoRef.current);
            return () => {
                videoTrack.detach();
            };
        }
    }, [videoTracks]);

    useEffect(() => {
        const audioTrack = audioTracks[0];
        if (audioTrack) {
            setAudioEnabled(audioTrack.isEnabled);
            audioTrack.attach(audioRef.current);
            return () => {
                audioTrack.detach();
            };
        }
    }, [audioTracks]);


    const handleTrackDisabled = (track: Video.RemoteTrack) => {
        track.on('disabled', () => {
            if (track.kind === 'video') {
                console.log(`${participant?.info?.firstName} disabled video`);
                setVideoEnabled(false)
            }
            if (track.kind === 'audio') {
                console.log(`${participant?.info?.firstName} disabled audio`);
                setAudioEnabled(false)
            }
        });
        track.on('enabled', () => {
            if (track.kind === 'video') {
                console.log(`${participant?.info?.firstName} enabled video`);
                setVideoEnabled(true)
            }
            if (track.kind === 'audio') {
                console.log(`${participant?.info?.firstName} enabled audio`);
                setAudioEnabled(true)
            }
        });
    }

    const trackpubsToTracks = (trackMap: Map<string, any>) =>
        Array.from(trackMap.values())
            .map((publication) => publication.track)
            .filter((track) => track !== null);


    const trackSubscribed = (track: Video.LocalTrack) => {
        console.log(`Tracks subscribed for ${participant?.info?.firstName}`)
        const remote = participant?.current as Video.RemoteParticipant;
        if (remote) {
            remote.tracks.forEach(publication => {
                if (publication.isSubscribed && publication.track) {
                    handleTrackDisabled(publication.track);
                }

                publication.on('subscribed', handleTrackDisabled);
            });
        }

        if (track.kind === "video") {
            if (track.name === 'screen-share') {
                onRemoteScreenShared && onRemoteScreenShared(track, true);
            }

            setVideoTracks((videoTracks) => [...videoTracks, track]);
        } else if (track.kind === "audio") {
            setAudioTracks((audioTracks) => [...audioTracks, track]);
        } else if (track.kind === 'data') {
            track.on('message', data => {
                notification.info({
                    key: data,
                    message: <b>{participant?.info?.firstName}:</b>,
                    duration: 15,
                    placement: 'bottomLeft',
                    description: data,
                    icon: <MessageTwoTone twoToneColor={'#1CBEC0'} />,
                    onClick: () => {
                        notification.close(data);
                    },
                });
            });
        }
    };

    const trackUnsubscribed = (track: Video.LocalTrack) => {
        console.log(`Tracks unsubscribed for ${participant?.info?.firstName}`);
        if (track.kind === "video") {
            if (track.name === 'screen-share') {
                onRemoteScreenShared && onRemoteScreenShared(track, false);
            }

            setVideoTracks((videoTracks) => videoTracks.filter((v) => v !== track));
        } else if (track.kind === "audio") {
            setAudioTracks((audioTracks) => audioTracks.filter((a) => a !== track));
        }
    };


    return (
        <div className={`${styles.container} animate__animated animate__zoomIn animate__fast`}>
            <div className={`${styles.content}`}>
                <video className={styles.video} ref={videoRef} style={{ display: isSelf ? selfVideoEnabled ? 'initial' : 'none' : videoEnabled ? 'initial' : 'none' }} autoPlay={true} />
                <div className={styles.cameraOffContainer}>
                    <Avatar src={participant?.info?.photoUrl} size={100} />
                </div>
                <div className={styles.videoOverlay} style={videoRoomStore?.screenShareMode ? { display: 'none'}: {}} />
                {!videoRoomStore?.screenShareMode &&
                    <div className={styles.userInfo}>
                        <div className={styles.details}>
                            <Avatar src={participant?.info?.company?.logoUrl} className={styles.companyAvatar} />
                            <div className={styles.texts}>
                                <div className={styles.participantName}>
                                    {participant?.info?.firstName} {participant?.info?.lastName}
                                </div>
                                <Paragraph ellipsis={{ rows: 1 }} className={styles.participantTitle}>{participant?.info?.role}, {participant?.info?.company?.name}</Paragraph>
                            </div>
                        </div>
                        <div className={styles.controlButtons}>
                            <div className={styles.controlButton}>
                                {isSelf ?
                                    <div>
                                        {selfAudioEnabled ? <AudioOutlined style={{ color: '#06c2a6' }} /> : <AudioMutedOutlined style={{ color: '#ee1949' }} />}
                                    </div>
                                    :
                                    <div>
                                        {audioEnabled ? <AudioOutlined style={{ color: '#06c2a6' }} /> : <AudioMutedOutlined style={{ color: '#ee1949' }} />}
                                    </div>
                                }
                            </div>
                            <div className={styles.controlButton}>
                                {isSelf ?
                                    <div>
                                        {selfVideoEnabled ? <VideoCameraOutlined style={{ color: '#06c2a6' }} /> : <VideoCameraOutlined style={{ color: '#ee1949' }} />}
                                    </div>
                                    :
                                    <div>
                                        {videoEnabled ? <VideoCameraOutlined style={{ color: '#06c2a6' }} /> : <VideoCameraOutlined style={{ color: '#ee1949' }} />}
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                }
            </div>
            { !isSelf && <audio ref={audioRef} autoPlay={true} muted={isSelf || !audioEnabled} />}
        </div >
    );
};

export default inject('videoRoomStore')(observer(VideoParticipant));