
import { Avatar, Button, Dropdown, Form, Input, Menu, notification, Radio, Select, Typography } from 'antd';
import Modal from 'antd/lib/modal/Modal';
import { inject, observer } from 'mobx-react';
import moment, { duration } from 'moment';
import React, { useEffect, useRef, useState } from 'react';
import Lottie from 'react-lottie';
import MembersStore from '../../../../../store/MembersStore';
import SessionStore from '../../../../../store/SessionStore';
import styles from './SuggestMemberModal.module.css';

interface Props {
    visible: boolean,
    onCancel: () => void,
    sessionStore?: SessionStore,
    membersStore?: MembersStore,
}

function SuggestMemberModal({ visible, onCancel, sessionStore, membersStore }: Props) {
    const [isBusy, setBusy] = useState(false);
    const { authUser } = sessionStore!;

    const onFinish = async (values: any) => {
        setBusy(true);
        const { fullName, compamy, relationship } = values;
        await membersStore?.suggestMember(fullName, compamy, relationship === 'yes');
        setBusy(false);
        onCancel();

        notification.success({
            message: `Thanks! We'll take it from here.`,
            description: `Our team will examine if this person qualifies to join us.`,
            duration: 8, 
        })
    }

    return (
        <Modal visible={visible} 
        width={600}
        footer={null} 
        onCancel={onCancel}>
            <div className={styles.container}>
                <div className={styles.header}>Suggest a new Leader</div>
                <div className={styles.subHeader}>
                    <div>Our members are proven global leaders in the travel industry. We grow and thrive by having Leaders like you in it. </div>
                    <div style={{ marginTop: 4, fontWeight: 600}}>Have someone in mind who is a perfect fit?</div>
                </div>
                <Form name='question-form'
                    style={{ width: '100%' }}
                    className='question-form'
                    layout='vertical'
                    onFinish={onFinish}>
                    <Form.Item
                        label={`Full name`}
                        name='fullName'
                        rules={[{ required: true, message: 'Please fill their name' }]}>
                        <Input />
                    </Form.Item>
                    <Form.Item
                        label={'Company'}
                        name='company'>
                        <Input />
                    </Form.Item>
                    <Form.Item
                        label={'Do you know them personally?'}
                        name='relationship'
                        rules={[{ required: true, message: 'Please choose an option' }]}>
                        <Radio.Group>
                            <Radio.Button value={'yes'}>Yes</Radio.Button>
                            <Radio.Button value={'No'}>No</Radio.Button>
                        </Radio.Group>
                    </Form.Item>

                    <Form.Item>
                        <Button loading={isBusy} htmlType='submit' size='large' type='primary' className={styles.loginButton}>
                            Suggest this person
                        </Button>
                    </Form.Item>
                    <div className={styles.askingAsContainer}>
                        <Avatar src={authUser?.photoUrl} size='small' />
                        <div>Suggesting as <span>{authUser?.fullName}</span></div>
                    </div>
                </Form>
            </div>
        </Modal>)

}

export default inject('sessionStore','membersStore')(observer(SuggestMemberModal));