
import _ from 'lodash';
import { action, makeObservable, observable, runInAction } from 'mobx';
import moment from 'moment';
import EventModel from '../common/models/EventModel';
import UserModel, { UserPublicData } from '../common/models/UserModel';
import FirebaseClient, {FirestoreQuery} from '../services/FirebaseClient';
import { PASS_EVENTS_THRESHOLD } from './EventsStore';
import RootStore from './RootStore';

const MEMBERS_PER_PAGE = 20;
export const NEW_MEMBERS_STRIP_COUNT = 4;

class MembersStore {
    rootStore: RootStore;
    firebase: FirebaseClient;
    members: UserModel[] = [];
    hasMoreMembers: boolean = true;
    newestMembers: UserModel[] = [];
    membersQuery: any;
    currentViewedMember?: UserPublicData;
    prevoiusLocation?: string;
    userDrawerOpen: boolean = false;

    constructor(rootStore: RootStore, firebase: FirebaseClient) {
        makeObservable(this, {
            members: observable,
            fetchMembers: action,
            currentViewedMember: observable,
            userDrawerOpen: observable,
            newestMembers: observable,
            hasMoreMembers: observable,
            viewMember: action,
            fetchMemeber: action,
            openUserDrawer: action,
            setCurrentViewedMember: action,
        })

        this.rootStore = rootStore;
        this.firebase = firebase;
        this.membersQuery = this.firebase.users().limit(MEMBERS_PER_PAGE).orderBy('createdAt', 'desc');
    }

    openUserDrawer = (open = true) => {
        this.userDrawerOpen = open;

        if (!open) {
            setTimeout(() => {
                runInAction(() => {
                    this.currentViewedMember = undefined;
                });

            }, 300);
        }
    }

    viewMember = (userModel?: UserPublicData, prevoiusLocation?: string) => {
        this.currentViewedMember = userModel;
        this.openUserDrawer()
    }

    setCurrentViewedMember = (userModel?: UserModel) => {
        this.currentViewedMember = userModel;
    }

    fetchMemeber = async (userId: string) => {
        const userModel = await this.firebase.getUser(userId);

        runInAction(() => {
            this.viewMember(userModel);
        });
    }

    fetchById = async (userId: string) => {
        const userModel = await this.firebase.getUser(userId);
        return userModel;
    }

    fetchByName = async (name: string) => {
        const byFirstName = await this.firebase.users()
            .where('firstName', '>=', name)
            .limit(5).get();

        const byLastName = await this.firebase.users()
            .where('lastName', '>=', name)
            .limit(5).get();

        const users = _.uniqBy([...byFirstName.docs, ...byLastName.docs], (a => a.id)).map(d => UserModel.mapFromServer({ id: d.id, ...d.data() }));
        return users;
    }

    fetchMembers = async () => {
        const result = await this.membersQuery.get();
        const lastVisible = _.last(result.docs);

        if (!lastVisible || result.empty) {
            runInAction(() => {
                this.hasMoreMembers = false;
            })

            return result.docs.map((d: any) => UserModel.mapFromServer({ id: d.id, ...d.data() }));;
        }

        this.membersQuery = this.membersQuery.startAfter(lastVisible);
        const newData = result.docs.map((d: any) => UserModel.mapFromServer({ id: d.id, ...d.data() }));

        runInAction(() => {
            this.members = _.concat(this.members, newData)
        })
    }

    fetchManagers = async () => {
        const result = await this.firebase.users()
            .where('manager', '==', true)
            .limit(20)
            .get();

        return result.docs.map(d => { return UserModel.mapFromServer({ id: d.id, ...d.data() }) });
    }

    async fetchNewestMembers() {
        if (this.newestMembers.length > 0) {
            return;
        }

        const result = await this.firebase.users()
            .limit(10)
            .orderBy('createdAt', 'desc')
            .get();
        const users = result.docs.map(d => UserModel.mapFromServer({ id: d.id, ...d.data() }));

        runInAction(() => {
            this.newestMembers = _.take(_.shuffle(users), NEW_MEMBERS_STRIP_COUNT);
        })
    }

    fetchEventsForMember = async (memberId: string, limit = 2) => {
        const now = moment().unix() - PASS_EVENTS_THRESHOLD; // keep today's events 
        const result = await this.firebase.events()
            .where('endDate', '>=', now)
            .where('attendeesIds', 'array-contains', memberId)
            .orderBy('endDate', 'asc')
            .limit(limit)
            .get();

        return result.docs.map(d => EventModel.mapFromServer({ id: d.id, ...d.data() }));
    }

    suggestMember = async (name: string, company: string, personalRelationship: boolean) => {
        const { authUser } = this.rootStore.sessionStore;
        await this.firebase.notify(`**💁🏿‍♂️ Member suggest **
        Name: ${name}
        Company: ${company}
        By our member: ${authUser?.fullName} from ${authUser?.company?.name}
        Personal Relationship: ${personalRelationship}`);
    };

    getRandomPeople = async (count: number = 4) => {
        if (this.members.length > count) {
            return _.take(_.shuffle(this.members), count);
        }

        if (this.newestMembers.length > count) {
            return _.take(this.newestMembers, count);
        }

        await this.fetchMembers();
        return _.take(_.shuffle(this.members), count);
    }
}


export default MembersStore;


