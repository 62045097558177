import React, { useState, useEffect, useRef } from "react";
import styles from './VideoButtonsStrip.module.css';
import { MessageTwoTone } from '@ant-design/icons';

import Video from 'twilio-video';
import { Avatar, Button, Form, Popover, Input, notification } from "antd";

interface Props {
    className?: string,
    onEndConversation?: () => void,
    onSendMessage: (message: string) => void,
}

function VideoButtonsStrip({ className, onEndConversation, onSendMessage }: Props) {
    const [message, setMessage] = useState('');
    const [messagePopoverVisible, setMessagePopoverVisible] = useState(false);
    const inputRef = useRef<Input>(null);

    const onMessageChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
        setMessage(event.target.value);
    }

    const handleVisibleChange = (visible: boolean) => {
        setMessagePopoverVisible(visible);

        if(visible) {
            inputRef?.current?.input.focus();
        }
    };

    const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (e.key === 'Enter') {
            onSendMessageClicked();
        }
    }


    const onSendMessageClicked = () => {
        onSendMessage(message);

        notification.info({
            key: message.toString(),
            message: <b>You:</b>,
            duration: 15,
            placement: 'bottomLeft',
            description: message,
            icon: <MessageTwoTone twoToneColor={'#1CBEC0'} />,
            onClick: () => {
                notification.close(message);
            },
        });

        setMessage('');
        setMessagePopoverVisible(false);
    }

    return (
        <div className={`${styles.container} ${className}`}>
            <Button onClick={onEndConversation} size='large' className={styles.endTalkButton}>End Conversation</Button>
            <div className={styles.divider} />
            <Popover visible={messagePopoverVisible}
                onVisibleChange={handleVisibleChange}
                placement="bottom"
                content={
                    <div>
                        <Input onKeyPress={handleKeyDown}
                            ref={inputRef}
                            value={message}
                            onChange={onMessageChanged}
                            placeholder='Add you message here' />
                        <Button type={'primary'} className={styles.sendMessageButton} onClick={onSendMessageClicked}>Send</Button>
                    </div>
                }
                trigger="click">
                <Button size='large' className={styles.regularButton}>Send a message</Button>
            </Popover>


        </div>
    );
};

export default VideoButtonsStrip;