import { Button, message, Modal, Popconfirm } from 'antd';
import { inject, observer } from 'mobx-react';
import React, { useEffect, useState } from 'react';
import { isMobile } from 'react-device-detect';
import { Link, Route, Switch } from 'react-router-dom';
import Logo from '../../../../../../../common/components/logo/Logo';
import { cleanUrl, hideTawkChatBubble } from '../../../../../../../common/utils/Utils';
import EventsStore from '../../../../../../../store/EventsStore';
import EventStore from '../../../../../../../store/EventStore';
import SessionStore from '../../../../../../../store/SessionStore';
import SettingsStore from '../../../../../../../store/SettingsStore';
import ViewStateStore from '../../../../../../../store/ViewStateStore';
import EventTimer from '../eventTimer/EventTimer';
import styles from './EventLounge.module.css';

interface Props {
    sessionStore?: SessionStore;
    eventStore?: EventStore;
    eventsStore?: EventsStore;
    settingsStore?: SettingsStore;
}

function EventLounge({ sessionStore, eventStore, eventsStore, settingsStore }: Props) {
    const { authUser } = sessionStore!;
    const { event } = eventStore!;
    const { miscellaneous } = settingsStore!;
    const [hideWatermark, setHideWatermark] = useState(isMobile);
    const [busy, setIsBusy] = useState(false);

    const spatialLink = event?.spatialLink || miscellaneous?.spatialDefaultUrl;

    useEffect(() => {
        if (isMobile) {
            hideTawkChatBubble();
        }

    }, [])

    const onWatermarkClicked = () => {
        if (authUser?.isManager) {
            setHideWatermark(true);
        }
    }

    const onStartEvent = async () => {
        setIsBusy(true);
        try {
            await eventsStore?.startEvent(event!.id!);
            message.success('Event has started!');
        } catch (error) {
            console.log(`Starting event failed: ${error}`);
            message.error('We had trouble starting this event...');
        } finally {
            setIsBusy(false);
        }
    }

    const onEndEvent = async () => {
        await eventsStore?.endEvent(event!.id!);

        Modal.confirm({
            width: 600,
            content: (<div className={styles.notesContainer}>
                Would you like to collect feedback from attendees?
            </div>),
            title: 'Feedback Collection',
            okText: 'Yes',
            async onOk() {
                eventsStore?.collectFeedback(event?.id!);
                message.success('Feedback collection emails were sent!');
            },
            onCancel() {
                Modal.destroyAll();
            },
        })
    }

    return (
        <div className={styles.container}>
            {event &&
                <iframe className={styles.iframe} frameBorder="0"
                    allow="camera;microphone"
                    src={`${authUser?.isStaff ? spatialLink : cleanUrl(event?.spatialLink!)}`}
                    scrolling="yes"
                    allowFullScreen={true}></iframe>
            }
            <EventTimer timerModel={event?.timer} />
            {!hideWatermark &&
                <div className={styles.floating}>
                    <div className={styles.watermark} onDoubleClick={onWatermarkClicked}>
                        <Logo hideTagline className={styles.logo} />
                    </div>
                    {authUser?.isStaff &&
                        <div className={styles.buttonsContainer}>
                            {!event?.isEnded && !event?.isActive &&
                                <Popconfirm
                                    title="Are you sure you want to start? It will inform all attendees that the event is live."
                                    onConfirm={onStartEvent}
                                    okText="Start Event"
                                    placement='bottomLeft'
                                    cancelText="Cancel">
                                    <Button loading={busy} size='large' type='primary' className={styles.startSessionButton}>Start Event</Button>
                                </Popconfirm>
                            }
                            {event?.isActive && !event?.isEnded &&
                                <div className={styles.endButtonContainer}>
                                    <Popconfirm
                                        title="Are you sure?"
                                        onConfirm={onEndEvent}
                                        okText="End Event"
                                        placement='bottomLeft'
                                        cancelText="Cancel">
                                        <Button loading={busy} size='large' className={styles.endSessionButton}>
                                            End Event
                                        </Button>
                                    </Popconfirm>
                                    <div className={styles.eventLiveContainer}>
                                        <div className={`${styles.liveBubble} animate__animated animate__pulse animate__infinite animate__slow`} />
                                        <div>Live</div>
                                    </div>
                                </div>
                            }
                        </div>
                    }
                </div>
            }
        </div>
    )
}

export default inject('sessionStore', 'eventStore', 'eventsStore', 'settingsStore')(observer(EventLounge));