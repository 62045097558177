import { inject, observer } from 'mobx-react';
import React, { useEffect, useState } from 'react';
import styles from './EventChat.module.css';
import SessionStore from '../../../../../../../../store/SessionStore';
import EventStore from '../../../../../../../../store/EventStore';
import ViewStateStore from '../../../../../../../../store/ViewStateStore';
import { Empty, Mentions, message } from 'antd';
import ChatMessage from './message/ChatMessage';
import { SendOutlined } from '@ant-design/icons';
import { useRef } from 'react';


interface Props {
    sessionStore?: SessionStore;
    eventStore?: EventStore;
    viewStateStore?: ViewStateStore;
}

function EventChat({ eventStore, viewStateStore, sessionStore }: Props) {
    const { chatMessages, sendMessage, newChatMessagesNotificaionVisible } = eventStore!;
    const { authUser } = sessionStore!;
    const [messageContent, setMessage] = useState('');
    const [mentions, setMentions] = useState(Array<string>());
    const messagesEnd = useRef<HTMLDivElement>(null);

    useEffect(() => {
        messagesEnd.current?.scrollIntoView({ behavior: "smooth" });
    }, [chatMessages])

    const onInputChanged = (value: string) => {
        setMessage(value);
    }

    const onMentionSelected = (option: any, prefix: string) => {
        setMentions(prev => [...prev, option.value.toString()])
    }

    const onSendMessage = () => {
        if (!messageContent) {
            return;
        };

        if (!authUser) {
            message.info(`You must sign-in to send messages.`);
            return;
        }

        sendMessage(messageContent, mentions);
        setMessage('');
    }

    const handleKeyDown = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
        if (e.key === 'Enter') {
            onSendMessage();
            setTimeout(() => {
                setMessage('');
            }, 300)
        }
    }

    return (
        <div className={styles.container}>
            {/* <div className={styles.tabWithNotification}>
                Live Chat
                <div className={styles.notification} style={{ display: newChatMessagesNotificaionVisible ? 'block' : 'none' }} />
            </div> */}
            {chatMessages.length === 0 ?
                <Empty className={styles.empty}
                    imageStyle={{
                        height: 40,
                    }}
                    description='No messages here (yet)'
                >
                </Empty>
                :
                <div className={styles.messagesContainer}>
                    {chatMessages.slice().reverse().map(message => <ChatMessage key={message.id} messageModel={message} />)}
                    <div style={{ display: 'float', paddingBottom: 0 }}
                        ref={messagesEnd}>

                    </div>
                </div>
            }
            <div className={`message-container ${styles.sendMessageContainer}`}>
                <Mentions onKeyPress={handleKeyDown} placeholder={'Start typing...'} value={messageContent} onChange={onInputChanged} onSelect={onMentionSelected} className={styles.input}>
                    <Mentions.Option value="everyone">@everyone</Mentions.Option>
                </Mentions>
                <div className={styles.sendButton} onClick={onSendMessage}>
                    <SendOutlined />
                </div>
            </div>
        </div>
    )
}

export default inject('sessionStore', 'eventStore', 'viewStateStore')(observer(EventChat));