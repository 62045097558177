import { Button, Form, Input } from 'antd';
import { inject, observer } from 'mobx-react';
import React, { useEffect, useState } from 'react';
import Lottie from 'react-lottie';
import { Redirect } from 'react-router-dom';
import { hideTawkChatBubble } from '../../common/utils/Utils';
import AnalyticsReporter from '../../services/analytics/AnalyticsReporter';
import RemoteSettingsProvider from '../../services/settings/RemoteSettingsProvider';
import OnboardingStore from '../../store/OnboardingStore';
import SessionStore from '../../store/SessionStore';
import ViewStateStore from '../../store/ViewStateStore';
import AddCompanyDrawer from './components/addCompanyDrawer/AddCompanyDrawer';
import BasicDetails from './components/basicDetails/BasicDetails';
import OnboardingDone from './components/doneStep/OnboardingCompleted';
import OnboardingHeader from './components/header/OnboardingHeader';
import NavigationButtons from './components/navigationButtons/NavigationButtons';
import ObjectivesStep from './components/objectivsStep/ObjectivesStep';
import SectorsSecondaryStep from './components/sectorsSecondaryStep/SectorsSecondaryStep';
import SectorsStep from './components/sectorsStep/SectorsStep';
import UserTypeStep from './components/userTypeStep/UserTypeStep';
import styles from './OnboardingPage.module.css';
import passportAnimation from './passport2.json'

const lottieFile = {
    loop: false,
    autoplay: true,
    animationData: passportAnimation,
    rendererSettings: {
        preserveAspectRatio: 'xMidYMid slice'
    }
};

interface Props {
    sessionStore: SessionStore,
    analyticsReporter: AnalyticsReporter,
    onboardingStore: OnboardingStore,
    remoteSettingsProvider: RemoteSettingsProvider,
    history: any,
}

function OnboardingPage({ sessionStore, history, onboardingStore, analyticsReporter, remoteSettingsProvider }: Props) {
    const [step, setStep] = useState(0);
    const { sessionFetched, authUser, isGuest, accessPermitted, isAuthed, onboardingCompleted } = sessionStore;

    useEffect(() => {
        analyticsReporter.trackPage('Onboarding Welcome');
        hideTawkChatBubble();
    }, [])

    useEffect(() => {
        if (authUser) {
            onboardingStore.setInitialData(authUser.firstName, authUser.lastName, authUser.photoUrl)
        }
    }, [authUser])


    if (sessionFetched && !authUser) {
        return <Redirect to={{ pathname: '/login'}} />
    }

    if (isAuthed && !accessPermitted) {
        return <Redirect to='/waiting-list' />
    }

    if(isAuthed && isGuest && onboardingCompleted) {
        return <Redirect to='/guest-confirmation?auto=1' />
    }


    if (isAuthed && onboardingCompleted) {
        return <Redirect to={'/'} />
    }

    const nextStep = () => {
        setStep(step + 1);
    }

    const previousStep = () => {
        setStep(step - 1);
    }

    return (
        <div className={styles.container}>
            <div className={styles.content}>
                {step === 0 &&
                    <div className={styles.welcomePhase}>
                        <OnboardingHeader title={`Hey${authUser?.firstName ? ' ' + authUser?.firstName : ''}, welcome aboard!`}
                            subTitle={`Let's setup your profile quickly to make the experience more personalized. `} />

                        <div className={styles.welcomeAnimation}>
                            <Lottie options={lottieFile} />
                        </div>

                        <NavigationButtons buttonTitle={`Get Started \u00A0 👉`} onNext={nextStep} />
                        <div className={styles.logoutButton}>
                            Not {authUser?.firstName ? authUser?.firstName : authUser?.email}? <a onClick={sessionStore.logout}>Logout</a>
                        </div>
                    </div>
                }

                {step === 1 &&
                    <BasicDetails currentUser={sessionStore.authUser} onNext={nextStep} onBack={previousStep} />
                }
                {step === 2 &&
                    <UserTypeStep onNext={nextStep} />
                }
                {step === 3 &&
                    <SectorsStep onNext={nextStep} onBack={previousStep} />
                }
                {step === 4 &&
                    <SectorsSecondaryStep onNext={nextStep} onBack={previousStep} />
                }
                {step === 5 &&
                    <ObjectivesStep onNext={nextStep} />
                }
                {step === 6 &&
                    <OnboardingDone history={history} />
                }
            </div>

            <AddCompanyDrawer />

        </div >
    )
}


const layout = {

};

export default inject('sessionStore', 'viewStateStore', 'onboardingStore','analyticsReporter', 'remoteSettingsProvider')(observer(OnboardingPage));
