import { inject, observer } from 'mobx-react';
import React, { useEffect, useState } from 'react';
import { Redirect } from 'react-router-dom';
import styles from './AddToCalendarPage.module.css'
import SessionStore from '../../store/SessionStore';
import { Button } from 'antd';
import EventsStore from '../../store/EventsStore';
import EventModel from '../../common/models/EventModel';
import AddToCalendar from '../dashboard/events/components/addToCalendar/AddToCalendar';
import Logo from '../../common/components/logo/Logo';
import loadingAnimation from '../../common/images/loading.json';
import Lottie from 'react-lottie';
import AnalyticsReporter from '../../services/analytics/AnalyticsReporter';

const lottieFile = {
    loop: true,
    autoplay: true,
    animationData: loadingAnimation,
    rendererSettings: {
        preserveAspectRatio: 'xMidYMid slice'
    }
};


interface Props {
    eventsStore: EventsStore;
    analyticsReporter: AnalyticsReporter;
    location: any;
    history: any;
    match: any;
}

function AddToCalendarPage({ match, eventsStore, analyticsReporter }: Props) {
    const [event, setEvent] = useState<EventModel>();
    useEffect(() => {
        analyticsReporter.trackPage('Add To Calendar Page');
        const { eventId } = match.params;

        if (!eventId) {
            return;
        }

        eventsStore.getEvent(eventId).then(event => {
            setEvent(event);
        })
    }, []);

    if (!event) {
        return <div className={styles.container}>
            <div className={styles.loading}>
                <Lottie options={lottieFile} />
            </div>
        </div>
    }

    return (
        <div className={styles.container}>
            <div className={styles.content}>
                <div className={styles.header}>
                    {event.title}
                </div>
                <div className={styles.topHeaderText}>{event.longDateString}</div>
                <div className={styles.subHeader}>
                    Good leaders come. Great leaders come on time.
            <br />
                Be a great leader. 😎
                </div>

                <AddToCalendar event={event} className={styles.addToCalendarButton}>
                    <Button size='large' type='primary'>
                        ADD TO CALEDAR
                </Button>
                </AddToCalendar>
                <Logo className={styles.logo} hideTagline={true} />
            </div>
        </div>
    )
}

export default inject('eventsStore', 'analyticsReporter')(observer(AddToCalendarPage));