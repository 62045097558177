import Checkbox from 'antd/lib/checkbox/Checkbox';
import { AutoComplete, Button, Divider, Form, Input, message, notification, Radio, Select, Tabs, Tag } from 'antd';
import React, { useEffect, useState } from 'react';
import SectionHeader from '../../../common/components/sectionHeader/SectionHeader';
import styles from './AccountPage.module.css'
import SessionStore from '../../../store/SessionStore';
import { inject, observer } from 'mobx-react';
import _ from 'lodash';
import AnalyticsReporter from '../../../services/analytics/AnalyticsReporter';
import MembershipTab from './membershipTab/MembershipTab';
const { Option } = AutoComplete;
const { TabPane} = Tabs;

interface Props {
    sessionStore: SessionStore,
    analyticsReporter: AnalyticsReporter,

}

function AccountPage({ analyticsReporter, sessionStore }: Props) {


    return (
        <div className={styles.container}>
            <SectionHeader title='My Account'
                subTitle='Manage your membership and settings related to you account..' />
            <Tabs defaultActiveKey="1" className={styles.tabs}>
                <TabPane tab="Membership" key="1">
                    <MembershipTab/>
                </TabPane>
            </Tabs>
        </div >
    )
}

export default inject('analyticsReporter', 'sessionStore')(observer(AccountPage));
