
import { Avatar, Button, Modal, Progress, Tooltip } from 'antd';
import { inject, observer } from 'mobx-react';
import React, { ReactNode, useEffect, useState } from 'react';
import styles from './WelcomeModal.module.css';
import SessionStore from '../../../../store/SessionStore';
import { sleepAsync } from '../../../../common/utils/Utils';
import henryImage from './assets/henry.jpg';
import randomPersonImage from './assets/person.jpg';
import randomCompanyImage from './assets/traveltechnation.jpg';
import emojiGif from './assets/emoji.gif';
import airbnbLogo from './assets/airbnb.jpg';
import amadeusLogo from './assets/amadeus.jpg';
import carnival from './assets/carnival.jpg';
import certares from './assets/certares.jpg';
import delta from './assets/delta.jpg';
import jetlue from './assets/jetblue.jpg';
import kayak from './assets/kayak.jpg';
import lufthansa from './assets/lufthansa.jpg';
import marriott from './assets/marriott.jpg';
import tripadvisor from './assets/tripadvisor.jpg';
import yourTableVideo from './assets/yourtable.mp4';
import logoAnimated from '../../../../common/images/logo-animation.json';
import Lottie from 'react-lottie';
import UserAvatar from '../../../../common/components/avatar/UserAvatar';
import UserModel from '../../../../common/models/UserModel';


const lottieFile = {
    loop: false,
    autoplay: true,
    animationData: logoAnimated,
    rendererSettings: {
        preserveAspectRatio: 'xMidYMid slice'
    }
};


interface Props {
    sessionStore?: SessionStore;
}

function WelcomeModal({ sessionStore }: Props) {
    const { authUser, loggedIn } = sessionStore!;
    const [visible, setVisible] = useState(false);
    const [title, setTitle] = useState<string | ReactNode>('Welcome to the club!');
    const [text, setText] = useState<string | ReactNode>('');
    const [textAnimated, setTextAnimated] = useState(false);
    const [titleAnimated, setTitleAnimated] = useState(false);
    const [sceneAnimated, setSceneAnimated] = useState(false);
    const [currentScene, setCurrentScene] = useState<ReactNode>();
    const [explainerOver, setExplainerOver] = useState(false);
    const [explainerProgress, setExplainerProgress] = useState(0);


    useEffect(() => {
        if (loggedIn) {
            setTimeout(() => {
                setVisible(!authUser?.welcomeExplainerShown);
            }, 1500);


            cacheImages(authUser);

            startAnimation();
        }
    }, [loggedIn]);

    const startAnimation = async () => {
        setInterval(() => {
            setExplainerProgress(prev => prev + 0.3)
        }, 1000);

        setText(firstText);
        await sleepAsync(2000);
        setCurrentScene(<FirstScene />);
        setSceneAnimated(true);
        await sleepAsync(5000);
        setCurrentScene(undefined);

        setText(secondaryText(authUser?.firstName || ''));
        setTextAnimated(true);
        setSceneAnimated(false);
        await sleepAsync(500);
        setCurrentScene(<UserScene userModel={authUser} />);
        setSceneAnimated(true);
        await sleepAsync(6000);

        setTextAnimated(false)
        setSceneAnimated(false);
        await sleepAsync(500);

        setText(thirdText)
        setTextAnimated(true);
        await sleepAsync(9500);

        setTextAnimated(false)
        setSceneAnimated(false);
        await sleepAsync(500);
        setCurrentScene(undefined);

        setText(forthText)
        setTextAnimated(true);
        await sleepAsync(500);
        setCurrentScene(<EventsScene />);
        setSceneAnimated(true);
        await sleepAsync(9000);

        setTextAnimated(false)
        setSceneAnimated(false);
        await sleepAsync(500);
        setCurrentScene(undefined);

        setText(fithText)
        setTextAnimated(true);
        await sleepAsync(1000);
        setSceneAnimated(true);
        setCurrentScene(<TableScene />);
        await sleepAsync(8500);

        setTextAnimated(false)
        await sleepAsync(500);

        setTitle(endTitle(authUser?.firstName || ''))
        setText(endPhase(onDismiss))
        setCurrentScene(undefined);
        setExplainerOver(true);
        setTextAnimated(true);

    }

    const onDismiss = async () => {
        setVisible(false);
        await sessionStore?.welcomeExplainerShown();
    }

    const getAnimation = () => {
        return explainerOver ? fadeInAnimation : fadeUpAnimation;
    }

    const renderCurrentScene = () => {
        return currentScene;
    }

    const cacheImages = (authUser?: UserModel) => {
        const img = new Image();
        img.src = authUser?.photoUrl || '';
    }


    return (
        <Modal
            visible={visible}
            footer={null}
            centered
            className={`welcome-modal ${styles.modal}`}
            bodyStyle={{ padding: 0 }}
            onCancel={onDismiss}
            maskClosable={false}
            closable={true}>
            <div className={styles.container}>
                {/* <Progress strokeColor={'#1cbec0'} className={styles.progressBar} percent={explainerProgress} showInfo={false} /> */}
                <div className={styles.progressBarRaw} />
                <div className={styles.founderAvatar}>
                    <Avatar className={styles.avatar} src={henryImage} />
                </div>
                <div className={styles.content}>
                    <div className={`${styles.header} ${titleAnimated ? fadeUpAnimation : {}} `}>{title}</div>
                    <div className={styles.textsContainer}>
                        <div className={`${styles.text} ${textAnimated ? getAnimation() : {}} `}>
                            {text}
                        </div>
                    </div>
                    {currentScene &&
                        <div className={`${styles.sceneBase} ${sceneAnimated ? fadeInAnimation : {}}`}>
                            {renderCurrentScene()}
                        </div>
                    }
                </div>
            </div>
        </Modal>);
}

const fadeUpAnimation = 'animate__animated animate__fadeInUp';
const fadeInAnimation = 'animate__animated animate__fadeIn';

const firstText = <div className={styles.welcomeText}>
    <div>Travel Tech Leaders is an invite-only club of proven global leaders. </div> 
    <div>Our mission is to tackle Travel’s biggest challenges and <span className={styles.bolded}>lead the industry forward.</span></div>
</div>

const secondaryText = (firstName: string) => <div>
    <div>Every ‘Leader’ was hand picked and vetted.</div>
    <div className={styles.bolded}>Yes, that includes you, {firstName} 👑.</div>
</div>

const thirdText = <div className={`${styles.thirdText}`}>The Leaders platform works for you <span className={styles.bolded}>24/7.</span> It automatically connects you with other Leaders based on similar interests (and some AI magic tricks 🎩).</div>

const forthText = <div className={`${styles.forthText}`}>
    As a Leader, you’ll access our exclusive member meetings. Putting you in the same room with decision makers from <span className={styles.bolded}>leading organizations creating the future, today.</span>
</div>

const fithText = <div>
    <div>Meet and network with guest speakers and other Leaders from all over the world.</div>
    <div>Forget “Zoom fatigue”, our meetings are <span className={styles.bolded}>intimate and fully interactive.</span></div>
</div>

const endTitle = (firstName: string) => <div>It's your turn, {firstName} 💪</div>
const endPhase = (onClick: () => void) => <div>
    <div>
        <div>Go ahead and save your seat in one of the upcoming meetings, see who else is here and explore everything else we’ve prepared for you. This is only the beginning.</div>
    </div>
    <Button type='primary'
        size='large'
        onClick={onClick}
        className={styles.exploreButton} >
        Go Explore
    </Button>
    <div className={styles.teamContainer}>
        <div className={styles.teamText}>Henry and the team of Travel Tech Leaders</div>
        <Avatar.Group>
            <Tooltip placement='bottom' title={'Henry Chen Weinstein - Travel Tech Nation'}>
                <Avatar className={styles.teamAvatar} src={'https://firebasestorage.googleapis.com/v0/b/traveltechnation.appspot.com/o/general%2Fhenry-min%20(1).jpg?alt=media&token=9bd305d2-1dea-47c8-9112-3036f320cd5f'} />
            </Tooltip>
            <Tooltip placement='bottom' title={'Rom Shiri - Travel Tech Nation'}>
                <Avatar className={styles.teamAvatar} src={'https://firebasestorage.googleapis.com/v0/b/traveltechnation.appspot.com/o/general%2Fprofile_abcyl5_0%20(1).jpeg?alt=media&token=1fed04fb-3e56-42f8-95f9-7729c5ee10ea'} />
            </Tooltip>
            <Tooltip placement='bottom' title={'Walter Buschta - Phocuswright'}>
                <Avatar className={styles.teamAvatar} src={'https://firebasestorage.googleapis.com/v0/b/traveltechnation.appspot.com/o/general%2Fprofile_vqs3yo_ProfilePicSmallSize.jpg?alt=media&token=00a8adfa-89b8-4c87-9916-81c8856a534b'} />
            </Tooltip>
            <Tooltip placement='bottom' title={'Pete Comeau - Phocuswright'}>
                <Avatar className={styles.teamAvatar} src={'https://firebasestorage.googleapis.com/v0/b/traveltechnation.appspot.com/o/general%2Fprofile_x3cvwt_ComeauPete_2018red.jpg?alt=media&token=5db5b0ea-cd05-4b0c-b77b-74f2fab2e448'} />
            </Tooltip>
        </Avatar.Group>
    </div>
</div>

function FirstScene() {
    return <div className={styles.firstScene}>
        <div className={styles.logoAnimation}>
            <Lottie options={lottieFile} />
        </div>
    </div>
}

function UserScene({ userModel }: { userModel?: UserModel }) {
    const [showRandomPerson, setShowRandomPerson] = useState(false);
    const [showEmoji, setShowEmoji] = useState(false);

    useEffect(() => {
        setTimeout(() => {
            setShowRandomPerson(true);
            setTimeout(() => {
                setShowEmoji(true);
                setTimeout(() => {
                    setShowEmoji(false);
                }, 2300)
            }, 2200)
        }, 7500)
    });

    return <div className={styles.secondScene}>
        <div className={styles.secondSceneContainer}>
            <UserAvatar size='large' className={`${styles.scAvatar} animate__animated animate__pulse`} userImage={userModel?.photoUrl} companyImage={userModel?.company?.logoUrl} />
            {showRandomPerson && <UserAvatar size='large' className={`${styles.scAvatar} animate__animated animate__pulse`} userImage={randomPersonImage} companyImage={randomCompanyImage} />}
            {showEmoji && <img src={emojiGif} className={`${styles.emojiGif} animate__animated animate__pulse`} />}
        </div>
    </div>
}

function EventsScene() {
    const [logos, setLogos] = useState(companies)
    return <div className={styles.eventsScene}>
        <div className={styles.eventsSceneLogosGrid}>
            {logos.map((logo, index) => {
                return <CompanyLogo key={index} index={index} logo={logo} />
            })}
        </div>

    </div>
}

function CompanyLogo({ index, logo }: { index: number, logo: string }) {
    const [animationClass, setAnimationClass] = useState('');

    useEffect(() => {
        setAnimationClass(fadeInAnimation);
    }, [index])

    return animationClass ? <img key={index} className={`${styles.eventsSceneLogo} ${animationClass}`} src={logo} /> : <div />

}

function TableScene() {
    return <div className={styles.tableScene}>
        <video className={styles.tableVideoContainer} src={yourTableVideo} autoPlay={true} muted />
    </div>
}

const companies = [
    airbnbLogo,
    tripadvisor,
    carnival,
    delta,
    certares,
    jetlue,
    marriott,
    amadeusLogo,
    lufthansa,
    kayak
]

export default inject('sessionStore')(observer(WelcomeModal));

