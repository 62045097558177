
import { Avatar, Button, Drawer, Dropdown, Form, Input, List, Menu, message, Popconfirm, Popover, Select, Spin, Typography } from 'antd';
import Modal from 'antd/lib/modal/Modal';
import _ from 'lodash';
import { debounce } from 'lodash';
import { inject, observer } from 'mobx-react';
import moment from 'moment';
import React, { useEffect, useRef, useState } from 'react';
import { CSVLink } from 'react-csv';
import AttendeeModel from '../../../../../common/models/AttendeeModel';
import EventModel from '../../../../../common/models/EventModel';
import UserModel, { UserPublicData } from '../../../../../common/models/UserModel';
import ControlPanelStore from '../../../../../store/ControlPanelStore';
import EventsStore from '../../../../../store/EventsStore';
import MembersStore from '../../../../../store/MembersStore';
import SessionStore from '../../../../../store/SessionStore';
import ViewStateStore from '../../../../../store/ViewStateStore';
import styles from './ManageAttendeesModal.module.css';

interface Props {
    visible: boolean,
    onCancel: () => void,
    sessionStore?: SessionStore,
    event?: EventModel,
    viewStateStore?: ViewStateStore,
    attendees: AttendeeModel[],
    eventsStore?: EventsStore;
    membersStore?: MembersStore;
    controlPanelStore?: ControlPanelStore;
}

function ManageAttendeesModal({ visible, onCancel, sessionStore, viewStateStore, attendees, eventsStore, event, membersStore, controlPanelStore }: Props) {
    const [isBusy, setBusy] = useState(false);
    const { authUser } = sessionStore!;
    const { isMobile } = viewStateStore!;
    const [fetching, setFetching] = useState(false);
    const [fetchedUsers, setFetchedUsers] = useState(Array<UserModel>());
    const [selectedMembers, setSelectedMembers] = useState(Array<UserModel>());
    const [exportData, setExportData] = useState(Array<any>());
    const [addAttendeeModalVisible, setAddAttendeeModalVisible] = useState(false);

    const fetchUser = async (value: string) => {
        setFetching(true);
        const result = await membersStore!.fetchByName(value)
        setFetchedUsers(result);
        setFetching(false);
    }

    const removeAttendee = async (attende: AttendeeModel) => {
        setBusy(true);
        await eventsStore?.removeAttendee(event?.id!, attende);
        setBusy(false);
        message.success('Attendee removed from this event.')
    }

    const notRegisteredMembers = async () => {
        const allMembers = await controlPanelStore?.fetchAllMembers();
        const leaders = allMembers?.filter(u => u.leadersMember);
        const currentAttendees = attendees.map(a => a.user);
        const notGoing = _.differenceBy(leaders, currentAttendees || [], (u => u?.id));
        setExportData(notGoing);
    }

    const addAttendee = async () => {
        let count = 0;
        setBusy(true);
        for (const member of selectedMembers) {
            if (_.some(event?.attendeesIds, m => m === member.id)) {
                continue;
            }
            count++;
            await eventsStore?.addAttendee(event?.id!, member);
        }
        setBusy(false)
        message.success(`${count} Attendees have been added!`);
        setAddAttendeeModalVisible(false);
        setSelectedMembers([]);
    }

    const onSelect = (value: string, option: any) => {
        const user = option.extra;
        setSelectedMembers([...selectedMembers, user]);
    }

    const onDeselect = (value: string, option: any) => {
        const user = option.extra;
        setSelectedMembers([...selectedMembers.filter(m => m.id !== user.id)]);
    }

    const mapToCSV = (attendees: AttendeeModel[]) => {
        return attendees.map(a => {
            return {
                ...a.user,
                attended: a.attended,
                company: a.user?.company?.name,
            }
        });
    }

    const mapToCSVMissingMembers = (users: any[]) => {
        return users.map(a => {
            return {
                ...a,
                company: a.company?.name,
            }
        });
    }

    return (
        <Drawer
            bodyStyle={{ padding: 0, lineHeight: 1.2 }}
            placement='right'
            width={isMobile ? '90%' : 660}
            onClose={onCancel}
            closable={true}
            className='manage-attendees-modal'
            visible={visible}
            footer={null}
        >
            <div className={`${styles.container}`}>
                <div className={styles.content}>
                    <div className={styles.topPart}>
                        <div className={styles.header}>
                            Attendee List
                        </div>
                        <div className={styles.buttons}>
                            <Popover
                                content={
                                    <div className={styles.addMemberContainer}>
                                        <Select
                                            mode='multiple'
                                            allowClear
                                            notFoundContent={fetching ? <Spin size="small" /> : null}
                                            filterOption={false}
                                            onSelect={onSelect}
                                            onDeselect={onDeselect}
                                            onSearch={debounce(fetchUser, 600)}
                                            size={'large'}
                                            className={styles.userSelect}>
                                            {
                                                fetchedUsers.map(user => {
                                                    return <Select.Option extra={user} value={user.id!} key={user.id}>
                                                        <div className={styles.attendeeOption}>
                                                            <Avatar className={styles.attendeeAvatar} src={user.photoUrl} />
                                                            <div className={styles.attendeeName}>{user.firstName} {user.lastName}</div>
                                                        </div>
                                                    </Select.Option>

                                                })
                                            }
                                        </Select>

                                        <Button className={styles.addMemberButton} loading={isBusy} onClick={addAttendee}>
                                            Add
                                        </Button>
                                    </div>
                                }
                                title="Find Member"
                                trigger="click"
                                visible={addAttendeeModalVisible}
                                onVisibleChange={(changed) => setAddAttendeeModalVisible(changed)}
                            >
                                <Button type='default' onClick={() => setAddAttendeeModalVisible(true)}>Add manually</Button>
                            </Popover>

                            <Dropdown
                                overlay={
                                    <Menu>
                                        <Menu.Item>
                                            <Button type='link'>
                                                <CSVLink filename={`export_attendees_${moment().format('DD/MM/YYYY-HH:mm')}.csv`} data={mapToCSV(attendees)} headers={csvHeaders}>
                                                    Registered Attendees
                                            </CSVLink>
                                            </Button>
                                        </Menu.Item>
                                        <Menu.Item>
                                            <Button type='link' loading={exportData.length === 0} disabled={exportData.length === 0}>
                                                <CSVLink filename={`export_missing_attendees_${moment().format('DD/MM/YYYY-HH:mm')}.csv`} data={mapToCSVMissingMembers(exportData)} headers={csvHeaders}>
                                                    Not Registered Attendees
                                             </CSVLink>
                                            </Button>
                                        </Menu.Item>
                                    </Menu>
                                } placement="bottomLeft"
                                trigger={['click']}>
                                <Button onClick={notRegisteredMembers} type='primary' disabled={attendees.length === 0} className={styles.exportButton}>
                                    Export to CSV
                                </Button>
                            </Dropdown>
                        </div>
                    </div>


                    <List
                        style={{ marginBottom: 10 }}
                        itemLayout="horizontal"
                        dataSource={attendees}
                        locale={{ emptyText: 'No attendees (yet)...' }}
                        renderItem={attendee => (
                            <List.Item
                                actions={[<Popconfirm
                                    title={'Are you sure?'}
                                    onConfirm={() => removeAttendee(attendee)}>
                                    <Button loading={isBusy} type='link' key="remove-speaker">Remove</Button>
                                </Popconfirm>]}>
                                <List.Item.Meta
                                    avatar={<Avatar src={attendee.user?.photoUrl} />}
                                    title={attendee.user?.fullName}
                                    description={`${attendee.user?.title}`}
                                />
                            </List.Item>
                        )}
                    />
                </div>
            </div>
        </Drawer>)

}

const csvHeaders = [
    { label: "First Name", key: "firstName" },
    { label: "Last Name", key: "lastName" },
    { label: "Gender", key: "gender" },
    { label: "Email", key: "email" },
    { label: "Company", key: "company" },
    { label: "Role/Title", key: "role" },
    { label: "Type", key: "type" },
    { label: "Timezone", key: "timezone" },
    { label: "Leader", key: "leadersMember" },
    { label: "Guest", key: "guest" },
    { label: "Attented", key: "attended" },
]

export default inject('sessionStore', 'viewStateStore', 'eventsStore', 'membersStore', 'controlPanelStore')(observer(ManageAttendeesModal));