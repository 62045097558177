import request from 'request-promise-native';
const IPDATA_KEY = '2559ef97a04178eb661a797e63b49bc9967ae0b0695ccbcceed80e30';

class UserInfoProvider {

    getInfo = async () => {
        const data = await request({
            uri: `https://api.ipdata.co/?api-key=${IPDATA_KEY}`,
            json: true,
        });
        return {
            country: data.country_name,
            countryCode: data.country_code,
            city: data.city,
            region: data.region,
            continent: data.continent_name,
            flagIcon: data.flag,
            latitude: data.latitude,
            longitude: data.longitude,
            isEU: data.is_eu,
        };
    }
}

export default UserInfoProvider;