import { Avatar, Dropdown, Menu } from 'antd';
import { inject, observer } from 'mobx-react';
import React, { useState } from 'react';
import { LogoutOutlined, UserOutlined } from '@ant-design/icons';
import SessionStore from '../../../../store/SessionStore';
import ViewStateStore from '../../../../store/ViewStateStore';
import styles from './HeaderContent.module.css';
import menuIconMobile from '../../images//menu-icon-mobile.png';
import UserAvatar from '../../../../common/components/avatar/UserAvatar';
import EditProfileBubble from '../editProfileBubble/EditProfileBubble';
import Logo from '../../../../common/components/logo/Logo';
import SettingsStore from '../../../../store/SettingsStore';

interface Props {
    siderCollapsed: boolean,
    toggleSider: () => void,
    sessionStore?: SessionStore,
    settingsStore?: SettingsStore,
    viewStateStore?: ViewStateStore,
    history: any,
    
}

function HeaderContent({ siderCollapsed, toggleSider, viewStateStore, sessionStore, history, settingsStore }: Props) {
    const { isMobile } = viewStateStore!;

    return (
        <div className={styles.container}>
            {siderCollapsed && isMobile && <img onClick={toggleSider} src={menuIconMobile} className={styles.menuIconMobile} />}

            <div className={styles.sidePanel}>
                <img className={styles.logo} src={settingsStore?.space?.smallLogo} />
                <EditProfileBubble />
            </div>

        </div>
    )
}



export default inject('sessionStore', 'viewStateStore', 'settingsStore')(observer(HeaderContent));
