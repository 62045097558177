import React from 'react';
import styles from './LiveBadge.module.css';

interface Props {
    className?: string;
    style?: any;
}

function LiveBadge({ className, style }: Props) {
    return (
        <div style={style} className={`${styles.container} ${className}`}>
            <div className={`${styles.circle}`} />
            <span>Live</span>
        </div>)

}

export default LiveBadge;