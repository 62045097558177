import React, { useState } from 'react';
import styles from './Card.module.css';

interface Props {
    className?: string,
    style?: any,
    onClick?: () => void;
}

function Card({ children, className, style, onClick }: React.PropsWithChildren<Props>) {
    return (
        <div onClick={onClick} className={`${styles.container} ${className}`} style={style}>
            {children}
        </div>
    )
}

export default Card;