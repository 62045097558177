import { inject, observer } from 'mobx-react';
import React, { useEffect, useState } from 'react';
import styles from './ChatMessage.module.css';
import MessageModel from '../../../../../../../../../common/models/MessageModel';
import UserAvatar from '../../../../../../../../../common/components/avatar/UserAvatar';
import moment from 'moment';
import { Avatar } from 'antd';

interface Props {
    messageModel: MessageModel;
}

function ChatMessage({ messageModel }: Props) {
    const { by, message, createdAt, role } = messageModel;

    return (
        <div className={styles.container}>
            <div className={styles.avatar}>
                <Avatar src={by?.photoUrl} size='small' />
            </div>
            <div className={styles.texts}>
                <div className={styles.titleContainer}>
                    <div className={styles.name}>{by?.firstName} {by?.lastName?.charAt(0).toUpperCase()}.</div>
                    {role === 'host' && <div className={styles.host}>Host</div>}
                    {createdAt && <div className={styles.time}>• {moment.unix(createdAt).format('HH:mm')}</div>}
                </div>
                <div className={styles.message}>
                    {message}
                </div>
            </div>
        </div>
    )
}

export default ChatMessage;