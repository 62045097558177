import { Popconfirm, Tooltip } from "antd";
import { inject, observer } from "mobx-react";
import React, { useEffect } from "react";
import { Link, useHistory, useLocation } from "react-router-dom";
import SettingsStore from "../../../store/SettingsStore";
// import { inject, observer } from "mobx-react";
// import { } from '@ant-design/icons';
import logo from '../../images/logo-small.svg';
import styles from './SmallLogoButton.module.css';



interface Props {
    linkTo?: string;
    className?: string;
    settingsStore?: SettingsStore;
}


function SmallLogoButton({ linkTo = '/home', className, settingsStore }: Props) {

    const onClick = () => {
        window.location.replace(linkTo);
    }


    return <div className={className}>
        <Popconfirm placement='left' title='Are you sure you want to leave?' onConfirm={onClick}>
            {/* <Tooltip title='Go back to the Leaders platfrom' placement='left' mouseEnterDelay={0.8}> */}
            <div className={`${styles.logoContainer}`}>
                <img src={settingsStore?.space?.squareLogo} className={styles.logo} />
            </div>
            {/* </Tooltip> */}
        </Popconfirm>
    </div>

}


export default inject('settingsStore')(observer(SmallLogoButton));