
import firebase from 'firebase';
import _ from 'lodash';
import { observable, makeObservable, computed, runInAction, action, makeAutoObservable, reaction } from 'mobx';
import moment from 'moment';
import { MembershipSettings, SettingsModel, SpaceSettings } from '../common/models/SettingsModel';
import UserModel from '../common/models/UserModel';
import { isMobile } from '../common/utils/Utils';
import FirebaseClient from '../services/FirebaseClient';
import UserInfoProvider from '../services/UserInfoProvider';
import RootStore from './RootStore';

const hideNavigationSiderPages = ['/live/', '/meeting/']

class SettingsStore {
    rootStore: RootStore;
    firebase: FirebaseClient;
    generalSettings?: SettingsModel;

    constructor(rootStore: RootStore, firebase: FirebaseClient) {
        makeObservable(this, {
            generalSettings: observable,
            membership: computed,
            space: computed,
            email: computed,
            miscellaneous: computed,
            access: computed,
            isTTNL: computed,
        })

        this.rootStore = rootStore;
        this.firebase = firebase;
        this.subscribeForSettings();
    }

    onSettingsChanged(callback: (settings?: SettingsModel) => void) {
        reaction(
            () => this.generalSettings,
            () => callback(this.generalSettings)
        )
    }

    get membership() {
        // return { 
        //     enabled: true,
        // } as MembershipSettings
         return this.generalSettings?.membership;
    }

    get space() {
        return this.generalSettings?.space;
    }

    get email() {
        return this.generalSettings?.email;
    }

    get miscellaneous() {
        return this.generalSettings?.miscellaneous;
    }

    get terminology() {
        return this.generalSettings?.terminology;
    }
    
    get access() {
        return this.generalSettings?.access;
    }

    subscribeForSettings = () => {
        this.firebase.settings('general').onSnapshot((snap) => {
            runInAction(() => {
                this.generalSettings = snap.data() as SettingsModel;
            });
        });
    }

    
    enableMembership = async (enabled: boolean) => {
        this.firebase.settings('general').set({
            membership: {
                enabled: enabled
            }
        }, { merge: true })
    }

    updateSettings = async (change: any) => {
        await this.firebase.settings('general').set({
            ...change,
        }, { merge: true })
    }

    get isTTNL() {
        return window.location.host.indexOf('traveltechnation') > -1
        || this.space?.name === 'Travel Tech Leaders'; 
    }
}


export default SettingsStore;


