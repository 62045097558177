
import { Avatar, Button, Dropdown, Form, Input, Menu, Select, Typography } from 'antd';
import Modal from 'antd/lib/modal/Modal';
import { inject, observer } from 'mobx-react';
import moment from 'moment';
import React, { useEffect, useRef, useState } from 'react';
import Lottie from 'react-lottie';
import UserAvatar from '../../../../../common/components/avatar/UserAvatar';
import EventModel, { Speaker } from '../../../../../common/models/EventModel';
import makeUrls, { CalendarEvent } from '../../../../../common/utils/icsHelper';
import SessionStore from '../../../../../store/SessionStore';
import styles from './AddQuestionModal.module.css';

interface Props {
    visible: boolean,
    onCancel: () => void,
    onSubmit: (questionText: string) => void,
    sessionStore?: SessionStore,
}

function AddQuestionModal({ visible, onCancel, onSubmit, sessionStore }: Props) {
    const [isBusy, setBusy] = useState(false);
    const { authUser } = sessionStore!;

    const onFinish = async (values: any) => {
        setBusy(true);
        setTimeout(() => {
            onSubmit(values.questionText);
            setBusy(false);
        }, 1500);
    }

    return (
        <Modal visible={visible} footer={null} onCancel={onCancel}>
            <div className={styles.container}>
                <div className={styles.header}>What's on your mind?</div>
                <div className={styles.subHeader}>
                    We and the speakers will do the best to address your question during the meeting.
                </div>
                <Form name='question-form'
                    style={{ width: '100%' }}
                    className='question-form'
                    layout='vertical'
                    onFinish={onFinish}>
                    <Form.Item
                        name='questionText'
                        rules={[{ required: true, message: 'Please add a longer description for the company' }]}>
                        <Input.TextArea placeholder='Type your question here...' maxLength={200} minLength={20} />
                    </Form.Item>

                    <Form.Item>
                        <Button loading={isBusy} htmlType='submit' size='large' type='primary' className={styles.loginButton}>
                            Submit  Your Question
                        </Button>
                    </Form.Item>
                    <div className={styles.askingAsContainer}>
                        <Avatar src={authUser?.photoUrl} size='small' />
                        <div>Asking as <span>{authUser?.fullName}</span></div>
                    </div>
                </Form>
            </div>
        </Modal>)

}

export default inject('sessionStore')(observer(AddQuestionModal));