
import { Avatar, Button, Dropdown, Form, Input, Menu, notification, Radio, Select, Typography, Modal, Switch, message } from 'antd';
import { BellOutlined } from '@ant-design/icons'
import { inject, observer } from 'mobx-react';
import moment, { duration } from 'moment';
import React, { useEffect, useRef, useState } from 'react';
import Lottie from 'react-lottie';
import MembersStore from '../../../../../store/MembersStore';
import SessionStore from '../../../../../store/SessionStore';
import styles from './MeetMemberModal.module.css';
import EventModel from '../../../../../common/models/EventModel';
import UserModel, { UserPublicData } from '../../../../../common/models/UserModel';
import ViewStateStore from '../../../../../store/ViewStateStore';
import _ from 'lodash';
import { useHistory } from 'react-router-dom';
import UserAvatar from '../../../../../common/components/avatar/UserAvatar';
import ConnectionsStore from '../../../../../store/ConnectionsStore';

interface Props {
    visible: boolean,
    onCancel: () => void,
    sessionStore?: SessionStore,
    membersStore?: MembersStore,
    connectionsStore?:ConnectionsStore,
    viewStateStore?: ViewStateStore,
}

function MeetMemberModal({ visible, onCancel, sessionStore, membersStore, connectionsStore }: Props) {
    const [isBusy, setBusy] = useState(false);
    const { currentViewedMember } = membersStore!;
    const { authUser } = sessionStore!;
    const [notifyChecked, setNotifiedChecked] = useState(false);
    const [events, setEvents] = useState(Array<EventModel>());

    useEffect(() => {
        if (visible) {
            const isFollowed = currentViewedMember?.followers && currentViewedMember?.followers?.indexOf(authUser!.id!) > -1;
            setNotifiedChecked(isFollowed || false);
            membersStore?.fetchEventsForMember(currentViewedMember?.id!).then(events => {
                setEvents(events);
            })
        } else {
            setEvents([]);
        }
    }, [visible])

    const onNotifyChanged = async (notify: boolean) => {
        if(authUser?.followers && authUser?.followers.length >= 10) {
            message.warn(`You can't follow more then 10 members.`)
            return;
        }

        try {
            setBusy(true);
            if (notify) {

                await connectionsStore?.followMember(currentViewedMember!);
                setNotifiedChecked(true);
                message.success(`Great! you'll be notifed when ${UserModel.pronouneHe(currentViewedMember?.gender)} attends a meeting.`)
            } else {
                await connectionsStore?.unfollowMember(currentViewedMember!);
                setNotifiedChecked(false);
                message.info(`You won't get notifications on ${currentViewedMember?.firstName} anymore.`)
            }
        } catch (error) {
            message.error(error);
        } finally {
            setBusy(false);
        }
    }

    return (
        <Modal visible={visible}
            width={600}
            footer={null}
            bodyStyle={{ padding: 0 }}
            onCancel={onCancel}>
            <div className={styles.container}>
                {/* <div className={styles.bubblesContainer}>
                    <UserAvatar size={60} userImage={authUser?.photoUrl} className={styles.userAvatar} /> 
                    <UserAvatar size={60} userImage={currentViewedMember?.photoUrl} /> 
                </div> */}
                <div className={styles.header}>Meet {currentViewedMember?.firstName}</div>
                <div className={styles.subHeader}>
                    <div>You can meet <b>{currentViewedMember?.firstName}</b> at one of our upcoming meetings.</div>
                    <div>All our meetings are intimate and include time for networking.</div>
                </div>
                {events.length > 0 &&
                    <div className={styles.upcomingEventsContainer}>
                        <div className={styles.events}>
                            {events.map(e => <EventItem key={e.id} eventModel={e} currentViewedMember={currentViewedMember} />)}
                        </div>
                    </div>
                }
                <div className={styles.followContainer}>
                    <div className={styles.notifyText}>
                        <BellOutlined style={{ marginRight: 4 }} />
                        Get notifed when <b>{currentViewedMember?.firstName}</b> attends a meeting
                    </div>
                    <Switch
                        onChange={onNotifyChanged}
                        loading={isBusy}
                        checked={notifyChecked}
                        size='default'
                        checkedChildren={'Enabled'}
                        unCheckedChildren={'Disabled'}
                    />
                </div>
            </div>
        </Modal>)
}

const EventItem = ({ eventModel, currentViewedMember }: { eventModel: EventModel, currentViewedMember?: UserPublicData }) => {
    const history = useHistory();

    const attendClicked = () => {
        history.push(`/e/${eventModel.shortId}`)
    }

    return <div className={styles.eventItem}>
        <div className={styles.eventTitle}>{eventModel.title}</div>
        <div className={styles.eventDate}>{eventModel.longDateString}</div>
        <div className={styles.currentMemberSnippet}>
            <Avatar size='small' src={currentViewedMember?.photoUrl} />
            <div>{currentViewedMember?.firstName} will be there</div>
        </div>
        <Button onClick={attendClicked} className={styles.attendToButton} type='default' size='middle'>
            Attend too
        </Button>
    </div>

}

export default inject('sessionStore', 'membersStore', 'viewStateStore', 'connectionsStore')(observer(MeetMemberModal));