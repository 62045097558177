import { inject, observer } from 'mobx-react';
import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import MembershipCard from '../../../common/components/membershipCard/MembershipCard';
import SectionHeader from '../../../common/components/sectionHeader/SectionHeader';
import { showTawkChatBubble } from '../../../common/utils/Utils';
import AnalyticsReporter from '../../../services/analytics/AnalyticsReporter';
import MembersStore from '../../../store/MembersStore';
import SessionStore from '../../../store/SessionStore';
import SettingsStore from '../../../store/SettingsStore';
import ViewStateStore from '../../../store/ViewStateStore';
import WelcomeModal from '../componnents/welcomeModal/WelcomeModal';
import EventsStrip from './components/eventsStrip/EventsStrip';
import MembersStrip from './components/membersStrip/MembersStrip';
import ResearchStrip from './components/researchStrip/ResourcesStrip';
import styles from './DiscoverPage.module.css';

interface Props {
    sessionStore: SessionStore,
    viewStateStore: ViewStateStore,
    membersStore: MembersStore,
    analyticsReporter: AnalyticsReporter,
    settingsStore: SettingsStore;
}

function DiscoverPage({ sessionStore, analyticsReporter, membersStore, settingsStore, viewStateStore }: Props) {
    const { authUser } = sessionStore;
    const { isTTNL } = settingsStore;
    const { newestMembers } = membersStore;
    const location = useLocation();

    useEffect(() => {
        analyticsReporter.trackPage('Discovery Page');
        membersStore.fetchNewestMembers().then();
        showTawkChatBubble();
    }, [])

    useEffect(() => {
        const params = new URLSearchParams(location.search);
        if(params.has("membership")) {
            viewStateStore.setPaymentWallVisible(true, 'Zero Credits Email');
        }
    }, [])

    return (
        <div className={styles.container}>
            <div className={styles.strips}>
                {authUser && <MembershipCard className={styles.membershipCard} />}
                <EventsStrip className={styles.mobileStrip} />
                <MembersStrip
                    className={styles.mobileStrip}
                    title={'New members'}
                    subTitle={'See who else became a member in our community recently.'}
                    members={newestMembers} />
                {authUser && <ResearchStrip />}
            </div>

            { isTTNL && <WelcomeModal /> }

        </div >
    )
}

export default inject('sessionStore', 'viewStateStore', 'analyticsReporter', 'membersStore', 'settingsStore')(observer(DiscoverPage));