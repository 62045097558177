import { inject, observer } from "mobx-react";
import React, { useEffect, useState } from "react";
import { useRouteMatch } from "react-router";
import { useHistory, useLocation } from "react-router-dom";
import Card from "../../../common/components/card/Card";
import Loader from "../../../common/components/loader/Loader";
import EventModel from "../../../common/models/EventModel";
import EventsStore from "../../../store/EventsStore";
import EventFeedback from "./components/eventFeedback/EventFeedback";
import styles from './EventFeedbackPage.module.css';

interface Props {
    eventsStore: EventsStore;
}

function EventFeedbackPage({ eventsStore }: Props) {
    const [eventId, setEventId] = useState();
    const [event, setEvent] = useState<EventModel>();
    const [initialRating, setInitialRating] = useState(0);
    const match = useRouteMatch();
    const history = useHistory();
    const location = useLocation();

    useEffect(() => {
        const { eventId } = match.params as any;
        const rating = new URLSearchParams(location.search).get("star");
        eventsStore.getEvent(eventId).then(event => {
            if(rating) {
                setInitialRating(parseInt(rating))
            }
            setEvent(event);
        })

    }, [])

    const onFeedbackSubmitted = () => {
        setTimeout(() => {
            history.replace('/home');
        }, 2000);
    }

    return <div className={styles.container}>
        {!event &&
            <Loader size='large' />
        }
        {event &&
            <Card className={`${styles.content}`}>
                <EventFeedback 
                onFeedbackSubmitted={onFeedbackSubmitted} 
                event={event!}
                initialRating={initialRating} />
            </Card>
        }


    </div>
}

export default inject('eventsStore')(observer(EventFeedbackPage));