
import { Button, Form, Input, message, Modal, notification, Select, Slider } from 'antd';
import { inject, observer } from 'mobx-react';
import React, { useEffect, useRef, useState } from 'react';
import RoundTableModel from '../../../../../../../../../common/models/RoundTableModel';
import EventStore from '../../../../../../../../../store/EventStore';
import styles from './RoundTableVideoRoom.module.css';
import Video, { createLocalTracks, LocalDataTrack, LocalParticipant } from 'twilio-video';
import VideoRoomStore from '../../../../../../../../../store/VideoRoomStore';
import { AudioFilled, VideoCameraFilled, AudioMutedOutlined, SettingFilled } from '@ant-design/icons'
import { disconnect } from 'process';
import { ParticipantData } from '../../../../../../../../../common/components/videoRoom/VideoRoom';
import Participant from '../../../../../../../../../common/components/videoRoom/Participant';
import GroupParticipant from './GroupParticipant';
import noVideoIcon from './../../../../../../../../../common/images/ic-no-video.png';
import Lottie from 'react-lottie';
import loadingAnimation from '../../../../../../../../../common/images/loading.json';
import _ from 'lodash';
import SettingsModal from '../../../settingsModal/SettingsModal';


interface Props {
    eventStore?: EventStore;
    videoRoomStore?: VideoRoomStore;
}


function RoundTableVideoRoom({ eventStore, videoRoomStore }: Props) {
    const [isBusy, setBusy] = useState(false);
    const { currentTable, conversationVideoToken, getTableIndex } = eventStore!;
    const [participants, setParticipants] = useState(Array<ParticipantData>());
    const [currentParticipant, setCurrentParticipant] = useState<ParticipantData>();
    const [localDataTrack, setLocalDataTrack] = useState(new LocalDataTrack());
    const [audioEnabled, setAudioEnabled] = useState(true);
    const [videoEnabled, setVideoEnabled] = useState(true);
    const [deviceSettingsVisible, setDeviceSettingsVisible] = useState(false);
    const { setCurrentRoom, clearCurrentRoom } = videoRoomStore!;

    useEffect(() => {
        window.addEventListener('beforeunload', () => leave);

        return () => {
            leave();
            window.removeEventListener('beforeunload', () => leave);
        }
    }, [])

    useEffect(() => {
        console.log('=== Initiating Room ===');
        createLocalTracks({
            video: videoRoomStore?.selectedVideoInput ? { deviceId: videoRoomStore.selectedVideoInput } : true,
            audio: videoRoomStore?.selectedVideoInput ? { deviceId: videoRoomStore.selectedAudioInput } : true,
        }).then(localTracks => {
            Video.connect(conversationVideoToken!, {
                name: currentTable?.id,
                tracks: [...localTracks, localDataTrack],
                audio: true,
                video: { height: 720, frameRate: 24, width: 1280 },
                bandwidthProfile: {
                    video: {
                        mode: 'grid',
                        maxTracks: 10,
                        renderDimensions: {
                            high: { height: 1080, width: 1920 },
                            standard: { height: 720, width: 1280 },
                            low: { height: 176, width: 144 }
                        }
                    }
                },
                maxAudioBitrate: 16000,
                preferredVideoCodecs: [{ codec: 'VP8', simulcast: true }],
                networkQuality: { local: 1, remote: 1 }
            }).then(room => {
                console.log(`Room connected (ID: ${room.sid})`)
                setCurrentRoom(room);
                setCurrentParticipant({ current: room.localParticipant, info: mapToParticipantInfo(room.localParticipant) });
                setCurrentParticipants(room.participants);
                room.on('participantConnected', participantConnected);
                room.on('participantDisconnected', participantDisconnected);
                room.on('disconnected', (room: Video.Room) => {
                    console.log('Disconnected from room.')
                    stopVideoTracks(room.localParticipant.videoTracks);
                    stopAudioTracks(room.localParticipant.audioTracks);
                });

            }).catch(error => {
                console.log('round tables error:')
                console.error(error);
                eventStore?.generateConversationToken();
            });
        })

    }, []);

    const setCurrentParticipants = (participants: Map<string, Video.RemoteParticipant>) => {
        const currentParticipantsRaw = Array.from(participants, ([name, value]) => ({ name, value }));;
        const currentParticipants = currentParticipantsRaw.map(p => {
            return { current: p.value, info: mapToParticipantInfo(p.value) }
        })

        setParticipants(prev => [...prev, ...currentParticipants]);
    }

    const participantConnected = (participant: Video.Participant) => {
        const participantInfo = mapToParticipantInfo(participant)

        if (!participantInfo) {
            console.warn(`Connected: Couldn't find participant info for ${participant.identity}`);
            return;
        }

        console.log(`Partcipant connected: ${participantInfo?.firstName}`);
        const newParticipant: ParticipantData = { current: participant, info: mapToParticipantInfo(participant) };
        setParticipants(prevParticipants => [...prevParticipants, newParticipant]);
    };

    const participantDisconnected = (participant: Video.Participant) => {
        const participantInfo = mapToParticipantInfo(participant)

        if (!participantInfo) {
            console.warn(`Disconnect: Couldn't find participant info for ${participant.identity}`);
            return;
        }

        console.log(`Partcipant diconnected: ${participantInfo?.firstName}`);
        eventStore?.particicapntDisconnectedFromRoundTable(participantInfo, currentTable!);
        setParticipants(prevParticipants => [...prevParticipants.filter(p => p.current.identity !== participant.identity)]);
    };


    const stopVideoTracks = (tracks: Map<String, Video.LocalVideoTrackPublication>) => {
        tracks.forEach(trackPublication => {
            trackPublication.track.stop();
            trackPublication.unpublish();
        });
    }
    const stopAudioTracks = (tracks: Map<string, Video.LocalAudioTrackPublication>) => {
        tracks.forEach(trackPublication => {
            trackPublication.track.stop();
            trackPublication.unpublish();
        });
    }

    const toggleAudio = () => {
        videoRoomStore!.currentRoom?.localParticipant.audioTracks.forEach(publication => {
            audioEnabled ? publication.track.disable() : publication.track.enable();
        });

        setAudioEnabled(!audioEnabled);
    }
    const toggleVideo = () => {
        videoRoomStore!.currentRoom?.localParticipant.videoTracks.forEach(publication => {
            videoEnabled ? publication.track.disable() : publication.track.enable();
        });

        setVideoEnabled(!videoEnabled);
    }

    const mapToParticipantInfo = (participant: Video.Participant | LocalParticipant) => {
        const attendee = eventStore?.attendees?.find(p => p.id === participant.identity);
        return attendee?.user?.toPublicData();
    }


    const leave = async () => {
        setBusy(true);
        await eventStore?.leaveRoundTable();
        clearCurrentRoom();
        setBusy(false);
        console.log('Left room.');
    }

    const showTable = () => {
        return currentTable && currentTable.participants?.length! >= 2;
    }

    const onInputChanged = async (videoInput: string, audioInput: string) => {
        console.log(`Participant Input changed - Video: ${videoInput} Audio: ${audioInput}`);
        const currentTableTemp = currentTable;
        await leave();
        const hideMessage = message.loading('Rejoining table...', 0);
        setTimeout(async () => {
            hideMessage();
            if(currentTableTemp) {
                await eventStore?.joinRoundTable(currentTableTemp)
            }
        }, 1500)
        
    }


    const gridTemplateAccordingToPartcipantsNumber = () => {
        const participantsNumber = participants.length + 1; // adding self
        switch (participantsNumber) {
            case 0:
            case 1:
            case 2:
                return styles.twoParticipants;
            case 3:
                return styles.threeParticipants
            case 4:
                return styles.fourParticipants;
            case 5:
                return styles.fiveParticipants;
            case 6:
                return styles.sixParticipants;
            case 7:
                return styles.sevenParticipants;
            case 8:
                return styles.eightParticipants;
            case 9:
                return styles.nineParticipants;
            default:
                return styles.nineParticipants;
        }

    }

    return (
        <Modal
            visible={showTable() ? true : false}
            className={`round-tables ${styles.container}`}
            footer={null}
            centered
            closable={false}
        >
            <div className={`${styles.content}`} >
                {videoRoomStore!.currentRoom ?
                    <div className={`${styles.grid} ${gridTemplateAccordingToPartcipantsNumber()}`}>
                        <GroupParticipant
                            selfAudioEnabled={audioEnabled}
                            selfVideoEnabled={videoEnabled}
                            isSelf={true}
                            participant={currentParticipant} />
                        {participants.map(participant => {
                            return <GroupParticipant key={participant.current.identity}
                                selfAudioEnabled={audioEnabled}
                                selfVideoEnabled={videoEnabled}
                                isSelf={false}
                                participant={participant} />
                        })}
                    </div>
                    :
                    <div className={styles.loaderContainer}>
                        <div className={styles.loadingAnimation}>
                            <Lottie options={lottieFile} />
                        </div>
                    </div>

                }

                <div className={styles.bottomStrip}>
                    <div className={`${styles.stripButton} ${!audioEnabled && styles.stripButtonDisabled}`} onClick={() => toggleAudio()}>
                        {audioEnabled ?
                            <AudioFilled className={styles.stripButtonIcon} /> :
                            <AudioMutedOutlined className={styles.stripButtonIcon} />
                        }
                    </div>
                    <div className={`${styles.stripButton} ${!videoEnabled && styles.stripButtonDisabled}`} onClick={() => toggleVideo()}>
                        {videoEnabled ?
                            <VideoCameraFilled className={styles.stripButtonIcon} /> :
                            <img src={noVideoIcon} className={styles.noVideoIcon} />
                        }
                    </div>
                    <div className={styles.divider} />
                    <div className={`${styles.stripButton}`} onClick={() => setDeviceSettingsVisible(true)}>
                        <SettingFilled className={styles.stripButtonIcon} />
                    </div>
                    <div className={styles.leaveTableButton}
                        onClick={leave}>
                        Leave Table
                    </div>

                    <div className={styles.tableDetails}>
                        <div className={styles.tableNumber}>Table number {getTableIndex()}</div>
                        <div className={styles.tableTitle}>{currentTable?.title}</div>
                    </div>
                </div>
            </div>

            {deviceSettingsVisible &&
                <SettingsModal
                    visible={deviceSettingsVisible}
                    onInputsChanged={onInputChanged}
                    onCancel={() => setDeviceSettingsVisible(false)} />
            }

        </Modal>)
}


const lottieFile = {
    loop: true,
    autoplay: true,
    animationData: loadingAnimation,
    rendererSettings: {
        preserveAspectRatio: 'xMidYMid slice'
    }
};


export default inject('eventStore', 'videoRoomStore')(observer(RoundTableVideoRoom));