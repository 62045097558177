import React, { useState } from 'react';
import styles from './Logo.module.css';
import logo from './logo-svg.svg';
import phocuswrightLogo from './pw-logo.png';
import { inject, observer } from 'mobx-react';
import SettingsStore from '../../../store/SettingsStore';

interface Props {
    style?: any,
    hideTagline?: boolean,
    className?: string,
    linkTo?: string,
    settingsStore?: SettingsStore;
}

function Logo({ style, className, linkTo, hideTagline = false, settingsStore }: Props) {
    const onClick = () => {
        if(linkTo) {
        window.location.replace(linkTo);
        }
    }

    return (
        <div onClick={onClick} className={`${styles.container} ${className}`} style={{...style, cursor: linkTo ? 'pointer' : 'unset'}}>
            <img src={settingsStore?.space?.logo} className={styles.logo}  />
            { !hideTagline && settingsStore?.isTTNL &&
                <div className={styles.phocuswright}>
                    <span>In partnership with</span>
                    <img src={phocuswrightLogo} alt="phocusright-logo" />
                </div>
            }
        </div>
    )
}

export default inject('settingsStore')(observer(Logo));