
import { Avatar, Typography } from 'antd';
import React from 'react';
import UserAvatar from '../../../../../common/components/avatar/UserAvatar';
import { Speaker } from '../../../../../common/models/EventModel';
import styles from './SpeakerItem.module.css';
const { Paragraph } = Typography;

interface Props {
    speaker: Speaker;
    mode?: 'default' | 'large';
    colored?: boolean;
}

function SpeakerItem({ speaker, mode = 'default', colored = false }: Props) {
    const isLargeMode = mode === 'large';
    return (
        <div className={`${styles.container} ${colored && styles.colored}`}>
            <div>
            <UserAvatar userImage={speaker.photoUrl} companyImage={speaker.companyLogoUrl} className={styles.avatar} />
            </div>
            <div className={styles.texts}>
                <div className={styles.nameContainer}>
                    <Paragraph className={styles.name} ellipsis={{ rows: 1 }}>{speaker.name}</Paragraph>
                    {speaker.role === 'host' && <div className={styles.host}>Host</div>}
                </div>
                <Paragraph className={styles.title} ellipsis={{ rows: isLargeMode ? 2 : 1 }}>
                    {isLargeMode ? `${speaker.title} at ${speaker.companyName}` : speaker.companyName}
                </Paragraph>
            </div>
        </div>)

}

export default SpeakerItem;