import { Popover, Tooltip, Button, message, Typography } from "antd";
import React, { useState } from "react";
import UserAvatar from "../../../../../../../../common/components/avatar/UserAvatar";
import AttendeeInfo from "../../attendeeInfo/AttendeeInfo";
import { inject, observer } from "mobx-react";
import { VideoCameraOutlined, StarFilled } from '@ant-design/icons';
import styles from './PeopleTab.module.css';
import EventStore from "../../../../../../../../store/EventStore";
import SessionStore from "../../../../../../../../store/SessionStore";
import AttendeeModel from "../../../../../../../../common/models/AttendeeModel";
const { Paragraph } = Typography;


interface Props {
    eventStore?: EventStore;
    sessionStore?: SessionStore;
}

function PeopleTab({ eventStore, sessionStore }: Props) {
    const { authUser } = sessionStore!;
    const { event, onlineAttendees, userActiveConversation, setCurrentConversationRequest } = eventStore!;
    const [isBusy, setBusy] = useState(false);

    const onConversationRequest = async (attendee: AttendeeModel) => {
        if (isBusy) {
            return;
        }

        if (!authUser) {
            message.info('You must be signed-in');
            return;
        }

        setBusy(true);
        const conversation = await eventStore?.createConversation(authUser?.toPublicData(), attendee.user?.toPublicData());
        setCurrentConversationRequest(conversation!);
        setBusy(false);

    }

    return (
        <div className={styles.container}>
            {/* <div className={styles.tabWithNotification}>
                People
                <div className={styles.onlineUsers}>
                    <div className={styles.onlineBubble} />
                    {onlineAttendees?.length}
                </div>
            </div> */}

            <div className={styles.attendees}>

                {onlineAttendees?.map(attendee =>
                    <div className={styles.attendeeDivider} key={attendee.id}>
                        <Popover mouseEnterDelay={0.7} destroyTooltipOnHide key={attendee.id} trigger={['hover']}
                            content={<AttendeeInfo userInfo={attendee.user?.toPublicData()} />}
                            placement={'left'}>
                            <div style={!attendee.isOnline ? { opacity: 0.4 } : {}}
                                className={styles.attendeeContainer}>
                                <UserAvatar className={styles.userAvatar} online={attendee.isOnline} size='tiny' userImage={attendee?.user?.photoUrl} />
                                <div className={styles.texts}>
                                    <div className={styles.attendeeName}>
                                        <Paragraph ellipsis={{ rows: 1, expandable: false }}
                                            style={{ marginBottom: 0, maxWidth: 140, fontSize: 14 }}>{attendee?.user?.shortName}</Paragraph>
                                        <div className={styles.badges}>
                                            {authUser?.id === attendee.id && <span className={styles.youBadge}>You</span>}
                                            {attendee.isSpeaker && <span className={styles.speakerBadge}>Speaker</span>}
                                            {attendee.user?.isManager && <span className={styles.hostBadge}>Host</span>}
                                            {attendee.firstTimer && authUser?.isManager &&
                                                <Tooltip title='First time attending'>
                                                    <span className={styles.firstTimerBadge}><StarFilled /></span>
                                                </Tooltip>
                                            }
                                        </div>
                                    </div>
                                    <Paragraph className={styles.attendeeTitle} ellipsis={{ rows: 1, expandable: false }}
                                        style={{ marginBottom: 0, maxWidth: 120 }}>{attendee?.user?.company?.name || attendee?.user?.role}</Paragraph>
                                </div>
                            </div>

                        </Popover>
                        {
                            event?.isPrivateTalksEnabled &&
                            attendee.isFreeToTalk &&
                            attendee.isOnline &&
                            authUser?.id !== attendee.id &&
                            !userActiveConversation &&
                            <Tooltip title={'Talk in private'}
                                mouseEnterDelay={0.3}
                                placement='left'>
                                <Button onClick={() => onConversationRequest(attendee)} size='small' className={styles.talkButton}>
                                    <VideoCameraOutlined />
                                </Button>
                            </Tooltip>
                        }
                        {
                            !attendee.isFreeToTalk && attendee.isOnline &&
                            <div className={styles.busy}>
                                Busy
                            </div>
                        }
                    </div>

                )
                }
            </div>
        </div>);
}


export default inject('sessionStore', 'eventStore')(observer(PeopleTab));