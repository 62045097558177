import { Tabs } from 'antd';
import React, { useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import SectionHeader from '../../../common/components/sectionHeader/SectionHeader';
import AmbassadorTab from './ambassadorTab/AmbassadorTab';
import GuestInviteTab from './guestInviteTab/GuestInviteTab';
import MembersTab from './membersTab/UsersTab';
import styles from './ControlPanel.module.css';
import { inject, observer } from 'mobx-react';
import SessionStore from '../../../store/SessionStore';
import SettingsTab from './settingsTab/SettingsTab';
const { TabPane } = Tabs;

interface Props {
    sessionStore: SessionStore;
}

function ControlPanel({ sessionStore }: Props) {
    const { authUser, loggedIn } = sessionStore;
    const history = useHistory();

    useEffect(() => {
        if (loggedIn) {
            if (!authUser?.isStaff) {
                history.replace('/home');
            }
        }
    }, [loggedIn])

    return (
        <div className={styles.container}>
            <SectionHeader title='Settings'
                subTitle='This page is visible to manager only.' />
            <Tabs defaultActiveKey="1" className={styles.tabs}>
                <TabPane tab="Member Invite" key="1">
                    <AmbassadorTab />
                </TabPane>
                <TabPane tab="Guests Invite" key="2">
                    <GuestInviteTab />
                </TabPane>
                <TabPane tab="Users" key="3">
                    <MembersTab />
                </TabPane>
                {authUser?.isManager &&
                    <TabPane tab="Settings" key="4">
                        <SettingsTab />
                    </TabPane>
                }
            </Tabs>
        </div>
    )
}

export const ControlPanelHeader = ({ title, description, size }: { title: string, size?: 'small' | 'large', description?: string }) => {
    return <div className={styles.header} style={size === 'small' ? { fontSize: 16, fontWeight: 600 } : {}}>
        <div>{title}</div>
        <div className={styles.description}>{description}</div>
    </div>
}

export default inject('sessionStore')(observer(ControlPanel));