import algoliasearch, { SearchClient, SearchIndex } from 'algoliasearch/lite';

class Algolia {
    searchClient: SearchClient;
    index: SearchIndex;

    constructor() {
        this.searchClient = algoliasearch('RH84BMIQU0', '8e1c50b744a3db1be4507bd497add89e');
        this.index = this.searchClient.initIndex('prod_ttn');
    }

    search = async (searchTerm: string) => {
        const results = await this.index.search(searchTerm, {
            hitsPerPage: 4,
        });

        return results.hits;
    }

}

export default Algolia;