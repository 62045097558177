import { Button, Modal, Popover, Tabs, Typography, Switch, message, Tooltip, Radio, Input, InputNumber, Form } from 'antd';
import { inject, observer } from 'mobx-react';
import React, { useEffect, useRef, useState } from 'react';
import { RightOutlined, LeftOutlined, FireOutlined, FireFilled, StarFilled, VideoCameraOutlined } from '@ant-design/icons'
import UserAvatar from '../../../../../../../common/components/avatar/UserAvatar';
import EventStore from '../../../../../../../store/EventStore';
import SessionStore from '../../../../../../../store/SessionStore';
import ViewStateStore from '../../../../../../../store/ViewStateStore';
import styles from './EventSider.module.css';
import _ from 'lodash';
import AttendeeModel from '../../../../../../../common/models/AttendeeModel';
import EventChat from './chat/EventChat';
import AttendeeInfo from '../attendeeInfo/AttendeeInfo';
import { RadioChangeEvent } from 'antd/lib/radio';
import NavigationPanel from './navigationPanel/NavigationPanel';
import EventSettingsModal from './settingsModal/EventSettingsModal';
import { isIPad13, isMobile, isTablet } from 'react-device-detect';
import PeopleTab from './peopleTab/PeopleTab';

const { Paragraph } = Typography;

interface Props {
    sessionStore?: SessionStore;
    eventStore?: EventStore;
    viewStateStore?: ViewStateStore;
    onEditEvent: () => void;
}

function EventSider({ sessionStore,
    eventStore,
    viewStateStore,
    onEditEvent,
}: Props) {

    const { authUser } = sessionStore!;
    const { openSider,
        onlineAttendees,
        inLounge,
        inBackstage,
        inRoundTables,
        currentUserLocation,
        newChatMessagesNotificaionVisible } = eventStore!;
    const [isBusy, setBusy] = useState(false);

    const onTabClicked = (activeKey: string, e: any) => {
        if (activeKey === '2') {
            eventStore?.setNewMessageNotificationVisible(false);
        }
    }

    return (
        <div className={`${styles.container} event-sider`}>
            <NavigationPanel className={openSider === 'none' ? styles.navigationPanel : ''} onEditEvent={onEditEvent} />

            {openSider !== 'none' &&
                <Tabs defaultActiveKey="1"
                    centered
                    className={styles.tabs}
                    onTabClick={onTabClicked}>
                    <Tabs.TabPane
                        tab={
                            <div className={styles.tabWithNotification}>
                                People
                                <div className={styles.onlineUsers}>
                                    <div className={styles.onlineBubble} />
                                    {onlineAttendees?.length} online
                                </div>
                            </div>
                        }
                        key="1">
                        <PeopleTab />
                    </Tabs.TabPane>
                    {currentUserLocation !== 'lounge' &&
                        <Tabs.TabPane
                            tab={
                                <div className={styles.tabWithNotification}>
                                    Live Chat
                                    <div className={styles.notification} style={{ display: newChatMessagesNotificaionVisible ? 'block' : 'none' }} />
                                </div>
                            } key="2">
                            <EventChat />
                        </Tabs.TabPane>
                    }
                </Tabs>
            }

            {/* {openSider !== 'none' &&
                <div className={styles.tabContainer}>
                    {openSider === 'people' && <PeopleTab />}
                    {openSider === 'people' && <EventChat />}
                </div>
            } */}

            {/* 
            {(authUser?.kapara || isTablet || isMobile) &&
                <div className={styles.handler} style={siderCollapsed && !isMobile ? { opacity: 1 } : {}} onClick={() => setSiderCollapsed(!siderCollapsed)}>
                    {siderCollapsed ? <LeftOutlined /> : <RightOutlined />}
                </div>
            } */}

        </div>
    )
}

export default inject('sessionStore', 'eventStore', 'membersStore', 'viewStateStore')(observer(EventSider));