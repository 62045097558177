import { message, notification } from 'antd';
import React, { useEffect, useState } from 'react';
import Lottie from 'react-lottie';
import Video, { LocalDataTrack, createLocalTracks } from 'twilio-video';
import { UserPublicData } from '../../models/UserModel';
import VideoButtonsStrip from './buttonsStrip/VideoButtonsStrip';
import ParticipantIntroBubble from './introBubble/ParticipantIntroBubble';
import Participant from './Participant';
import ParticipantInfo from './participantInfo/ParticipantInfo';
import loadingAnimation from './loading.json';
import styles from './VideoRoom.module.css'
import { inject, observer } from 'mobx-react';
import VideoRoomStore from '../../../store/VideoRoomStore';

interface Props {
    videoToken?: string,
    roomName: string,
    onLogout?: () => void;
    localUserInfo?: UserPublicData,
    participantsInfo?: UserPublicData[],
    videoRoomStore?: VideoRoomStore,
}

export type ParticipantData = {
    info?: UserPublicData,
    current: Video.Participant,
}

function VideoRoom({ videoToken, roomName, participantsInfo, onLogout, videoRoomStore }: Props) {
    const [participants, setParticipants] = useState(Array<ParticipantData>());
    const [localDataTrack, setLocalDataTrack] = useState(new LocalDataTrack());
    const { currentRoom, setCurrentRoom, clearCurrentRoom } = videoRoomStore!;

    useEffect(() => {
        if (!videoToken) {
            return;
        }

        createLocalTracks({
            video: true,
            audio: true,
        }).then(localTracks => {
            Video.connect(videoToken, {
                name: roomName,
                tracks: [...localTracks, localDataTrack]
            }).then(room => {
                setCurrentRoom(room);
                room.on('participantConnected', participantConnected);
                room.on('participantDisconnected', participantDisconnected);
                room.on('disconnected', (room: Video.Room) => {
                    console.log('Conversation disconnected.')
                    stopVideoTracks(room.localParticipant.videoTracks);
                    stopAudioTracks(room.localParticipant.audioTracks);
                });

                room.participants.forEach(participantConnected);
            });
        })

    }, [videoToken]);

    // useEffect(() => {
    //     setTimeout(() => {
    //         if (!currentRoom) {
    //             
    //             onEndConversation();
    //         }
    //     }, 15000);
    // }, [currentRoom]);

    useEffect(() => {
        return () => {
            clearCurrentRoom();
        }
    },[])

    const participantConnected = (participant: Video.Participant) => {
        const participantInfo = participantsInfo?.find(p => p.id === participant.identity);

        if (!participantInfo) {
            console.error(`Couldn't find participant info for ${participant.identity}`);
            return;
        }

        setParticipants(prevParticipants => [...prevParticipants, { info: participantInfo!, current: participant }]);
    };

    const participantDisconnected = (participant: Video.Participant) => {
        clearCurrentRoom();
        onLogout && onLogout();
    };
    


    const stopVideoTracks = (tracks: Map<String, Video.LocalVideoTrackPublication>) => {
        tracks.forEach(trackPublication => {
            trackPublication.track.stop();
            trackPublication.unpublish();
        });
    }
    const stopAudioTracks = (tracks: Map<string, Video.LocalAudioTrackPublication>) => {
        tracks.forEach(trackPublication => {
            trackPublication.track.stop();
            trackPublication.unpublish();
        });
    }

    const mapParticipant = (participant: Video.Participant): ParticipantData => {
        const info = participantsInfo?.find(p => p.id === participant.identity)
        return { info: info, current: participant }
    }

    const toggleAudio = (enabled: boolean) => {
        currentRoom?.localParticipant.audioTracks.forEach(publication => {
            enabled ? publication.track.disable() : publication.track.enable();

        });
    }
    const toggleVideo = (enabled: boolean) => {
        currentRoom?.localParticipant.videoTracks.forEach(publication => {
            enabled ? publication.track.disable() : publication.track.enable();
        });
    }

    const onEndConversation = () => {
        clearCurrentRoom();
        onLogout && onLogout();
    }

    const onSendMessage = (message: string) => {
        console.log('Message sent:' + message);
        localDataTrack.send(message);
    }

    if (participants.length === 0) {
        return <div className={styles.waitingContainer}>
            <div className={styles.loadingAnimation}>
                <Lottie options={lottieFile} />
            </div>
            Waiting for everyone to enter the room...
            <div onClick={onEndConversation} className={styles.cancelMeetingButton}>Cancel</div>
        </div>
    }

    return (
        <div className={styles.container}>
            <div className={styles.uptown}>
                <div className={styles.participantsContainer}>
                    {currentRoom && <Participant
                        isSelf={true}
                        key={currentRoom?.localParticipant.identity}
                        participant={mapParticipant(currentRoom?.localParticipant)}
                        onToggleAudio={toggleAudio}
                        onToggleVideo={toggleVideo}
                    />}

                    {participants.map(participant => <Participant key={participant.current.identity} participant={participant} />)}

                </div>
            </div>
            <div className={styles.downtown}>
                <VideoButtonsStrip onEndConversation={onEndConversation}
                    onSendMessage={onSendMessage}
                    className={styles.buttonsContainer} />
                <div className={styles.infoContainer}>
                    {
                        participants.map(participant => <ParticipantIntroBubble key={participant.current.identity} userInfo={participant.info!} />)
                    }
                </div>
            </div>


        </div>
    )
}

const lottieFile = {
    loop: true,
    autoplay: true,
    animationData: loadingAnimation,
    rendererSettings: {
        preserveAspectRatio: 'xMidYMid slice'
    }
};


export default inject('videoRoomStore')(observer(VideoRoom));