import { Button, Carousel, Form, Input, Rate } from "antd";
import { CarouselRef } from "antd/lib/carousel";
import { useForm } from "antd/lib/form/Form";
import { inject, observer } from "mobx-react";
import React, { useEffect, useRef, useState } from "react";
import { StarFilled } from '@ant-design/icons';
import EventModel from "../../../../../common/models/EventModel";
import EventsStore from "../../../../../store/EventsStore";
import SessionStore from "../../../../../store/SessionStore";
import styles from './EventFeedback.module.css';



interface Props {
    event: EventModel;
    eventsStore?: EventsStore;
    sessionStore?: SessionStore;
    initialRating?: number, 
    onFeedbackSubmitted?: () => void;
}

function EventFeedback({ event, eventsStore, sessionStore, onFeedbackSubmitted, initialRating }: Props) {
    const notesInputRef = useRef<any>(null);
    const [busy, setBusy] = useState(false);
    const [step, setStep] = useState(0);
    const [form] = useForm();
    const { authUser } = sessionStore!;

    useEffect(() => {
        if(initialRating) {
            onGeneralRatingChanged(initialRating);
        }
    },[initialRating]);

    const onGeneralRatingChanged = async (value: number) => {
        await eventsStore?.addFeedbackRating(event, value);
        setStep(prev => prev + 1);
    }

    const onNotesSubmitted = async (values: any) => {
        await eventsStore?.addFeedbackNotes(event.id!, values.notes);
        setStep(prev => prev + 1);
        onFeedbackSubmitted && onFeedbackSubmitted();
    }

    return (<div className={styles.container}>
        { step === 0 &&
            <div className={`${styles.phaseContainer} ${styles.ratePhase}`}>
                <div>
                    <div className={styles.header}>How's the meeting so far?</div>
                    <div className={styles.subHeader}>Tell us your overall feeling.</div>
                    <div className={styles.rate}>
                        <Rate onChange={onGeneralRatingChanged} allowHalf defaultValue={initialRating} />
                    </div>
                    <div className={styles.feedbackGuide}>
                      <StarFilled className={styles.starIcon}/> 
                      <span>Your feedback matters!</span>
                    </div>
                </div>
            </div>
        }
        { step === 1 &&
            <div className={styles.phaseContainer}>
                <div className={styles.header} style={{ fontSize: 18}}>Anything else we should do next time?</div>
                <Form className={styles.meetingNotesForm}
                    form={form}
                    onFinish={onNotesSubmitted}>
                    <Form.Item
                        name={'notes'}>
                        <Input.TextArea
                            ref={notesInputRef}
                            className={styles.notesTextarea}
                            rows={4} />
                    </Form.Item>
                    <Button size='large' type='primary' className={styles.notesDoneButton} htmlType={'submit'}>
                        Submit Feedback
                    </Button>
                </Form>
            </div>
        }
        { step === 2 &&
            <div className={styles.phaseContainer}>
                <div className={styles.header}>Thank you! 💪</div>
                <div className={styles.subHeader}>Your feedback helps us to be better.</div>
            </div>
        }
    </div>);
}


export default inject('eventsStore', 'sessionStore')(observer(EventFeedback));