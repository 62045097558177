import React, { useEffect, useState } from "react";
import { SwapOutlined, CheckCircleOutlined, CloseCircleOutlined, ClockCircleOutlined } from '@ant-design/icons';
import styles from './MeetingInfoModal.module.css';
import { Avatar, List, Modal, Tag, Timeline, Tooltip } from "antd";
import IntroRequestModel from "../../../../../common/models/IntroRequestModel";
import UserAvatar from "../../../../../common/components/avatar/UserAvatar";
import moment from "moment";
import _ from "lodash";
import MeetingModel, { MeetingParticipant } from "../../../../../common/models/MeetingModel";
import UserModel, { ConversationModel } from "../../../../../common/models/UserModel";
import Paragraph from "antd/lib/typography/Paragraph";
import { inject, observer } from "mobx-react";
import ConnectionsStore from "../../../../../store/ConnectionsStore";
import MembersStore from "../../../../../store/MembersStore";
import { LinkOutlined, CalendarOutlined } from '@ant-design/icons';


interface Props {
    visible: boolean;
    onCancel: () => void;
    meeting?: MeetingModel;
    connectionsStore?: ConnectionsStore;
    membersStore?: MembersStore;
}

type MeetingInfoItem = {
    participant: MeetingParticipant,
    conversation: ConversationModel
}

function MeetingInfoModal({ onCancel, visible, meeting, connectionsStore, membersStore }: Props) {
    const [items, setItems] = useState(Array<MeetingInfoItem>());

    useEffect(() => {
        if (meeting) {
            meeting.participants?.forEach(p => {
                connectionsStore?.getConversationById(p.id!, meeting.id!).then(conversation => {
                    setItems(prev => [...prev, { participant: p, conversation: conversation }]);
                });
            })
        } else {
            setItems([]);
        }

    }, [meeting])

    const onCancelClicked = () => {
        onCancel();
    }

    return (<Modal
        visible={visible}
        footer={null}
        width={700}
        title={'Meeting information'}
        className={styles.container}
        onCancel={onCancelClicked}>
        <div className={styles.content}>
            <div className={styles.detailsContainer}>
                <div className={styles.header}>Details</div>
                <div className={styles.subHeader}><CalendarOutlined /> When</div>
                <div className={styles.date}>
                    {moment.unix(meeting?.scheduledOn!).format('dddd, MMMM D, HH:mm z')}
                </div>
                <div className={styles.meetingLink}>
                <span className={styles.subHeader}><LinkOutlined /> Meeting Link</span>  <Paragraph className={styles.link} copyable={{ text: `${window.location.protocol}//${window.location.host}/meeting/${meeting?.id}`}}>{window.location.protocol}//{window.location.host}/meeting/{meeting?.id}</Paragraph>
                </div>
            </div>
            <div className={styles.header}>Participants</div>
            <List
                itemLayout="horizontal"
                dataSource={items}
                renderItem={item => (
                    <List.Item key={item.participant.id}
                        actions={[<a key="view-feedback">Feedback</a>]}
                    >
                        <List.Item.Meta
                            avatar={
                                <Avatar src={item.participant.user?.photoUrl} />
                            }
                            title={<div className={styles.userTitle} onClick={() => membersStore?.viewMember(item.participant.user)}>{item.participant.user?.firstName} {item.participant.user?.lastName}</div>}
                            description={`${UserModel.title(item.participant.user?.role, item.participant.user?.company)}`}
                        />
                        <div className={styles.itemContent}>
                            <Tag color={item.participant.attended ? 'success' : 'purple'}>{item.participant.attended ? 'Showed up' : `Didn't show up`}</Tag>
                        </div>
                    </List.Item>
                )}
            />
        </div>
    </Modal>);
}


export default inject('connectionsStore', 'membersStore')(observer(MeetingInfoModal));