import React, { ChangeEvent, ChangeEventHandler, useEffect, useRef, useState } from "react";
import { inject, observer } from "mobx-react";
import { UserOutlined, ArrowRightOutlined, DownOutlined, EyeOutlined } from '@ant-design/icons';
import styles from './NewIntroModal.module.css';
import { Alert, Avatar, Button, Drawer, Dropdown, Input, Menu, message, Modal } from "antd";
import MembersStore from "../../../../../store/MembersStore";
import UserAvatar from "../../../../../common/components/avatar/UserAvatar";
import UserModel from "../../../../../common/models/UserModel";
import MemberSearchPopover from "../../../../../common/components/memberSearchPopover/MemberSearchPopover";
import SessionStore from "../../../../../store/SessionStore";
import Flag from "../../../../../common/components/flag/Flag";
import ConnectionsStore from "../../../../../store/ConnectionsStore";
import IntroRequestModel from "../../../../../common/models/IntroRequestModel";
const FILL_TEMPLATE = '[FILL HERE]'


interface Props {
    visible: boolean;
    membersStore?: MembersStore;
    sessionStore?: SessionStore;
    connectionsStore?: ConnectionsStore;
    viewedIntro?: IntroRequestModel;
    onCancel: () => void;
    onConfirm: (requsted: UserModel, requester: UserModel, selectedIntroducer: UserModel, purpose: string, isNew: boolean) => Promise<void>;
}

function NewIntroModal({ onCancel, onConfirm, visible, membersStore, sessionStore, connectionsStore, viewedIntro }: Props) {
    const { authUser, loggedIn } = sessionStore!
    const [busy, setBusy] = useState(false);
    const [requestedUser, setRequestedUser] = useState<UserModel>();
    const [requesterUser, setRequesterUser] = useState<UserModel>();
    const [managers, setManagers] = useState<UserModel[]>();
    const [selectedIntroducer, setSelectedIntroducer] = useState<UserModel>();
    const notesInputRef = useRef<any>(null);
    const [purposeValue, setPurposeValue] = useState('');
    const [previewVisible, showPreview] = useState(false);
    const [previewContent, setPreviewContent] = useState<{ subject: string, html: string }>();

    useEffect(() => {
        if (loggedIn) {
            membersStore?.fetchManagers().then(result => {
                setManagers(result);
                setSelectedIntroducer(result.find(u => u.id === authUser?.id))
            });
        }
    }, [loggedIn])

    useEffect(() => {
        if(viewedIntro) {
            onIntroToApprove(viewedIntro)
        } else {
            setRequestedUser(undefined);
            setRequesterUser(undefined);
            setPurposeValue('');
        }
    },[viewedIntro])

    const onIntroToApprove = async (intro: IntroRequestModel) => {
        const requested = await membersStore?.fetchById(viewedIntro?.requested?.id!);
        const requester = await membersStore?.fetchById(viewedIntro?.requester?.id!);
        setRequestedUser(requested);
        setRequesterUser(requester);
        setPurposeValue(intro.purpose!);
    }

    const onConfirmClicked = async () => {
        if (!requestedUser || !requesterUser) {
            message.warning(`Select members for introduction.`)
            return;
        }

        if (!selectedIntroducer) {
            message.warning(`Please select an introducer`)
            return;
        }

        // if (purposeValue.length === 0 || purposeValue.indexOf(FILL_TEMPLATE)) {
        //     message.warning(`Please fill introduction purpose`);
        //     return;
        // }

        setBusy(true);
        await onConfirm(requestedUser, requesterUser, selectedIntroducer!, purposeValue, viewedIntro ? false : true);
        setBusy(false);
    }

    const onCancelClicked = () => {
        onCancel();
    }

    const onRequesterMemberSelected = (user: UserModel) => {
        setRequesterUser(user);
        // setPurposeValue(`${user.firstName} would like to discuss about ${FILL_TEMPLATE}`);
    }

    const onRequestedMemberSelected = (user: UserModel) => {
        setRequestedUser(user);
    }

    const onIntroducerChanged = (user: UserModel) => {
        setSelectedIntroducer(user);
    }

    const onPurposeValueChanged = (event: ChangeEvent<HTMLTextAreaElement>) => {
        setPurposeValue(event.target.value);
    }

    const preview = async () => {
        if (!requesterUser || !requestedUser || !selectedIntroducer) {
            message.warning(`Introduction participants haven't been selected yet.`)
            return;
        }
        setBusy(true);
        const result = await connectionsStore?.getIntroductionPreview(requestedUser!.toPublicData(), requesterUser!.toPublicData(), selectedIntroducer!, purposeValue);
        setBusy(false);
        showPreview(true);
        setPreviewContent(result);
    }

    return (<Modal
        centered
        visible={visible}
        onCancel={onCancelClicked}
        className={styles.container}
        footer={[
            <Button key="back" onClick={onCancel}>
                Cancel
            </Button>,
            <Button icon={<EyeOutlined />} className={styles.previewButton} key="preview" loading={busy} onClick={preview}>
                Preview
            </Button>,
            <Button
                key="submit"
                type="primary"
                loading={busy}
                onClick={onConfirmClicked}
            >
                {viewedIntro ? 'Approve Intro' : 'Create Intro'}
            </Button>,
        ]}>
        <div className={styles.content}>
            <div className={styles.choosingContainer}>
                <div className={styles.choosingBoxContainer}>
                    <div className={styles.header}>Introduce...</div>
                    <MemberChoosingBox blockChange={viewedIntro !== undefined} initialUser={requesterUser} onMemberSelected={onRequesterMemberSelected} />
                </div>
                <div className={styles.arrow}>
                    <ArrowRightOutlined />
                </div>
                <div className={styles.choosingBoxContainer}>
                    <div className={styles.header}>To...</div>
                    <MemberChoosingBox blockChange={viewedIntro !== undefined} initialUser={requestedUser} onMemberSelected={onRequestedMemberSelected} />
                </div>
            </div>
            {requestedUser &&
                <div className={styles.info}>
                    <Alert className={styles.alert} message={<span>
                        A introduction request will be sent to <b>{requestedUser.firstName}</b> for approval.
                    </span>} type="info" showIcon />
                </div>
            }
            <div className={styles.introducer}>
                <div className={styles.header}>Introduction Purpose</div>
                <div className={styles.description}>We'll add this text to the introduction request email as is.</div>
                <Input.TextArea
                    onChange={onPurposeValueChanged}
                    ref={notesInputRef}
                    value={purposeValue}
                    bordered={true}
                    placeholder='Add introduction purpose here'
                    rows={3}
                    className={styles.notesInput} />

            </div>
            <div className={styles.introducer}>
                <div className={styles.header}>Introducer</div>
                <div className={styles.description}>The introducer will be presented as the one who initiated this introduction.</div>
                <Dropdown overlay={
                    <Menu>
                        {managers?.map(manager =>
                            <Menu.Item onClick={() => onIntroducerChanged(manager)} key={manager.id}>
                                <div className={styles.menuButton}>
                                    <Avatar src={manager?.photoUrl} size={24} />
                                    <div className={styles.introducerName}>{manager?.fullName}</div>
                                    {manager?.id === authUser?.id &&
                                        <div className={styles.youBadge}>YOU</div>
                                    }
                                </div>
                            </Menu.Item>

                        )}
                    </Menu>
                }
                    trigger={['click']}>
                    <div className={styles.introducerButton}>
                        <Avatar src={selectedIntroducer?.photoUrl} size={24} />
                        <div className={styles.introducerName}>{selectedIntroducer?.fullName}</div>
                        {selectedIntroducer?.id === authUser?.id &&
                            <div className={styles.youBadge}>YOU</div>}
                        <DownOutlined className={styles.downIcon} />
                    </div>
                </Dropdown>
            </div>
        </div>
        <Drawer
            title={'Preview Introduction'}
            placement="right"
            width={640}
            closable={true}
            onClose={() => showPreview(false)}
            visible={previewVisible}
        >
            <div>
                <div className={styles.previewHeader}>Subject:</div>
                <div className={styles.subject}>{previewContent?.subject}</div>
            </div>
            <div style={{ marginTop: 30 }} className={styles.previewHeader}>Email Content:</div>
            <div className={styles.emailContent} dangerouslySetInnerHTML={{ __html: previewContent?.html! }} />
        </Drawer>
    </Modal>);
}

interface MemberChoosingBoxProps {
    onMemberSelected: (user: UserModel) => void;
    initialUser?: UserModel;
    blockChange: boolean,
}

function MemberChoosingBox({ onMemberSelected, initialUser, blockChange }: MemberChoosingBoxProps) {
    const [user, setUser] = useState<UserModel>();
    const [searchVisible, setSearchVisible] = useState(false);

    useEffect(() => {
        if(initialUser){
            setUser(initialUser);
        } else {
            setUser(undefined)
        }
    },[initialUser])

    const onSelected = (users: UserModel[]) => {
        if (users.length > 0) {
            setUser(users[0]);
            onMemberSelected(users[0])
            setSearchVisible(false);
        }
    }

    return <MemberSearchPopover visible={searchVisible && !blockChange}
        onVisibiltyChanged={(visible) => setSearchVisible(visible)}
        onSelected={onSelected}>
        {user ?
            <div className={`${styles.memberChoosingBoxContainer} member-box`}>
                <UserAvatar userImage={user.photoUrl} companyImage={user.company?.logoUrl} />
                <div className={styles.texts}>
                    <div className={styles.choosingBoxMemberName}>{user.fullName}</div>
                    <div className={styles.choosingBoxSubHeader}>{user.company?.name}</div>
                    <div className={styles.choosingBoxLocation}>
                        {user.location &&
                            <div className={styles.location}>
                                <Flag userLocation={user.location} />
                                <span style={{ marginLeft: 4 }}>{user.location.country}</span>
                            </div>
                        }
                        <div style={{ marginLeft: 4 }}> ({user.timezone})</div>
                    </div>
                </div>
            </div>
            :

            <div className={`${styles.memberChoosingBoxContainer} ${!user && styles.selectBox}`}>
                <Avatar style={{ flexShrink: 0 }} icon={<UserOutlined />} size={64} />
                <div className={styles.texts}>
                    <div className={styles.choosingBoxHeader}>Select a member</div>
                    {/* <div className={styles.choosingBoxSubHeader}>This member will have to accept this introduction.</div> */}
                </div>
            </div>
        }
    </MemberSearchPopover>

}


export default inject('membersStore', 'sessionStore', 'connectionsStore')(observer(NewIntroModal));