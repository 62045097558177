import React, { ReactNode, useEffect, useState } from "react";
import { inject, observer } from "mobx-react";
import { ArrowUpOutlined, ArrowDownOutlined, DownOutlined } from '@ant-design/icons';
import styles from './MetricsPage.module.css';
import SessionStore from "../../../../store/SessionStore";
import ControlPanelStore from "../../../../store/ControlPanelStore";
import Card from "../../../../common/components/card/Card";
import SectionHeader from "../../../../common/components/sectionHeader/SectionHeader";
import { Button, Dropdown, Menu, Radio, Select } from "antd";
import { useHistory } from "react-router";
import { ResponsiveLine } from '@nivo/line'
import ViewStateStore from "../../../../store/ViewStateStore";
import Chart from "react-apexcharts";
import moment from "moment";
import { RadioChangeEvent } from "antd/lib/radio";
import { ResponsivePie } from '@nivo/pie'
import _ from "lodash";
import StringUtils from "../../../../common/utils/StringUtils";
import Loader from "../../../../common/components/loader/Loader";
import SettingsStore from "../../../../store/SettingsStore";


interface Props {
    sessionStore: SessionStore,
    controlPanelStore: ControlPanelStore,
    viewStateStore: ViewStateStore;
    settingsStore: SettingsStore;
}

function MetricsPage({ sessionStore, controlPanelStore, viewStateStore, settingsStore }: Props) {
    const { loggedIn, authUser } = sessionStore;
    const { allUsers, members, payingMembers, recentStats, membersChange, payingMembersChange } = controlPanelStore;
    const history = useHistory();
    const [selectedTimeFrame, setSelectedTimeframe] = useState(7);
    const [membersChartData, setMembersChartData] = useState<any>();
    const [membersChartOptions, setMembersChartOptions] = useState<any>();
    const [filterValue, setFilterValue] = useState<'type' | 'sector'>('type');
    const [selectedData, setSelectedData] = useState<any[]>([]);
    const [typeChartData, setTypeChartData] = useState<any[]>([]);
    const [sectorChartData, setSectorChartData] = useState<any[]>([]);
    const [genderChartData, setGenderChartData] = useState<any[]>([]);
    const [countryChartData, setCountryChartData] = useState<any[]>([]);

    useEffect(() => {
        if (loggedIn) {
            if (!authUser?.isManager) {
                history.push('/')
                return;
            }

            setSelectedTimeframe(7);
            controlPanelStore.fetchAllMembers();
        }
    }, [loggedIn]);

    useEffect(() => {
        controlPanelStore.fetchStats(selectedTimeFrame);
    }, [selectedTimeFrame])

    useEffect(() => {
        if (!recentStats || recentStats.length === 0) {
            return;
        }

        setMembersChartData([{
            name: "Members",
            data: recentStats.map(s => s.totalMembers)
        }]);

        if (settingsStore.membership?.enabled) {
            setMembersChartData((prev: any) => [...prev, {
                name: "Paying Members",
                data: recentStats.map(s => s.totalPaidMembers)
            }]);
        }

        setMembersChartOptions({
            xaxis: {
                categories: recentStats.map(s => moment.unix(s.date.seconds).format('MMMM D')),
            }
        })

    }, [recentStats])

    useEffect(() => {
        updateCharts();
    }, [members])

    const updateCharts = async () => {
        const byTypeGroups = _.groupBy(members, a => a.type);
        const byTypeData = [];
        for (const type in byTypeGroups) {
            const group = byTypeGroups[type];
            byTypeData.push({
                id: type,
                label: StringUtils.percentage(group.length, members.length),
                value: group.length,

            })
        }

        setTypeChartData(byTypeData);

        const byGenderGroups = _.groupBy(members, a => a.gender);
        const byGenderData = [];
        for (const gender in byGenderGroups) {
            const group = byGenderGroups[gender];
            byGenderData.push({
                id: gender,
                label: StringUtils.percentage(group.length, members.length),
                value: group.length,
            })
        }

        setGenderChartData(byGenderData);

        const bySectorGroups = _.groupBy(_.flatMap(members, a => a.primarySectors), sector => sector);
        const bySectorData = [];
        for (const groupKey in bySectorGroups) {
            const group = bySectorGroups[groupKey];
            bySectorData.push({
                id: groupKey,
                label: StringUtils.percentage(group.length, members.length),
                value: group.length,
            })
        }

        setSectorChartData(bySectorData);

        const byCountryGroups = _.groupBy(members, a => a.location?.country);
        const byCountryData = [];
        for (const groupKey in byCountryGroups) {
            const group = byCountryGroups[groupKey];
            byCountryData.push({
                id: groupKey,
                label: StringUtils.percentage(group.length, members.length),
                value: group.length,
            })
        }

        setCountryChartData(byCountryData);
    }

    const onTimeframeChanged = (value: number) => {
        setSelectedTimeframe(value);
    }

    const onFilterChanged = (e: RadioChangeEvent) => {
        const value = e.target.value;
        setFilterValue(value);
    }


    return (<div className={styles.container}>
        <SectionHeader title={'Metrics'} subTitle={'Learn more about members and trends in your club.'} />
        <div className={styles.content}>
            <Dropdown overlay={<Menu>
                <Menu.Item key="1" onClick={() => onTimeframeChanged(7)}>
                    Last 7 days
                </Menu.Item>
                <Menu.Item key="2" onClick={() => onTimeframeChanged(30)}>
                    Last 30 days
                </Menu.Item>
            </Menu>}>
                <Button type={'text'} className={styles.timeframeButton}>
                    Last {selectedTimeFrame} days <DownOutlined />
                </Button>
            </Dropdown>
            <div className={styles.topSection}>
                <div className={styles.left}>
                    <MetricsCard title={'Total Members'}
                        number={members.length}
                        secondaryNumber={membersChange}
                        positive={membersChange > 0} />
                    {settingsStore.membership?.enabled &&
                        <MetricsCard title={'Active Memberships'}
                            number={payingMembers.length}
                            secondaryNumber={payingMembersChange}
                            positive={payingMembersChange > 0} />
                    }
                </div>

                <Card className={styles.membersChart}>
                    {membersChartData &&
                        <Chart height={300} options={{
                            chart: {
                                height: 300,
                                type: 'line',
                                zoom: {
                                    enabled: false
                                }
                            },
                            stroke: {
                                curve: 'straight',
                                colors: [viewStateStore.primaryColor, '#00e496']
                            },
                            title: {
                                text: 'Trend',
                                align: 'left'
                            },
                            grid: {
                                row: {
                                    colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
                                    opacity: 0.5
                                },
                            },
                            ...membersChartOptions
                        }} series={membersChartData} type="line" />
                    }
                </Card>
            </div>
            <div className={styles.secondStrip}>
                <div className={styles.subHeader}>Attributes</div>
                <div className={styles.grid}>
                    <Card>
                        <div className={styles.chart}>
                            <div className={styles.filterChartHeader}>
                                <div >Type</div>
                            </div>
                            <div className={styles.chartContainer}>
                                {typeChartData.length === 0 ?
                                    <Loader size='large' />
                                    :
                                    <ResponsivePie
                                        data={typeChartData}
                                        margin={{ top: 40, right: 60, bottom: 30, left: 60 }}
                                        innerRadius={0.5}
                                        padAngle={0.7}
                                        colors={{ scheme: 'orange_red' }}
                                        cornerRadius={3}
                                        activeOuterRadiusOffset={8}
                                        arcLinkLabelsDiagonalLength={9}
                                        arcLinkLabelsStraightLength={9}
                                        borderWidth={1}
                                        borderColor={{ from: 'color', modifiers: [['darker', 0.2]] }}
                                        arcLinkLabelsSkipAngle={10}
                                        arcLinkLabelsTextColor="#333333"
                                        arcLinkLabelsThickness={2}
                                        arcLinkLabelsColor={{ from: 'color' }}
                                        arcLabelsSkipAngle={10}
                                        arcLabelsTextColor={{ from: 'color', modifiers: [['darker', 2]] }}
                                        arcLabel="label"
                                        arcLinkLabel="id"
                                    />
                                }
                            </div>
                        </div>
                    </Card>
                    <Card>
                        <div className={styles.chart}>
                            <div className={styles.filterChartHeader}>
                                <div >Sector</div>
                            </div>
                            <div className={styles.chartContainer}>
                                {sectorChartData.length === 0 ?
                                    <Loader size='large' />
                                    :
                                    <ResponsivePie
                                        data={sectorChartData}
                                        margin={{ top: 40, right: 80, bottom: 30, left: 80 }}
                                        innerRadius={0.5}
                                        padAngle={0.7}
                                        colors={{ scheme: 'pastel1' }}
                                        cornerRadius={3}
                                        activeOuterRadiusOffset={8}
                                        arcLinkLabelsDiagonalLength={9}
                                        arcLinkLabelsStraightLength={9}
                                        borderWidth={1}
                                        borderColor={{ from: 'color', modifiers: [['darker', 0.2]] }}
                                        arcLinkLabelsSkipAngle={10}
                                        arcLinkLabelsTextColor="#333333"
                                        arcLinkLabelsThickness={2}
                                        arcLinkLabelsColor={{ from: 'color' }}
                                        arcLabelsSkipAngle={10}
                                        arcLabelsTextColor={{ from: 'color', modifiers: [['darker', 2]] }}
                                        arcLabel="label"
                                        arcLinkLabel="id"
                                    />
                                }
                            </div>

                        </div>
                    </Card>
                    <Card>
                        <div className={styles.chart}>
                            <div className={styles.filterChartHeader}>
                                <div >Country</div>
                            </div>
                            <div className={styles.chartContainer}>
                                {countryChartData.length === 0 ?
                                    <Loader size='large' />
                                    :
                                    <ResponsivePie
                                        data={countryChartData}
                                        margin={{ top: 40, right: 60, bottom: 30, left: 60 }}
                                        innerRadius={0.5}
                                        padAngle={0.7}
                                        colors={{ scheme: 'nivo' }}
                                        cornerRadius={3}
                                        activeOuterRadiusOffset={8}
                                        arcLinkLabelsDiagonalLength={9}
                                        arcLinkLabelsStraightLength={9}
                                        borderWidth={1}
                                        borderColor={{ from: 'color', modifiers: [['darker', 0.2]] }}
                                        arcLinkLabelsSkipAngle={10}
                                        arcLinkLabelsTextColor="#333333"
                                        arcLinkLabelsThickness={2}
                                        arcLinkLabelsColor={{ from: 'color' }}
                                        arcLabelsSkipAngle={10}
                                        arcLabelsTextColor={{ from: 'color', modifiers: [['darker', 2]] }}
                                        arcLabel="label"
                                        arcLinkLabel="id"
                                    />
                                }
                            </div>
                        </div>
                    </Card>
                    <Card>
                        <div className={styles.chart}>
                            <div className={styles.filterChartHeader}>
                                <div >Gender</div>
                            </div>
                            <div className={styles.chartContainer}>
                                {genderChartData.length === 0 ?
                                    <Loader size='large' />
                                    :
                                    <ResponsivePie
                                        data={genderChartData}
                                        margin={{ top: 40, right: 60, bottom: 30, left: 60 }}
                                        innerRadius={0.5}
                                        padAngle={0.7}
                                        colors={{ scheme: 'blue_purple' }}
                                        cornerRadius={3}
                                        activeOuterRadiusOffset={8}
                                        arcLinkLabelsDiagonalLength={9}
                                        arcLinkLabelsStraightLength={9}
                                        borderWidth={1}
                                        borderColor={{ from: 'color', modifiers: [['darker', 0.2]] }}
                                        arcLinkLabelsSkipAngle={10}
                                        arcLinkLabelsTextColor="#333333"
                                        arcLinkLabelsThickness={2}
                                        arcLinkLabelsColor={{ from: 'color' }}
                                        arcLabelsSkipAngle={10}
                                        arcLabelsTextColor={{ from: 'color', modifiers: [['darker', 2]] }}
                                        arcLabel="label"
                                        arcLinkLabel="id"
                                    />
                                }
                            </div>
                        </div>
                    </Card>
                </div>

            </div>
        </div>
    </div>);
}

interface MetricsCardProps {
    title: string,
    subTitle?: string,
    number: number | string | ReactNode,
    secondaryNumber?: number | string | ReactNode,
    positive?: boolean,
}

const MetricsCard = ({ title, subTitle, number, secondaryNumber, positive }: MetricsCardProps) => {

    return <Card className={styles.metricsCardContainer}>
        <div className={styles.metricsTitle}>
            {title}
        </div>
        <div className={styles.metricsNumberContainer}>
            <div className={styles.metricsNumber}>
                {number ? number : <Loader size='small' />}
            </div>
            {subTitle &&
                <div className={styles.subTitle}>
                    {subTitle}
                </div>
            }
            {secondaryNumber !== 0 &&
                <div className={`${styles.bubble} ${positive ? styles.greenBubble : styles.redBubble}`}>
                    {positive ? <ArrowUpOutlined className={styles.icon} /> : <ArrowDownOutlined className={styles.iconRed} />} {secondaryNumber}
                </div>
            }
        </div>
    </Card>
}

export default inject('sessionStore', 'controlPanelStore', 'viewStateStore', 'settingsStore')(observer(MetricsPage));

