import React from "react";
import { isWindows } from "react-device-detect";
import { Location } from "../../models/UserModel";
// import { inject, observer } from "mobx-react";
// import { } from '@ant-design/icons';
import styles from './Flag.module.css';
const flag = require('country-code-emoji');

interface Props {
    userLocation: Location,
}

function Flag({ userLocation }: Props) {
    return (<div className={styles.location}>
        {!isWindows ?
            <span>
                {flag(userLocation.countryCode)}
            </span>
            :
            <span>
                {userLocation.flagIcon && <img src={userLocation.flagIcon} />}
            </span>
        }
    </div>);
}


export default Flag;