
import { Avatar, Button, Form, Input, message, Modal, Select, Slider } from 'antd';
import _ from 'lodash';
import { inject, observer } from 'mobx-react';
import React, { useEffect, useState } from 'react';
import AttendeeModel from '../../../../../../../../../common/models/AttendeeModel';
import RoundTableModel from '../../../../../../../../../common/models/RoundTableModel';
import EventStore from '../../../../../../../../../store/EventStore';
import styles from './AddOrEditRoundTableModal.module.css';

interface Props {
    visible: boolean;
    eventStore?: EventStore;
    roundTable?: RoundTableModel;
    onConfirm: (title: string, maxSeats: number, preselectedAttendees: string[], roundTableId?: string) => Promise<void>;
    onCancel: () => void;
    onDelete?: (roundTable?: RoundTableModel) => Promise<void>;
}

function AddOrEditRoundTableModal({ visible, onCancel, onConfirm, onDelete, roundTable, eventStore }: Props) {
    const [form] = Form.useForm();
    const [isBusy, setBusy] = useState(false);
    const [selectedMembers, setSelectedMembers] = useState(Array<AttendeeModel>());
    const { attendees } = eventStore!

    useEffect(() => {
        if (roundTable?.preselectedAttendees) {
            const preselected = _.intersectionWith(attendees, roundTable.preselectedAttendees, (a, b) => a.id == b);
            setSelectedMembers(preselected);
            form.setFieldsValue({
                preselectedAttendees: preselected.map(s => s.user?.fullName)
            })
        }

    }, [roundTable?.preselectedAttendees])

    const onConfirmClicked = () => {
        setBusy(true);
        form.validateFields()
            .then(async (values: any) => {
                const { title, maxSeats } = values;
                await onConfirm(title, maxSeats, selectedMembers.map(m => m.id!), roundTable?.id);
                form.resetFields();
                setBusy(false);
            })
            .catch(info => {
                console.log('Validate Failed:', info);
                setBusy(false);
            });
    }

    const onSelect = (value: string, option: any) => {
        const attendee = option.extra;
        setSelectedMembers([...selectedMembers, attendee]);
    }

    const onDeselect = (value: string, option: any) => {
        const attendee = option.extra;
        setSelectedMembers([...selectedMembers.filter(m => m.id !== attendee.id)]);
    }

    const onClear = () => {
        setSelectedMembers([]);
    }

    const onDeleteClicked = () => {
        setBusy(true);
        onDelete && onDelete(roundTable);
        setBusy(false);
    }

    return (
        <Modal
            visible={visible}
            title={roundTable ? 'Edit table' : 'Add new table'}
            okText={roundTable ? 'Update' : 'Add Table'}
            className={'edit-round-table-modal'}
            cancelText="Cancel"
            confirmLoading={isBusy}
            onCancel={onCancel}
            onOk={onConfirmClicked}>
            <Form
                form={form}
                initialValues={{
                    title: roundTable?.title,
                    maxSeats: roundTable?.maxSeats || 2,
                    preselectedAttendees: roundTable?.preselectedAttendees,
                }}
                layout="vertical"
                name="add-round-table-form">
                <Form.Item
                    name="title"
                    label="Title">
                    <Input placeholder='Optinal' />
                </Form.Item>
                <Form.Item
                    name="maxSeats"
                    label="Number of Seats"
                    style={{ maxWidth: 240 }}>
                    <Slider defaultValue={2} max={8} min={2} tooltipVisible tooltipPlacement={'bottom'} />
                </Form.Item>
                <Form.Item
                    name="preselectedAttendees"
                    style={{ marginTop: 54 }}
                    label="Preselected Attendees"
                    tooltip={'Select specific attendees who can join this table.'}>

                    <Select
                        mode="multiple"
                        allowClear
                        size={'large'}  
                        onClear={onClear}
                        onSelect={onSelect}
                        onDeselect={onDeselect}
                        className={styles.userSelect}>
                        {
                            attendees?.map(attendee => {
                                return <Select.Option value={attendee.user?.fullName || ''} extra={attendee} key={attendee.id}>
                                    <div className={styles.attendeeOption}>
                                        <Avatar className={styles.attendeeAvatar} src={attendee.user?.photoUrl} />
                                        <div className={styles.attendeeName}>{attendee.user?.fullName}</div>
                                    </div>
                                </Select.Option>

                            })
                        }

                    </Select>
                </Form.Item>
                {roundTable &&
                    <Form.Item
                        style={{ marginTop: 40 }}
                        name="delete">
                        <Button loading={isBusy}
                            size={'large'}
                            type='primary'
                            onClick={onDeleteClicked}
                            danger={true}
                            disabled={roundTable?.participants && roundTable?.participants.length > 0}>
                            Delete Table
                    </Button>
                    </Form.Item>
                }

            </Form>
        </Modal>)
}

export default inject('eventStore')(observer(AddOrEditRoundTableModal));