
import { AutoComplete, Avatar, Button, Form, Input, InputNumber, message, Modal, Radio, Select, Switch } from 'antd';
import { inject, observer } from 'mobx-react';
import React, { useEffect, useState } from 'react';
import Card from '../../../../common/components/card/Card';
import ImageUpload from '../../../../common/components/imageUpload/ImageUpload';
import UserModel from '../../../../common/models/UserModel';
import { sleepAsync } from '../../../../common/utils/Utils';
import Algolia from '../../../../services/Algolia';
import ControlPanelStore from '../../../../store/ControlPanelStore';
import MembersStore from '../../../../store/MembersStore';
import OnboardingStore from '../../../../store/OnboardingStore';
import SessionStore from '../../../../store/SessionStore';
import SettingsStore from '../../../../store/SettingsStore';
import AddCompanyDrawer from '../../../onboarding/components/addCompanyDrawer/AddCompanyDrawer';
import CompanyBox from '../../../onboarding/components/companyBox/CompanyBox';
import ProfilePhoto from '../../../onboarding/components/profilePhoto/ProfilePhoto';
import styles from './EditUserModal.module.css';
const { Option } = AutoComplete;

interface Props {
    userModel: UserModel;
    controlPanelStore?: ControlPanelStore;
    sessionStore?: SessionStore;
    membersStore?: MembersStore;
    onboardingStore?: OnboardingStore;
    settingsStore?: SettingsStore;
    visible: boolean;
    algolia?: Algolia;
    onFinish?: () => void;
    onCancel: () => void;
}

function EditUserModal({ visible, onCancel, userModel, algolia, controlPanelStore, sessionStore, membersStore, onFinish, onboardingStore, settingsStore }: Props) {
    const [form] = Form.useForm();
    const { authUser } = sessionStore!;
    const [hits, setHits] = useState(Array<any>());
    const [companyInputValue, setCompanyInputValue] = useState('');
    const [isBusy, setIsBusy] = useState(false);
    const [referrerUser, setReferrerUser] = useState<UserModel>();
    const [profilePhotoUrl, setProfilePhotoUrl] = useState(userModel.photoUrl);
    const { setSelectedCompany, selectedCompany } = onboardingStore!;

    useEffect(() => {
        if (userModel.company) {
            if (userModel.company?.id) {
                setSelectedCompany({
                    name: userModel.company?.name,
                    logo: { url: userModel.company?.logoUrl },
                    objectID: userModel.company?.id,
                    'short-description': userModel.company?.shortDescription,
                    slug: userModel.company?.slug,
                })
            } else if (userModel.company?.name) {
                form.setFieldsValue({
                    companySelect: userModel.company.name,
                })
            }
        }

        let status = ''
        if (userModel.leadersMember) {
            status = 'leader';
        }
        if (userModel.guest) {
            status = 'guest'
        } if (userModel.staff) {
            status = 'staff'
        }

        form.setFieldsValue({
            status: status,
            eventCredits: userModel.membership?.credits?.events,
            introCredits: userModel.membership?.credits?.intros,
        })

        if (userModel.referrerUser) {
            membersStore?.fetchById(userModel.referrerUser).then(user => {
                setReferrerUser(user);
            });
        }


    }, []);

    const onProfileUploadFinish = (imageUrl: any) => {
        setProfilePhotoUrl(imageUrl);
    }

    const onCompanySearch = async (searchValue: string) => {
        if (searchValue.length < 3) {
            return;
        }

        const results: any = await algolia?.search(searchValue);
        results.push({ type: 'add' });
        setHits(results || []);
    }

    const addNewCompany = () => {
        onboardingStore?.addCompanyDrawer(true);
    }

    const onCompanySelected = async (value: string, option: any) => {
        if (!option.extra) {
            form.setFieldsValue({ companySelect: companyInputValue });
            return;
        }

        const company = option.extra;
        setSelectedCompany(company);
    }

    const onCompanyValueChange = (value: string) => {
        setCompanyInputValue(value);
    }

    const onCompanyUnlink = () => {
        setSelectedCompany(null);
    }

    const onConfirm = () => {
        form.validateFields()
            .then(async (values: any) => {
                setIsBusy(true);
                const { firstName, lastName, bio, role, isTest, status, isManager, email, eventCredits, introCredits } = values;
                let userStatusArgs: any;
                if (status === 'leader') {
                    userStatusArgs = {
                        leadersMember: true,
                        guest: false,
                        staff: false,
                    }
                }
                if (status === 'staff') {
                    userStatusArgs = {
                        leadersMember: true,
                        guest: false,
                        staff: true,
                    }
                }
                if (status === 'guest') {
                    userStatusArgs = {
                        leadersMember: false,
                        guest: true,
                        staff: false,
                    }
                }

                let membershipArgs: any = {};
                if (settingsStore?.membership?.enabled) {
                    membershipArgs = {
                        membership: {
                            credits: {
                                events: eventCredits,
                                intros: introCredits,
                            }
                        }
                    }
                }

                await controlPanelStore?.updateUser(userModel.id!, {
                    firstName: firstName,
                    lastName: lastName,
                    bio: bio,
                    role: role,
                    photoUrl: profilePhotoUrl,
                    manager: isManager,
                    test: isTest,
                    email: email,
                    company: selectedCompany ? {
                        id: selectedCompany.objectID,
                        name: selectedCompany.name,
                        shortDescription: selectedCompany['short-description'],
                        slug: selectedCompany.slug,
                        logoUrl: selectedCompany.logo?.url,
                    } : companyInputValue ? {
                        id: undefined,
                        shortDescription: undefined,
                        slug: undefined,
                        logoUrl: undefined,
                        name: companyInputValue,

                    } : undefined,
                    ...userStatusArgs,
                    ...membershipArgs,
                })
                await sleepAsync(1500);
                setIsBusy(false);
                onFinish && onFinish();
            })
            .catch(info => {
                console.log('Validate Failed:', info);
            });
    }

    return (
        <Modal
            visible={visible}
            centered
            title="Edit user"
            className='edit-user-modal'
            okText="Update"
            bodyStyle={{ height: '600px', overflowY: 'auto' }}
            cancelText="Cancel"
            onCancel={onCancel}
            confirmLoading={isBusy}
            onOk={onConfirm}
        >
            <Form
                style={{ width: '100%' }}
                form={form}
                className='profile'
                name="profile"
                layout="vertical"
                initialValues={{
                    firstName: userModel.firstName,
                    lastName: userModel.lastName,
                    role: userModel.role,
                    bio: userModel.bio,
                    primarySectors: userModel.primarySectors,
                    secondarySectors: userModel.secondarySectors,
                    isTest: userModel.test,
                    isManager: userModel.manager,
                    email: userModel.email
                }}
            >
                <Form.Item
                    name="photoUrl"
                    label="User Profile Picture">
                    <ImageUpload previousImage={userModel.photoUrl}
                        prefix='user_profile'
                        onUploadFinish={onProfileUploadFinish} />
                </Form.Item>

                <div className='doubleInputGrid'>
                    <Form.Item
                        label='First Name'
                        name="firstName"
                        rules={[{ required: true, message: 'Please enter first name.' }]}
                    >
                        <Input size='large' />
                    </Form.Item>
                    <Form.Item
                        label='Last Name'
                        name="lastName"
                        rules={[{ required: true, message: 'Please enter last name.' }]}
                    >
                        <Input size='large' />
                    </Form.Item>
                </div>

                <Form.Item label='Company'
                    name='companySelect'>
                    {selectedCompany ?
                        <CompanyBox name={selectedCompany.name} logoUrl={selectedCompany.logo?.url} onUnlink={onCompanyUnlink} />
                        :
                        <AutoComplete size={'large'}
                            onSearch={onCompanySearch}
                            onSelect={onCompanySelected}
                            onChange={onCompanyValueChange}>
                            {
                                hits.map((hit: any) =>
                                    hit.type === 'add' ?
                                        <Option key='add' value=''>
                                            <div className={styles.addNewButtonLink} onClick={addNewCompany}>+ Add New Company</div>
                                        </Option>
                                        :
                                        <Option key={hit._id} value={hit.name} extra={hit} >
                                            <div className={styles.companySearchHit}>
                                                <img src={hit.logo?.url} className={styles.companySearchLogo} />
                                                <div className={styles.companySearchName}>{hit.name}</div>
                                            </div>
                                        </Option>
                                )
                            }
                        </AutoComplete>
                    }
                </Form.Item>
                <Form.Item
                    label='Role'
                    name="role"
                    rules={[{ required: true, message: 'Please enter your role in the company.' }]}
                >
                    <Input size='large' placeholder={'i.e - CEO, VP Marketing etc.'} />
                </Form.Item>
                <Form.Item label='Bio'
                    name='bio'
                    hasFeedback
                    tooltip='Write as if you were introducing yourself.'
                    rules={[{ required: true, message: 'Please add a bio of yourself' }]}>
                    <Input.TextArea maxLength={500} minLength={100} showCount />
                </Form.Item>
                <Form.Item
                    label='Email'
                    name="email"
                    rules={[{ required: true, message: 'Please enter email.' }]}
                >
                    <Input size='large' />
                </Form.Item>
                <Form.Item label="Member Status"
                    rules={[{ required: true, message: `Please select an option` }]}
                    name="status">
                    <Radio.Group size='large'>
                        <Radio.Button value={'leader'}>Member</Radio.Button>
                        <Radio.Button value={'staff'}>Staff</Radio.Button>
                        <Radio.Button value={'guest'}>Guest</Radio.Button>
                    </Radio.Group>
                </Form.Item>
                {settingsStore?.membership?.enabled &&
                    <div className={styles.subHeader}>Trial Credits</div>
                }
                {settingsStore?.membership?.enabled && 
                <div className={styles.sideBySide}>
                    <div>
                        <div className={styles.label}>Events:</div>
                        <Form.Item name='eventCredits'>
                            <InputNumber className={styles.input} min={0} max={10} />
                        </Form.Item>
                    </div>
                    <div>
                        <div className={styles.label}>Intro Requests:</div>
                        <Form.Item name='introCredits'>
                            <InputNumber className={styles.input} min={0} max={10} />
                        </Form.Item>
                    </div>
                </div>
                }
                {authUser?.kapara && 
                <Form.Item label="Is test?"
                    name='isTest'
                    valuePropName='checked'>
                    <Switch disabled={!sessionStore!.authUser?.isAdmin} checkedChildren="Yes" unCheckedChildren="No" />
                </Form.Item>
                }
                <Form.Item label="Is Manager?"
                    name='isManager'
                    valuePropName='checked'>
                    <Switch disabled={!sessionStore!.authUser?.isManager} checkedChildren="Yes" unCheckedChildren="No" />
                </Form.Item>
                {referrerUser &&
                    <div className={styles.referrerUserContainer}>
                        <Avatar size='small' src={referrerUser.photoUrl} />
                        <div>Invited by {referrerUser.fullName}</div>
                    </div>
                }
                <div style={{ marginTop: 20 }}>
                    User ID: {userModel.id}
                </div>
            </Form>
            <AddCompanyDrawer />
        </Modal>)
}

export default inject('controlPanelStore', 'algolia', 'sessionStore', 'membersStore', 'onboardingStore', 'settingsStore')(observer(EditUserModal));