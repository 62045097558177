import { Button, Drawer, Form, Input, Radio, Select, message, DatePicker, TimePicker, List, Avatar, Tag, Switch, Divider, InputNumber, Checkbox } from 'antd';
import { inject, observer } from 'mobx-react';
import React, { useEffect, useState } from 'react';
import styles from './AddEventDrawer.module.css';
import ViewStateStore from '../../../../../store/ViewStateStore';
import { RadioChangeEvent } from 'antd/lib/radio';
import TimezoneUtils from '../../../../../common/utils/TimezoneUtils';
import moment from 'moment-timezone';
import ReactMde from "react-mde";
import Showdown from "showdown";
import "react-mde/lib/styles/css/react-mde-all.css";
import AddSpeakerModal from '../addSpeakerModal/AddSpeakerModal';
import EventModel, { EventReminder, EventTheme, Speaker, TargetAudience } from '../../../../../common/models/EventModel';
import EventsStore from '../../../../../store/EventsStore';
import { getEventThemes } from '../../../../../common/utils/constants';
import _ from 'lodash';
import SessionStore from '../../../../../store/SessionStore';
import ImageUpload from '../../../../../common/components/imageUpload/ImageUpload';
import TargetingModal from '../targetingModal/TargetingModal';
import SettingsStore from '../../../../../store/SettingsStore';

const converter = new Showdown.Converter({
    tables: true,
    simplifiedAutoLink: true,
    strikethrough: true,
    tasklists: true
});


interface Props {
    event?: EventModel;
    viewStateStore?: ViewStateStore,
    eventsStore?: EventsStore,
    sessionStore?: SessionStore
    settingsStore?: SettingsStore
    isOpen: boolean,
    onClose: () => void,
}


function AddEventDrawer({ viewStateStore, eventsStore, sessionStore, settingsStore, isOpen, onClose, event }: Props) {
    const editMode = event ? true : false;
    const { isMobile, primaryColor } = viewStateStore!;
    const { miscellaneous } = settingsStore!;
    const { authUser } = sessionStore!;
    const { addEvent, editEvent } = eventsStore!;
    const [form] = Form.useForm();
    const [busy, setBusy] = useState(false);
    const [isOnlineEvent, setIsOnlineEvent] = useState(true);
    const [isExternalEvent, setIsExternalEvent] = useState(false);
    const [description, setDescription] = useState('');
    const [selectedTab, setSelectedTab] = useState<"write" | "preview">("write");
    const [speakers, setSpeakers] = useState(Array<Speaker>());
    const [timezones, setTimezones] = useState(Array<{ text: string, value: string }>())
    const [addSpeakerModalVisible, setAddSpeakerModalVisible] = useState(false);
    const [targetingModalVisible, setTargetingModalVisible] = useState(false);
    const [themeColor, setThemeColor] = useState(primaryColor);
    const [themeBackground, setThemeBackground] = useState('');
    const [targets, setTargets] = useState(Array<TargetAudience>());
    const [targetToEdit, setTargetToEdit] = useState<TargetAudience>();
    const [reminder, setReminder] = useState<EventReminder>({ wasSent: false, timeBefore: 24, enabled: false });

    useEffect(() => {
        initTimezones();
    }, []);

    useEffect(() => {
        if (!event) {
            return;
        }

        const startDate = moment.unix(event.startDate!).tz(event.timezone!);
        const endDate = moment.unix(event.endDate!).tz(event.timezone!);

        const minutesDiff = endDate.diff(startDate, 'minutes');
        const durationHours = Math.floor(minutesDiff / 60);
        const durationMinutes = minutesDiff - durationHours * 60;

        form.setFieldsValue({
            timezone: event?.timezone,
            startDate: startDate,
            startTime: startDate,
            durationHours,
            durationMinutes,
            themeColor: event.themeColor || primaryColor,
            isQuestionsEnabled: event.isQuestionsEnabled,
            autoStart: event.autoStart,
            spatialLink: event.spatialLink || miscellaneous?.spatialDefaultUrl,
            webinarId: event.webinarId || miscellaneous?.zoomWebinarId,
            recordUrl: event.recordUrl,
            approvalNeeded: event.approvalNeeded || [],
        })

        if (event.reminder) {
            form.setFieldsValue({
                timeBefore: event.reminder.timeBefore,
                reminderEnabled: event.reminder.enabled,
            })
            setReminder(event.reminder);
        }

        setIsOnlineEvent(event?.type === 'online');
        setIsExternalEvent(event?.eventPlatform === 'external');
        setThemeColor(event?.themeColor || primaryColor);
        setThemeBackground(event.themeBackground || '');
        setSpeakers(event.speakers || [])
        setTargets(event.targets || []);

    }, [event]);


    const initTimezones = () => {
        form.setFieldsValue({ timezone: moment.tz.guess() })
        for (var tz of TimezoneUtils.getTimeZones()) {
            timezones.push({ text: `(GMT${moment.tz(tz).format('Z')}) ${tz}`, value: tz });
        }

        timezones.sort((a, b) => a.text.localeCompare(b.text));
    }

    const onSubmit = () => {
        form.submit();
    }

    const onEventTypeChanged = (e: RadioChangeEvent) => {
        const type = e.target.value;

        if (type === 'online') {
            setIsOnlineEvent(true);
            setIsExternalEvent(false);
            form.setFieldsValue({
                eventPlatform: 'in-house',
            })
        } else {
            form.setFieldsValue({
                eventPlatform: 'external',
            })
            setIsExternalEvent(false);
            setIsOnlineEvent(false);
        }
    }

    const onEventPlatformChanged = (e: RadioChangeEvent) => {
        const platform = e.target.value;
        setIsExternalEvent(platform === 'external');
    }

    const onThemeColorChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
        const value = event.target.value;
        setThemeColor(value);
    }

    const onThemeBackgroundFinish = (imageUrl: any) => {
        setThemeBackground(imageUrl);
    }

    const onAddSpeakerConfirm = (speaker: Speaker) => {
        setAddSpeakerModalVisible(false);
        setSpeakers([...speakers, speaker]);
    }

    const onTargetingCreateOrUpdate = (target: TargetAudience, edit: boolean) => {
        setTargetingModalVisible(false);
        setTargetToEdit(undefined);
        if (edit) {
            setTargets([...targets.filter(t => t.type !== target.type), target]);
        } else {
            setTargets([...targets, target]);
        }

    }

    const removeSpeaker = (speaker: Speaker) => {
        setSpeakers(speakers.filter(s => s.photoUrl !== speaker.photoUrl));
    }

    const editTarget = (target: TargetAudience) => {
        setTargetToEdit(target);
        setTargetingModalVisible(true);
    }

    const removeTarget = (target: TargetAudience) => {
        setTargets(targets.filter(t => t.type !== target.type));
    }

    const onFinish = async (values: any) => {
        try {
            setBusy(true);
            const { title,
                description,
                startDate,
                durationHours,
                durationMinutes,
                startTime,
                timezone,
                type,
                theme,
                themeColor,
                themeTitle,
                eventPlatform,
                location,
                externalLink,
                isActive,
                isEnded,
                isQuestionsEnabled,
                webinarId,
                spatialLink,
                seatLimit,
                isHidden,
                autoStart,
                recordUrl,
                approvalNeeded } = values;

            const startDateUnix = combineDate(startDate, startTime, timezone);
            const endDateUnix = startDateUnix + durationHours * 3600 + durationMinutes * 60;

            if (startDate < endDateUnix) {
                message.error('The event ends before it starts - please set a proper duration');
                return;
            }

            const data = {
                title,
                description,
                startDate: startDateUnix,
                endDate: endDateUnix,
                timezone,
                type,
                theme,
                themeColor,
                themeTitle,
                eventPlatform,
                location,
                externalLink,
                speakers,
                isActive,
                isQuestionsEnabled,
                isEnded,
                webinarId,
                spatialLink,
                themeBackground,
                targets,
                seatLimit,
                isHidden,
                autoStart,
                recordUrl,
                approvalNeeded,
                reminder,
            };

            if (editMode) {
                await editEvent(event!.id!, data);
            } else {
                await addEvent(data);
            }

            onClose();
            message.success(editMode ? 'Event has been updated!' : 'Event has been created successfully!');
        } catch (error) {
            message.error('Something went wrong while adding the event...')
        } finally {
            setBusy(false);
        }
    }

    const combineDate = (date: moment.Moment, time: moment.Moment, timezone: string) => {
        date.hour(time.hour());
        date.minute(time.minute());
        date.second(0);
        date.tz(timezone, true);
        return date.unix();
    }

    const onReminderChanged = (value: boolean) => {
        setReminder({ ...reminder, enabled: value })
    }

    const onTimeBeforeChanged = (value?: string | number) => {
        setReminder({ ...reminder, timeBefore: parseInt(value as string) })
    }


    return <Drawer
        className={styles.container}
        bodyStyle={{ padding: 0, lineHeight: 1.2 }}
        placement='right'
        width={isMobile ? '100%' : 660}
        onClose={onClose}
        closable={true}
        title={editMode ? 'Edit event' : 'Add new event'}
        visible={isOpen}
        footer={
            <div
                style={{
                    textAlign: 'right',
                }}
            >
                <Button size={'large'} onClick={onClose} style={{ marginRight: 8, fontWeight: 600 }}>
                    Cancel
                </Button>
                <Button loading={busy} size={'large'} onClick={onSubmit} type="primary" style={{ fontWeight: 600 }}>
                    {editMode ? 'Update Details' : 'Add Event'}
                </Button>
            </div>
        }
    >
        <div className={styles.content}>
            <Form
                className='add-event'
                form={form}
                name="add-event-form"
                initialValues={{
                    title: event?.title,
                    description: event?.description,
                    timezone: event?.timezone,
                    type: event?.type,
                    location: event?.location,
                    externalLink: event?.externalLink,
                    isActive: event?.isActive,
                    isEnded: event?.isEnded,
                    eventPlatform: event?.eventPlatform,
                    theme: event?.theme,
                    themeColor: primaryColor,
                    themeTitle: event?.themeTitle,
                    isQuestionsEnabled: event?.isQuestionsEnabled,
                    seatLimit: event?.seatLimit,
                    isHidden: event?.isHidden,
                    durationHours: 1,
                    durationMinutes: 0,
                    autoStart: true,
                    spatialLink: miscellaneous?.spatialDefaultUrl,
                    webinarId: miscellaneous?.zoomWebinarId,
                    reminderEnabled: false,
                    timeBefore: 24,
                }}
                onFinish={onFinish}
                layout="vertical"
            >
                <Form.Item label='Title'
                    name='title'
                    rules={[{ required: true, message: 'Please enter a title for this event.' }]}>
                    <Input />
                </Form.Item>

                <div className='doubleInputGrid'>
                    <Form.Item label="Sub Title"
                        rules={[{ required: false, message: `Please add a theme title` }]}
                        name='themeTitle'>
                        <Input placeholder={'Optional'} />
                    </Form.Item>
                    <div className={styles.themeColor}>
                        <Form.Item label="Theme Color"
                            rules={[{ required: true, message: `Please add a theme color` }]}
                            name='themeColor'>
                            <Input onChange={onThemeColorChanged} />
                        </Form.Item>
                        <div style={{ backgroundColor: themeColor }} className={styles.colorPicker} />
                    </div>
                </div>

                <Form.Item label='Description'
                    name='description'
                    tooltip='Add a description for this event - what is it about etc.'
                    rules={[{ required: true, message: 'Please add a longer description' }]}>
                    <ReactMde
                        value={description}
                        onChange={setDescription}
                        selectedTab={selectedTab}
                        onTabChange={setSelectedTab}
                        generateMarkdownPreview={markdown =>
                            Promise.resolve(converter.makeHtml(markdown))
                        }
                    />
                </Form.Item>

                <div className='doubleInputGrid'>
                    <Form.Item label={`Start date & time`}
                        name='startDate'
                        rules={[{ required: true, message: `Please select a start date` }]}>
                        <DatePicker />
                    </Form.Item>
                    <Form.Item label={' '}
                        name='startTime'
                        rules={[{ required: true, message: `Please select a start time` }]}>
                        <TimePicker use12Hours minuteStep={5} format="h:mm A" />
                    </Form.Item>
                </div>

                <div className={styles.side2side}>
                    <Form.Item label={`Duration`}
                        name='durationHours'
                        rules={[{ required: true, message: `Please set duration` }]}>
                        <Input className={styles.smallInput} min={0} max={24} addonAfter='Hours' />
                    </Form.Item>
                    <Form.Item label={` `}
                        name='durationMinutes'
                        rules={[{ required: true, message: `Please set duration minutes` }]}>
                        <Input className={styles.smallInput} min={0} max={60} addonAfter='Minutes' />
                    </Form.Item>
                </div>


                <Form.Item label="Start on time automatically"
                    tooltip={'If set, the event will start on the time specifed automatically and notifications will be sent to all attendees to join.'}
                    name='autoStart'
                    valuePropName='checked'>
                    <Switch />
                </Form.Item>


                <div className={styles.side2side}>

                    <Form.Item label="Send a reminder to attendees"
                        name='reminderEnabled'
                        valuePropName='checked'>
                        <Switch onChange={onReminderChanged} />
                    </Form.Item>
                    {reminder.enabled &&
                        <div className={styles.row}>
                            <Form.Item label={` `}
                                name='timeBefore'
                                rules={[{ required: true, message: `Please set a value` }]}>
                                <InputNumber onChange={onTimeBeforeChanged} className={styles.smallInput2} min={1} />
                            </Form.Item>
                            <div className={styles.suffix}>Hours Before</div>
                        </div>
                    }
                </div>




                <Form.Item label='Timezone'
                    name='timezone'
                    rules={[{ required: true, message: `Please select a timezone` }]}>
                    <Select size='large'
                        placeholder='Please Select...'>
                        {timezones.map(tz => (
                            <Select.Option key={tz.value} value={tz.value}>{tz.text}</Select.Option>
                        ))}
                    </Select>
                </Form.Item>

                <Form.Item label="Where is the event taking place?"
                    rules={[{ required: true, message: `Please select an option` }]}
                    name="type">
                    <Radio.Group size='large' onChange={onEventTypeChanged}>
                        <Radio.Button value={'online'}>Online</Radio.Button>
                        <Radio.Button value={'physical'}>In-Person</Radio.Button>
                    </Radio.Group>
                </Form.Item>
                {isOnlineEvent &&
                    <Form.Item label="Which event platform is used?"
                        rules={[{ required: true, message: `Please select an option` }]}
                        name="eventPlatform">
                        <Radio.Group size='large' onChange={onEventPlatformChanged}>
                            <Radio.Button value={'in-house'}>In House</Radio.Button>
                            <Radio.Button value={'external'}>External</Radio.Button>
                        </Radio.Group>
                    </Form.Item>
                }

                {isExternalEvent &&
                    <Form.Item label='External Platform Link'
                        tooltip={'If this event happens externally (i.e Zoom), please a provide a link to it.'}
                        name='externalLink'
                        rules={[{ required: false, type: 'url', message: `Please add a valid URL (forgot to add "https://"?)` }]}>
                        <Input placeholder='https://' />
                    </Form.Item>
                }

                {!isOnlineEvent &&
                    <Form.Item label='Location'
                        name='location'
                        rules={[{ required: true, message: 'Physical event? Please add locaiton.' }]}>
                        <Input />
                    </Form.Item>
                }

                <Form.Item
                    name="seatLimit"
                    label="Seat Limit"
                    tooltip={'Set a limit to how many people can register to this event.'}>
                    <InputNumber min={5} max={999} />
                </Form.Item>
                <Divider />
                <div className={styles.speakersContainer}>
                    <div className={styles.speakersLabel}>
                        Speakers
                    </div>
                    <List
                        style={{ marginBottom: 10 }}
                        itemLayout="horizontal"
                        dataSource={speakers}
                        locale={{ emptyText: 'No speakers were added yet...' }}
                        renderItem={speaker => (
                            <List.Item
                                actions={[<a key="remove-speaker" onClick={() => removeSpeaker(speaker)}>Remove</a>]}>
                                <List.Item.Meta
                                    avatar={<Avatar src={speaker.photoUrl} />}
                                    title={speaker.name}
                                    description={`${speaker.title} at ${speaker.companyName}`}
                                />
                            </List.Item>
                        )}
                    />
                    <Button onClick={() => setAddSpeakerModalVisible(true)}>
                        Add Speaker
                    </Button>
                </div>
                <Divider />
                <div className={styles.speakersContainer}>
                    <div className={styles.speakersLabel}>
                        Targeting
                    </div>
                    <List
                        style={{ marginBottom: 10 }}
                        itemLayout="horizontal"
                        dataSource={targets}
                        locale={{ emptyText: 'No targets were added' }}
                        renderItem={target => (
                            <List.Item
                                actions={[<a key="edit-targeting" onClick={() => editTarget(target)}>Edit</a>, <a key="remove-targeting" onClick={() => removeTarget(target)}>Remove</a>]}>
                                <List.Item.Meta
                                    title={_.capitalize(target.type)}
                                />
                            </List.Item>
                        )}
                    />
                    <Button onClick={() => setTargetingModalVisible(true)}>
                        Add Target
                    </Button>
                </div>
                <Form.Item style={{ marginTop: 34 }}
                    name="approvalNeeded"
                    label="Approval Needed"
                    tooltip={'Set what kind of registrations are subject for approval by you'}>
                    <Checkbox.Group value={event?.approvalNeeded} options={[{
                        label: 'Members', value: 'members'
                    },
                    {
                        label: 'Guests', value: 'guests'
                    }]} />
                </Form.Item>
                <Divider />
                <Form.Item
                    name="themeBackgroundImage"
                    label="Background Image">
                    <ImageUpload
                        onRemove={() => { setThemeBackground('') }}
                        previousImage={event?.themeBackground}
                        prefix={`event_${event?.shortId}_bg`}
                        onUploadFinish={onThemeBackgroundFinish} />
                </Form.Item>
                <Divider />

                <Form.Item label="Questions enabled?"
                    tooltip={'Set whether attendes can ask questions before the event starts.'}
                    name='isQuestionsEnabled'
                    valuePropName='checked'>
                    <Switch checkedChildren="Yes" unCheckedChildren="No" />
                </Form.Item>

                <Form.Item label="Is hidden?"
                    tooltip={'Set whether this event is hidden for non-admin members.'}
                    name='isHidden'
                    valuePropName='checked'>
                    <Switch checkedChildren="Yes" unCheckedChildren="No" />
                </Form.Item>

                <Form.Item label='Recording Url'
                    tooltip={'Am external link to a video hosting provider that contains a record of this session.'}
                    name='recordUrl'
                    rules={[{ required: false, type: 'url', message: `Please add a valid URL (forgot to add "https://"?)` }]}>
                    <Input placeholder='https://wwww.youtube.com/v/xw232' />
                </Form.Item>


                <Divider />

                <Form.Item label="Is active?"
                    tooltip={'Set whether this event is running now or not.'}
                    name='isActive'
                    valuePropName='checked'>
                    <Switch disabled={!authUser?.kapara} checkedChildren="Yes" unCheckedChildren="No" />
                </Form.Item>
                <Form.Item label="Is Ended?"
                    tooltip={'Set whether this event is ended.'}
                    name='isEnded'
                    valuePropName='checked'>
                    <Switch disabled={!authUser?.kapara} checkedChildren="Yes" unCheckedChildren="No" />
                </Form.Item>

                <Form.Item label='Zoom Webinar ID'
                    name='webinarId'
                >
                    <Input disabled={!authUser?.kapara} />
                </Form.Item>


                <Form.Item label='Spatial Link'
                    name='spatialLink'
                >
                    <Input disabled={!authUser?.kapara} />
                </Form.Item>

            </Form>
            <AddSpeakerModal visible={addSpeakerModalVisible}
                onCreate={onAddSpeakerConfirm}
                onCancel={() => setAddSpeakerModalVisible(false)} />

            <TargetingModal visible={targetingModalVisible}
                target={targetToEdit}
                onCreateOrUpdate={onTargetingCreateOrUpdate}
                onCancel={() => {
                    setTargetingModalVisible(false);
                    setTargetToEdit(undefined);
                }} />
        </div>
    </Drawer>
}

export default inject('viewStateStore', 'eventsStore', 'sessionStore', 'settingsStore')(observer(AddEventDrawer));