import React, { useState, useEffect, useRef } from "react";
import styles from './GroupParticipant.module.css';
import Video from 'twilio-video';
import { AudioMutedOutlined, AudioOutlined, VideoCameraOutlined, MessageTwoTone, InfoCircleOutlined } from '@ant-design/icons';
import { Avatar, notification, Popover, Tooltip, Typography } from "antd";
import { ParticipantData } from "../../../../../../../../../common/components/videoRoom/VideoRoom";
import { deviceDetect, isSafari } from "react-device-detect";
import AttendeeInfo from "../../../attendeeInfo/AttendeeInfo";
const { Paragraph } = Typography;

interface Props {
    participant?: ParticipantData,
    isSelf?: boolean;
    selfAudioEnabled: boolean,
    selfVideoEnabled: boolean,
}

function GroupParticipant({ participant, isSelf, selfAudioEnabled, selfVideoEnabled }: Props) {
    const [videoTracks, setVideoTracks] = useState(Array<Video.VideoTrack>());
    const [audioTracks, setAudioTracks] = useState(Array<Video.AudioTrack>());
    const [audioEnabled, setAudioEnabled] = useState(true);
    const [videoEnabled, setVideoEnabled] = useState(true);
    const [tooltipVisible, setTooltipVisible] = useState(false);

    const videoRef = useRef<any>();
    const audioRef = useRef<any>();

    useEffect(() => {
        if (!participant) {
            return;
        }

        console.log(`Initiating participant tube for ${participant.info?.firstName}`)
        setVideoTracks(trackpubsToTracks(participant.current.videoTracks));
        setAudioTracks(trackpubsToTracks(participant.current.audioTracks));

        participant.current.on("trackSubscribed", trackSubscribed);
        participant.current.on("trackUnsubscribed", trackUnsubscribed);

        if(isSelf) {
            setTimeout(() => {
                setTooltipVisible(true);
                setTimeout(() => {
                    setTooltipVisible(false);
                }, 5000);
            }, 3000);
            
        }

        return () => {
            setVideoTracks([]);
            setAudioTracks([]);
            participant.current.removeAllListeners();
        };
    }, [participant]);

    useEffect(() => {
        const videoTrack = videoTracks[0];
        if (videoTrack) {
            setVideoEnabled(videoTrack.isEnabled);
            videoTrack.attach(videoRef.current);
            return () => {
                videoTrack.detach();
            };
        }
    }, [videoTracks]);

    useEffect(() => {
        const audioTrack = audioTracks[0];
        if (audioTrack) {
            setAudioEnabled(audioTrack.isEnabled);
            audioTrack.attach(audioRef.current);
            return () => {
                audioTrack.detach();
            };
        }
    }, [audioTracks]);


    const handleTrackDisabled = (track: Video.RemoteTrack) => {
        track.on('disabled', () => {
            if (track.kind === 'video') {
                console.log(`${participant?.info?.firstName} disabled video`);
                setVideoEnabled(false)
            }
            if (track.kind === 'audio') {
                console.log(`${participant?.info?.firstName} disabled audio`);
                setAudioEnabled(false)
            }
        });
        track.on('enabled', () => {
            if (track.kind === 'video') {
                console.log(`${participant?.info?.firstName} enabled video`);
                setVideoEnabled(true)
            }
            if (track.kind === 'audio') {
                console.log(`${participant?.info?.firstName} enabled audio`);
                setAudioEnabled(true)
            }
        });
    }

    const trackpubsToTracks = (trackMap: Map<string, any>) =>
        Array.from(trackMap.values())
            .map((publication) => publication.track)
            .filter((track) => track !== null);


    const trackSubscribed = (track: Video.LocalTrack) => {
        console.log(`Tracks subscribed for ${participant?.info?.firstName}`)
        const remote = participant?.current as Video.RemoteParticipant;
        if (remote) {
            remote.tracks.forEach(publication => {
                if (publication.isSubscribed && publication.track) {
                    handleTrackDisabled(publication.track);
                }

                publication.on('subscribed', handleTrackDisabled);
            });
        }

        if (track.kind === "video") {
            setVideoTracks((videoTracks) => [...videoTracks, track]);
        } else if (track.kind === "audio") {
            setAudioTracks((audioTracks) => [...audioTracks, track]);
        } else if (track.kind === 'data') {
            track.on('message', data => {
                notification.info({
                    key: data,
                    message: <b>{participant?.info?.firstName}:</b>,
                    duration: 15,
                    placement: 'bottomLeft',
                    description: data,
                    icon: <MessageTwoTone twoToneColor={'#1CBEC0'} />,
                    onClick: () => {
                        notification.close(data);
                    },
                });
            });
        }
    };

    const trackUnsubscribed = (track: Video.LocalTrack) => {
        console.log(`Tracks unsubscribed for ${participant?.info?.firstName}`);
        if (track.kind === "video") {
            setVideoTracks((videoTracks) => videoTracks.filter((v) => v !== track));
        } else if (track.kind === "audio") {
            setAudioTracks((audioTracks) => audioTracks.filter((a) => a !== track));
        }
    };


    return (
        <div className={`${styles.container} animate__animated animate__zoomIn animate__fast`}>
            <div className={`${styles.content} ${isSafari && styles.contentSafariBug}`}>
                {isSelf && <div className={styles.youBadge}>You</div>}
                <video className={styles.video} ref={videoRef} style={{ display: isSelf ? selfVideoEnabled ? 'initial' : 'none' : videoEnabled ? 'initial' : 'none' }} autoPlay={true} />
                <div className={styles.cameraOffContainer}>
                    <Avatar src={participant?.info?.photoUrl} size={100} />
                </div>

                <div className={styles.videoOverlay} />



                <div className={styles.userInfo}>
                    <Popover destroyTooltipOnHide trigger={['hover']}
                        content={<AttendeeInfo userInfo={participant?.info} />}
                        placement={'top'}>
                        <div className={styles.details}>
                            <Avatar src={participant?.info?.company?.logoUrl} className={styles.companyAvatar} />
                            <div className={styles.texts}>
                                <div className={styles.participantName}>
                                    {participant?.info?.firstName} {participant?.info?.lastName}
                                    <Tooltip color='#1cbcbeb5' visible={tooltipVisible} title={<div className={styles.tooltip}>💡 Hover your mouse to see more info about a person</div>} >
                                        <InfoCircleOutlined className={styles.infoBadge} />
                                    </Tooltip>
                                </div>
                                <Paragraph ellipsis={{ rows: 1 }} className={styles.participantTitle}>{participant?.info?.role}, {participant?.info?.company?.name}</Paragraph>
                            </div>
                        </div>
                    </Popover>
                    <div className={styles.controlButtons}>
                        <div className={styles.controlButton}>
                            {isSelf ?
                                <div>
                                    {selfAudioEnabled ? <AudioOutlined style={{ color: '#06c2a6' }} /> : <AudioMutedOutlined style={{ color: '#ee1949' }} />}
                                </div>
                                :
                                <div>
                                    {audioEnabled ? <AudioOutlined style={{ color: '#06c2a6' }} /> : <AudioMutedOutlined style={{ color: '#ee1949' }} />}
                                </div>
                            }
                        </div>
                        <div className={styles.controlButton}>
                            {isSelf ?
                                <div>
                                    {selfVideoEnabled ? <VideoCameraOutlined style={{ color: '#06c2a6' }} /> : <VideoCameraOutlined style={{ color: '#ee1949' }} />}
                                </div>
                                :
                                <div>
                                    {videoEnabled ? <VideoCameraOutlined style={{ color: '#06c2a6' }} /> : <VideoCameraOutlined style={{ color: '#ee1949' }} />}
                                </div>
                            }
                        </div>
                    </div>
                </div>
            </div>
            { !isSelf && <audio ref={audioRef} autoPlay={true} muted={isSelf || !audioEnabled} />}
        </div >
    );
};

export default GroupParticipant;