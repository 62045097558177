import { Button, Empty } from 'antd';
import _ from 'lodash';
import { inject, observer } from 'mobx-react';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom';
import SectionHeader from '../../../common/components/sectionHeader/SectionHeader';
import EventModel from '../../../common/models/EventModel';
import AnalyticsReporter from '../../../services/analytics/AnalyticsReporter';
import EventsStore from '../../../store/EventsStore';
import SessionStore from '../../../store/SessionStore';
import ViewStateStore from '../../../store/ViewStateStore';
import NewEventDrawer from './components/addEventDrawer/AddEventDrawer';
import EventBox from './components/eventBox/EventBox';
import EventCard from './components/eventCards/EventCard';
import styles from './EventsPage.module.css';

interface Props {
    sessionStore: SessionStore,
    eventsStore: EventsStore,
    analyticsReporter: AnalyticsReporter,
    viewStateStore: ViewStateStore,
}

function EventsPage({ sessionStore, eventsStore, analyticsReporter, viewStateStore }: Props) {
    const [newEventDrawerOpen, setNewEventDrawerOpen] = useState(false);
    const { authUser } = sessionStore;
    const { eventTerm } = viewStateStore;
    const { futureEvents, pastEvents, futureEventsByDate } = eventsStore;
    const [filter, setFilter] = useState<'past' | 'future'>('future');
    const history = useHistory();
    const location = useLocation();

    useEffect(() => {
        analyticsReporter.trackPage('Events Page');


    }, [])

    useEffect(() => {
        const filter = new URLSearchParams(location.search).get("filter");
        if (filter === 'past') {
            setFilter('past');
        } else {
            setFilter('future');
        }
    }, [location])

    const openNewEventDrawer = () => {
        setNewEventDrawerOpen(true);
    }

    const onNewEventDrawerClose = () => {
        setNewEventDrawerOpen(false);
    }

    const onPastClicked = () => {
        setFilter('past')
        history.push('/calendar?filter=past');
    }

    const onFutureClicked = () => {
        setFilter('future')
        history.push('/calendar');
    }

    return (
        <div className={styles.container}>
            <div className={styles.sectionContainer}>
                <SectionHeader title={`${_.capitalize(eventTerm)}s Calendar`}
                    subTitle={`Check out our full calendar and save your spot in one of our upcoming ${eventTerm}s.`} />
                <div className={styles.buttons}>
                    <Button className={`${styles.filterButton} ${filter === 'past' ? styles.filterButtonSelected : ''}`}
                        type='default'
                        size={"large"}
                        onClick={onPastClicked}>
                        Past
                    </Button>
                    <Button className={`${styles.filterButton} ${filter === 'future' ? styles.filterButtonSelected : ''}`}
                        type='default'
                        size={"large"}
                        onClick={onFutureClicked}>
                        Upcoming
                    </Button>
                    {authUser?.isStaff &&
                        <div className={styles.divider} />
                    }

                    {authUser?.isStaff &&
                        <Button className={styles.addNewEventButton}
                            type='primary'
                            size={"large"}
                            onClick={openNewEventDrawer}>
                            Create New
                        </Button>
                    }
                </div>
            </div>

            {filter === 'past' &&
                <div className={styles.eventsGrid}>
                    {pastEvents.map(event => {
                        return (
                            <EventCard eventModel={event} key={event.id} />
                        )
                    })}
                </div>
            }
            {filter === 'future' &&
                <div>
                    {futureEvents.length === 0 ? <div className={styles.emptyEvents}>No upcoming {eventTerm}s...</div>
                        :
                        <div className={styles.futureEventsGrid}>
                            {
                                Object.entries(futureEventsByDate).map(([key, value]) => {
                                    const date = moment(key, 'DD/MM/YYYY');
                                    const events = value;
                                    return <div key={key}>
                                        <div>
                                            {date.isSame(moment(), 'day') && <div className={styles.dateHeaderToday}>Today</div>}
                                            {date.isSame(moment().add(1, 'days'), 'day') && <div className={styles.dateHeaderToday}>Tomorrow</div>}
                                            {date.isAfter(moment().add(1, 'days'), 'day') && <div className={styles.dateHeader}><span>{date.format('DD')}</span><span className={styles.momthName}>{date.format('MMM')}</span></div>}
                                            {date.isAfter(moment().add(1, 'days'), 'day') && <div className={styles.dayInTheWeek}>{date.format('dddd')}</div>}
                                        </div>
                                        <div className={styles.dateDivider} />
                                        <div className={styles.eventsGrid} style={{ marginTop: 20 }}>
                                            {events.map(event => {
                                                return (
                                                    <EventCard eventModel={event} key={event.id} />
                                                )
                                            })}
                                        </div>
                                    </div>
                                })
                            }
                        </div>
                    }
                </div>
            }

            <NewEventDrawer isOpen={newEventDrawerOpen} onClose={onNewEventDrawerClose} />

        </div>
    )
}

export default inject('sessionStore', 'eventsStore', 'analyticsReporter', 'viewStateStore')(observer(EventsPage));