import React from "react";
import Card from "../card/Card";
import { inject, observer } from "mobx-react";
// import { } from '@ant-design/icons';
import styles from './MembershipCard.module.css';
import SessionStore from "../../../store/SessionStore";
import { Button } from "antd";
import SettingsStore from "../../../store/SettingsStore";
import ViewStateStore from "../../../store/ViewStateStore";
import airbnbLogo from './airbnb.png';
import bookingLogo from './booking.png';
import amadeusLogo from './amadeus.png';
import turkishAirlinesLogo from './turkish.png';
import DiscountBadge from "../discountBadge/DiscountBadge";

interface Props {
    className?: string,
    sessionStore?: SessionStore;
    settingsStore?: SettingsStore;
    viewStateStore?: ViewStateStore;
}

function MembershipCard({className, sessionStore, settingsStore, viewStateStore} : Props) {
    const { authUser } = sessionStore!;

    if(!settingsStore?.membership?.enabled) {
        return <></>
    }

    if(authUser?.hasActiveMembership) {
        return <></>;
    }

    const onClick = () => {
        viewStateStore?.setPaymentWallVisible(true, 'Home Banner');
    }

    return (<Card className={`${styles.container} ${className}`}>
        <div className={styles.header}>You have <span className={styles.creditsNumber}>{authUser?.membership?.credits?.events}</span> ticket{authUser?.membership?.credits?.events !== 1 ? 's' : ''} left to join our meetings</div>
        <div className={styles.description}>Become a <span className={styles.premiumMember}>Premium Member</span> and get unlimited access to our events and benefits.</div>
        <div className={styles.buttonContainer}> 
        <Button onClick={onClick} className={styles.ctaButton} type={'primary'} size={'large'}>Get Premium Membership</Button>
        <DiscountBadge className={styles.discountBadge}/>
        </div>
        <div className={styles.socialProofContainer}>
            <div className={styles.socialProofText}>Join <b>100+</b> members and leaders from companies like:</div>
            <div className={styles.companies}>
                <img src={amadeusLogo} />
                <img src={bookingLogo} />
                <img src={airbnbLogo} />
                <img src={turkishAirlinesLogo} />
            </div>
        </div>
    </Card>);
}


export default inject('sessionStore', 'settingsStore', 'viewStateStore')(observer(MembershipCard));