import { Button, message } from "antd";
import React, { useState } from "react";
import DeviceSettings from "../deviceSettings/DeviceSettings";
import { inject, observer } from "mobx-react";
// import { } from '@ant-design/icons';
import styles from './VideoPreview.module.css';
import MeetingStore from "../../../../store/MeetingStore";
import SessionStore from "../../../../store/SessionStore";


interface Props {
    meetingStore?: MeetingStore;
    sessionStore?: SessionStore; 
}

function VideoPreview({ meetingStore, sessionStore }: Props) {
    const [busy, setBusy] = useState(false);

    const joinMeeting =  async () => {
        const { authUser } = sessionStore!;
        const isBelongToThisMeeting = meetingStore?.currentMeetingModel?.participants?.find(p => p.id === authUser?.id);
        
        if(!isBelongToThisMeeting) {
            message.info(`Sorry, you're not invited to this meeting.`);
            return;
        }

        setBusy(true);
        const joined = await meetingStore?.joinMeeting();
        setBusy(false);

        if(!joined) {
            message.error('Something went wrong...');
        }
    }

    return (<div className={styles.container}>
        <div className={styles.content}>
            <div className={styles.header}>
                Looking good?
            </div>
            <DeviceSettings />
            <Button loading={busy} onClick={joinMeeting} type='primary' size='large' className={styles.joinMeetingButton}>
                Join the meeting
            </Button>
        </div>
    </div>);
}


export default inject('meetingStore', 'sessionStore')(observer(VideoPreview));