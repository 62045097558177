import React, { useState, useEffect, useRef } from "react";
import styles from './ParticipantIntroBubble.module.css';
import Video from 'twilio-video';
import { Avatar, Button, Typography } from "antd";
import { ParticipantData } from "../VideoRoom";
import { UserPublicData } from "../../../models/UserModel";
import CompanyInfo from "../../../../pages/dashboard/componnents/userDrawer/companyInfo/CompanyInfo";
import Tag from "../../tag/Tag";
const { Paragraph } = Typography;

interface Props {
    className?: string,
    userInfo: UserPublicData,
}

function ParticipantIntroBubble({ className, userInfo }: Props) {

    return (
        <div className={`${styles.container} ${className}`}>
            <div className={styles.leftSide}>
                <div className={styles.header}>You’re talking with {userInfo.firstName} 👋</div>
                <Paragraph ellipsis={{ rows: 3, expandable: true }} className={styles.bio}>
                    {userInfo.bio}
                </Paragraph>
                <div className={styles.subHeader} style={{ marginTop: 24 }}>Active Industries</div>
                <div className={styles.industryTags}>
                    {userInfo?.sectors?.map(i => <Tag className={styles.tag} key={i}>{i}</Tag>)}
                </div>
            </div>
            <div className={styles.rightSide}>
                <div className={styles.subHeader}>Company</div>
                <div className={styles.comapnyInfo}>
                    <CompanyInfo hideLearnMore={true} textColor='#E8E3FF' companyModel={userInfo.company} onCompanyClicked={() => { }} />
                </div>
            </div>
        </div>
    );
};

export default ParticipantIntroBubble;