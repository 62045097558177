import _ from "lodash";
import UserModel from "../../common/models/UserModel";
import StringUtils from "../../common/utils/StringUtils";

class BioBuilder {

    build(userModel: UserModel) {
        let bio = '';
        bio = `${this.buildBasicPart(userModel)}`;

        if(userModel.primarySectors) {
            bio = `${bio} ${this.buildMainFocusPart(userModel.primarySectors)}.`;
        }

        if(userModel.secondarySectors) {
            bio = `${bio} ${this.buildSecondaryFocusPart(userModel.secondarySectors)}.`;
        }

        if(userModel.objectives && userModel.objectives.length > 0) {
            bio = `${bio} ${this.buildObjectivesPart(userModel)}.`;
        }
        
        return bio;
    }

    private buildBasicPart(userModel: UserModel) {
        return `${userModel.firstName} is a ${userModel.title}`
    }

    private buildMainFocusPart(sectors: string[]) {
        return `and mainly focuses on ${StringUtils.listToSentence(sectors)}`
    }

    private buildSecondaryFocusPart(sectors: string[]) {
        return `More specifically, initiatives in the world of ${StringUtils.listToSentence(sectors)}`
    }

    private buildObjectivesPart(userModel: UserModel) {
        const userObjectiveTexts = _.intersectionBy(objectiveTexts, userModel.objectives || [], a => a.id);
        return `${userModel.firstName} would also like ${StringUtils.listToSentence(userObjectiveTexts.map(o => o.text))}`
    }
}

const objectiveTexts: any[] = [
    { id: 'raise-funding', text: 'to raise money for their company' },
    { id: 'invest', text: 'to invest in interesting travel startups and companies' },
    { id: 'network', text: 'to meet other interesting people in the travel industry'},
    { id: 'business-development', text: 'to expand their company by finding new business opportunities' },
    { id: 'knowledge', text: 'to gain more professional/industry knowledge' },
    { id: 'team-growth', text: `to meet talented people that potentialy would join their company` },
    { id: 'brainstorm', text: `brainstorming with other peers with similar interests`},
    { id: 'mentor', text: `to mentor and help founders and startups`},
    { id: 'explore-companies', text: `explore other disruptive travel companies` },
]

export default new BioBuilder();