import {Button, Drawer, Form, Input, Radio, Upload, Select, InputNumber, Tag, message } from 'antd';
import { inject, observer } from 'mobx-react';
import React, { useState } from 'react';
import styles from './AddCompanyDrawer.module.css';
import ViewStateStore from '../../../../store/ViewStateStore';
import { getCountries, getIndustries } from '../../../../common/utils/Utils';
import OnboardingStore from '../../../../store/OnboardingStore';
import { beforeUpload, getBase64 } from '../../../../common/utils/UploadUtils';


interface Props {
    viewStateStore?: ViewStateStore,
    onboardingStore?: OnboardingStore,
}


function AddCompanyDrawer({ viewStateStore, onboardingStore }: Props) {
    const { addCompanyDrawer,
        showAddCompanyDrawer,
        addCompanyDrawerIntialData,
        setSelectedCompany,
        addNewCompany } = onboardingStore!;
    const { isMobile } = viewStateStore!;
    const [form] = Form.useForm();
    const countries = getCountries();
    const industries = getIndustries();
    const [busy, setBusy] = useState(false);
    const [imageUrl, setImageUrl] = useState('');

    const onClose = () => {
        addCompanyDrawer(false)
    }

    const onSubmit = () => {
        form.submit();
    }

    const customUpload = async ({ onError, onSuccess, file }: any) => {
        try {
            const image = await onboardingStore?.uploadCompanyLogo(file);
            onSuccess(image, file);
        } catch (e) {
            onError(e);
        }
    };

    const normFile = (e: any) => {
        if (Array.isArray(e)) {
            return e;
        }
        return e && e.fileList;
    };


    const handleChange = (info: any) => {
        if (info.file.status === 'uploading') {
            return;
        }
        if (info.file.status === 'done') {
            getBase64(info.file.originFileObj, imageUrl => {
                setImageUrl(imageUrl);
            });
        }
    };

    const onFinish = async (values: any) => {
        try {
            setBusy(true);
            values.logo = values.logo[0]?.response;
            const company = await addNewCompany(values)
            setSelectedCompany(company);
            addCompanyDrawer(false);
        } catch (error) {
            message.error('Something went wrong while adding the company...')
        } finally {
            setBusy(false);
        }
    }

    const onInvalidForm = (errorInfo: any) => {
        message.error('Some fields were invalid or missing. Please scroll down to see the errors.')
    }

    function tagRender(props: any) {
        const { label, value, closable, onClose } = props;

        return (
            <Tag className={styles.industryTag} closable={closable} onClose={onClose} style={{ marginRight: 3 }}>
                {label}
            </Tag>
        );
    }


    return <Drawer
        className={styles.container}
        bodyStyle={{ padding: 0, lineHeight: 1.2 }}
        placement='right'
        width={isMobile ? '100%' : 660}
        onClose={onClose}
        closable={true}
        title={'Add new company'}
        visible={showAddCompanyDrawer}
        footer={
            <div
                style={{
                    textAlign: 'right',
                }}
            >
                <Button size={'large'} onClick={onClose} style={{ marginRight: 8, fontWeight: 600 }}>
                    Cancel
              </Button>
                <Button loading={busy} size={'large'} onClick={onSubmit} type="primary" style={{ fontWeight: 600 }}>
                    Add Company
              </Button>
            </div>
        }
    >
        <div className={styles.content}>
            <Form
                className='add-company'
                form={form}
                name="basic"
                onFinish={onFinish}
                onFinishFailed={onInvalidForm}
                initialValues={{ name: addCompanyDrawerIntialData?.companyName }}
                layout="vertical"
            >
                <Form.Item
                    name="logo"
                    label="Company Logo"
                    valuePropName="fileList"
                    getValueFromEvent={normFile}
                    extra='Preferably square photo (min 100x100).'
                    rules={[{ required: true, message: 'Please upload a logo' }]}>
                    <Upload
                        name="logo"
                        listType='picture-card'
                        accept="image/*"
                        onChange={handleChange}
                        customRequest={customUpload}
                        showUploadList={false}
                        multiple={false}
                        beforeUpload={(file, list) => beforeUpload(file, (errorMessage) => message.error(errorMessage))}>
                        <div>
                            {imageUrl ? <img src={imageUrl} className={styles.companyLogo} /> :
                                <div>
                                    <div className={styles.uploadText}>Upload</div>
                                </div>}
                        </div>
                    </Upload>
                </Form.Item>
                <Form.Item label='Name'
                    name='name'
                    rules={[{ required: true, message: 'Please enter the name of the company.' }]}>
                    <Input placeholder='i.e Airbnb' />
                </Form.Item>
                <Form.Item label='One-Liner'
                    name='oneliner'
                    tooltip='Describe what your company does in one or two lines.'
                    rules={[{ required: true, message: 'Please add a one-liner.' }]}>
                    <Input maxLength={140} />
                </Form.Item>
                <Form.Item label='Full Description'
                    name='fullDescription'
                    tooltip='A longer description of your company - who you are and what problem you are solving.'
                    rules={[{ required: true, message: 'Please add a longer description for the company' }]}>
                    <Input.TextArea maxLength={2000} minLength={100} style={{ minHeight: 140 }} showCount />
                </Form.Item>
                <Form.Item label='Website'
                    name='website'
                    rules={[{ required: true, type: 'url', message: `Please add a valid URL (forgot to add "https://"?)` }]}>
                    <Input placeholder='https://' />
                </Form.Item>
                <div className='doubleInputGrid'>
                    <Form.Item label='Headquarter Location'
                        name='country'
                        rules={[{ required: true, message: `Please select a country` }]}>
                        <Select size='large'
                            placeholder='Please Select...'>
                            {countries.map(c => (
                                <Select.Option key={c} value={c}>{c}</Select.Option>
                            ))}
                        </Select>
                    </Form.Item>
                    <Form.Item label='City'
                        name='city'>
                        <Input />
                    </Form.Item>
                </div>
                <div className='doubleInputGrid'>
                    <Form.Item label='Year Founded'
                        name='yearFounded'
                        rules={[{ required: false, message: `Please valid year (i.e 2012)` }]}>
                        <InputNumber max={2100} min={1800} placeholder='i.e 2020' style={{ width: '100%' }} />
                    </Form.Item>
                    <Form.Item label='Number Of Employees'
                        name='numberOfEmployees'>
                        <Select size='large'
                            placeholder='Please Select...'>
                            <Select.Option value="1-10">1-10</Select.Option>
                            <Select.Option value="11-50">11-50</Select.Option>
                            <Select.Option value="51-100">51-100</Select.Option>
                            <Select.Option value="101-250">101-250</Select.Option>
                            <Select.Option value="251-500">251-500</Select.Option>
                            <Select.Option value="501-1000">501-1000</Select.Option>
                            <Select.Option value="1001-5000">1001-5000</Select.Option>
                            <Select.Option value="5001-10000">5001-10000</Select.Option>
                            <Select.Option value="10001+">10001+</Select.Option>
                        </Select>
                    </Form.Item>
                </div>

                <Form.Item label='Industries'
                    name='industries'
                    rules={[{ required: true, message: `Please select at least one industry.` }]}>
                    <Select size='large'
                        className='industries-selector'
                        placeholder='Please Select...'
                        mode='multiple'
                        tagRender={tagRender}   >
                        {industries.map(c => (
                            <Select.Option key={c} value={c}>{c}</Select.Option>
                        ))}
                    </Select>
                </Form.Item>

                <Form.Item label="Funding Stage"
                    name="fundingStage"
                    tooltip='What’s the current funding status.'
                >
                    <Radio.Group size='large'>
                        <Radio.Button value={'boostrapping'}>Boostrapping</Radio.Button>
                        <Radio.Button value={'pre-seed'}>Pre-Seed</Radio.Button>
                        <Radio.Button value={'seed'}>Seed</Radio.Button>
                        <Radio.Button value={'series-a'}>Series A</Radio.Button>
                        <Radio.Button value={'series-b'}>Series B</Radio.Button>
                        <Radio.Button value={'series-c+'}>Series C+</Radio.Button>
                    </Radio.Group>
                </Form.Item>
                <div className='doubleInputGrid'>
                    <Form.Item label='Total Funding'
                        name='totalFunding'>
                        <InputNumber
                            placeholder='i.e 1500000'
                            formatter={value => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                            parser={(value: any) => value.replace(/\$\s?|(,*)/g, '')}
                            style={{ width: '100%' }} />
                    </Form.Item>
                    <Form.Item label='Number Of Rounds'
                        name='numberOfRounds'>
                        <Select size='large'
                            placeholder='Please Select...'>
                            <Select.Option value={1}>1</Select.Option>
                            <Select.Option value={2}>2</Select.Option>
                            <Select.Option value={3}>3</Select.Option>
                            <Select.Option value={4}>4</Select.Option>
                            <Select.Option value={5}>5</Select.Option>
                            <Select.Option value={6}>6</Select.Option>
                            <Select.Option value={7}>7</Select.Option>
                            <Select.Option value={8}>8</Select.Option>
                            <Select.Option value={9}>9</Select.Option>
                            <Select.Option value={10}>10</Select.Option>
                        </Select>
                    </Form.Item>
                </div>


            </Form>

        </div>
    </Drawer>
}



export default inject('viewStateStore', 'onboardingStore')(observer(AddCompanyDrawer));