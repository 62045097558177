import { inject, observer } from 'mobx-react';
import React, { useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import SectionHeader from '../../../common/components/sectionHeader/SectionHeader';
import UserModel from '../../../common/models/UserModel';
import AnalyticsReporter from '../../../services/analytics/AnalyticsReporter';
import MembersStore from '../../../store/MembersStore';
import SessionStore from '../../../store/SessionStore';
import ViewStateStore from '../../../store/ViewStateStore';
import UserBox from './components/userBox/UserBox';
import styles from './MembersPage.module.css'
import InfiniteScroll from 'react-infinite-scroll-component';
import Loader from '../../../common/components/loader/Loader';
import { Button } from 'antd';
import SuggestMemberModal from './components/suggestMemberModal/SuggestMemberModal';
import suggestIcon from './suggest-icon.png';
import SettingsStore from '../../../store/SettingsStore';


interface Props {
    membersStore: MembersStore;
    viewStateStore: ViewStateStore;
    analyticsReporter: AnalyticsReporter,
    settingsStore: SettingsStore;
    history: any;
    match: any;
}

function MembersPage({ membersStore, match, analyticsReporter, settingsStore }: Props) {
    const { members, viewMember, fetchMemeber, hasMoreMembers, currentViewedMember } = membersStore;
    const { memberId } = match.params;
    const [suggestMemberModalVisible, setSuggestMemberModalVisible] = useState(false);

    useEffect(() => {
        analyticsReporter.trackPage('Members Page');
    }, [])


    useEffect(() => {
        if (memberId && !currentViewedMember) {
            fetchMemeber(memberId)
        }

    }, [memberId]);

    const onMemberClicked = (userModel: UserModel) => {
        viewMember(userModel);
    }

    useEffect(() => {
        if (members.length > 0) {
            return;
        }

        membersStore.fetchMembers();
    }, []);

    const loadMore = () => {
        membersStore.fetchMembers();
    }

    return (
        <div className={styles.container} id='container' >
            <div className={styles.sectionContainer}>
                {settingsStore.isTTNL && <Button className={styles.suggestMemberButton}
                    type='default'
                    size={"large"}
                    icon={<img src={suggestIcon} className={styles.suggestIcon} />}
                    onClick={() => setSuggestMemberModalVisible(true)}>
                    Suggest a member
                </Button>
                }
                <SectionHeader title={'Members'} subTitle={'Check out other members in our community and connect with relevant people. '} />
            </div>
            <InfiniteScroll
                className={styles.membersGrid}
                dataLength={members.length}
                next={loadMore}
                hasMore={hasMoreMembers}
                scrollableTarget='container'
                loader={<Loader size={'small'} />}
            >
                {members.map(m => {
                    return <UserBox showOnline onClick={onMemberClicked} key={m.id} userModel={m} />
                })}
            </InfiniteScroll>
            {hasMoreMembers && members.length > 0 &&
                <Button onClick={loadMore} size={'large'} className={styles.showMoreButton}>
                    Show More
                </Button>
            }



            <SuggestMemberModal
                visible={suggestMemberModalVisible}
                onCancel={() => { setSuggestMemberModalVisible(false) }} />
        </div>
    )
}

export default inject('membersStore', 'viewStateStore', 'analyticsReporter', 'settingsStore')(observer(MembersPage));
