
import { Avatar, Button, Dropdown, Menu, Select, Typography } from 'antd';
import Modal from 'antd/lib/modal/Modal';
import { inject, observer } from 'mobx-react';
import moment from 'moment';
import React, { useEffect, useRef, useState } from 'react';
import Lottie from 'react-lottie';
import EventModel, { Speaker } from '../../../../../common/models/EventModel';
import SessionStore from '../../../../../store/SessionStore';
import SettingsStore from '../../../../../store/SettingsStore';
import { RegistrationStatus } from '../../../../guestConfirmation/GuestConfirmationPage';
import AddToCalendar from '../addToCalendar/AddToCalendar';
import styles from './AttentedModal.module.css';
import calendarAnimatiom from './calendar.json';
import qaImage from './faq.png'

const lottieFile = {
    loop: false,
    autoplay: true,
    animationData: calendarAnimatiom,
    rendererSettings: {
        preserveAspectRatio: 'xMidYMid slice'
    }
};

interface Props {
    visible: boolean,
    onCancel: () => void,
    onSubmitQestionClicked?: () => void,
    event?: EventModel,
    registrationStatus?: RegistrationStatus;
    sessionStore?: SessionStore;
    settingsStore?: SettingsStore;
}

function AttentedModal({ settingsStore, sessionStore, visible, onCancel, event, onSubmitQestionClicked, registrationStatus }: Props) {
    const { authUser }  = sessionStore!;
    const { terminology } = settingsStore!;

    const renderMessage = () => {
        if (registrationStatus === 'pending') {
            return <div className={styles.centered}>
                <div className={styles.header}>⏳ Waiting Approval</div>
                <div className={styles.subHeader}>
                    Thanks, {authUser?.firstName}! This {terminology?.eventTerm} requires an approval of every attendee.  
                    <br/>Our team will soon check your application and send you a confirmation email once it's approved.
                </div>
            </div>
        }

        if (event?.isQuestionsEnabled) {
            return <div className={styles.centered}>
                <div className={styles.header}>Great, you're in!</div>
                <div className={styles.subHeader}>
                    We like our meetings engaged. Have a burning question you want us to address during the meeting?
                </div>
                <div className={styles.actionButtonsContainer}>
                    <div className={styles.actionButton} onClick={onSubmitQestionClicked}>
                        <img src={qaImage} />
                        <div>Submit a question</div>
                    </div>

                </div>
                <div>

                </div>
                <div className={styles.centered} style={{ marginTop: 12 }}>
                    <div className={styles.subHeader} style={{ fontSize: 14 }}><b>P.S - </b> Add it to your calendar so you won't miss out. </div>
                    <AddToCalendar event={event}>
                        <Button size='large'>
                            Add to calendar
                        </Button>
                    </AddToCalendar>
                </div>
            </div>
        } else {
            return <div className={styles.centered}>
                <div className={styles.header}>Great, you're in!</div>
                <div className={styles.subHeader}>
                    Add it to your calendar so you won't miss out. <br/> We'll also send you a reminder before the event starts.
                </div>
                <div className={styles.calendarAnimation}>
                    <Lottie options={lottieFile} />
                </div>
                <AddToCalendar event={event}>
                    <Button size='large'>
                        Add to calendar
                    </Button>
                </AddToCalendar>
            </div>
        }
    }
    return (
        <Modal centered width={700} visible={visible} footer={null} onCancel={onCancel}>
            <div className={styles.container}>
                {renderMessage()}
            </div>
        </Modal>)

}

export default inject('settingsStore', 'sessionStore')(observer(AttentedModal));