import { inject, observer } from 'mobx-react';
import React, { useEffect, useState } from 'react';
import { Redirect, useLocation } from 'react-router-dom';
import styles from './AccountUpdatePage.module.css'
import SessionStore from '../../store/SessionStore';
import { Button } from 'antd';
import EventsStore from '../../store/EventsStore';
import EventModel from '../../common/models/EventModel';
import AddToCalendar from '../dashboard/events/components/addToCalendar/AddToCalendar';
import Logo from '../../common/components/logo/Logo';
import loadingAnimation from '../../common/images/loading.json';
import Lottie from 'react-lottie';
import AnalyticsReporter from '../../services/analytics/AnalyticsReporter';


interface Props {
    eventsStore: EventsStore;
}

function AccountUpdatePage({ }: Props) {
    const [email, setEmail] = useState('');
    const location = useLocation();
    
    useEffect(() => {
        const newEmail = new URLSearchParams(location.search).get("newEmail");
        setEmail(newEmail || '');

        setTimeout(() => {
            window.location.replace(`${window.origin}/login`);
        }, 4000);
    }, []);


    return (
        <div className={styles.container}>
            <div className={styles.content}>
                <div className={styles.header}>Your email has been updated!</div>
                <div className={styles.subHeader}>You'll be transferred in a few seconds...</div>
            </div>
        </div>
    )
}

export default AccountUpdatePage;