import React, { useEffect, useRef, useState } from "react";
import { Button, Form, message, Modal, Select } from "antd";
import styles from './EventEndedModal.module.css';
import { SoundFilled, VideoCameraFilled } from '@ant-design/icons';
import _ from "lodash";
import { inject, observer } from "mobx-react";
import EventsStore from "../../../../../../../store/EventsStore";
import SettingsStore from "../../../../../../../store/SettingsStore";
import { useHistory } from "react-router-dom";
import EventModel from "../../../../../../../common/models/EventModel";
import EventCardSpeaker from "../../../eventCards/components/speaker/EventCardSpeaker";
import SessionStore from "../../../../../../../store/SessionStore";
import TimeUtils from "../../../../../../../common/utils/TimeUtils";
import { count } from "console";

const MAX_SPEAKERS_TO_SHOW = 2;
const KICK_OUT_TIMER = TimeUtils.getSeconds(15);
interface Props {
    visible?: boolean,
    onCancel: () => void;
    eventsStore?: EventsStore;
    currentEvent?: EventModel;
    sessionStore?: SessionStore;
    settingsStore?: SettingsStore;
}

function EventEndedModal({ visible, onCancel, settingsStore, eventsStore, currentEvent, sessionStore }: Props) {
    const history = useHistory();
    const { futureEvents } = eventsStore!;
    const { authUser } = sessionStore!;
    const [kickoutTimer, setKickoutTimer] = useState<NodeJS.Timeout>();
    const [countdown, setCountdown] = useState(KICK_OUT_TIMER);

    useEffect(() => {
        let interval;

        if (visible) {
            const timer = setTimeout(() => {
                window.location.replace('/home');
            }, KICK_OUT_TIMER);

            interval = setInterval(() => {
                setCountdown(prev => prev - 1000)
            }, 1000)

            setKickoutTimer(timer);
        } else {
            clearInterval(interval);
        }
    }, [visible]);

    const getUpcomingEvents = () => {
        return _.take(futureEvents
            .filter(e => e.id !== currentEvent?.id)
            .filter(e => !e.isEnded), 2);
    }

    const onDismiss = () => {
        kickoutTimer && clearTimeout(kickoutTimer);
        setKickoutTimer(undefined);
        onCancel();
    }

    const fullCalendarButtonClicked = () => {
        history.replace('/calendar');
    }

    const onEventClicked = (event: EventModel) => {
        kickoutTimer && clearTimeout(kickoutTimer);
        history.replace(`/e/${event!.shortId}`);
    }


    return <Modal
        visible={visible}
        footer={null}
        centered
        closable={authUser?.isStaff ? true : false}
        onCancel={() => authUser?.isStaff && onDismiss()}
    >
        <div className={styles.content}>
            <div className={styles.header}>The {settingsStore?.terminology?.eventTerm} has been ended</div>
            <div className={styles.subHeader}>Thanks for coming! Please check out our upcoming {settingsStore?.terminology?.eventTerm}s:</div>
            <div className={styles.events}>
                {getUpcomingEvents().map(event => {
                    return <div onClick={() => onEventClicked(event)} key={event.id} className={styles.eventItem}>
                        <div className={styles.eventTitle}>{event.title}</div>
                        <div className={styles.eventDate}>{event.longDateString}</div>
                        {event.speakers && event.speakers.length > 0 &&

                            <div className={styles.speakersContainer}>
                                <div className={styles.speakers}>
                                    {_.take(event.speakers, 2)?.map((speaker, index) => {
                                        return <EventCardSpeaker key={index} speaker={speaker} />
                                    })}
                                    {(event.speakers.length - MAX_SPEAKERS_TO_SHOW) > 0 && <div className={styles.extraSpeakers}>+{event.speakers.length - MAX_SPEAKERS_TO_SHOW}</div>}
                                </div>
                            </div>}
                        <Button className={styles.attendButton} type='primary' onClick={() => onEventClicked(event)}>Attend →</Button>
                    </div>
                })}
            </div>
            <Button onClick={fullCalendarButtonClicked} className={styles.calendarButton} type='default'>See Full Calender</Button>
            <div className={styles.timerText}>
                You'll be transferred to home page in {countdown / 1000} seconds...
            </div>
        </div>
    </Modal>
}

export default inject('sessionStore', 'eventsStore', 'settingsStore')(observer(EventEndedModal));