import UserModel, { UserPublicData } from "./UserModel";

class IntroRequestModel {
    id?: string;
    requester?: UserPublicData;
    requested?: UserPublicData;
    reason?: IntroReason;
    purpose?: string;
    timeline?: IntroTimelineItem[];
    status?: IntroStatus;
    deniedReason?: IntroDenyReason;
    approvedByModerator?: boolean;
    source?: IntroSource;
    introducer?: UserModel;
    createdAt?: number;
    lastUpdate?: number;
    requesterTimeAvailability?: TimeAvailability[];
    requestedTimeAvailability?: TimeAvailability[];
    requestedApprovedEmailSent?: boolean;
    requestedOpenedIntro?: boolean;
    requesterOpenedIntro?: boolean;
    meetingId?: string; 

    constructor(
        id?: string,
        requester?: UserPublicData,
        requested?: UserPublicData,
        reason?: IntroReason,
        timeline?: IntroTimelineItem[],
        status?: IntroStatus,
        deniedReason?: IntroDenyReason,
        approvedByModerator?: boolean,
        source?: IntroSource,
        createdAt?: number,
        lastUpdate?: number,
        requesterTimeAvailability?: TimeAvailability[],
        requestedTimeAvailability?: TimeAvailability[],
        requestedApprovedEmailSent?: boolean,
        requestedOpenedIntro?: boolean,
        requesterOpenedIntro?: boolean,
        meetingId?: string,
        introducer?: UserModel,
        purpose?: string) {
        this.id = id;
        this.requester = requester;
        this.requested = requested;
        this.reason = reason;
        this.timeline = timeline;
        this.status = status;
        this.deniedReason = deniedReason;
        this.approvedByModerator = approvedByModerator;
        this.source = source;
        this.createdAt = createdAt;
        this.lastUpdate = lastUpdate;
        this.requesterTimeAvailability = requesterTimeAvailability;
        this.requestedTimeAvailability = requestedTimeAvailability;
        this.requestedApprovedEmailSent = requestedApprovedEmailSent;
        this.requestedOpenedIntro = requestedOpenedIntro;
        this.requesterOpenedIntro = requesterOpenedIntro;
        this.meetingId = meetingId;
        this.introducer = introducer;
        this.purpose = purpose;
    }

    static scheduledTime = (intro?: IntroRequestModel) => {
        return intro?.requesterTimeAvailability && intro.requesterTimeAvailability[0];
    }

    static mapFromServer(data: any) {
        return new IntroRequestModel(
            data.id,
            data.requester,
            data.requested,
            data.reason,
            data.timeline,
            data.status,
            data.deniedReason,
            data.approvedByModerator,
            data.source,
            data.createdAt,
            data.lastUpdate,
            data.requesterTimeAvailability,
            data.requestedTimeAvailability,
            data.requestedApprovedEmailSent,
            data.requestedOpenedIntro,
            data.requesterOpenedIntro,
            data.meetingId,
            data.introducer,
            data.purpose);
    }
}

export type TimeAvailability = number;

export type IntroTimelineItem = {
    message: string,
    createdAt: number,
    type: 'waiting' | 'denied' | 'approved' | 'regular',
}

export type IntroReason = 'casual' | 'business'

export type IntroStatus = 'pending-admin-approval' | 'admin-rejected' | 'pending-member-approval'  | 'member-accepted' | 'member-rejected' | 'expired' | 'rescheduled' | 'completed';

export type IntroDenyReason = 'not-relevant' | 'too-busy' | 'acquaintances' | 'other';

export type IntroSource = 'member' | 'manager';

export default IntroRequestModel;
