import React, { useState } from 'react';
import styles from './UserAvatar.module.css';
import noProfileImage from './no-profile.png';

interface Props {
    userImage?: string,
    companyImage?: string,
    online?: boolean,
    className?: string,
    userImageClass?: string,
    size?: 'tiny' | 'small' | 'medium' | 'large' | number;
}


function UserAvatar({ userImage, companyImage, size = 'small', online = false, className, userImageClass }: Props) {
    const getSize = () => {
        if (size === 'tiny')
            return { userPhoto: { width: 40, height: 40 }, companyPhoto: { height: 15, width: 15 }, presenceDot: { height: 10, width: 10, borderWidth: 1 } };
        if (size === 'medium')
            return { userPhoto: { width: 100, height: 100 }, companyPhoto: { height: 35, width: 35 }, presenceDot: { height: 22, width: 22, borderWidth: 3 } };
        if (size === 'large')
            return { userPhoto: { width: 100, height: 100 }, companyPhoto: { height: 35, width: 35 }, presenceDot: { height: 22, width: 22, borderWidth: 3 } };

        if (typeof size === 'number') {
            return { userPhoto: { width: size, height: size }, companyPhoto: { height: size / 2.5, width: size / 2.5 }, presenceDot: { height: 10, width: 10, borderWidth: 1 } };
        }

        return {}
    }

    return (
        <div className={`${styles.container} ${className}`}>
            {userImage && <img className={`${styles.userImage} ${userImageClass}`} src={userImage} style={getSize().userPhoto} />}
            {!userImage && <div className={`${styles.nonImage} ${userImageClass}`} style={getSize().userPhoto}>
                    <img src={noProfileImage} className={styles.noProfileImage}/>
                </div>}
            {companyImage && <img className={styles.companyImage} src={companyImage} style={getSize().companyPhoto} />}
            {online && <div className={styles.onlineDot} style={getSize().presenceDot} />}
            {online && <div className={styles.onlineDotRipple} style={getSize().presenceDot} />}

        </div>
    )
}

export default UserAvatar;