import { Layout, Menu, message } from 'antd';
import { HomeOutlined, TeamOutlined, CalendarOutlined, NodeIndexOutlined, LineChartOutlined, LockOutlined, DeploymentUnitOutlined, BookOutlined } from '@ant-design/icons';
import { inject, observer } from 'mobx-react';
import React, { useEffect } from 'react';
import { Switch, Route, Redirect, Link, NavLink } from "react-router-dom";
import SessionStore from '../../store/SessionStore';
import DiscoverPage from './discover/DiscoverPage';
import MembersPage from './members/MembersPage';
import styles from './DashboardPage.module.css';
import menuIcon from './images/menu-icon.svg';
import ViewStateStore from '../../store/ViewStateStore';
import HeaderContent from './componnents/header/HeaderContent';
import ProfilePage from './profile/ProfilePage';
import EventsPage from './events/EventsPage';
import UserDrawer from './componnents/userDrawer/UserDrawer';
import EventInfoPage from './events/EventInfoPage';
import RemoteSettingsProvider from '../../services/settings/RemoteSettingsProvider';
import CompanyPage from './company/CompanyPage';
import ControlPanel from './admin/ControlPanel';
import AboutPage from './about/AboutPage';
import MeetingRoomPage from '../meetingRoom/MeetingRoomPage';
import ConnectionsPage from './connections/ConnectionsPage';
import IntroductionsManagementPage from './introductions/IntroductionsManagementPage';
import EventFeedbackPage from './events/EventFeedbackPage';
import MetricsPage from './admin/metrics/MetricsPage';
import AccountPage from './account/AccountPage';
import PaywallModal from '../../common/components/paywallModal/PaywallModal';
import ResourcesPage from '../resources/ResourcesPage';
import ResourcePage from '../resources/ResourcePage';
import SettingsStore from '../../store/SettingsStore';
import ScrollToTop from '../../common/components/scrollToTop/ScrollToTop';
import { useRef } from 'react';

const { Header, Content, Footer, Sider } = Layout;
const siderWidth = 260;
const siderWidthCollapsed = 80;
const guestAllowedPages = ['/live-event', '/live', '/profile', '/feedback']

interface Props {
    sessionStore: SessionStore,
    viewStateStore: ViewStateStore,
    settingsStore: SettingsStore;
    remoteSettingsProvider: RemoteSettingsProvider,
    match: any,
    history: any,
    location: any
}

function DashboardPage({ match, sessionStore, viewStateStore, location, history, settingsStore }: Props) {
    const { url } = match;
    const { isMobile, siderCollapsed, setSiderCollapsed, fullPageMode, checkoutCompleted } = viewStateStore;
    const { sessionFetched, authUser, isGuest, accessPermitted, isAuthed, onboardingCompleted } = sessionStore;
    const containerRef = useRef<any>(null)

    useEffect(() => {
        if (checkoutCompleted) {
            message.success(`You're now a Premium Member! 🎉`)
        }

    }, [checkoutCompleted])

    if (sessionFetched && !authUser) {
        return <Redirect to={{ pathname: '/login', state: { from: location.pathname } }} />
    }

    if (isAuthed && !accessPermitted) {
        return <Redirect to='/waiting-list' />
    }

    if (isAuthed && !onboardingCompleted) {
        return <Redirect to='/onboarding' />
    }

    if (isGuest) {
        const guestAllowed = guestAllowedPages.find(page => location.pathname.indexOf(page) > -1)
        if (!guestAllowed) {
            return <Redirect to='/guest-confirmation' />
        }
    }

    const toggleSider = (userInitiated = false) => {
        setSiderCollapsed(!siderCollapsed, userInitiated);
    };

    const showHeader = () => {
        if (fullPageMode) {
            return false;
        }

        if (isGuest) {
            return false;
        }

        return true;
    }

    return (
        <Layout className={styles.container}>
            <Sider className={styles.sider}
                collapsible
                style={viewStateStore.hideNavigationSider() ? { display: 'none' } : {}}
                collapsed={siderCollapsed}
                width={siderWidth}
                collapsedWidth={isMobile ? 0 : siderWidthCollapsed}
                breakpoint="xxl"
                onBreakpoint={(wasBreak) => {
                    sessionFetched && setSiderCollapsed(wasBreak);
                }}
                trigger={null}
            >
                <div>
                    <div className={styles.siderHeader} style={siderCollapsed ? { justifyContent: 'center' } : {}}>
                        {!siderCollapsed && <Link to='/'><img src={settingsStore.space?.squareLogo} className={styles.siderLogo} /></Link>}
                        <img onClick={() => toggleSider(true)} src={menuIcon} className={styles.menuIcon} style={siderCollapsed ? { transform: 'rotateY(180deg)' } : {}} />
                    </div>
                    <Menu className={styles.siderMenu}
                        onSelect={() => isMobile && toggleSider(true)}
                        theme='light'
                        mode="inline"
                        selectedKeys={[location.pathname]}>
                        <Menu.Item key="/home" icon={<HomeOutlined />}>
                            <NavLink to='/home'>Home</NavLink>
                        </Menu.Item>
                        <Menu.Item key="/calendar" icon={<CalendarOutlined />}>
                            <NavLink to='/calendar' style={{ textTransform: 'capitalize' }}>{viewStateStore.eventTerm}s</NavLink>
                        </Menu.Item>
                        <Menu.Item key="/members" icon={<TeamOutlined />}>
                            <NavLink to='/members'>Members</NavLink>
                        </Menu.Item>
                        <Menu.Item key="/connections" icon={<DeploymentUnitOutlined />}>
                            <NavLink to='/connections'>My Connections</NavLink>
                        </Menu.Item>
                        <div style={{ backgroundColor: '#f2f2f2', height: 1, margin: '20px 8px' }} />

                        <Menu.Item key="/resources" icon={<BookOutlined />}>
                            <NavLink to='/resources'>Resources</NavLink>
                        </Menu.Item>
                        {authUser?.isStaff && <div style={{ backgroundColor: '#f2f2f2', height: 1, margin: '20px 8px' }} />}
                        {authUser?.isStaff &&
                            <Menu.Item key="/intro-management" icon={<NodeIndexOutlined />}>
                                <NavLink to='/intro-management'>Intro Management</NavLink>
                            </Menu.Item>
                        }
                        {authUser?.isManager &&
                            <Menu.Item key="/metrics" icon={<LineChartOutlined />}>
                                <NavLink to='/metrics'>{`Metrics`}</NavLink>
                            </Menu.Item>
                        }
                        {authUser?.isStaff &&
                            <Menu.Item key="/control-panel" icon={<LockOutlined />}>
                                <NavLink to='/control-panel'>Settings</NavLink>
                            </Menu.Item>
                        }
                    </Menu>
                </div>
            </Sider>
            <Layout className="site-layout" >
                <div ref={containerRef} />
                {showHeader() &&
                    <Header className={styles.header} style={{ left: isMobile ? 0 : siderCollapsed ? siderWidthCollapsed : siderWidth }}>
                        <HeaderContent siderCollapsed={siderCollapsed} toggleSider={toggleSider} history={history} />
                    </Header>
                }

                <Content id='dash-content' className={`${styles.content} ${fullPageMode && styles.fullPageMode}`}>
                    <ScrollToTop container={containerRef} />
                    <Switch>
                        <Route path={`/home`} component={DiscoverPage} />
                        <Route path={`/members`} component={MembersPage} />
                        <Route path={`/member/:memberId`} component={MembersPage} />
                        <Route path={`/events`} component={EventsPage} />
                        <Route path={`/event/:eventId`} component={EventInfoPage} />
                        <Route path={`/meetings`} component={EventsPage} />
                        <Route path={`/calendar`} component={EventsPage} />
                        <Route path={`/resources`} component={ResourcesPage} />
                        <Route path={`/r/:slug`} component={ResourcePage} />
                        <Route exact path={`/e/:eventId`} component={EventInfoPage} />
                        <Route path={`/e/:eventId/feedback`} component={EventFeedbackPage} />
                        <Route path={`/profile`} component={ProfilePage} />
                        <Route path={`/account`} component={AccountPage} />
                        <Route path={`/connections`} component={ConnectionsPage} />
                        <Route path={`/control-panel`} component={ControlPanel} />
                        <Route path={`/metrics`} component={MetricsPage} />
                        <Route path={`/intro-management`} component={IntroductionsManagementPage} />
                        <Route path={`/company/:companyId`} component={CompanyPage} />
                        <Route path={`/meeting/:meetingId`} component={MeetingRoomPage} />
                        <Route path={`/about`} component={AboutPage} />
                        <Route exact path={url} component={() => <Redirect to={`/home`} />} />
                        <Redirect to='/home' /> {/* 404 */}
                    </Switch>
                </Content>
            </Layout>
            <UserDrawer history={history} />
            <PaywallModal />
        </Layout >

    )
}

export default inject('sessionStore', 'viewStateStore', 'settingsStore')(observer(DashboardPage));
