
import _, { round } from 'lodash';
import { observable, makeObservable, computed, runInAction, action, makeAutoObservable } from 'mobx';
import moment from 'moment';
import AttendeeModel, { AttendeeStatus } from '../common/models/AttendeeModel';
import EventConversationModel from '../common/models/EventConversationModel';
import EventModel, { EventState, Question, Speaker } from '../common/models/EventModel';
import MessageModel from '../common/models/MessageModel';
import RoundTableModel from '../common/models/RoundTableModel';
import UserModel, { Presence, UserPublicData } from '../common/models/UserModel';
import StringUtils from '../common/utils/StringUtils';
import FirebaseClient from '../services/FirebaseClient';
import RootStore from './RootStore';
import SessionStore from './SessionStore';
export const API_KEY = 'fe6NwWbVSBG80_qX0yclVg';
const EVENT_TRANSITION_TIMEOUT = 10000;


class EventStore {
    rootStore: RootStore;
    sessionStore: SessionStore;
    firebase: FirebaseClient;
    event?: EventModel;
    eventFetched = false;
    attendees?: AttendeeModel[];
    attendeesFetched = false;
    chatMessages: MessageModel[] = [];
    roundTables: RoundTableModel[] = [];
    currentConvesationRequest?: EventConversationModel;
    userActiveConversation?: EventConversationModel;
    userCurrentRoundTable?: RoundTableModel;
    conversationVideoToken?: string;
    statusCheckerInterval?: any;
    inBackstage: boolean = false;
    inRoundTables: boolean = false;
    inLounge: boolean = false;
    showPeopleGettingInNotificaiton?: boolean;
    newChatMessagesNotificaionVisible: boolean = true;
    currentUserLocation?: EventState;
    feedbackPopupVisible: boolean = false;
    openSider: 'none' | 'people' = 'none'; 
    onNewAttendeeOnline?: (attende: AttendeeModel) => void;
    onIncomingConversation?: (conversation: EventConversationModel) => void;
    onOutgoingConversationChange?: (conversation: EventConversationModel) => void;
    onIncomingConversationChange?: (conversation: EventConversationModel) => void;
    onChatMessageRecieved?: (message: MessageModel) => void;


    constructor(rootStore: RootStore, sessionStore: SessionStore, firebase: FirebaseClient) {
        makeObservable(this, {
            event: observable,
            attendees: observable,
            onlineAttendees: computed,
            eventFetched: observable,
            attendeesFetched: observable,
            userActiveConversation: observable,
            conversationVideoToken: observable,
            showPeopleGettingInNotificaiton: observable,
            newChatMessagesNotificaionVisible: observable,
            currentConvesationRequest: observable,
            setNewMessageNotificationVisible: action,
            inBackstage: observable,
            inLounge: observable,
            inRoundTables: observable,
            roundTables: observable,
            userCurrentRoundTable: observable,
            currentUserLocation: observable,
            feedbackPopupVisible: observable,
            showFeedbackPopup: action,
            goToLounge: action,
            goToRoundTables: action,
            goToStage: action,
            chatMessages: observable,
            openSider: observable,
            isTransitioning: computed,
            currentTable: computed,
            currentAttendee: computed,
            joinRoundTable: action,
            leaveRoundTable: action,
            setOpenSider: action,
        })

        this.rootStore = rootStore;
        this.sessionStore = sessionStore;
        this.firebase = firebase;
    }

    get isTransitioning() {
        return this.event?.stateTransition ? true : false;
    }

    get currentTable() {
        return this.roundTables.find(table => table.id === this.userCurrentRoundTable?.id)
    }

    get onlineAttendees() {
        return this.attendees?.filter(u => u.presence?.state === 'online');
    }

    get currentAttendee() {
        const { authUser } = this.sessionStore;
        return this.attendees?.find(u => u.id === authUser?.id);
    }

    getTableIndex = () => {
        return this.roundTables.findIndex(table => table.id === this.userCurrentRoundTable?.id) + 1;
    }

    goToStage = () => {
        this.currentUserLocation = 'stage';
        this.openSider = 'people';
    }

    goToLounge = () => {
        this.currentUserLocation = 'lounge';
        this.openSider = 'none';
    }

    goToRoundTables = () => {
        this.currentUserLocation = 'tables';
        this.openSider = 'people';
    }

    showFeedbackPopup = (show: boolean) => {
        this.feedbackPopupVisible = show;
    }

    setOpenSider = (location: 'none' | 'people') => {
        this.openSider = location;
    }

    changeEventState = async (state: EventState, onFinish?: () => void) => {
        await this.firebase.event(this.event?.id!).update({
            stateTransition: { to: state }
        });

        setTimeout(async () => {
            runInAction(() => {
                this.showPeopleGettingInNotificaiton = true;
            })

            await this.firebase.event(this.event?.id!).update({
                state: state,
                stateTransition: null,
            });

            setTimeout(() => {
                runInAction(() => {
                    this.showPeopleGettingInNotificaiton = false;
                });
                onFinish && onFinish();

            }, 4000);
        }, EVENT_TRANSITION_TIMEOUT);
    }

    listenForEvent = (eventId: string) => {
        const unsubscribe = this.firebase.events().where('shortId', '==', eventId).onSnapshot(snap => {
            const eventDoc = snap.docs[0];
            const newEventData = EventModel.mapFromServer({ id: eventDoc.id, ...eventDoc.data() });
            runInAction(() => {
                if (!this.currentUserLocation) {
                    this.currentUserLocation = newEventData.state;
                }

                if (newEventData.state !== this.event?.state) {
                    this.currentUserLocation = newEventData.state || this.currentUserLocation;
                }

                this.event = newEventData;
                this.eventFetched = true;
            });
        });

        return unsubscribe;
    }

    listenForIncomingConvesations(eventId: string, userId: string) {
        const unsubscribe = this.firebase.eventConversations(eventId)
            .where('toUser.id', '==', userId)
            .onSnapshot(snap => {
                snap.docChanges().forEach(change => {
                    const conversation = EventConversationModel.mapFromServer({ id: change.doc.id, ...change.doc.data() });
                    if (change.type === 'added') {
                        if (conversation?.status === 'requested' && !conversation.isOldRequest) {
                            this.onIncomingConversation && this.onIncomingConversation(conversation);
                        }
                    }
                    if (change.type === 'modified') {
                        this.onIncomingConversationChange && this.onIncomingConversationChange(conversation);
                    }
                });
            });

        return unsubscribe;
    }

    listenForOutgoingConvesations(eventId: string, userId: string) {
        const unsubscribe = this.firebase.eventConversations(eventId)
            .where('byUser.id', '==', userId)
            .onSnapshot(snap => {
                snap.docChanges().forEach(change => {
                    const conversation = EventConversationModel.mapFromServer({ id: change.doc.id, ...change.doc.data() });
                    if (change.type === 'modified') {
                        this.onOutgoingConversationChange && this.onOutgoingConversationChange(conversation);
                    }
                });
            });

        return unsubscribe;
    }

    listenForRoundTables(eventId: string) {
        const unsubscribe = this.firebase.eventRoundTables(eventId)
            .orderBy('createdAt')
            .onSnapshot(snap => {
                const roundTables: RoundTableModel[] = [];
                snap.docs.map(doc => {
                    roundTables.push(RoundTableModel.mapFromServer({ id: doc.id, ...doc.data() }));
                })

                runInAction(() => {
                    this.roundTables = roundTables;
                })
            });

        return unsubscribe;
    }

    subscribeForMessages(eventId: string) {
        const recentMessagesDate = moment().subtract(30, 'minutes').toDate();
        const unsubscribe = this.firebase.eventMessages(eventId)
            // .where('createdAt', '>=', recentMessagesDate)
            .orderBy('createdAt', 'desc')
            .limit(100)
            .onSnapshot(snap => {
                const messages: MessageModel[] = [];
                snap.docs.map(doc => {
                    messages.push(MessageModel.mapFromServer({ id: doc.id, ...doc.data() }));
                });

                snap.docChanges().forEach(change => {
                    const message = MessageModel.mapFromServer({ id: change.doc.id, ...change.doc.data() })
                    if (change.type === 'added' && message.isRecent) {
                        this.onChatMessageRecieved && this.onChatMessageRecieved(message);
                        this.setNewMessageNotificationVisible(true);
                    }
                });

                runInAction(() => {
                    this.chatMessages = messages;
                });
            });

        return unsubscribe;
    }


    listenForEventAttendees = (eventId: string) => {
        const unsubscribe = this.firebase.event(eventId)
            .collection('attendees')
            //.where('presence.state', '==', 'online')
            .onSnapshot(snap => {
                const attendees: AttendeeModel[] = [];
                snap.docs.map(doc => {
                    attendees.push(AttendeeModel.mapFromServer({ id: doc.id, ...doc.data() }));
                })

                snap.docChanges().map(change => {
                    const attendeeDoc = change.doc;
                    const attendee = AttendeeModel.mapFromServer({ id: attendeeDoc.id, ...attendeeDoc.data() });

                    if (change.type === 'modified' && attendee.isOnline && attendee.notifyEntrance) {
                        this.onNewAttendeeOnline && this.onNewAttendeeOnline(attendee);
                    }
                })

                runInAction(() => {
                    const currentUserId = this.sessionStore.authUser?.id;
                    const firstTimersFirst = _.orderBy(attendees, a => a.firstTimer, 'asc');
                    const userFirst = _.orderBy(firstTimersFirst, a => a.id === currentUserId, 'desc');
                    this.attendees = userFirst;
                    this.attendeesFetched = true;
                });
            });

        return unsubscribe;
    }

    createConversation = async (byUser?: UserPublicData, toUser?: UserPublicData) => {
        const createdAt = moment().unix();
        const status = 'requested';
        const conversationRef = await this.firebase.eventConversations(this.event!.id!).add({
            byUser,
            toUser,
            status,
            createdAt,
        });

        return new EventConversationModel(conversationRef.id, byUser, toUser, status, createdAt);
    }

    acceptConversation = async (conversation: EventConversationModel) => {
        await this.firebase.eventConversation(this.event!.id!, conversation.id!).update({
            status: 'accepted'
        });
    }

    rejectConversation = async (conversation: EventConversationModel) => {
        await this.firebase.eventConversation(this.event!.id!, conversation.id!).update({
            status: 'rejected'
        });
    }

    cancelConversation = async (conversation: EventConversationModel) => {
        await this.firebase.eventConversation(this.event!.id!, conversation.id!).update({
            status: 'rejected',
            canceled: true,
        });

    }

    setActiveConversation = (conversation?: EventConversationModel) => {
        runInAction(() => {
            this.userActiveConversation = conversation;
        })
    }

    checkConversationValidity = async (conversation: EventConversationModel) => {
        const latestConversationModel = await this.firebase.getEventConversation(this.event?.id!, conversation.id!)
        if (latestConversationModel.canceled || latestConversationModel.status === 'rejected') {
            return false;
        }

        return true;
    }

    generateConversationToken = async () => {
        if (this.conversationVideoToken) {
            return this.conversationVideoToken;
        }

        const token = await this.firebase.generateVideoToken();
        this.conversationVideoToken = token;
        return token;
    }

    setAttended = async () => {
        const { authUser } = this.sessionStore;
        if (!authUser) {
            return;
        }

        if (!this.currentAttendee?.attended) {
            this.sessionStore.addActivityItem(`Attended event: ${this.event?.title}`, 'event', 'calendar');
        }

        this.firebase.eventAttendee(this.event?.id!, authUser.id!).update({
            attended: true,
            notifyEntrance: false,
        })
    }

    setAttendeePresence = async (state: string) => {
        if (!this.event) {
            return;
        }

        const { authUser } = this.sessionStore;
        if (!authUser) {
            return;
        }

        await this.firebase.event(this.event.id!)
            .collection('attendees')
            .doc(authUser?.id).update({
                presence: { state: state },
                notifyEntrance: true,
                status: 'free',
            });
    }

    setAttendeeStatus = async (status: AttendeeStatus) => {
        try {
            const { authUser } = this.sessionStore;
            await this.firebase.event(this.event!.id!)
                .collection('attendees')
                .doc(authUser!.id!).update({
                    status: status,
                    notifyEntrance: false,
                });
        } catch (error) {
            console.log(error);
        }
    }

    checkIfFirstTimer = async () => {
        const { authUser } = this.sessionStore;

        if (!authUser?.accomplishments?.attendedToEvent) {
            await this.firebase.eventAttendee(this.event?.id!, authUser?.id!).update({
                firstTimer: true
            });

            await this.sessionStore.attendedToEventAccomplished();
            return true;
        }

        return false;
    }

    sendMessage = async (message: string, mentions: string[]) => {
        const { authUser } = this.sessionStore;

        await this.firebase.addMessageToEvent(this.event?.id!, {
            message,
            mentions,
            role: authUser?.isManager ? 'host' : 'regular',
            by: authUser?.toPublicData(),
        })
    }

    setCurrentConversationRequest = (conversationRequest?: EventConversationModel) => {
        runInAction(() => {
            this.currentConvesationRequest = conversationRequest;
        });
    }

    setEventTimer = async (label: string, minutesFromNow: number) => {
        await this.firebase.setEventTimer(this.event?.id!, label, minutesFromNow);
    }

    clearEventTimer = async () => {
        await this.firebase.clearEventTimer(this.event?.id!)
    }

    enablePrivateTalks = async (enbaled: boolean) => {
        await this.firebase.event(this.event?.id!).update({
            isPrivateTalksEnabled: enbaled
        })
    }

    enableRoundTables = async (enabled: boolean) => {
        await this.firebase.event(this.event?.id!).update({
            isRoundTablesEnabled: enabled
        })

        if (enabled && this.roundTables.length === 0) {
            await this.firebase.addInitialRoundTables(this.event?.id!);
        }
    }

    enableStage = async (enabled: boolean) => {
        await this.firebase.event(this.event?.id!).update({
            isStageEnabled: enabled
        })
    }


    canAccessBackstage = () => {
        const { authUser } = this.sessionStore;

        if (authUser?.isStaff || authUser?.test) {
            return true;
        }

        return false;
    }

    setNewMessageNotificationVisible = (visible: boolean) => {
        runInAction(() => {
            this.newChatMessagesNotificaionVisible = visible;
        })
    }

    addRoundTable = async (title: string, maxSeats: number, preselectedAttendees: string[]) => {
        await this.firebase.addRoundTable(this.event!.id!, title, maxSeats, preselectedAttendees);
    }

    editRoundTable = async (id: string, title: string, maxSeats: number, preselectedAttendees: string[]) => {
        await this.firebase.updateRoundTable(id, this.event!.id!, title, maxSeats, preselectedAttendees);
    }

    deleteRoundTable = async (id: string) => {
        await this.firebase.deleteRoundTable(id, this.event!.id!);
    }

    joinRoundTable = async (roundTable: RoundTableModel) => {
        if (this.userCurrentRoundTable) {
            await this.leaveRoundTable(roundTable);
        }

        const userInfo = this.sessionStore.authUser?.toPublicData();
        const joined = await this.firebase.joinRoundTable(roundTable.id!, this.event!.id!, userInfo);
        if (joined) {
            await this.setAttendeeStatus('busy');
            runInAction(() => {
                this.userCurrentRoundTable = roundTable;
            });

            return true;
        } else {
            return false;
        }
    }

    leaveRoundTable = async (roundTable?: RoundTableModel) => {
        const userInfo = this.sessionStore.authUser?.toPublicData();
        if (roundTable) {
            await this.firebase.leaveRoundTable(roundTable.id!, this.event!.id!, userInfo);
        }

        if (this.userCurrentRoundTable) {
            await this.firebase.leaveRoundTable(this.userCurrentRoundTable.id!, this.event!.id!, userInfo);
        }

        await this.setAttendeeStatus('free');

        runInAction(() => {
            this.userCurrentRoundTable = undefined;
        })
    }

    particicapntDisconnectedFromRoundTable = async (userInfo: UserPublicData, roundTable: RoundTableModel) => {
        await this.firebase.leaveRoundTable(roundTable.id!, this.event!.id!, userInfo);
    }

    cleanRoundTables = async () => {
        await this.firebase.cleanRoundTables(this.roundTables, this.event?.id!);
    }


    attendeeStatusCheck = () => {
        this.statusCheckerInterval = setInterval(async () => {
            try {
                console.log('updating user presence...');
                await this.firebase.event(this.event!.id!)
                    .collection('attendees')
                    .doc(this.sessionStore.authUser?.id).update({
                        presence: { state: 'online' },
                        notifyEntrance: false,
                    });
            } catch (error) {
                console.warn('Update user presence error:' + error);
            }
        }, 1000 * 60); // every mintue;
    }


    reset() {
        clearInterval(this.statusCheckerInterval)
        this.currentUserLocation = undefined;
        this.onIncomingConversation = undefined;
        this.onOutgoingConversationChange = undefined;
        this.onIncomingConversationChange = undefined;
        this.onChatMessageRecieved = undefined;
        this.conversationVideoToken = '';
        this.setActiveConversation(undefined);
        this.setAttendeePresence('offline')
            .then(() => console.log('User updated to offline for event')).catch(error => {
                console.log(error);
            });

        this.event = undefined;
        this.eventFetched = false;
        this.attendees = undefined;
        this.attendeesFetched = false;
        this.chatMessages = [];
        this.roundTables = [];
        this.userActiveConversation = undefined;
        this.userCurrentRoundTable = undefined;
        this.inBackstage = false;
        this.inRoundTables = false;
        this.inLounge = false;
    }
}


export default EventStore;


