import React, { useEffect, useRef, useState } from "react";
import { inject, observer } from "mobx-react";
// import { } from '@ant-design/icons';
import styles from './MeetingFeedback.module.css';
import MeetingStore from "../../../../store/MeetingStore";
import ConnectionsStore from "../../../../store/ConnectionsStore";
import { Avatar, Button, Carousel, Form, Input, message, Rate, Tooltip } from "antd";
import { CarouselRef } from "antd/lib/carousel";
import { ConversationModel } from "../../../../common/models/UserModel";
import { useForm } from "antd/lib/form/Form";
import SessionStore from "../../../../store/SessionStore";
import _ from "lodash";


interface Props {
    meetingStore?: MeetingStore;
    connectionsStore?: ConnectionsStore;
    sessionStore?: SessionStore;
    conversation?: ConversationModel;
    onComplete?: () => void;
}

function MeetingFeedback({ meetingStore, connectionsStore, sessionStore, conversation, onComplete }: Props) {
    const { otherParticipants, currentMeetingModel } = meetingStore!;
    const carousel = useRef<CarouselRef>(null);
    const notesInputRef = useRef<any>(null);
    const [convesationModel, setConversationModel] = useState<ConversationModel>();
    const [busy, setBusy] = useState(false);
    const [form] = useForm();

    useEffect(() => {
        if (conversation) {
            setConversationModel(conversation);
            form.setFieldsValue({
                notes: conversation.notes
            })
        }
        else {
            connectionsStore?.getConversation(currentMeetingModel?.id!).then(model => {
                setConversationModel(model);
                form.setFieldsValue({
                    notes: model.notes
                })
            })
        }
    }, []);

    const getOtherParticipant = () => {
        const { authUser } = sessionStore!;
        if (convesationModel) {
            return _.first(convesationModel.participants?.filter(p => p.id !== authUser?.id));
        } else {
            return otherParticipants && otherParticipants[0];
        }
    }

    const onRateChanged = (value: number) => {
        connectionsStore?.createOrUpdateConversation(convesationModel?.id!, {
            happend: true,
            feedback: {
                score: value,
            }
        });

        carousel.current?.next();
    }

    const onDidNotMet = () => {
        connectionsStore?.createOrUpdateConversation(convesationModel?.id!, {
            happend: false,
        });

        // carousel.current?.next();
        compelted();
    }

    const onNotesSubmitted = async (values: any) => {
        setBusy(true);
        const { notes } = values;
        await connectionsStore?.saveNotes(convesationModel?.id!, notes);
        setBusy(false);
        compelted();
    }

    const compelted = () => {
        message.success(`Thanks, we're all set!`);

        if (onComplete) {
            onComplete();
        } else {
            setTimeout(() => {
                window.location.replace('/connections');
            }, 1000);
        }
    }

    const otherParticipant = getOtherParticipant();

    return (<div className={styles.container}>
        <div className={styles.content}>
            <Carousel ref={carousel} dotPosition={'bottom'} dots={false}>
                <div className={`${styles.phaseContainer} ${styles.ratePhase}`}>
                    <div>
                        <Avatar.Group
                            maxCount={4}
                            maxStyle={{ height: 54, width: 54, display: 'flex', justifyContent: 'center', alignItems: 'center', fontSize: 16, color: '#f56a00', backgroundColor: '#fde3cf' }}>
                            {currentMeetingModel?.participants?.map(participant => {
                                return <Tooltip key={participant?.id} title={`${participant.user?.company?.name}`} placement="top">
                                    <Avatar size={80} src={participant?.user?.photoUrl} />
                                </Tooltip>
                            })}
                        </Avatar.Group>
                        {otherParticipant ?
                            <div className={styles.header}>How was your meeting with {otherParticipant?.user?.firstName}?</div>
                            :
                            <div className={styles.header}>How was your meeting?</div>
                        }
                        <div className={styles.subHeader}>Your feedback is private and won't be shared with anyone.</div>

                        <div className={styles.rate}>
                            <Rate onChange={onRateChanged} allowHalf defaultValue={0} />
                        </div>

                        {!currentMeetingModel?.isFuture &&
                            <Button size='large' className={styles.didNotMetButton} type='default' onClick={onDidNotMet}>
                                We didn't meet
                        </Button>
                        }
                    </div>
                </div>
                <div className={styles.phaseContainer}>
                    <div className={styles.header}>Your meeting notes</div>
                    <div className={styles.subHeader}>Your notes are private for your own future reference.</div>
                    <Form className={styles.meetingNotesForm}
                        form={form}
                        onFinish={onNotesSubmitted}>
                        <Form.Item
                            name={'notes'}>
                            <Input.TextArea
                                ref={notesInputRef}
                                className={styles.notesTextarea}
                                placeholder='Add your notes here...'
                                rows={6} />
                        </Form.Item>
                        <Button size='large' type='primary' className={styles.notesDoneButton} htmlType={'submit'}>
                            I'm done
                        </Button>
                    </Form>
                </div>
                <div className={styles.phaseContainer}>
                    ddd
                </div>
            </Carousel>
        </div>
    </div>);
}


export default inject('meetingStore', 'connectionsStore', 'sessionStore')(observer(MeetingFeedback));