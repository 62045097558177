import { Layout, message, Modal, notification, Avatar, Card } from 'antd';
import { inject, observer } from 'mobx-react';
import React, { useEffect, useState } from 'react';
import { Link, Prompt, Redirect, Route, Switch, useHistory, useLocation } from 'react-router-dom';
import EventStore from '../../../../../store/EventStore';
import SessionStore from '../../../../../store/SessionStore';
import ViewStateStore from '../../../../../store/ViewStateStore';
import EventSider from './components/eventSider/EventSider';
import EventLounge from './components/lounge/EventLounge';
import EventStage from './components/stage/EventStage';
import styles from './LiveEventPage.module.css';
import loadingAnimation from './../../../../../common/images/loading.json';
import Lottie from 'react-lottie';
import EventTransitionNotification from './components/eventTransitionNotification/EventTransitionNotification';
import AttendeeModel from '../../../../../common/models/AttendeeModel';
import UserAvatar from '../../../../../common/components/avatar/UserAvatar';
import AddEventDrawer from '../addEventDrawer/AddEventDrawer';
import EventConversationModel from '../../../../../common/models/EventConversationModel';
import IncomingConversationNotification from './components/incomingConversation/IncomingConversationNotification';
import ConversationRequestModal from './components/conversationRequestModal/ConversationRequestModal';
import ConversationPrepareModal from './components/conversationPrepareModal/ConversationPrepareModal';
import { hideTawkChatBubble, sleepAsync } from '../../../../../common/utils/Utils';
import EventConversationRoom from './components/eventConversationRoom/EventConversationRoom';
import MessageModel from '../../../../../common/models/MessageModel';
import { CloseOutlined, StarFilled } from '@ant-design/icons';
import EventRoundTables from './components/roundTables/EventRoundTables';
import BrowserSupport from '../../../../../common/components/browserSupport/BrowserSupport';
import AnalyticsReporter from '../../../../../services/analytics/AnalyticsReporter';
import EventFeedback from '../eventFeedback/EventFeedback';
import moment from 'moment';
import TimeUtils from '../../../../../common/utils/TimeUtils';
import PaywallModal from '../../../../../common/components/paywallModal/PaywallModal';
import EventEndedModal from './components/eventEndedModal/EventEndedModal';
const { Header, Footer, Sider, Content } = Layout;

export const INCOMING_CONVERSATION_WAIT_PERIOD = 10;
const SHOW_FEEDBACK_MINUTES_BEFORE_END = 60 * 5;
// const siderWidth = 310;
const siderWidthCollapsed = 70;

interface Props {
    sessionStore: SessionStore;
    eventStore: EventStore;
    viewStateStore: ViewStateStore;
    analyticsReporter: AnalyticsReporter,
    location: any;
    history: any;
    match: any;
}


function LiveEventPage({ sessionStore, eventStore, viewStateStore, match, history, analyticsReporter }: Props) {
    const { loggedIn, authUser, sessionFetched } = sessionStore;
    const { event,
        isTransitioning,
        eventFetched,
        userActiveConversation,
        setActiveConversation,
        currentUserLocation,
        showPeopleGettingInNotificaiton,
        attendeeStatusCheck,
        canAccessBackstage,
        attendeesFetched,
        currentConvesationRequest,
        setCurrentConversationRequest,
        showFeedbackPopup,
        openSider,
        feedbackPopupVisible } = eventStore;
    const { isMobile } = viewStateStore;
    const [editEventDrawerOpen, setEditEventDrawerOpen] = useState(false);
    const [conversationPrepareModalVisible, setConversationPrepareModalVisible] = useState(false);
    const [showEventEndedModal, setShowEventEndedModal] = useState(true);
    const location = useLocation();
    const historyNew = useHistory();

    useEffect(() => {
        analyticsReporter.trackPage('Live Event Page');
    }, [])

    useEffect(() => {
        const anonymous = new URLSearchParams(location.search).get("anonymous");

        if (!sessionFetched) {
            return;
        }

        if (!loggedIn && !anonymous) {
            historyNew.push({ pathname: '/login', state: { from: location.pathname } })
            return;
        }

        if (loggedIn) {
            const { eventId } = match.params;
            viewStateStore.setSiderCollapsed(true);
            eventStore.generateConversationToken().catch(error => console.error(error));
            const unsubscribe = eventStore.listenForEvent(eventId);
            return () => unsubscribe();
        }

        if (!loggedIn && anonymous) {
            const { eventId } = match.params;
            viewStateStore.setSiderCollapsed(true);
            if (authUser) {
                eventStore.generateConversationToken().catch(error => console.error(error));
            }
            const unsubscribe = eventStore.listenForEvent(eventId);
            return () => unsubscribe();
        }

    }, [loggedIn, sessionFetched]);

    useEffect(() => {
        let unsubscribeIncomingConversations: () => void;
        let unsubscribeOutgoingConversations: () => void;
        let unsubscribeEventMessages: () => void;
        let unsubscribeRoundTables: () => void;
        let unsubscribeAttendees: () => void;

        if (eventFetched) {
            unsubscribeAttendees = eventStore!.listenForEventAttendees(event?.id!);
            unsubscribeEventMessages = eventStore.subscribeForMessages(event?.id!);
            unsubscribeRoundTables = eventStore.listenForRoundTables(event?.id!);

            if (!authUser) {
                return;
            }

            eventStore.checkIfFirstTimer().then((firstTimer) => {
                console.log('Attend to event accomplished!')
            }).catch(error => {
                console.error('first timer check error', error);
            });


            eventStore.setAttendeePresence('online')
                .then(() => console.log('User updated to online for event'))
                .catch(error => {
                    console.log(error);
                    history.replace(`/e/${event?.shortId}`);
                });

            attendeeStatusCheck();
            unsubscribeIncomingConversations = eventStore.listenForIncomingConvesations(event?.id!, authUser?.id!);
            unsubscribeOutgoingConversations = eventStore.listenForOutgoingConvesations(event?.id!, authUser?.id!);
        }

        return () => {
            unsubscribeIncomingConversations && unsubscribeIncomingConversations();
            unsubscribeOutgoingConversations && unsubscribeOutgoingConversations();
            unsubscribeEventMessages && unsubscribeEventMessages();
            unsubscribeRoundTables && unsubscribeRoundTables();
            unsubscribeAttendees && unsubscribeAttendees();

        }
    }, [eventFetched]);

    useEffect(() => {
        viewStateStore.setFullPageMode(true);
        window.addEventListener("beforeunload", onUnload);
        eventStore.onNewAttendeeOnline = onNewAttendeeOnline;
        eventStore.onIncomingConversation = onIncomingConversation;
        eventStore.onOutgoingConversationChange = onOutgoingConversationChange;
        eventStore.onIncomingConversationChange = onIncomingConversationChange;
        eventStore.onChatMessageRecieved = onChatMessageRecieved;

        return () => {
            viewStateStore.setFullPageMode(false);
            eventStore.reset();
            window.removeEventListener("beforeunload", onUnload);
        }
    }, []);

    useEffect(() => {
        const interval = setInterval(() => {
            if (!eventFetched) {
                return;
            }
            try {
                const now = moment().unix();
                if (now > event?.endDate! - SHOW_FEEDBACK_MINUTES_BEFORE_END) {
                    if (eventStore.currentAttendee && !eventStore.currentAttendee.feedbackSubmitted) {
                        showFeedbackPopup(true);
                    }

                    clearInterval(interval);
                }
            } catch (error) {
                console.error(error);
            }
        }, TimeUtils.getMintues(1));
        return () => clearInterval(interval);
    }, [eventFetched]);


    useEffect(() => {
        if (attendeesFetched) {
            eventStore.setAttended().then().catch(error => {
                console.error('Error while adding attended activity: ' + error);
            });
        }
    }, [attendeesFetched])


    const onUnload = () => {
        eventStore.setAttendeePresence('offline').then(() => console.log('User updated to offline for event'))
    }

    const renderCurrentState = () => {
        if (userActiveConversation) {
            return <EventConversationRoom />
        }

        switch (currentUserLocation) {
            case 'lounge':
                return <EventLounge />
            case 'stage':
                return <EventStage />
            case 'tables':
                return <EventRoundTables />
            default:
                return <EventLounge />
        }
    }

    const LoadingModal = () => {
        return <div className={styles.loadingContainer}>
            <div className={styles.doneAnimation}>
                <Lottie options={lottieFile} />
            </div>
            <div className={styles.loadingHeader}>Getting you in...</div>
        </div>
    }

    const onNewAttendeeOnline = (attendee: AttendeeModel) => {
        if (attendee.id === authUser?.id) {
            return;
        }

        notification.open({
            className: `user-online ${styles.userOnline}`,
            placement: 'bottomLeft',
            duration: 3,
            message: ``,
            description: <div className={styles.newAttendeeNotification}>
                <UserAvatar size='tiny' userImage={attendee.user?.photoUrl} companyImage={attendee.user?.company?.logoUrl} />
                <div>{attendee.user?.fullName} has joined the event</div>
            </div>
        })
    };


    const onIncomingConversation = (conversation: EventConversationModel) => {
        console.log('Incoming conversation request...');

        notification.open({
            key: conversation.id,
            placement: 'bottomLeft',
            closeIcon: <div> </div>,
            message: ``,
            style: { width: isMobile ? '100%' : 624 },
            duration: INCOMING_CONVERSATION_WAIT_PERIOD,
            description: <IncomingConversationNotification conversation={conversation}
                onAccept={async (conversation) => {
                    notification.close(conversation.id || '');
                    await eventStore.acceptConversation(conversation);
                }}
                onReject={async () => {
                    notification.close(conversation.id || '');
                    await eventStore.rejectConversation(conversation);
                }
                } />
        })
    }

    const onOutgoingConversationChange = (conversation: EventConversationModel) => {
        if (conversation.status === 'rejected' || conversation.canceled) {
            console.log('Conversation has already canceled. Skipping...')
            return;
        }

        if (conversation.status === 'accepted') {
            setCurrentConversationRequest(undefined);
            navigateToConversationRoom(conversation);
        }
    }

    const onIncomingConversationChange = (conversation: EventConversationModel) => {
        if (conversation.canceled) {
            notification.close(conversation.id || '');
        }

        if (conversation.status === 'accepted') {
            navigateToConversationRoom(conversation);
        }
    }

    const onConversationRequestCanceled = async (conversation?: EventConversationModel) => {
        if (!conversation) { return }

        setCurrentConversationRequest(undefined);
        await eventStore.cancelConversation(conversation)
    }

    const onFeedbackSubmitted = () => {
        setTimeout(() => {
            showFeedbackPopup(false);
        }, 2000)
    }

    const onChatMessageRecieved = (message: MessageModel) => {
        if (message.isForEveryone) {
            notification.open({
                placement: 'bottomLeft',
                duration: 10,
                closeIcon: <div></div>,
                message: ``,
                description: <div className={styles.messageNotification}>
                    <Avatar size={30} src={message.by?.photoUrl} />
                    <div className={styles.messageNotificationTexts} >
                        <div className={styles.messageNotificationTitle} >{message.by?.firstName} says:</div>
                        <div className={styles.messageNotificationMessage}>{message.message}</div>
                    </div>
                </div>
            })
        }
    }

    const navigateToConversationRoom = async (conversation: EventConversationModel) => {
        setConversationPrepareModalVisible(true);
        await sleepAsync(2000);
        const valid = await eventStore.checkConversationValidity(conversation)

        if (!valid) {
            setConversationPrepareModalVisible(false);
            message.warning('Conversation was canceled. Please try again.');
            return;
        }

        const token = await eventStore.generateConversationToken();
        if (!token) {
            setConversationPrepareModalVisible(false);
            message.warning('Conversation was canceled (token generateion)');
            console.error(`Failed to generate token for ${conversation.id}`);
            return;
        }

        await eventStore.setAttendeeStatus('busy');
        setConversationPrepareModalVisible(false);
        setActiveConversation(conversation);
    }

    if (!event) {
        return <div className={styles.container} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            {LoadingModal()}
        </div>
    }

    return (
        <Layout className={styles.container}>
            <Layout className="site-layout">
                <Content className={styles.content} >
                    {renderCurrentState()}
                </Content>
            </Layout>

            <Sider className={styles.sider}
                width={'100%'}
                collapsible={true}
                // breakpoint='xl'
                // onBreakpoint={(wasBreak) => {
                //     if (isMobile) {
                //         return;
                //     }

                //     setSiderCollapsed(wasBreak && (authUser?.isManager || false));
                // }}
                trigger={null}
                collapsed={openSider === 'none'}
                // collapsedWidth={isMobile ? 0 : 0}
                style={{padding: 0}}>
                {event && <EventSider
                    onEditEvent={() => setEditEventDrawerOpen(true)} />}
            </Sider>

            {isTransitioning && <EventTransitionNotification eventTransition={event?.stateTransition} />}
            {showPeopleGettingInNotificaiton && <EventTransitionNotification loaderMode={true} />}

            <AddEventDrawer
                event={event}
                isOpen={editEventDrawerOpen}
                onClose={() => setEditEventDrawerOpen(false)} />


            <ConversationRequestModal
                conversation={currentConvesationRequest}
                onCancel={onConversationRequestCanceled} />

            <ConversationPrepareModal visible={conversationPrepareModalVisible} />

            <BrowserSupport />

            {feedbackPopupVisible &&
                <Card className={`${styles.feedbackCard} animate__animated animate__slideInUp`}>
                    <div className={styles.hideFeedbackButton} onClick={() => showFeedbackPopup(false)} >
                        <CloseOutlined />
                    </div>
                    <EventFeedback event={event!} onFeedbackSubmitted={onFeedbackSubmitted} />
                </Card>
            }
            <PaywallModal />
            <EventEndedModal currentEvent={event} visible={event?.isEnded && showEventEndedModal} onCancel={() => setShowEventEndedModal(false)} />
        </Layout >
    )
}

const lottieFile = {
    loop: true,
    autoplay: true,
    animationData: loadingAnimation,
    rendererSettings: {
        preserveAspectRatio: 'xMidYMid slice'
    }
};

export default inject('sessionStore', 'eventStore', 'viewStateStore', 'analyticsReporter')(observer(LiveEventPage));