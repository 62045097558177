import { Alert, Avatar, Button, Form, Input, List, Modal, notification, Select, Skeleton, Tabs, Tooltip } from 'antd';
import { inject, observer } from 'mobx-react';
import React, { useEffect, useState } from 'react';
import Card from '../../../../common/components/card/Card';
import InvitationModel from '../../../../common/models/InvitationModel';
import ControlPanelStore from '../../../../store/ControlPanelStore';
import SessionStore from '../../../../store/SessionStore';
import { ControlPanelHeader } from '../ControlPanel';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import styles from './GuestInviteTab.module.css';
import moment from 'moment';
import UserModel from '../../../../common/models/UserModel';
import EventModel from '../../../../common/models/EventModel';
import EventsStore from '../../../../store/EventsStore';
import { AttendeeRole } from '../../../../common/models/AttendeeModel';
const { TabPane } = Tabs;

interface Props {
    controlPanelStore?: ControlPanelStore;
    sessionStore?: SessionStore;
    eventsStore?: EventsStore;
}

function GuestInviteTab({ controlPanelStore, sessionStore, eventsStore }: Props) {
    const { loggedIn } = sessionStore!;
    const [busy, setBusy] = useState(false);
    const [guestLinkModalVisible, setGuestLinkModalVisible] = useState(false);
    const [linkGeneratedModal, setLinkGeneratedModal] = useState(false);
    const [events, setEvents] = useState(Array<EventModel>());
    const [guestLink, setGuestLink] = useState('');
    const [form] = Form.useForm();

    const openGuestLinkModal = async () => {
        setBusy(true);
        if (events.length === 0) {
            setEvents(eventsStore?.futureEvents || []);
        }

        setBusy(false);
        setGuestLinkModalVisible(true);
    }

    const generateGuestLink = async () => {
        await form.validateFields().then(async values => {
            const { eventShortId, guestRole } = values;
            const event = events.find(e => e.shortId === eventShortId)?.toPublicData();
            const invitationLink = await controlPanelStore?.generateGuestInvitationLink(event!, guestRole, false);
            setGuestLink(invitationLink || '');
            setGuestLinkModalVisible(false);
            setLinkGeneratedModal(true);
        }).catch(error => {
            console.log('Validation failed:', error);
        });
    }

    const onLinkGeneratedModalClosed = () => {
        setLinkGeneratedModal(false);
        setGuestLink('');
    }


    const onCopyLinkClicked = () => {
        notification.success({
            message: 'Link Copied!',
            placement: 'bottomRight'
        })
    }

    return (
        <Card className={styles.container}>
            <ControlPanelHeader title={'Guest Link'} />
            <div>Create a new invitation link to invite guests to one of our events.</div>
            <Button loading={busy} onClick={openGuestLinkModal} className={styles.generateButton} type='primary' size={'large'}>Generate Guest Link</Button>
            <Modal title={'Generate link for event'} visible={guestLinkModalVisible}
                okText="Generate"
                cancelText="Cancel"
                confirmLoading={busy}
                onCancel={() => setGuestLinkModalVisible(false)}
                onOk={generateGuestLink}>
                <Form
                    form={form}
                    layout="vertical"
                    className='guest-invite-modal'
                    name="guestInviteModal">
                    <Form.Item
                        name="eventShortId"
                        label="To which event you want to invite?"
                        rules={[{ required: true, message: 'Please select an event' }]}>
                        <Select size='large' placeholder='Choose Event'>
                            {events.map(event => <Select.Option key={event.shortId} value={event.shortId!}>{event.title}</Select.Option>)}
                        </Select>
                    </Form.Item>
                    <Form.Item
                        name="guestRole"
                        label="What is the role of the guest?"
                        rules={[{ required: true, message: 'Please select a role' }]}>
                        <Select size='large' placeholder='Choose role' defaultValue='attendee'>
                            <Select.Option value={'attendee'}>Attendee</Select.Option>
                            <Select.Option value={'speaker'}>Speaker</Select.Option>
                        </Select>
                    </Form.Item>
                    <Alert message={<div style={{ fontSize: 12 }}>
                        <div><b>Attendee -</b> regular guest with no special capabilites</div>
                        <div><b>Speaker -</b> Speakers can go to the back stage during the event.</div>
                    </div>} type="info" />
                </Form>
            </Modal>
            <Modal footer={null} visible={linkGeneratedModal} onCancel={onLinkGeneratedModalClosed}>
                <div className={styles.linkGeneratedModal}>
                    <div className={styles.header}>Here you go! 🤟</div>
                    <div className={styles.subHeader}>share this link with the attendee:</div>
                    <Input className={styles.input} size={'large'} value={guestLink} />
                    <CopyToClipboard text={guestLink}>
                        <Button onClick={onCopyLinkClicked}>Copy Link</Button>
                    </CopyToClipboard>
                </div>
            </Modal>
        </Card>
    )
}

export default inject('controlPanelStore', 'sessionStore', 'eventsStore')(observer(GuestInviteTab));