import { Avatar, Button, Form, Input, List, Modal, notification, Popconfirm, Skeleton, Switch, Tabs, Tag, Tooltip } from 'antd';
import { inject, observer } from 'mobx-react';
import React, { useEffect, useState } from 'react';
import Card from '../../../../common/components/card/Card';
import InvitationModel from '../../../../common/models/InvitationModel';
import ControlPanelStore from '../../../../store/ControlPanelStore';
import SessionStore from '../../../../store/SessionStore';
import { ControlPanelHeader } from '../ControlPanel';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import styles from './AmbassadorTab.module.css';
import moment from 'moment';
import UserModel from '../../../../common/models/UserModel';
import { useForm } from 'antd/lib/form/Form';
import SettingsStore from '../../../../store/SettingsStore';
const { TabPane } = Tabs;

interface Props {
    controlPanelStore?: ControlPanelStore;
    sessionStore?: SessionStore;
    settingsStore?: SettingsStore;
}

function AmbassadorTab({ controlPanelStore, sessionStore, settingsStore }: Props) {
    const { generateInvitationLink, fetchInvitationLinks, deleteInvitation, fetchUserReferrals } = controlPanelStore!;
    const { loggedIn } = sessionStore!;
    const [busy, setBusy] = useState(false);
    const [links, setLinks] = useState(Array<InvitationModel>());
    const [referredUsers, setReferredUsers] = useState(Array<UserModel>());
    const [linkModalVisible, setLinkModalVisible] = useState(false);
    const [form] = useForm();
    

    useEffect(() => {
        if (loggedIn) {
            fetchInvitationLinks().then(invitations => {
                setLinks(invitations);
            });

            fetchUserReferrals().then(users => {
                setReferredUsers(users);
            });
        }
    }, [loggedIn]);

    const buildLink = (id?: string) => {
        return `${settingsStore?.space?.websiteUrl}/invitation/${id}`;
    }

    const generateNewLink = async () => {
        setBusy(true);
        const values = await form.validateFields();
        const { trial } = values;
        await generateInvitationLink({ noTrial: !trial });
        setLinks(await fetchInvitationLinks());
        form.resetFields();
        setBusy(false);
        setLinkModalVisible(false);
    }

    const onCopyLinkClicked = () => {
        notification.success({
            message: 'Link Copied!',
            placement: 'bottomRight'
        })
    }

    const onDelete = async (invitationId: string) => {
        setBusy(true);
        await deleteInvitation(invitationId);
        setLinks(await fetchInvitationLinks());
        setBusy(false);
    }

    return (
        <Card className={styles.container}>
            <ControlPanelHeader title={'Invitation Link'} />
            <div>Create a new invitation link to invite new members.</div>
            <Button loading={busy} onClick={() => setLinkModalVisible(true)} className={styles.generateButton} type='primary' size={'large'}>Generate New Link</Button>
            <div className={styles.yourLinksContainer}>
                <ControlPanelHeader size='small' title={'Your Links'} />
                <List
                    itemLayout="horizontal"
                    dataSource={links}
                    locale={{ emptyText: 'No linkes were generated yet' }}
                    renderItem={item => (
                        <List.Item
                            actions={[
                                <CopyToClipboard key='copy' text={`${buildLink(item.id)}`}>
                                    <a onClick={onCopyLinkClicked} style={{ fontWeight: 600 }}>Copy Link</a>
                                </CopyToClipboard>,
                                <Popconfirm title={'Are you sure?'} onConfirm={() => onDelete(item.id!)}>
                                    <Button type={'link'} danger style={{padding: 0}}> Delete    </Button>
                                </Popconfirm>
                            ]}
                        >
                            <a href={`${buildLink(item.id)}`} target='_blank' className={styles.link}>{buildLink(item.id)}</a>
                            {settingsStore?.membership?.enabled &&
                                <div>{item.membership?.noTrial ?
                                    <Tag color='volcano'>NO TRIAL</Tag>
                                    :
                                    <Tag color='purple'>TRIAL</Tag>
                                }
                                </div>}
                        </List.Item>
                    )}
                />

            </div>
            <div className={styles.referrals}>
                <ControlPanelHeader size='small' title={'Your Referrals'} />
                <List
                    itemLayout="horizontal"
                    locale={{ emptyText: `You haven't referred any user yet...` }}

                    dataSource={referredUsers}
                    renderItem={user => (
                        <List.Item>
                            <List.Item.Meta
                                avatar={<Avatar src={user.photoUrl} />}
                                title={user.fullName}
                                description={user.title}
                            />
                        </List.Item>
                    )}
                />

            </div>
            <Modal
                visible={linkModalVisible}
                okText='Generate'
                onOk={generateNewLink}
                confirmLoading={busy}
                onCancel={() => setLinkModalVisible(false)}
                className={styles.linkModal}>
                <div className={styles.linkModalContnet}>
                    <div className={styles.subHeader}>Options</div>
                    <Form
                        form={form}
                        layout="vertical"
                        name="form_in_modal"
                        initialValues={ { trial: true }}
                    >
                        <Form.Item label="Allow Trial?"
                            tooltip={'Set whether trial credits will be applied for members with this invite'}
                            name='trial'
                            valuePropName='checked'>
                            <Switch disabled={!settingsStore?.membership?.enabled} defaultChecked={true} />
                        </Form.Item>
                    </Form>

                </div>

            </Modal>
        </Card>


    )
}

export default inject('controlPanelStore', 'sessionStore', 'settingsStore')(observer(AmbassadorTab));