import { inject, observer } from 'mobx-react';
import React, { useEffect, useState } from 'react';
import { Redirect, useLocation, useRouteMatch } from 'react-router-dom';
import styles from './CheckoutPage.module.css'
import SessionStore from '../../store/SessionStore';
import EventsStore from '../../store/EventsStore';
import EventModel from '../../common/models/EventModel';
import Logo from '../../common/components/logo/Logo';
import loadingAnimation from '../../common/images/loading.json';
import AnalyticsReporter from '../../services/analytics/AnalyticsReporter';
import { Button } from 'antd';
import MembersStore from '../../store/MembersStore';
import UserModel from '../../common/models/UserModel';
import Loader from '../../common/components/loader/Loader';
import { isRunningDebug } from '../../common/utils/Utils';
import SettingsStore from '../../store/SettingsStore';

const yearlyMembershipUrl = 'https://store.payproglobal.com/checkout?products[1][id]=66449&currency=USD';
const sixMonthMembershipUrl = 'https://store.payproglobal.com/checkout?products[1][id]=66766&currency=USD';

interface Props {
    sessionStore?: SessionStore;
    membersStore?: MembersStore;
    settingsStore?: SettingsStore;
    analyticsReporter?: AnalyticsReporter;
    selectedPlan?: string;
    external?: boolean;
    onChangePlan?: () => void;
}

function CheckoutPage({ sessionStore, analyticsReporter,membersStore, external = true, selectedPlan, onChangePlan, settingsStore }: Props) {
    const { authUser } = sessionStore!;
    const location = useLocation();
    const [plan, setPlan] = useState('');
    const [loader, showLoader] = useState(true);
    const [user, setUser] = useState<UserModel | undefined>(authUser || undefined);

    useEffect(() => {
        if (selectedPlan) {
            setPlan(selectedPlan)
        }
    }, [selectedPlan])

    useEffect(() => {
        analyticsReporter?.trackPage('Checkout Page');
        const searchParams = new URLSearchParams(location.search);
        const p = searchParams.get('p');
        const u = searchParams.get('u');
        p && setPlan(p);
        if(u) {
            membersStore?.fetchById(u).then(result => {
                setUser(result);
            })
        }
    }, []);

    const getPlanUrl = () => {
        let extras = `billing-email=${user?.email}&x-userid=${user?.id}&exfo=742`
        if(isRunningDebug()) {
            extras = extras + `&use-test-mode=true&secret-key=d4NzhDtCSe`
        }

        let url = ''
        if(plan === 'Yearly-Membership') {
            url = yearlyMembershipUrl;
        }
        if(plan === '6-Month-Membership') {
            url = sixMonthMembershipUrl;
        }

        return url + `&${extras}`;
    }


    const shareLink = () => {
        const url = `${window.location.protocol}//${window.location.host}/checkout?p=${plan}&u=${authUser?.id}`;
        const body = `Membership payment page: %0D%0A${encodeURIComponent(url)}`
        window.open(`mailto:?subject=${settingsStore?.space?.name} Membership&body=${body}`, '_blank');

        sessionStore?.addActivityItem(`Checkout Link Shared`, 'payment', 'money');
    }


    return (
        <div className={styles.container}>
            <div className={styles.content} style={external ? {} : { padding: 0 }}>
                {external && <div className={styles.header}>Premium Membership</div>}
                {user && external && <div className={styles.payingUser}>Paying for <span className={styles.userName}>{user?.fullName}</span></div> }
                <div className={styles.description}>Selected plan: <span className={styles.planName}>{plan}</span>{!external && <span className={styles.changePlan} onClick={onChangePlan}>Change</span>}</div>
                {!external &&
                    <div className={styles.shareContainer}>
                        <div className={styles.shareHeader}>
                            <span>Need someone else to pay for this? </span><span  onClick={shareLink} className={styles.shareLink}>Email this link</span>
                            </div>
                    </div>
                }
                
                <div className={styles.iframeWrapper}>
                    { user && <iframe onLoad={() => showLoader(false)} className={styles.iframe} src={getPlanUrl()} /> }
                    <Loader visible={loader} size='medium' className={styles.loader} />
                </div>
                <div className={styles.smallLetters}>Your membership will auto-renew when expired - we’ll send you an email reminder before we make another charge</div>
                {external &&
                    <Logo className={styles.logo} linkTo='/' />
                }
            </div>
        </div>
    )
}

export default inject('sessionStore', 'viewStateStore', 'analyticsReporter', 'membersStore', 'settingsStore')(observer(CheckoutPage));