import { AutoComplete, Button, Form, Input, Tag, Upload, message } from 'antd';
import _ from 'lodash';
import { inject, observer } from 'mobx-react';
import React, { useEffect, useState } from 'react';
import OnboardingStore, { Sector } from '../../../../store/OnboardingStore';
import NavigationButtons from '../navigationButtons/NavigationButtons';
import StepWrapper from '../stepWrapper/StepWrapper';
import styles from './SectorsSecondaryStep.module.css';

const { Option } = AutoComplete;
const MAX_SELECTED = 3;

interface Props {
    onNext: () => void,
    onBack: () => void,
    onboardingStore?: OnboardingStore,
}


function SectorsSecondaryStep({ onboardingStore, onNext, onBack }: Props) {
    const { subSectors, selectedSubSectors, onSectorsSecondarySubmit } = onboardingStore!;
    const [selectedTags, setSelctedTags] = useState<string[]>(selectedSubSectors || []);
    const [isBusy, setIsBusy] = useState(false);

    useEffect(() => {
        if (subSectors?.length === 0) {
            onNext();
        }
    }, [subSectors])

    const handleChange = (tag: string, checked: boolean) => {
        if (checked) {
            setSelctedTags([...selectedTags, tag]);
        } else {
            setSelctedTags(selectedTags.filter(t => t !== tag));
        }
    }

    const submitSectors = async () => {
        setIsBusy(true);

        setTimeout(async () => {
            await onSectorsSecondarySubmit(selectedTags);
            setIsBusy(false);
            onNext();
        }, 1000);
    }

    return <StepWrapper title={`Great, what else?`}
        subTitle={`Tell us more specifically what you're into.`}>
        <div className={`sectors ${styles.content}`}>
            <div className={styles.sectorsContainer}>
                {_.uniq([...subSectors || [], 'Other'])?.map(tag => <Tag.CheckableTag
                    key={tag}
                    onChange={checked => handleChange(tag, checked)}
                    checked={selectedTags.indexOf(tag) > -1}>
                    {tag}
                </Tag.CheckableTag>
                )}
            </div>

        </div>

        <NavigationButtons buttonTitle={'Next'}
            onNext={submitSectors}
            isBusy={isBusy}
            showBack={true}
            onBack={onBack} />

    </StepWrapper>
}


export default inject('onboardingStore')(observer(SectorsSecondaryStep));